import { Component, OnInit } from '@angular/core';  
import { HttpClient, HttpErrorResponse } from '@angular/common/http'; 
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router'; 
import { cronogramaPagoTo } from 'src/app/model/UserModel/cronogramaPagoTo';
import { ModalService } from 'src/app/configuration/providers/modal';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { userTo } from 'src/app/model/UserModel/userTo';
import { func } from 'src/app/constants/func';
import { URL } from 'src/app/constants/url';
import { responseCronogramaTo } from 'src/app/model/UserModel/responseCronogramaTo';
import { monthTypeMaping } from 'src/app/constants/monthTypeMaping';
import { detalleMesCronogramaTo } from 'src/app/model/UserModel/detalleMesCronogramaTo';



@Component({
  selector: 'ark-cronograma',
  templateUrl: './cronograma.component.html',
  styleUrls: ['./cronograma.component.css'] 
})
export class CronogramaComponent implements OnInit {

objUserTo = null; 

lastYear  = 0;
year      = 0;
month     = 0;
nextYear  = 0;

listMonths = null;

claseCont           = false; 
claseSubCont        = false; 

listDetailCronoBD : Array<cronogramaPagoTo> = [] as Array<cronogramaPagoTo>;


constructor(private httpClient: HttpClient, 
            private modalService: ModalService,
            private loginService: LoginService, 
            private router: Router,
            private  spinner: NgxSpinnerService) { }  


  ngOnInit() { 
      this.objUserTo  = <userTo>this.loginService.currentUser(); 
      

      this.year  = func.currentYear();
      this.month = func.currentMonth();   
      this.lastYear = this.year - 1 ;
      this.nextYear = this.year + 1 ; 
      this.getPaymentSchedule(this.year); 

  } 
  
  gotoPage(page: String){
    this.router.navigate([page]);
  }
 
  calculateNextYear(yearPrm: number){
    this.year = yearPrm;
    this.lastYear = this.year - 1 ;
    this.nextYear = this.year + 1 ;

    this.getScheduleByYear(this.year);
  }

  calculateBeforeYear(yearPrm: number){
    this.year = yearPrm;
    this.lastYear = this.year - 1 ;
    this.nextYear = this.year + 1 ;

    this.getScheduleByYear(this.year);
  }


  getPaymentSchedule(yearPrm: number){ 

      this.spinner.show();
      this.httpClient.get(URL.SERVICE_GET_PAYMENT_SCHEDULE + this.objUserTo.rut,  
              { headers: func.WSHeader() })
            . subscribe(
            (response) => { 

              console.log("datos" + JSON.stringify(response));
              let cronograma = <responseCronogramaTo>response;  
              this.listDetailCronoBD = cronograma.items; 

              this.getScheduleByYear(yearPrm); 

            },
            (err: HttpErrorResponse) => {
                console.log(err.error);
                console.log(err.name);
                console.log(err.message);
                console.log(err.status); 

              this.spinner.hide();
              return ;
          },
          () => {
            this.spinner.hide();
          console.log("************* this.httpClient.post ()::" + "The observable is now completed.");
          }
      ); 

      console.log("la lista es :: "+JSON.stringify(this.listMonths)); 
  }


  getScheduleByYear(yearPrm: number){
    this.spinner.show();
    this.listMonths = monthTypeMaping.getListMonts(); 
    var mapaMesesDetalle = monthTypeMaping.getListMontsMap(); 
    let detail : Array<detalleMesCronogramaTo> = [] as Array<detalleMesCronogramaTo>;    

    if(this.listDetailCronoBD.length > 0){
        for(var x = 0; x < this.listDetailCronoBD.length; x++){ 
            for(var i = 0; i < this.listMonths.length; i++){ 
              // logica para pintar el cuadro del mes actual
              let mesRecorriendo = this.listMonths[i].month_code; 
              this.listMonths[i].current_month  = false;  
              if(mesRecorriendo == this.month){
                  this.listMonths[i].current_month  = true; 
              } 
              // fin de logica para pintar el cuadro del mes actual
              console.log("fecha de pago :: " + this.listDetailCronoBD[x].payment_date);
              let anhoCronograma = func.getYearFromDate(this.listDetailCronoBD[x].payment_date); 
              
              if(yearPrm == parseInt(anhoCronograma)){ 
                let mesCronograma = func.getMonthFromDate(this.listDetailCronoBD[x].payment_date);   
                if(mesRecorriendo == parseInt(mesCronograma)){ // 1 - 12
    
                    this.listMonths[i].hasList = true;
                    let pagosMes = mapaMesesDetalle.get(mesRecorriendo); // obtengo la lista de pagos del mes
                    let lista = this.listDetailCronoBD[x]
                    pagosMes.push(lista);
                    mapaMesesDetalle.set(mesRecorriendo, pagosMes);
                    break;  
                } 
              } 
                  
          }
        }

        // poniendo en los meses sus detalles de pagos
        for(var i = 0; i < this.listMonths.length; i++){    
            this.listMonths[i].listDetailCrono = mapaMesesDetalle.get(this.listMonths[i].month_code);
        } 
    }
    this.spinner.hide();
  }



}