import { Component, OnInit, Input, ViewChild } from '@angular/core';  
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http'; 
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from 'src/app/configuration/providers/modal';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { userTo } from 'src/app/model/UserModel/userTo';
import { URL } from 'src/app/constants/url';
import { func } from 'src/app/constants/func';
import { estadoCuentaTo } from 'src/app/model/UserModel/estadoCuentaTo';
import { responseEstadoCuentaTo } from 'src/app/model/UserModel/responseEstadoCuentaTo';
import { monthTypeMaping } from 'src/app/constants/monthTypeMaping';
import { FormControl, Validators } from '@angular/forms';  
import { parameterTableTo } from 'src/app/model/UserModel/parameterTableTo';
import { Router } from '@angular/router';
import { SimplemodalComponent } from '../../AppComponents/simplemodal/simplemodal.component';


@Component({
  selector: 'ark-report-estado-cuenta',
  templateUrl: './report-estado-cuenta.component.html',
  styleUrls: ['./report-estado-cuenta.component.css']
})
export class ReportEstadoCuentaComponent implements OnInit {
 
  @ViewChild(SimplemodalComponent,{static: false}) simplemodalComponent: SimplemodalComponent;
  listEstadoCuenta : Array<estadoCuentaTo> = [] as Array<estadoCuentaTo>; 
  objUserTo  = null; 
  listMonths = null;

  year = new FormControl('');
  month = new FormControl('');
  rut = new FormControl('');

  paramYear:Array<parameterTableTo> = [] as Array<parameterTableTo>;
  paramRut:Array<parameterTableTo> = [] as Array<parameterTableTo>;

  meses = new Map(); 
  hasStatement = false;

  constructor(private modalService: ModalService, 
              private httpClient: HttpClient,
              private loginService: LoginService,
              private router: Router,
              private  spinner: NgxSpinnerService) { }

  ngOnInit() { 
     this.objUserTo = <userTo>this.loginService.currentUser(); 
     //this.listMonths =  monthTypeMaping.getListMonts();

     var arrayRut: [] = this.objUserTo.rut_code.split(',');
     for(var i = 0 ; i< arrayRut.length; i++){
        let parametro = {} as parameterTableTo;
        parametro.parameter_table_cd = arrayRut[i]; 
        this.paramRut.push(parametro);

        this.rut.setValue(arrayRut[i]);
     }

     var d = new Date();
     var n = d.getFullYear();
     var m = d.getMonth();
     var newYear = 0;

     this.year.setValue(n);
     this.month.setValue(m);

      n = n ;
      //Condicion Enero
      if(m==0){
       newYear=n-1;
      }else{
        newYear= n;
      }

      if(newYear==2022){
        this.listMonths = [];
        this.listMonths = monthTypeMaping.getMontDecember();
      }else if (newYear!=n) {
        this.listMonths = [];
      }else{
        this.listMonths = [];
        for (var i=0; i<=m-1;i++){
         this.listMonths.push(monthTypeMaping.getListMonts()[i]);
        }
      }
      

      for(var i = 2022 ; i <=newYear ; i++) {
        let parametro = {} as parameterTableTo;
        parametro.parameter_table_pk = i; 
        this.paramYear.push(parametro);
      }
      let periodo = this.year.value;
      this.loadDocumentos(periodo);
      this.hasStatement = false;
  }

  gotoPage(page: String){
    this.router.navigate([page]); 
  }

  onChange(paramYear){

    var actual = new Date();
    var yearActual = actual.getFullYear();
    var monthActual = actual.getMonth();

    if(Number(paramYear.currentTarget.options[paramYear.currentTarget.options.selectedIndex].text)==2022){
      this.listMonths = monthTypeMaping.getMontDecember();
    }else if (Number(paramYear.currentTarget.options[paramYear.currentTarget.options.selectedIndex].text)==yearActual){
      this.month.setValue(monthActual);
      this.listMonths = [];
      for (var i=0; i<=monthActual-1;i++){
        this.listMonths.push(monthTypeMaping.getListMonts()[i]);
      }
    }else{
      this.listMonths = [];
      this.listMonths = monthTypeMaping.getListMonts();
    }
  }

  reloadStatement(){
    if(this.year.value==2022){
      this.month.setValue(12);
    }
    console.log(this.year.value+'/'+this.month.value);
    let path = this.year.value+'/'+this.month.value; 
    let rut = this.rut.value;
    

    var body = {
      "path":  path,
      "account_number":  rut
    }

    this.spinner.show();  
    
    this.httpClient.post(URL.SERVICE_API_V1_CORE_POST_PDF_RUT, body,       
          { headers: func.WSHeaderTokenCore() ,  responseType: 'blob'  } )
        . subscribe(
        (response) => {    

          var downloadURL = window.URL.createObjectURL(response);
          var link = document.createElement('a');
          link.href = downloadURL;
          link.download = rut+".pdf";
          link.click();    
        },
        (err: HttpErrorResponse) => {
            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);  
            this.spinner.hide();
            this.simplemodalComponent.alertar("Mensaje de Alerta","No esta disponible el Estado de Cuenta Seleccionado");
            return ;
        },
        () => {
          this.spinner.hide();
        }
    ); 

  }

  
  loadDocumentos(periodo :String){   

    this.listEstadoCuenta = [] as Array<estadoCuentaTo>;  
    this.spinner.show();  
    let rutaService = '';
    this.hasStatement = false;

    if(periodo == ''){
      rutaService = URL.SERVICE_API_V1_USER + this.objUserTo.id_user_pk +"/"+null+
                          URL.ACCOUNT_STATEMENTS_END ;
    }else{
      rutaService = URL.SERVICE_API_V1_USER + this.objUserTo.id_user_pk +"/"+periodo+
                          URL.ACCOUNT_STATEMENTS_END;
    }

    this.httpClient.get(rutaService,
            {  headers: func.WSHeader() })
        . subscribe(
              (response) => {  
                  console.log(" response :: " + JSON.stringify(response) ); 
                  
                  let responseBd = <responseEstadoCuentaTo>response;  
                  // this.listEstadoCuenta = responseBd.items;

                  if(responseBd.items.length > 0){
                      this.hasStatement = true;

                      for(var i=0 ; i < responseBd.items.length ; i++){
                        let detalle : estadoCuentaTo;
                        detalle = {} as estadoCuentaTo ; 
                        detalle = responseBd.items[i];  
                        let menBD = func.getMonthFromDate(responseBd.items[i].period);
                        let anho  = func.getYearFromDate(responseBd.items[i].period);

                        this.loadMapMonthEmptyStatement(responseBd.items[i].period);
                        this.meses.set(menBD, detalle); 
                        
                        if(this.month.value != ''){  
                          this.listEstadoCuenta.push(detalle); 
                        }   
                      } 
                      if(this.month.value == ''){
                        for (var [clave, valor] of this.meses.entries()) {
                          this.listEstadoCuenta.push(valor);
                        }
                      }   
                  } 
              },
              (err: HttpErrorResponse) => {
                console.log(err.error);
                console.log(err.name);      
                console.log(err.message);
                console.log(err.status); 
                this.spinner.hide();  
            },
            () => {
              this.spinner.hide();
              console.log("************* this.httpClient.post ()::" + "The get estado de cuenta now completed.");
            }
      ); 
  }
  /*
  reloadStatement(){
    if(this.month.value == ''){
      let periodo = this.year.value; 
      this.loadDocumentos(periodo);
    }else{
      let mes = this.month.value;
      if(mes<10){
        mes = "0"+mes; 
      }
      let periodo = this.year.value+''+mes; 
      this.loadDocumentos(periodo);
    }
  }
  */

  loadMapMonthEmptyStatement(period: String){ 
    console.log(period);
    let detalle : estadoCuentaTo;
    detalle = {} as estadoCuentaTo ;
    detalle.period = (period.substr(0,5)+"01"+period.substr(7,period.length));
    detalle.file_code = '';
    this.meses.set("01", detalle);
    detalle = {} as estadoCuentaTo ;
    detalle.period = (period.substr(0,5)+"02"+period.substr(7,period.length));
    detalle.file_code = '';
    this.meses.set("02", detalle);
    detalle = {} as estadoCuentaTo ;
    detalle.period = (period.substr(0,5)+"03"+period.substr(7,period.length));
    detalle.file_code = '';
    this.meses.set("03", detalle); 
    detalle = {} as estadoCuentaTo ;
    detalle.period = (period.substr(0,5)+"04"+period.substr(7,period.length));
    detalle.file_code = '';
    this.meses.set("04", detalle); 
    detalle = {} as estadoCuentaTo ;
    detalle.period = (period.substr(0,5)+"05"+period.substr(7,period.length));
    detalle.file_code = '';
    this.meses.set("05", detalle); 
    detalle = {} as estadoCuentaTo ;
    detalle.period = (period.substr(0,5)+"06"+period.substr(7,period.length));
    detalle.file_code = '';
    this.meses.set("06", detalle); 
    detalle = {} as estadoCuentaTo ;
    detalle.period = (period.substr(0,5)+"07"+period.substr(7,period.length));
    detalle.file_code = '';
    this.meses.set("07", detalle); 
    detalle = {} as estadoCuentaTo ;
    detalle.period = (period.substr(0,5)+"08"+period.substr(7,period.length));
    detalle.file_code = '';
    this.meses.set("08", detalle); 
    detalle = {} as estadoCuentaTo ;
    detalle.period = (period.substr(0,5)+"09"+period.substr(7,period.length));
    detalle.file_code = '';
    this.meses.set("09", detalle); 
    detalle = {} as estadoCuentaTo ;
    detalle.period = (period.substr(0,5)+"10"+period.substr(7,period.length));
    detalle.file_code = '';
    this.meses.set("10", detalle); 
    detalle = {} as estadoCuentaTo ;
    detalle.period = (period.substr(0,5)+"11"+period.substr(7,period.length));
    detalle.file_code = '';
    this.meses.set("11", detalle); 
    detalle = {} as estadoCuentaTo ;
    detalle.period = (period.substr(0,5)+"12"+period.substr(7,period.length));
    detalle.file_code = '';
    this.meses.set("12", detalle); 
  }


  /***********/
  public closeModal(id: string) {
    this.modalService.close(id);
  }

  openTerminos(){
    //this.modalService.open('idTerminos');  
    this.simplemodalComponent.openModalInfo();
  }
/*
  closeTerminos(file_code: String){ 
    this.modalService.close('idTerminos');
  }
  */

}
