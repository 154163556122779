import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';  
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { userTo } from 'src/app/model/UserModel/userTo';
import { URL } from 'src/app/constants/url';
import { func } from 'src/app/constants/func';
import { InvestorRequest } from 'src/app/model/UserModel/InvestorRequest';
import { errorMaping } from 'src/app/constants/errorMaping';
import { SimplemodalComponent } from '../simplemodal/simplemodal.component';
import { TourmodalComponent } from '../tourmodal/tourmodal.component';
import { ModalService } from 'src/app/configuration/providers/modal';
//import { ModalService } from 'src/app/configuration/providers/modal';


@Component({
  selector: 'ark-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
}) 

export class HomeComponent implements OnInit { 

 
  constructor(private httpClient: HttpClient,
              private loginService: LoginService,
              private modalService: ModalService,
              private  spinner: NgxSpinnerService, 
              private router: Router/*,
              private modalService: ModalService*/) { }
 
  fullname:String = "";

  @ViewChild(SimplemodalComponent,{static: false}) simplemodalComponent: SimplemodalComponent;
  userTo:userTo = this.loginService.currentUser();
  @ViewChild(TourmodalComponent,{static: false})                tourmodalComponent  : TourmodalComponent;


  ngOnInit() {
    //this.router.navigate(['/servers'],{relativeTo:this.router});
    //this.router.navigate(['/home']);
    this.spinner.show();
/*
    var flagRefresh = sessionStorage.getItem('refreshPage');
    if(flagRefresh == "1" || flagRefresh == null || flagRefresh == 'undefined'){
      sessionStorage.setItem("refreshPage","0");
      window.location.href = ENVIRONMENT.HOST_FRONTEND_APPCRES+'/home';//http://localhost:4200 //http://simvest.s3-website-us-west-2.amazonaws.com
    }
*/
    this.getFicha();
    this.getUserAccount();
    this.spinner.hide();
  }

  getUserAccount(){
    
    //console.log("Inicio el userAccount");
    let objUserTo = <userTo>this.loginService.currentUser();
    this.fullname = objUserTo.name;


    this.spinner.show();
    this.httpClient.get(URL.SERVICE_API_V1_USER + objUserTo.id_user_pk + 
                        URL.ERVICE_LOAD_INVERSIONES_USER_ACCOUNT,
          { headers: func.WSHeader() })
        . subscribe(
        (response) => {  

          //console.log("response: \n"+JSON.stringify(response));
          objUserTo.investor_request = response as InvestorRequest;
          sessionStorage.setItem('loginUser', JSON.stringify(objUserTo));

        },
        (err: HttpErrorResponse) => {
            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);
            
            // this.mensaje = "Ocurrio un error mis inversiones \n " + err.message;
            // this.modalService.open('idModalErrorfull');
          this.spinner.hide();
          return ;
      },
      () => {
        this.spinner.hide();
        //console.log("************* this.httpClient.post ()::" + "The observable is now completed.");
      }
    );
  }

  getFicha() {

    let objUserTo = <userTo>this.loginService.currentUser();

    
    this.httpClient.get(URL.SERVICE_GET_CLIENT_REQUEST+'/'+objUserTo.id_user_pk+'/investor', { headers: func.WSHeader() })
            . subscribe(
          (response) => {  

              //console.log("response: \n"+JSON.stringify(response));

              objUserTo.investor_request = response as InvestorRequest;
              sessionStorage.setItem('loginUser', JSON.stringify(objUserTo));
              
          },
          (err: HttpErrorResponse) => {
            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);

            //console.log("response: \n"+JSON.stringify(objUserTo));
            objUserTo.investor_request = null;
            sessionStorage.setItem('loginUser', JSON.stringify(objUserTo));

            if(err.status == errorMaping.CODE_400) {
              this.spinner.hide();  return;
            }else {
              this.spinner.hide();  return;
            }
      },
      () => {
        this.spinner.hide();
        //console.log("************* this.httpClient.post ()::" + "The registrarSuscripcion POST observable is now completed.");
      }
    );
 
  }


  gotoPage(page: String){
    this.router.navigate([page]);
  } 
  
  openModalHome(){
    this.tourmodalComponent.openModal('idModalHome');  
  }

  
  openModal(page1){
    this.modalService.open(page1);  
  }

  openCloseModal(page1,page2){
    this.modalService.close(page1);
    this.modalService.open(page2);  
  }

  closeModal(page){ 
    this.modalService.close(page);
  }
  
  openTerminos(){
    //this.modalService.open('idTerminos');  
    this.simplemodalComponent.openModalInfo();
  }
/*
  closeTerminos(file_code: String){ 
    this.modalService.close('idTerminos');
  }*/
  

}
 