import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { step5 } from 'src/app/model/UserModel/step5';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { func } from 'src/app/constants/func';
import { ParameterService } from 'src/app/services/ParameterServices/parameter.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PARAMETER_TABLE_YES_NO } from 'src/app/constants/PARAMETER_TABLE_YES_NO';
import { countryMaping } from 'src/app/constants/countryMaping';
import { InvReqPersonReturn } from 'src/app/model/UserModel/InvReqPersonReturn';
import { parameterTableTo } from 'src/app/model/UserModel/parameterTableTo';
import { MASTER_TABLE } from 'src/app/constants/masterTable';

@Component({
  selector: 'ark-datosorigenfondos',
  templateUrl: './datosorigenfondos.component.html',
  styleUrls: ['./datosorigenfondos.component.css']
})
export class DatosorigenfondosComponent implements OnInit {

  @Input()  inputData:step5   	= {} as step5;
  @Output() outputDataEvent     = new EventEmitter<step5>();
  @Output() alertEvent        	= new EventEmitter<String>();
  @Output() modalEvent          = new EventEmitter<String>();
  
  formDatosOrigenFondos: FormGroup; //formulario
  average_monthly_income_usd_mask = new FormControl('', Validators.required);
  submitted_datosOrigenFondos:boolean = false;
  otroOrigenFondo:boolean = false;
  
  currentData:step5   = {} as step5;
  
  constructor(private formBuilder: FormBuilder, private  spinner: NgxSpinnerService,private parameterService: ParameterService) { }

  ngOnInit() {
    this.initVariables();
    this.loadParameters();
    this.setDefaultData();
    this.initForm();
    this.initActionsPostForm();
  }

  initVariables(){
    this.currentData                              = this.inputData;
    if(this.currentData.entity_InvReqPerson == null ){
      this.currentData.entity_InvReqPerson        = {} as InvReqPersonReturn;
    }
  }
  
  async loadParameters(){

    if(this.currentData.paramIncomeComesFrom == null){
      this.currentData.paramIncomeComesFrom = await this.parameterService.getParameter(MASTER_TABLE.INCOME_COMES_FROM) as Array<parameterTableTo>;   
    }
    
    if(this.currentData.paramCountry == null){
      this.currentData.paramCountry = await this.parameterService.getGeograficCountry() as Array<parameterTableTo>;
    }
    
    if(this.currentData.paramPatrimonialAmount == null){
      this.currentData.paramPatrimonialAmount = await this.parameterService.getParameter(MASTER_TABLE.PATRIMONIAL_AMOUNT) as Array<parameterTableTo>; 
    }

  }

  setDefaultData(){
    if(this.currentData.action == null || this.currentData.action == 'R'){
      
    }else{//M

    }
  }

  nullObject(object){
    return (object == null)?'':object;
  }

  initForm(){
    
    this.formDatosOrigenFondos = this.formBuilder.group({
      income_comes_from:                              [this.nullObject(this.currentData.entity_InvReqPerson.incomeComesFrom), Validators.required],
      country1_where_income_comes_fr:                 [this.nullObject(this.currentData.entity_InvReqPerson.country1WhereIncomeComesFr) ],
      country2_where_income_comes_fr:                 [this.nullObject(this.currentData.entity_InvReqPerson.country2WhereIncomeComesFr) ],
      country3_where_income_comes_fr:                 [this.nullObject(this.currentData.entity_InvReqPerson.country3WhereIncomeComesFr) ],
      funds_source_str:                               [this.nullObject(this.currentData.entity_InvReqPerson.fundsSourceStr), Validators.required], //parseInt(this.entity_InvReqPerson.funds_source_str+"")
      funds_source_others:                            [this.nullObject(this.currentData.entity_InvReqPerson.fundsSourceOthers)], 
      average_monthly_income_usd:                     [this.nullObject(this.currentData.entity_InvReqPerson.averageMonthlyIncomeUsd), Validators.required], 
      total_amount_assets_usd:                        [this.nullObject(this.currentData.entity_InvReqPerson.totalAmountAssetsUsd), Validators.required], 
      has_own_house:                                  [this.nullObject(this.currentData.entity_InvReqPerson.hasOwnHouse), Validators.required], 
      has_car:                                        [this.nullObject(this.currentData.entity_InvReqPerson.hasCar), Validators.required], 
      has_other_properties:                           [this.nullObject(this.currentData.entity_InvReqPerson.hasOtherProperties), Validators.required], 
      physical_securities_custody: 	                  [this.nullObject(this.currentData.entity_InvReqPerson.physicalSecuritiesCustody), Validators.required], 
    });

    if( this.formDatosOrigenFondos.controls.average_monthly_income_usd.value != null &&
        this.formDatosOrigenFondos.controls.average_monthly_income_usd.value != "" ){
      this.average_monthly_income_usd_mask.setValue(func.inputOnliDecimal(this.formDatosOrigenFondos.controls.average_monthly_income_usd.value));
    }
    
  }

  initActionsPostForm(){
    this.loadFuentesOrigenesFondoInvertir();
    this.loadFormatNumber();
    
    if(this.currentData.action == null || this.currentData.action == 'M' && !this.currentData.enabledForm){
      this.disabled_inputs_registrar_ficha()
    }

  }
  
  disabled_inputs_registrar_ficha(){
    eval('$(function(){$("#newInvestorDiv :input").prop("disabled", true); });');
    eval('$(function(){$(".navigateButton").prop("disabled", false); });');
  }
  
  enabled_inputs_registrar_ficha(){
    this.currentData.enabledForm = true;
    eval('$(function(){$("#newInvestorDiv :input").prop("disabled", false); });');
  }

  saveSetp5(){
    this.submitted_datosOrigenFondos = true;
    if(this.onValidation_datosOrigenFondos()){
      if(this.formDatosOrigenFondos.value.income_comes_from != 281 && this.formDatosOrigenFondos.value.country1_where_income_comes_fr == null){
        this.alertar("Debe seleccionar como minimo un país de origen de ingreso.");
        return;
      }
      this.setDataSetp5();
      this.outputDataEvent.emit(this.inputData);
    }else{
      this.alertar("Completar los datos obligatorios. Verifique por favor.");
    }
    
  }

  setDataSetp5(){
    this.setDataOrigenFondos();
    this.inputData = this.currentData;
  }

  alertar(mensaje:String){
    this.alertEvent.emit(mensaje);
    return;
  }

  openModal(mensaje:String)
  {
    this.modalEvent.emit(mensaje);
    return;
  }

  
  /*
      form functions
  */

  onValidation_datosOrigenFondos(){
    if(this.formDatosOrigenFondos.invalid){
      return false;
    }
    return true;
  }

  changeNationalityOrigenFondos(nationality:number){

    if(nationality == 1){

      if(this.formDatosOrigenFondos.controls.country1_where_income_comes_fr.value == this.localCountry ){
        this.formDatosOrigenFondos.controls.country1_where_income_comes_fr.setValue('');
        return;
      }

      
      if(this.formDatosOrigenFondos.controls.country1_where_income_comes_fr.value == null || 
         this.formDatosOrigenFondos.controls.country1_where_income_comes_fr.value == '' ){
            this.formDatosOrigenFondos.controls.country2_where_income_comes_fr.setValue('');
            this.formDatosOrigenFondos.controls.country3_where_income_comes_fr.setValue('');
      }

      if(this.formDatosOrigenFondos.value.country2_where_income_comes_fr != null && 
        this.formDatosOrigenFondos.value.country2_where_income_comes_fr != "" && 
        (this.formDatosOrigenFondos.value.country2_where_income_comes_fr == this.formDatosOrigenFondos.value.country1_where_income_comes_fr ) 
        ){
        this.formDatosOrigenFondos.controls.country2_where_income_comes_fr.setValue('');
      }

      if(this.formDatosOrigenFondos.value.country3_where_income_comes_fr != null && 
        this.formDatosOrigenFondos.value.country3_where_income_comes_fr != "" && 
        (this.formDatosOrigenFondos.value.country3_where_income_comes_fr == this.formDatosOrigenFondos.value.country1_where_income_comes_fr )
      ){
        this.formDatosOrigenFondos.controls.country3_where_income_comes_fr.setValue('');
      }

    }else if(nationality == 2){

      if(this.formDatosOrigenFondos.controls.country2_where_income_comes_fr.value == this.localCountry ){
        this.formDatosOrigenFondos.controls.country2_where_income_comes_fr.setValue("");
        return;
      }

      if(this.formDatosOrigenFondos.value.country1_where_income_comes_fr != null && 
        this.formDatosOrigenFondos.value.country1_where_income_comes_fr != "" && 
        (this.formDatosOrigenFondos.value.country1_where_income_comes_fr == this.formDatosOrigenFondos.value.country2_where_income_comes_fr )
      ){
        this.formDatosOrigenFondos.controls.country2_where_income_comes_fr.setValue("");
      }

      if(this.formDatosOrigenFondos.value.country3_where_income_comes_fr != null && 
        this.formDatosOrigenFondos.value.country3_where_income_comes_fr != "" && 
        (this.formDatosOrigenFondos.value.country3_where_income_comes_fr == this.formDatosOrigenFondos.value.country2_where_income_comes_fr )
      ){
        this.formDatosOrigenFondos.controls.country3_where_income_comes_fr.setValue("");
      }

    } else if(nationality == 3){

      if(this.formDatosOrigenFondos.controls.country3_where_income_comes_fr.value == this.localCountry ){
        this.formDatosOrigenFondos.controls.country3_where_income_comes_fr.setValue("");
        return;
      }

      if(this.formDatosOrigenFondos.value.country1_where_income_comes_fr != null && 
        this.formDatosOrigenFondos.value.country1_where_income_comes_fr != "" && 
        (this.formDatosOrigenFondos.value.country1_where_income_comes_fr == this.formDatosOrigenFondos.value.country3_where_income_comes_fr )
      ){
        this.formDatosOrigenFondos.controls.country3_where_income_comes_fr.setValue("");
      }

      if(this.formDatosOrigenFondos.value.country2_where_income_comes_fr != null && 
        this.formDatosOrigenFondos.value.country2_where_income_comes_fr != "" && 
        (this.formDatosOrigenFondos.value.country2_where_income_comes_fr == this.formDatosOrigenFondos.value.country3_where_income_comes_fr )
      ){
        this.formDatosOrigenFondos.controls.country3_where_income_comes_fr.setValue("");
      }

    }

  }

  /**
   
  changeNationalityOrigenFondos(nationality:number){

    if(nationality == 1){

      if(this.formDatosOrigenFondos.controls.country1_where_income_comes_fr.value == this.localCountry ){
        this.formDatosOrigenFondos.controls.country1_where_income_comes_fr.setValue('');
        return;
      }

      
      if(this.formDatosOrigenFondos.controls.country1_where_income_comes_fr.value == null || 
         this.formDatosOrigenFondos.controls.country1_where_income_comes_fr.value == '' ){
            this.formDatosOrigenFondos.controls.country2_where_income_comes_fr.setValue('');
            this.formDatosOrigenFondos.controls.country3_where_income_comes_fr.setValue('');
      }

      if(this.formDatosOrigenFondos.value.country2_where_income_comes_fr != null && 
        this.formDatosOrigenFondos.value.country2_where_income_comes_fr != "null" && 
        (this.formDatosOrigenFondos.value.country2_where_income_comes_fr == this.formDatosOrigenFondos.value.country1_where_income_comes_fr ) 
        ){
        this.formDatosOrigenFondos.controls.country1_where_income_comes_fr.setValue('');
      }

      if(this.formDatosOrigenFondos.value.country3_where_income_comes_fr != null && 
        this.formDatosOrigenFondos.value.country3_where_income_comes_fr != "null" && 
        (this.formDatosOrigenFondos.value.country3_where_income_comes_fr == this.formDatosOrigenFondos.value.country1_where_income_comes_fr )
      ){
        this.formDatosOrigenFondos.controls.country1_where_income_comes_fr.setValue(null);
      }

    }else if(nationality == 2){

      if(this.formDatosOrigenFondos.controls.country2_where_income_comes_fr.value == this.localCountry ){
        this.formDatosOrigenFondos.controls.country2_where_income_comes_fr.setValue(null);
        return;
      }

      if(this.formDatosOrigenFondos.value.country1_where_income_comes_fr != null && 
        this.formDatosOrigenFondos.value.country1_where_income_comes_fr != "null" && 
        (this.formDatosOrigenFondos.value.country1_where_income_comes_fr == this.formDatosOrigenFondos.value.country2_where_income_comes_fr )
      ){
        this.formDatosOrigenFondos.controls.country2_where_income_comes_fr.setValue(null);
      }

      if(this.formDatosOrigenFondos.value.country3_where_income_comes_fr != null && 
        this.formDatosOrigenFondos.value.country3_where_income_comes_fr != "null" && 
        (this.formDatosOrigenFondos.value.country3_where_income_comes_fr == this.formDatosOrigenFondos.value.country2_where_income_comes_fr )
      ){
        this.formDatosOrigenFondos.controls.country1_where_income_comes_fr.setValue(null);
      }

    } else if(nationality == 3){

      if(this.formDatosOrigenFondos.controls.country3_where_income_comes_fr.value == this.localCountry ){
        this.formDatosOrigenFondos.controls.country3_where_income_comes_fr.setValue(null);
        return;
      }

      if(this.formDatosOrigenFondos.value.country1_where_income_comes_fr != null && 
        this.formDatosOrigenFondos.value.country1_where_income_comes_fr != "null" && 
        (this.formDatosOrigenFondos.value.country1_where_income_comes_fr == this.formDatosOrigenFondos.value.country3_where_income_comes_fr )
      ){
        this.formDatosOrigenFondos.controls.country3_where_income_comes_fr.setValue(null);
      }

      if(this.formDatosOrigenFondos.value.country2_where_income_comes_fr != null && 
        this.formDatosOrigenFondos.value.country2_where_income_comes_fr != "null" && 
        (this.formDatosOrigenFondos.value.country2_where_income_comes_fr == this.formDatosOrigenFondos.value.country3_where_income_comes_fr )
      ){
        this.formDatosOrigenFondos.controls.country3_where_income_comes_fr.setValue(null);
      }

    }

  }

   */
  setDataOrigenFondos(){

    this.currentData.entity_InvReqPerson.incomeComesFrom                = this.formDatosOrigenFondos.value.income_comes_from;
    this.currentData.entity_InvReqPerson.country1WhereIncomeComesFr     = this.formDatosOrigenFondos.value.country1_where_income_comes_fr;
    this.currentData.entity_InvReqPerson.country2WhereIncomeComesFr     = this.formDatosOrigenFondos.value.country2_where_income_comes_fr;
    this.currentData.entity_InvReqPerson.country3WhereIncomeComesFr     = this.formDatosOrigenFondos.value.country3_where_income_comes_fr;
    this.currentData.entity_InvReqPerson.fundsSourceStr                 = this.formDatosOrigenFondos.value.funds_source_str;
    this.currentData.entity_InvReqPerson.fundsSourceOthers              = this.formDatosOrigenFondos.value.funds_source_others; 
    //aca dar los formatos//
    this.currentData.entity_InvReqPerson.averageMonthlyIncomeUsd        = this.formDatosOrigenFondos.value.average_monthly_income_usd;
    this.currentData.entity_InvReqPerson.totalAmountAssetsUsd           = this.formDatosOrigenFondos.value.total_amount_assets_usd;
    //fin de los formatos
    this.currentData.entity_InvReqPerson.hasOwnHouse                    = this.formDatosOrigenFondos.value.has_own_house; 
    this.currentData.entity_InvReqPerson.hasCar                         = this.formDatosOrigenFondos.value.has_car;
    this.currentData.entity_InvReqPerson.hasOtherProperties             = this.formDatosOrigenFondos.value.has_other_properties;
    this.currentData.entity_InvReqPerson.physicalSecuritiesCustody      = this.formDatosOrigenFondos.value.physical_securities_custody;
    //this.entity_InvReqPerson.valid_form_datos_origenfondos   = this.submitted_datosOrigenFondos;
  }

  get form_datosOrigenFondos() { return this.formDatosOrigenFondos.controls; }
  
  origenDatos(){

    this.formDatosOrigenFondos.controls.country2_where_income_comes_fr.setValue(null);
    this.formDatosOrigenFondos.controls.country3_where_income_comes_fr.setValue(null);
    if(this.formDatosOrigenFondos.controls.income_comes_from.value == 283){
        this.formDatosOrigenFondos.controls.country1_where_income_comes_fr.setValue(this.localCountry);
        
        //AMBOS
        this.formDatosOrigenFondos.controls['country1_where_income_comes_fr'].setValidators([Validators.required]);
        this.formDatosOrigenFondos.controls["country1_where_income_comes_fr"].updateValueAndValidity();
        this.formDatosOrigenFondos.controls['country2_where_income_comes_fr'].setValidators([Validators.required]);
        this.formDatosOrigenFondos.controls["country2_where_income_comes_fr"].updateValueAndValidity();
        this.formDatosOrigenFondos.controls["country3_where_income_comes_fr"].clearValidators();
        this.formDatosOrigenFondos.controls["country3_where_income_comes_fr"].updateValueAndValidity();
    } else{// if (this.formDatosOrigenFondos.controls.income_comes_from.value == 282 || this.formDatosOrigenFondos.controls.income_comes_from.value == 281) 
        //282 en el extranjero - 281 en el peru
        this.formDatosOrigenFondos.controls.country1_where_income_comes_fr.setValue(null);

        if(this.formDatosOrigenFondos.controls.income_comes_from.value == 282){
          //EN EL EXTRANJERO
          this.formDatosOrigenFondos.controls['country1_where_income_comes_fr'].setValidators([Validators.required]);
          this.formDatosOrigenFondos.controls["country1_where_income_comes_fr"].updateValueAndValidity();
          this.formDatosOrigenFondos.controls['country2_where_income_comes_fr'].clearValidators();
          this.formDatosOrigenFondos.controls["country2_where_income_comes_fr"].updateValueAndValidity();
          this.formDatosOrigenFondos.controls["country3_where_income_comes_fr"].clearValidators();
          this.formDatosOrigenFondos.controls["country3_where_income_comes_fr"].updateValueAndValidity();
        }else{// if(this.formDatosOrigenFondos.controls.income_comes_from.value == 281) or null
          //EN EL PERU
          this.formDatosOrigenFondos.controls['country1_where_income_comes_fr'].clearValidators();
          this.formDatosOrigenFondos.controls["country1_where_income_comes_fr"].updateValueAndValidity();
          this.formDatosOrigenFondos.controls['country2_where_income_comes_fr'].clearValidators();
          this.formDatosOrigenFondos.controls["country2_where_income_comes_fr"].updateValueAndValidity();
          this.formDatosOrigenFondos.controls["country3_where_income_comes_fr"].clearValidators();
          this.formDatosOrigenFondos.controls["country3_where_income_comes_fr"].updateValueAndValidity();
        }
    }/*else{
          this.formDatosOrigenFondos.controls['country1_where_income_comes_fr'].clearValidators();
          this.formDatosOrigenFondos.controls["country1_where_income_comes_fr"].updateValueAndValidity();
          this.formDatosOrigenFondos.controls['country2_where_income_comes_fr'].clearValidators();
          this.formDatosOrigenFondos.controls["country2_where_income_comes_fr"].updateValueAndValidity();
          this.formDatosOrigenFondos.controls["country3_where_income_comes_fr"].clearValidators();
          this.formDatosOrigenFondos.controls["country3_where_income_comes_fr"].updateValueAndValidity();
    }*/
  }
 
  setCharAt(str,index,chr) {
    if(index > str.length-1) return str;
    return str.substr(0,index) + chr + str.substr(index+1);
  }

  otroFuentesOrigenesFondoInvertir(){

    this.otroOrigenFondo = !this.otroOrigenFondo;

    if(this.otroOrigenFondo){      
      var miFunction = " $(function() { ";
      miFunction += " $('input[name=origenFondo-1]').prop('checked', 'checked'); ";
      miFunction += "});"
      eval(miFunction); 
    }else{
      var miFunction = " $(function() { ";
      miFunction += " $('input[name=origenFondo-1]').prop('checked', ''); ";
      miFunction += "});"
      eval(miFunction); 
      this.form_datosOrigenFondos.funds_source_others.setValue(null);
    }

  }

  loadFuentesOrigenesFondoInvertir(){

    if(this.form_datosOrigenFondos.funds_source_others.value != null && this.form_datosOrigenFondos.funds_source_others.value != ""){
      this.otroFuentesOrigenesFondoInvertir();
    }

    var miFunction = "$(function() { ";
    
    if(this.formDatosOrigenFondos.controls.funds_source_str.value != null){
      var initString = this.formDatosOrigenFondos.controls.funds_source_str.value;
      
      var pos0 =  initString.charAt(0);
      var pos1 =  initString.charAt(1);
      var pos2 =  initString.charAt(2);
      var pos3 =  initString.charAt(3);
      var pos4 =  initString.charAt(4);
      var pos5 =  initString.charAt(5);
      var pos6 =  initString.charAt(6);
      var pos7 =  initString.charAt(7);
      var pos8 =  initString.charAt(8);
      var pos9 =  initString.charAt(9);

      if(pos0=="1"){
        miFunction += "$(function() { $('input[name=origenFondo0]').prop('checked', 'checked'); });";
      }
      if(pos1=="1"){
        miFunction += "$(function() { $('input[name=origenFondo1]').prop('checked', 'checked'); });";
      }
      if(pos2=="1"){
        miFunction += "$(function() { $('input[name=origenFondo2]').prop('checked', 'checked'); });";
      }
      if(pos3=="1"){
        miFunction += "$(function() { $('input[name=origenFondo3]').prop('checked', 'checked'); });";
      }
      if(pos4=="1"){
        miFunction += "$(function() { $('input[name=origenFondo4]').prop('checked', 'checked'); });";
      }
      if(pos5=="1"){
        miFunction += "$(function() { $('input[name=origenFondo5]').prop('checked', 'checked'); });";
      }
      if(pos6=="1"){
        miFunction += "$(function() { $('input[name=origenFondo6]').prop('checked', 'checked'); });";
      }
      if(pos7=="1"){
        miFunction += "$(function() { $('input[name=origenFondo7]').prop('checked', 'checked'); });";
      }
      if(pos8=="1"){
        miFunction += "$(function() { $('input[name=origenFondo8]').prop('checked', 'checked'); });";
      }
      if(pos9=="1"){
        miFunction += "$(function() { $('input[name=origenFondo9]').prop('checked', 'checked'); });";
      }

    }
    
    miFunction += "});";
    eval(miFunction);
  }

  fuentesOrigenesFondoInvertir(posicion:number){

      var initString = "0000000000";
      if(this.formDatosOrigenFondos.controls.funds_source_str.value == null || 
          this.formDatosOrigenFondos.controls.funds_source_str.value == 'undefined' || 
          this.formDatosOrigenFondos.controls.funds_source_str.value == 'Nan'){
        this.formDatosOrigenFondos.controls.funds_source_str.setValue("0000000000");
      }else{
        initString = this.formDatosOrigenFondos.controls.funds_source_str.value;
      }
      
      var miFunction = "";
      
      var pos0 =  (posicion!=null && posicion==0)?((initString.charAt(0)=="0")?"1": "0"):initString.charAt(0);
      var pos1 =  (posicion!=null && posicion==1)?((initString.charAt(1)=="0")?"1": "0"):initString.charAt(1);
      var pos2 =  (posicion!=null && posicion==2)?((initString.charAt(2)=="0")?"1": "0"):initString.charAt(2);
      var pos3 =  (posicion!=null && posicion==3)?((initString.charAt(3)=="0")?"1": "0"):initString.charAt(3);
      var pos4 =  (posicion!=null && posicion==4)?((initString.charAt(4)=="0")?"1": "0"):initString.charAt(4);
      var pos5 =  (posicion!=null && posicion==5)?((initString.charAt(5)=="0")?"1": "0"):initString.charAt(5);
      var pos6 =  (posicion!=null && posicion==6)?((initString.charAt(6)=="0")?"1": "0"):initString.charAt(6);
      var pos7 =  (posicion!=null && posicion==7)?((initString.charAt(7)=="0")?"1": "0"):initString.charAt(7);
      var pos8 =  (posicion!=null && posicion==8)?((initString.charAt(8)=="0")?"1": "0"):initString.charAt(8);
      var pos9 =  (posicion!=null && posicion==9)?((initString.charAt(9)=="0")?"1": "0"):initString.charAt(9);

      this.formDatosOrigenFondos.controls.funds_source_str.setValue(pos0+pos1+pos2+pos3+pos4+pos5+pos6+pos7+pos8+pos9);


      if(posicion!=null && posicion==9){
        if(pos9=="1"){
          miFunction = "$(function() { $('input[name=origenFondo9]').prop('checked', 'checked'); });";
          eval(miFunction);
        }else{
          miFunction = "$(function() { $('input[name=origenFondo9]').prop('checked', ''); });";
          eval(miFunction);
        }
      }

      if(posicion!=null && posicion==8){
        if(pos8=="1"){
          miFunction = "$(function() { $('input[name=origenFondo8]').prop('checked', 'checked'); });";
          eval(miFunction);
        }else{
          miFunction = "$(function() { $('input[name=origenFondo8]').prop('checked', ''); });";
          eval(miFunction);
        }
      }

      if(posicion!=null && posicion==7 ){
        if(pos7=="1"){
          miFunction = "$(function() { $('input[name=origenFondo7]').prop('checked', 'checked'); });";
          eval(miFunction);
        }else{
          miFunction = "$(function() { $('input[name=origenFondo7]').prop('checked', ''); });";
          eval(miFunction);
        }
      }

      if(posicion!=null && posicion==6){
        if(pos6=="1"){
          miFunction = "$(function() { $('input[name=origenFondo6]').prop('checked', 'checked'); });";
          eval(miFunction);
        }else{
          miFunction = "$(function() { $('input[name=origenFondo6]').prop('checked', ''); });";
          eval(miFunction);
        }
      }

      if(posicion!=null && posicion==5){
        if(pos5=="1"){
          miFunction = "$(function() { $('input[name=origenFondo5]').prop('checked', 'checked'); });";
          eval(miFunction);
        }else{
          miFunction = "$(function() { $('input[name=origenFondo5]').prop('checked', ''); });";
          eval(miFunction);
        }
      }

      if(posicion!=null && posicion==4){
        if(pos4=="1"){
          miFunction = "$(function() { $('input[name=origenFondo4]').prop('checked', 'checked'); });";
          eval(miFunction);
        }else{
          miFunction = "$(function() { $('input[name=origenFondo4]').prop('checked', ''); });";
          eval(miFunction);
        }
      }

      if(posicion!=null && posicion==3){
        if(pos3=="1"){
          miFunction = "$(function() { $('input[name=origenFondo3]').prop('checked', 'checked'); });";
          eval(miFunction);
        }else{
          miFunction = "$(function() { $('input[name=origenFondo3]').prop('checked', ''); });";
          eval(miFunction);
        }
      }

      if(posicion!=null && posicion==2){
        if(pos2=="1"){
          miFunction = "$(function() { $('input[name=origenFondo2]').prop('checked', 'checked'); });";
          eval(miFunction);
        }else{
          miFunction = "$(function() { $('input[name=origenFondo2]').prop('checked', ''); });";
          eval(miFunction);
        }
      }

      if(posicion!=null && posicion==1){
        if(pos1=="1"){
          miFunction = "$(function() { $('input[name=origenFondo1]').prop('checked', 'checked'); });";
          eval(miFunction);
        }else{
          miFunction = "$(function() { $('input[name=origenFondo1]').prop('checked', ''); });";
          eval(miFunction);
        }
      }

      if(posicion!=null && posicion==0){
        if(pos0=="1"){
          miFunction = "$(function() { $('input[name=origenFondo0]').prop('checked', 'checked'); });";
          eval(miFunction);
        }else{
          miFunction = "$(function() { $('input[name=origenFondo0]').prop('checked', ''); });";
          eval(miFunction);
        }
      }
    
  }

  onKeyOutOnlyNumericDecimalDouble(event: any,objectMask:FormControl,objectTarget:FormControl){
    var target_value = event.target.value;
    target_value = func.replaceAll(target_value,',','');
    var valor:number = Number(target_value);
    var valorFormateado = func.formatNumber(valor);
    if( valorFormateado == "NaN" || valorFormateado == null){
      objectMask.setValue("0.00");
      objectTarget.setValue("0.00");
    }else{
      objectMask.setValue(valorFormateado);
      objectTarget.setValue(func.replaceAll(valorFormateado,',',''));

      //console.log("objectMask::"+objectMask.value);
      //console.log("objectTarget::"+objectTarget.value);
    }

  }

  loadFormatNumber(){
    //console.log("formateando valor::loadFormatNumber()");
    if(this.form_datosOrigenFondos.average_monthly_income_usd.value != null){
      var valor:number = Number(this.form_datosOrigenFondos.average_monthly_income_usd.value);
      var valorFormateado = func.formatNumber(valor);
      //console.log("formateando valor::valorFormateado::"+valorFormateado);
      this.average_monthly_income_usd_mask.setValue(valorFormateado);
      //this.form_datosOrigenFondos.average_monthly_income_usd.setValue(func.replaceAll(this.form_datosOrigenFondos.average_monthly_income_usd.value,',',''));
    }
  }


  /*
      public constants
  */

  get yes() { return PARAMETER_TABLE_YES_NO.YES.ID; }
  get no()  { return PARAMETER_TABLE_YES_NO.NO.ID; }
  get localCountry(){ return countryMaping.PERU;}

  /*
      generic functions
  */

  existInList(lista:Array<number>, id:number){
    for(var current of lista){
      if(current == id){
        return true;
      }
    }
    return false;
  }

  onKeyUpDNI(event: any, object:FormControl){
    var documentNumner = event.target.value;
    if(documentNumner.length != 8){
      object.setValue("");
      this.alertar("El DNI debe tener 8 caracteres.");//El DNI debe tener 8 dígitos
    }
  }

  onKeyUpOnliDecimal(event: any, object:FormControl){
    object.setValue(func.inputOnliDecimal(event.target.value));
  }

  onKeyOutOnlyNumericDecimal(event: any,object:FormControl){
    var valor:number = Number(event.target.value);
    var valorFormateado = func.formatNumber(valor);
    if( valorFormateado == "NaN" || valorFormateado == null){
      object.setValue("0.00");
    }else{
      object.setValue(valorFormateado);
    }
  }

  onKeyUpEmail(event: any, object:FormControl){
    object.setValue(func.inputEmail(event.target.value));
  }

  onKeyUpOnliNumeric(event: any, object:FormControl){
    object.setValue(func.inputOnliNumeric(event.target.value));
  }

  onKeyUpOnliText(event: any, object:FormControl){
    object.setValue(func.inputOnliText(event.target.value));
  }

  onKeyUpAplhaNumeric(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumeric(event.target.value));
  }

  onKeyUpAplhaNumericSpace(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumericSpace(event.target.value));
  }

  onKeyUpAplhaNumericSimple(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumericSimple(event.target.value));
  }

  onKeyUpAplhaNumericSpecial(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumericSpecial(event.target.value));
  }

  onKeyUpOnliTextAndSpace(event: any, object:FormControl){
    object.setValue(func.inputOnliTextAndSpace(event.target.value));
  }

  setMaxDateBirth() { 
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = (today.getFullYear()-18);
    
    this.currentData.maxDateBirth = yyyy + '-' + mm + '-' + dd ;
  }

  setMinDateBirth() { 
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = (today.getFullYear()-110);
    
    this.currentData.minDateBirth = yyyy + '-' + mm + '-' + dd ;
  }

  setMaxDateToday() { 
    var today = new Date();
    var dd = String(today.getDate()-1).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = (today.getFullYear());
    
    this.currentData.maxDateToday = yyyy + '-' + mm + '-' + dd ;
  }

}
