import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from 'src/app/configuration/providers/modal';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { userTo } from 'src/app/model/UserModel/userTo';
import { URL } from 'src/app/constants/url';
import { func } from 'src/app/constants/func';
import { userAccountTo } from 'src/app/model/UserModel/userAccountTo';
import { responseTo } from 'src/app/model/UserModel/responseTo';
import { userTokenTo } from 'src/app/model/UserModel/userTokenTo';
import { parameterTableTo } from 'src/app/model/UserModel/parameterTableTo';
import { ParameterService } from 'src/app/services/ParameterServices/parameter.service';
import { MASTER_TABLE } from 'src/app/constants/masterTable';
import { documentTypeMaping } from 'src/app/constants/documentTypeMaping';
import { SimplemodalComponent } from '../../AppComponents/simplemodal/simplemodal.component';
import { MESSAGE_ES } from 'src/app/constants/message_es';
import { errorMaping } from 'src/app/constants/errorMaping';


@Component({
  selector: 'ark-resendEmailAndToken',
  templateUrl: './resendEmailAndToken.component.html',
  styleUrls: ['./resendEmailAndToken.component.css']
})
export class ResendEmailAndToken implements OnInit {

  @ViewChild(SimplemodalComponent,{static: false}) simplemodalComponent: SimplemodalComponent;
  @Output() alertEvent        	= new EventEmitter<String>();
  
  mensaje = '';
  tipoRecuperacion = 1;


  documentType = new FormControl('');
  numeroDocumento = new FormControl(null, Validators.required);
  correo = new FormControl(null, [Validators.required, Validators.email]);
  userTokenTo = <userTokenTo>{};
  paramLoginType:Array<parameterTableTo> = [] as Array<parameterTableTo>;
  paramRecoveryType:Array<parameterTableTo> = [] as Array<parameterTableTo>;
  paramTipoDocumento:Array<parameterTableTo> = [] as Array<parameterTableTo>;
  documentSize = 0;
  submitted = false;
  loginType = new FormControl('');
  recoveryType = new FormControl('', Validators.required);

  constructor(private httpClient: HttpClient,
              private modalService: ModalService, 
              private router: Router,
              private parameterService: ParameterService,
              private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.loginType.setValue(2);
    this.spinner.show();
    this.getParameterTable();
    this.recoveryType.setValue(2);
    this.spinner.hide();
  }

  async getParameterTable(){
    
    this.paramTipoDocumento     =  await this.parameterService.getParameter(MASTER_TABLE.DOCUMENT_TYPE)  as Array<parameterTableTo>;
    if(this.paramTipoDocumento != null && this.paramTipoDocumento.length > 0){
      var tmpDocumentType:Array<parameterTableTo> = [] as Array<parameterTableTo>;
      for(var document of this.paramTipoDocumento){  if(document.short_integer == 1){  tmpDocumentType.push(document); } }
      this.paramTipoDocumento = tmpDocumentType;
    }

    
    this.documentType.setValue(this.paramTipoDocumento[0].parameter_table_pk);
    this.changeDocument();

    let parameters:Array<parameterTableTo> = [];
    let parameter:parameterTableTo;
    /*
    parameter = {} as parameterTableTo ;
    parameter.parameter_table_pk = 1;
    parameter.parameter_table_cd = '1';
    parameter.description= 'Doc de Identidad';
    parameters.push(parameter);
    */
    parameter = {} as parameterTableTo ;
    parameter.parameter_table_pk = 2;
    parameter.parameter_table_cd = '2';
    parameter.description= 'Correo';
    parameters.push(parameter);
    
    this.paramLoginType = parameters;

    parameters = [];

    parameter = {} as parameterTableTo ;
    parameter.parameter_table_pk = 2;
    parameter.parameter_table_cd = '2';
    parameter.description= 'Correo';
    parameters.push(parameter);

    parameter = {} as parameterTableTo ;
    parameter.parameter_table_pk = 1;
    parameter.parameter_table_cd = '1';
    parameter.description= 'Celular';
    parameters.push(parameter);
    
    this.paramRecoveryType = parameters;
    
  }
  
  limpiarForm() {
    this.documentType.setValue(this.paramTipoDocumento[0].parameter_table_pk);
    this.numeroDocumento.setValue(null);
    this.correo.setValue(null);
    this.submitted = false;
    //this.recoveryType.setValue(null);
    this.recoveryType.setValue(1);
  }

  changeLoginType(tipo){
    this.loginType.setValue(tipo);
    this.documentType.setValue(this.paramTipoDocumento[0].parameter_table_pk);
    this.numeroDocumento.setValue(null);     
    this.correo.setValue(null);
  }

  alertar(mensaje:String){
    this.alertEvent.emit(mensaje);
    return;
  }

  public onKeyUpDNI(){
    var documentNumner = this.numeroDocumento.value;
    if(documentNumner.length != 8){
      this.numeroDocumento.setValue(null);
      this.alertar("El DNI debe tener 8 caracteres.");//El DNI debe tener 8 dígitos
    }
  }

  recuperarContrasena() {

    var tipo: number = this.loginType.value;

    if(tipo == 2){
      this.submitted = true; 
      if ( this.correo.invalid ) {
        return;
      }
    } 

    if(tipo == 1){
      this.submitted = true; 
      if (this.recoveryType.invalid || this.numeroDocumento.invalid) {
        return;
      }
    }

    if ( (tipo === 1 && this.recoveryType.value== null || (this.documentType.value === null || this.documentType.value === null || this.numeroDocumento.value === '') ) ||
         (tipo === 2 && this.recoveryType.value== null ||  this.correo.value === '') ) {
          this.simplemodalComponent.alertar(MESSAGE_ES.CABECERA.WARNING, 'Debe Ingresar los datos para poder confirmar su cuenta. Verifique Por favor.');
          return;
    }
 
    var bodyParam = null;
    var urlService = URL.SERVICE_USER_ACCOUNT_RESEND;

    if(tipo === 1){;
      bodyParam = {
        "document_type": this.documentType.value,
	      "document_number": this.numeroDocumento.value
      }
    }else if (tipo === 2){
      bodyParam = {
        "email" : this.correo.value     
      }
    }

    this.spinner.show();
    this.httpClient.post(urlService,
          bodyParam, { headers: func.WSHeader() })
            .subscribe(
          (response) => {
            //console.log("response::"+JSON.stringify(response));
            if(response != null ){
              this.simplemodalComponent.alertar(MESSAGE_ES.CABECERA.WARNING, "Por seguridad, hemos enviado un código y un link de acceso a tu correo electrónico.");
            }else{
              this.simplemodalComponent.alertar(MESSAGE_ES.CABECERA.WARNING, "Revise la información proporcionada o contacte con su administrador");
            }
          },
          (err: HttpErrorResponse) => {

            if(err.status == errorMaping.CODE_422) {
              this.spinner.hide();  
              this.simplemodalComponent.alertar(MESSAGE_ES.CABECERA.WARNING, 'El cliente ya tiene su cuenta confirmada.');
              return;
            }else {
              this.spinner.hide();  
              this.simplemodalComponent.alertar(MESSAGE_ES.CABECERA.WARNING, 'Ocurrio un error al crear la solicitud de confirmación de cuenta. Por favor contacte con el adminsitrador.');
              return;
            }
            
          },
          () => {
            this.spinner.hide();
          }
    );

    this.limpiarForm();
  }


  closeModal(id: string) {
    this.limpiarForm();
    this.modalService.close(id);
  }

  gotoLogin() {
    this.router.navigate(["/login"]);
  } 
 
  gotoPage(page: String){
    this.router.navigate([page]);
  }

  changeDocument(){

    this.documentSize = 0;
    this.numeroDocumento.setValue(null);

    if(this.documentType.value != null && this.documentType.value == documentTypeMaping.DNI){
      this.documentSize = 8;
    }else if( this.documentType.value != null && 
            ( this.documentType.value == documentTypeMaping.CARNET_EXTRANJERIA || this.documentType.value == documentTypeMaping.PASAPORTE) ){
      this.documentSize = 14;
    }

  }
  

  onKeyDocumentNumber(event: any){  
  
    if(this.documentType.value == documentTypeMaping.DNI){
       this.numeroDocumento.setValue(func.validateImputDni(event.target.value));
    }else if(this.documentType.value == documentTypeMaping.CARNET_EXTRANJERIA){
       this.numeroDocumento.setValue(func.validateCarnetYPasaporte(event.target.value)); 
    }else if(this.documentType.value == documentTypeMaping.PASAPORTE){
      this.numeroDocumento.setValue(func.validateCarnetYPasaporte(event.target.value)); 
    }

  }

  
  onKeyUpPassword(event: any, object:FormControl){
    object.setValue(func.inputBlurPassword(event.target.value));
  }
  
  onKeyUpEmail(event: any, object:FormControl){
    object.setValue(func.inputEmail(event.target.value));
  }

  onKeyUpOnliNumeric(event: any, object:FormControl){
    object.setValue(func.inputOnliNumeric(event.target.value));
  }

  onKeyUpOnliText(event: any, object:FormControl){
    object.setValue(func.inputOnliText(event.target.value));
  }

  onKeyUpAplhaNumeric(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumeric(event.target.value));
  }

  onKeyUpAplhaNumericSpace(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumericSpace(event.target.value));
  }

  onKeyUpAplhaNumericSimple(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumericSimple(event.target.value));
  }

  onKeyUpAplhaNumericSpecial(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumericSpecial(event.target.value));
  }

  onKeyUpOnliTextAndSpace(event: any, object:FormControl){
    object.setValue(func.inputOnliTextAndSpace(event.target.value));
  }

}
