import { Component, OnInit, Input } from '@angular/core';  
import { HttpClient, HttpErrorResponse } from '@angular/common/http'; 
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from 'src/app/configuration/providers/modal';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { investmentTo } from 'src/app/model/UserModel/investmentTo';
import { responseNotificacionesTo } from 'src/app/model/UserModel/responseNotificacionesTo';
import { notificacionesTo } from 'src/app/model/UserModel/notificacionesTo';
import { userTo } from 'src/app/model/UserModel/userTo';


@Component({
  selector: 'ark-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.css']
})
export class NotificacionesComponent implements OnInit {

  objUserTo = null;
  mensaje = '';
  
  constructor(private httpClient: HttpClient, private modalService: ModalService,
              private loginService: LoginService, private router: Router,
              private  spinner: NgxSpinnerService) { } 
  
  listinvestmentToDolares : Array<investmentTo> = [] as Array<investmentTo>;
  listinvestmentToSoles : Array<investmentTo> = [] as Array<investmentTo>;
  
  notificacionesBd : responseNotificacionesTo = {} as responseNotificacionesTo;
  listNotificaiones : Array<notificacionesTo> = [] as Array<notificacionesTo>;

  correoDetalle = null;
  
  ngOnInit() { 
    this.objUserTo = <userTo>this.loginService.currentUser();
    this.loadNotificaciones();
  }

  loadNotificaciones(){ 

    let notificaciones : notificacionesTo = {} as notificacionesTo;
    notificaciones.notification_by = 'SIMVEST';
    notificaciones.notification_date = '01/01/2019';
    notificaciones.notification_hour = '10:10 AM';
    notificaciones.description = 'CORREO PRUEBA AAA';
    notificaciones.subject = 'Asunto 111';
    this.listNotificaiones.push(notificaciones);

    notificaciones = {} as notificacionesTo;
    notificaciones.notification_by = 'SIMVEST';
    notificaciones.notification_date = '02/02/2019';    
    notificaciones.notification_hour = '10:10 AM';
    notificaciones.description = 'CORREO PRUEBA BBB';
    notificaciones.subject = 'Asunto 111';
    this.listNotificaiones.push(notificaciones);

    notificaciones = {} as notificacionesTo;
    notificaciones.notification_by = 'SIMVEST';
    notificaciones.notification_date = '02/02/2019';
    notificaciones.notification_hour = '10:10 AM';
    notificaciones.description = 'CORREO PRUEBA CCC';
    notificaciones.subject = 'Asunto 111';
    this.listNotificaiones.push(notificaciones);


    notificaciones = {} as notificacionesTo;
    notificaciones.notification_by = 'SIMVEST';
    notificaciones.notification_date = '02/02/2019';
    notificaciones.notification_hour = '10:10 AM';
    notificaciones.description = 'CORREO PRUEBA DDD';
    notificaciones.subject = 'Asunto 111';
    this.listNotificaiones.push(notificaciones);


    notificaciones = {} as notificacionesTo;
    notificaciones.notification_by = 'SIMVEST';
    notificaciones.notification_date = '02/02/2019';
    notificaciones.notification_hour = '10:10 AM';
    notificaciones.description = 'CORREO PRUEBA EEE';
    notificaciones.subject = 'Asunto 111';
    this.listNotificaiones.push(notificaciones);


    notificaciones = {} as notificacionesTo;
    notificaciones.notification_by = 'SIMVEST';
    notificaciones.notification_date = '02/02/2019';
    notificaciones.notification_hour = '10:10 AM';
    notificaciones.description = 'CORREO PRUEBA FFF';
    notificaciones.subject = 'Asunto 111';
    this.listNotificaiones.push(notificaciones);

    notificaciones = {} as notificacionesTo;
    notificaciones.notification_by = 'SIMVEST';
    notificaciones.notification_date = '02/02/2019';    
    notificaciones.notification_hour = '10:10 AM';
    notificaciones.description = 'CORREO PRUEBA BBB';
    notificaciones.subject = 'Asunto 111';
    this.listNotificaiones.push(notificaciones);

    notificaciones = {} as notificacionesTo;
    notificaciones.notification_by = 'SIMVEST';
    notificaciones.notification_date = '02/02/2019';
    notificaciones.notification_hour = '10:10 AM';
    notificaciones.description = 'CORREO PRUEBA CCC';
    notificaciones.subject = 'Asunto 111';
    this.listNotificaiones.push(notificaciones);


    notificaciones = {} as notificacionesTo;
    notificaciones.notification_by = 'SIMVEST';
    notificaciones.notification_date = '02/02/2019';
    notificaciones.notification_hour = '10:10 AM';
    notificaciones.description = 'CORREO PRUEBA DDD';
    notificaciones.subject = 'Asunto 111';
    this.listNotificaiones.push(notificaciones);


    notificaciones = {} as notificacionesTo;
    notificaciones.notification_by = 'SIMVEST';
    notificaciones.notification_date = '02/02/2019';
    notificaciones.notification_hour = '10:10 AM';
    notificaciones.description = 'CORREO PRUEBA EEE';
    notificaciones.subject = 'Asunto 111';
    this.listNotificaiones.push(notificaciones);


    notificaciones = {} as notificacionesTo;
    notificaciones.notification_by = 'SIMVEST';
    notificaciones.notification_date = '02/02/2019';
    notificaciones.notification_hour = '10:10 AM';
    notificaciones.description = 'CORREO PRUEBA FFF';
    notificaciones.subject = 'Asunto 111';
    this.listNotificaiones.push(notificaciones);

    notificaciones = {} as notificacionesTo;
    notificaciones.notification_by = 'SIMVEST';
    notificaciones.notification_date = '02/02/2019';    
    notificaciones.notification_hour = '10:10 AM';
    notificaciones.description = 'CORREO PRUEBA BBB';
    notificaciones.subject = 'Asunto 111';
    this.listNotificaiones.push(notificaciones);

    notificaciones = {} as notificacionesTo;
    notificaciones.notification_by = 'SIMVEST';
    notificaciones.notification_date = '02/02/2019';
    notificaciones.notification_hour = '10:10 AM';
    notificaciones.description = 'CORREO PRUEBA CCC';
    notificaciones.subject = 'Asunto 111';
    this.listNotificaiones.push(notificaciones);


    notificaciones = {} as notificacionesTo;
    notificaciones.notification_by = 'SIMVEST';
    notificaciones.notification_date = '02/02/2019';
    notificaciones.notification_hour = '10:10 AM';
    notificaciones.description = 'CORREO PRUEBA DDD';
    notificaciones.subject = 'Asunto 111';
    this.listNotificaiones.push(notificaciones);


    notificaciones = {} as notificacionesTo;
    notificaciones.notification_by = 'SIMVEST';
    notificaciones.notification_date = '02/02/2019';
    notificaciones.notification_hour = '10:10 AM';
    notificaciones.description = 'CORREO PRUEBA EEE';
    notificaciones.subject = 'Asunto 111';
    this.listNotificaiones.push(notificaciones);


    notificaciones = {} as notificacionesTo;
    notificaciones.notification_by = 'SIMVEST';
    notificaciones.notification_date = '02/02/2019';
    notificaciones.notification_hour = '10:10 AM';
    notificaciones.description = 'CORREO PRUEBA FFF';
    notificaciones.subject = 'Asunto 111';
    this.listNotificaiones.push(notificaciones);
    notificaciones = {} as notificacionesTo;
    notificaciones.notification_by = 'SIMVEST';
    notificaciones.notification_date = '02/02/2019';    
    notificaciones.notification_hour = '10:10 AM';
    notificaciones.description = 'CORREO PRUEBA BBB';
    notificaciones.subject = 'Asunto 111';
    this.listNotificaiones.push(notificaciones);

    notificaciones = {} as notificacionesTo;
    notificaciones.notification_by = 'SIMVEST';
    notificaciones.notification_date = '02/02/2019';
    notificaciones.notification_hour = '10:10 AM';
    notificaciones.description = 'CORREO PRUEBA CCC';
    notificaciones.subject = 'Asunto 111';
    this.listNotificaiones.push(notificaciones);


    notificaciones = {} as notificacionesTo;
    notificaciones.notification_by = 'SIMVEST';
    notificaciones.notification_date = '02/02/2019';
    notificaciones.notification_hour = '10:10 AM';
    notificaciones.description = 'CORREO PRUEBA DDD';
    notificaciones.subject = 'Asunto 111';
    this.listNotificaiones.push(notificaciones);


    notificaciones = {} as notificacionesTo;
    notificaciones.notification_by = 'SIMVEST';
    notificaciones.notification_date = '02/02/2019';
    notificaciones.notification_hour = '10:10 AM';
    notificaciones.description = 'CORREO PRUEBA EEE';
    notificaciones.subject = 'Asunto 111';
    this.listNotificaiones.push(notificaciones);


    notificaciones = {} as notificacionesTo;
    notificaciones.notification_by = 'SIMVEST';
    notificaciones.notification_date = '02/02/2019';
    notificaciones.notification_hour = '10:10 AM';
    notificaciones.description = 'CORREO PRUEBA FFF';
    notificaciones.subject = 'Asunto 111';
    this.listNotificaiones.push(notificaciones);


    this.notificacionesBd.items = this.listNotificaiones;

 
    /** 
      this.spinner.show();
      this.httpClient.get(URL.SERVICE_API_V1_USER + this.objUserTo.id_user_pk +  
                          URL.SERVICE_LOAD_INVERSIONES_HOME_END + CURRENCY.DOLARES.ID , 
                            { headers: func.WSHeader() })
            . subscribe(
            (response) => {     
              let inversiones = <investmentTo>response;   


            },
            (err: HttpErrorResponse) => {
    
              console.log(err.error);
              console.log(err.name);
              console.log(err.message);
              console.log(err.status);
    
              this.spinner.hide();
              this.mensaje = "Ocurrio un problema al cargar las notificaciones. \n " + err.message;
              this.modalService.open('idModalWarning');
    
            },
            () => {
              this.spinner.hide();
              console.log("************* this.httpClient.post ()::" + "The  is now completed.");
            }
        );    */
  
        
  }
 

  gotoPage(page: String){
    this.router.navigate([page]);
  }


  closeModal(id: string) {
      this.modalService.close(id);
  }
  
  verDetalleCorreo(notificaciones: notificacionesTo){
    this.correoDetalle = notificaciones;
  }
  
}