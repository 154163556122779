import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { userTo } from 'src/app/model/UserModel/userTo';
import { Observable } from 'rxjs';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { ModalService } from 'src/app/configuration/providers/modal';
import { produtOriginMaping } from 'src/app/constants/produtOriginMaping';
import { SimplemodalComponent } from '../../AppComponents/simplemodal/simplemodal.component';
import { carritoDetalleTo } from 'src/app/model/UserModel/carritoDetalleTo';
import { LateralmenuSessionComponent } from '../lateralmenu-session/lateralmenu-session.component';
import { globalScreen } from 'src/app/model/UserModel/globalScreen';

@Component({
  selector: 'ark-headermenu-session',
  templateUrl: './headermenu-session.component.html',
  styleUrls: ['./headermenu-session.component.css']
})
export class HeadermenuSessionComponent implements OnInit {

  userLogged = this.loginService.isLogged();
  userMailToken = sessionStorage.getItem('mailToken');
  userTo:userTo = this.loginService.currentUser();
  userTo$:Observable<userTo>;
  
  
  @Output() outputDataEvent = new EventEmitter<globalScreen>();
  
  headText:String = '';
  bodyText:String = '';

  defaultHeadeText='Mensaje de Alerta';

  constructor(private loginService: LoginService, 
              private modalService: ModalService,
              private router: Router) { }

  ngOnInit() {
    this.userTo$ = this.loginService.getUserTo$();
    this.userTo$.subscribe( userTo => this.userTo = userTo );
    this.userTo$.subscribe( userTo => this.userLogged = ( (userTo.login_user != null) ? true : false ) );
  }

  async logout(){

    this.confirmar("Mensaje de Confirmación","¿Está seguro que desea cerrar sesión?");
  }

  gotoCarrito(){
    sessionStorage.setItem('productShop',null);
    sessionStorage.setItem('product',null);
    this.gotoPage('comprar');
  }

  countCarrito(){
    var listCarritoDetalleTo:Array<carritoDetalleTo> = JSON.parse(sessionStorage.getItem('listCarritoDetalleTo'));
    if(listCarritoDetalleTo!=null && listCarritoDetalleTo.length!=null && listCarritoDetalleTo.length >0){
      return ""+listCarritoDetalleTo.length;
    }
    return "";
  }
  
  confirmar(head:String,body:String){
    this.headText = (head != null)?head:this.defaultHeadeText;
    this.bodyText = body;  
    this.modalService.open('confirmar');
  }

  aceptar(id:String){
    this.modalService.close(id+"");
    this.loginService.logout();
  }

  rechazar(id:String){
    this.modalService.close(id+"");
  }

  gotoPageWithRut(page: String){
    if(this.userTo.rut!=null){
      this.gotoPage(page);
    }
  }

  gotoPage(page: String){
    //this.lateralmenuSessionComponent.setLatertal('');
    var varglobalScreen:globalScreen = {} as globalScreen;
    varglobalScreen.lateral = page;
    console.log("execute::this.outputDataEvent.emit::"+JSON.stringify(varglobalScreen));
    this.outputDataEvent.emit(varglobalScreen);
    this.router.navigate([page]);
  }

  verModal() {
    this.modalService.open('idModalContactenos');
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  gotoProducts(page: String){
    sessionStorage.setItem('menuOrigin', JSON.stringify(produtOriginMaping.MARKET));
    this.router.navigate([page]);
  }

  gotoPageMisInversiones(page: String){
    sessionStorage.setItem('menuOrigin', JSON.stringify(produtOriginMaping.INVERSIONES));
    this.router.navigate([page]);
  }
  
}
