import { ENVIRONMENT } from './config';

export const errorMaping = {

    CODE_400           : 400,
    ERROR_CODE_400     : 'PWD_6',
    CODE_405           : 405,
    CODE_409           : 409, 
    CODE_417           : 417, 
    CODE_422           : 422,
 }
