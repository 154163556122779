import { ENVIRONMENT } from './config';

export const MESSAGE_ES = {

    CABECERA:{
        WARNING :'Mensaje de Alerta',
        SUCESS  :'Mensaje de Éxito',
        ERROR   :'Mensaje de Error'
    },
    BOTONES:{
        ACEPTAR    :'Aceptar',
        CANCELAR   :'Cancelar',
        AGREGAR    :'Agregar',
        ELIMINAR   :'Eliminar',
        REMOVER    :'Remover',
        LIMPIAR    :'Limpiar',
    },
    GENERIC:{
        RESEND_TOKEN_ERROR:'Ocurrio un error al reenviar el token. Por favor contacte con el adminsitrador.',
        RESEND_TOKEN_SUCESS:'Se generó un nuevo token via SMS.',
        RECUPERAR_PASSWORD_VALIDACION:'Debe Ingresar los datos para poder recuperar su password.Verifique Por favor.',
        RECUPERAR_PASSWORD_SUCESS:'Se le acaba de enviar un correo con los pasos para actualizar su cambio de clave y un token.',
        RECUPERAR_PASSWORD_ERROR:'Ocurrio un error al crear la solicitud de recuperacion de clave. Por favor contacte con el adminsitrador.',
    },
    LOGIN:{
        AUTENTICACION_VALIDACION:'Debe Ingresar todos los datos para poder autenticarse. Verifique por favor.',
        AUTENTICACION_CREDENCIALES_ERROR:'Las credenciales son inválidas. Verifique por favor.',
    },
    LIBRO :{
        REGISTRO_EXITOSO:'Los datos fueron enviados satisfactoriamente.',
    },
    NUEVO_USUARIO:{

    },
    NEW_INVESTOR:{

    },
    INVESTMENTS:{

    },
    CASH_ACCOUNT:{

    },
    SAB_ACCOUNT:{

    },
    SHOPPING_CART:{

    },
    NOTIFICATIONS:{

    }
    
 }
