import { Component, OnInit, ViewChild } from '@angular/core'; 
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { ModalService } from 'src/app/configuration/providers/modal';
import { investmentTo } from 'src/app/model/UserModel/investmentTo';
import { userTo } from 'src/app/model/UserModel/userTo';
import { URL } from 'src/app/constants/url';
import { CURRENCY } from 'src/app/constants/CURRENCY';
import { func } from 'src/app/constants/func';
import { responseInvesmentTo } from 'src/app/model/UserModel/responseInvesmentTo';
import { ProductoDetalleTo } from 'src/app/model/UserModel/ProductoDetalleTo';
import { ViewInvestmentInteresPayment } from 'src/app/model/UserModel/ViewInvestmentInteresPayment';
import { ViewInvestmentAmortizationPayment } from 'src/app/model/UserModel/ViewInvestmentAmortizationPayment';
import { SimplemodalComponent } from '../../AppComponents/simplemodal/simplemodal.component';
import { pagoCapitalTo } from 'src/app/model/UserModel/pagoCapitalTo';

@Component({
  selector: 'ark-detalle-cronograma',
  templateUrl: './detalle-cronograma.component.html',
  styleUrls: ['./detalle-cronograma.component.css']
})
export class DetalleCronogramaComponent implements OnInit {

  objUserTo = null; 
  @ViewChild(SimplemodalComponent,{static: false}) simplemodalComponent: SimplemodalComponent;

  constructor(private httpClient: HttpClient, private modalService: ModalService,
              private loginService: LoginService, private router: Router,
              private  spinner: NgxSpinnerService) { } 

  // listinvestmentToDolares : Array<investmentTo> = [] as Array<investmentTo>;
  entityProductoDetalleTo : ProductoDetalleTo = {} as ProductoDetalleTo; 

  listViewInvestmentInteresPayment : Array<ViewInvestmentInteresPayment> = [] as Array<ViewInvestmentInteresPayment>;
  listViewInvestmentAmortizationPayment : Array<ViewInvestmentAmortizationPayment> = [] as Array<ViewInvestmentAmortizationPayment>;
  
  //temporal - aun no estan los campos para las otras vistas
  lstScheduleProductsInterest : Array<pagoCapitalTo> = [] as Array<pagoCapitalTo>;
  lstScheduleProductsCapital  : Array<pagoCapitalTo> = [] as Array<pagoCapitalTo>;

  ngOnInit() { 
      this.objUserTo = <userTo>this.loginService.currentUser();
      let invIdSecurityCodePk = JSON.parse(sessionStorage.getItem('invIdSecurityCodePk')); 
      //this.loadListViewInvestmentInteresPayment(invIdSecurityCodePk); 
      //this.loadListViewInvestmentAmortizationPayment(invIdSecurityCodePk); 
      this.loadProductDetailBySession(invIdSecurityCodePk); 
  }

  
  loadProductDetailBySession(invIdSecurityCodePk: String){ 

      var body = {
        "id_security_code_pk":  invIdSecurityCodePk
      }

      this.spinner.show(); 


      this.httpClient.post(URL.SERVICE_API_V1_CORE_GET_VIEW_PRODUCT_DETAIL
                  ,body,        
            { headers: func.WSHeaderTokenCore() })
            . subscribe(
            (response) => { 

              //console.log(" URL.SERVICE_API_V1_CORE_GET_VIEW_PRODUCT_DETAIL :: datos :: " + JSON.stringify(response));
              
  
              let productoDetalle = <ProductoDetalleTo>response; 
              this.entityProductoDetalleTo = productoDetalle;

              this.lstScheduleProductsInterest = this.entityProductoDetalleTo.scheduleProductsInterest;
              this.lstScheduleProductsCapital = this.entityProductoDetalleTo.scheduleProductsCapital;

            },
            (err: HttpErrorResponse) => {
                console.log(err.error);
                console.log(err.name);
                console.log(err.message);
                console.log(err.status); 
              this.spinner.hide();
              return ;
          },
          () => {
            this.spinner.hide();
          console.log("************* this.httpClient.post ()::" + "The observable is now completed.");
          }
      ); 
  } 
  

  loadListViewInvestmentInteresPayment(invIdSecurityCodePk: String){ 

      var body = {
        "security_code":  invIdSecurityCodePk,
        "rut":  this.objUserTo.rut
      }
      console.log("URL.SERVICE_API_V1_CORE_POST_INVESTMENTS_INTEREST_PAYMENT::"+URL.SERVICE_API_V1_CORE_POST_INVESTMENTS_INTEREST_PAYMENT);
      console.log("body::"+ JSON.stringify(body));
      this.spinner.show(); 
      this.httpClient.post(URL.SERVICE_API_V1_CORE_POST_INVESTMENTS_INTEREST_PAYMENT
                  ,body,        
            { headers: func.WSHeaderTokenCore() })
            . subscribe(
            (response) => { 
              console.log("response::"+JSON.stringify(response));
              this.listViewInvestmentInteresPayment = response as Array<ViewInvestmentInteresPayment>;; 
            },
            (err: HttpErrorResponse) => {
                console.log(err.error);
                console.log(err.name);
                console.log(err.message);
                console.log(err.status); 
              this.spinner.hide();
              return ;
          },
          () => {
            this.spinner.hide();
          //console.log("************* this.httpClient.post ()::" + "The observable is now completed.");
          }
      ); 
  }

  loadListViewInvestmentAmortizationPayment(invIdSecurityCodePk: String){ 

    var body = {
      "security_code":  invIdSecurityCodePk,
      "rut":  ""+this.objUserTo.rut
    }

    this.spinner.show(); 
    this.httpClient.post(URL.SERVICE_API_V1_CORE_POST_INVESTMENTS_AMORTIZATION_PAYMENT
                ,body,        
          { headers: func.WSHeaderTokenCore() })
          . subscribe(
          (response) => { 
            this.listViewInvestmentAmortizationPayment = response as Array<ViewInvestmentAmortizationPayment>; 
          },
          (err: HttpErrorResponse) => {
              console.log(err.error);
              console.log(err.name);
              console.log(err.message);
              console.log(err.status); 
            this.spinner.hide();
            return ;
        },
        () => {
          this.spinner.hide();
        console.log("************* this.httpClient.post ()::" + "The observable is now completed.");
        }
    ); 
}

  gotoPage(page: String){
    this.router.navigate([page]);
  } 

  downloadDetail(){ 
  
    var body = {
      "security_code":  this.entityProductoDetalleTo.idSecurityCodePk,
      "rut":  ""+this.objUserTo.rut
    }

    console.log("URL::"+URL.SERVICE_API_V1_CORE_GET_DOCUMENT_FILE_CLIENT_SECU_CRONO + " body:: " + JSON.stringify(body) );
    
    this.httpClient.post(URL.SERVICE_API_V1_CORE_GET_DOCUMENT_FILE_CLIENT_SECU_CRONO 
                , body,       
          { headers: func.WSHeaderTokenCore() ,  responseType: 'blob'  } )
        . subscribe(
        (response) => {    

          var downloadURL = window.URL.createObjectURL(response);
          var link = document.createElement('a');
          link.href = downloadURL;
          link.download = "Cronograma.pdf";
          link.click();    
        },
        (err: HttpErrorResponse) => {
            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);  
            this.spinner.hide();
            this.simplemodalComponent.alertar("Mensaje de Alerta","Ocurrio un error inesperado, contacte con el administrador.");
            return ;
        },
        () => {
          this.spinner.hide();
        }
    );  
  } 
    
  gotoPageDetalle(){
    let invIdSecurityCodePk = JSON.parse(sessionStorage.getItem('invIdSecurityCodePk')); 
    sessionStorage.setItem('invIdSecurityCodePk',JSON.stringify(invIdSecurityCodePk));
    this.router.navigate(['/detalleInversion']);
  }

  gotoPageDetMovimiento(){
    let invIdSecurityCodePk = JSON.parse(sessionStorage.getItem('invIdSecurityCodePk')); 
    sessionStorage.setItem('invIdSecurityCodePk',JSON.stringify(invIdSecurityCodePk));
    this.router.navigate(['/detalleMovimiento']);
  }

  formatNumber(number){
    return func.formatNumber(number);
  }

  formatNumberFourDecimal(number){  
    return func.formatNumberFourDecimal(number); 
  }  
  
  openTerminos(){
    //this.modalService.open('idTerminos');  
    this.simplemodalComponent.openModalInfo();
  }
/*
  closeTerminos(file_code: String){ 
    this.modalService.close('idTerminos');
  }*/

}