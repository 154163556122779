export const flowChangePassword = {

    LOAD_PROFILE                : 1,
    FORMAT_CHANGE_PASSWORD      : 2,
    READ_TOKEN                  : 3,
    EXPIRED_TOKEN               : 4,
    NOT_FOUND_TOKEN             : 5,
    NEW_PASSWORD                : 6

 }
