import { Component, OnInit, Input } from '@angular/core';
import { responseMovimientoTo } from 'src/app/model/UserModel/responseMovimientoTo';
import { ModalService } from 'src/app/configuration/providers/modal';
import { movimientoDetalleTo } from 'src/app/model/UserModel/movimientoDetalleTo';

@Component({
  selector: 'ark-movimientos',
  templateUrl: './movimientos.component.html',
  styleUrls: ['./movimientos.component.css']
})
export class MovimientosComponent implements OnInit {

  @Input() entity : responseMovimientoTo = {} as responseMovimientoTo;  //parametros
  //detail : movimientoDetalleTo = {} as movimientoDetalleTo; 
  security_serial = null;


  constructor(private modalService: ModalService) { }

  ngOnInit() {
    this.security_serial = JSON.parse(sessionStorage.getItem('serialCode'));

   
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  verDetalleMovimiento(pt: movimientoDetalleTo) {
    this.modalService.open('idModalDetail');
    // this.detail = pt;
  }


}
