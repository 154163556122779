import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { func } from 'src/app/constants/func';
import { URL } from 'src/app/constants/url';
import { surveyTo } from 'src/app/model/UserModel/surveyTo';
import { surveyDetailTo } from 'src/app/model/UserModel/surveyDetailTo';
import { parameterTableTo } from 'src/app/model/UserModel/parameterTableTo';
import { MASTER_TABLE } from 'src/app/constants/masterTable';

@Injectable({
  providedIn: 'root'
})
export class ParameterService {

  constructor(private httpClient: HttpClient, 
              private router: Router,
              private  spinner: NgxSpinnerService) { }

  
    async getParameter (masterTablePk)  {
    
      var bodyJson = {"id_master_table_fk": masterTablePk};
      return new Promise((resolve, reject) => {
          const settings = {
              method: 'POST',
              headers: func.WSHeaderTokenCore(),
              body: JSON.stringify(bodyJson),
              timeout: 20000,
          };
  
          let didTimeOut = false;
          let timeout  = setTimeout(() => {
              didTimeOut = true;
              //console.log("Error de Timeout en getParameter::timeout::getGeograficCountry");
              resolve(false);
          },20000);
          
          fetch(URL.SERVICE_PARAMETER_TABLE_BY_MASTER, settings)
              .then(response => response.json())
              .then(response => { 
                  if(!didTimeOut) {
                      clearTimeout(timeout);
                      resolve(response)
                  }
          })
          .catch((error) => {
              if(!didTimeOut){
                  resolve(false)
              }else{
                  //console.log("Error de Timeout en getParameter::catch::getGeograficCountry");
              }
          });
      });
       
  }

  async getParameterDocumentType (masterTablePk, juridicOrNatural)  {
    
    var bodyJson = {"id_master_table_fk": masterTablePk, "juridic_or_natural": juridicOrNatural};
    return new Promise((resolve, reject) => {
        const settings = {
            method: 'POST',
            headers: func.WSHeaderTokenCore(),
            body: JSON.stringify(bodyJson),
            timeout: 20000,
        };

        let didTimeOut = false;
        let timeout  = setTimeout(() => {
            didTimeOut = true;
            //console.log("Error de Timeout en getParameter::timeout::getGeograficCountry");
            resolve(false);
        },20000);
        
        fetch(URL.SERVICE_PARAMETER_TABLE_BY_MASTER, settings)
            .then(response => response.json())
            .then(response => { 
                if(!didTimeOut) {
                    clearTimeout(timeout);
                    resolve(response)
                }
        })
        .catch((error) => {
            if(!didTimeOut){
                resolve(false)
            }else{
                //console.log("Error de Timeout en getParameter::catch::getGeograficCountry");
            }
        });
    });
     
}

  
  async getLoginExternal() {
    console.log("startn getLoginExternal()");
  
    //let objUserTo = <userTo>this.loginService.currentUser();

    var bodyJson = {
      "userName":"ADMIN",
      "password":"adadad"
    }
    
    return new Promise((resolve, reject) => {
        const settings = {
            method: 'POST',
            headers: func.WSHeaderTokenCore(),
            body: JSON.stringify(bodyJson),
            timeout: 20000,
        };
        let url = URL.SERVICE_API_V1_CORE_LOGIN_EXTERNAL; //"http://localhost:8080/iSecurity/resources/users/loginexternal";//https://cors-anywhere.herokuapp.com/
        let didTimeOut = false;
        let timeout  = setTimeout(() => {
            didTimeOut = true;
            //console.log("ejecutando timer");
            resolve(false);
        },20000);
        
        //console.log("getLoginExternal::url: "+url);

        fetch(url, settings)
            .then(response => response.text())
            .then(response => { 
                if(!didTimeOut) {
                    //console.log("getLoginExternal::response:"+JSON.stringify(response))
                    clearTimeout(timeout);
                    resolve(response)
                }
        })
        .catch((error) => {
            if(!didTimeOut){
                resolve(false);
                //console.log("getLoginExternal::ERROR:resolve(false)")
            }else{
              //console.log("getLoginExternal::ERROR"+JSON.stringify(error))
            }
        });
    });
    
  }

  async getAllSurvey (idInvestorRequestPk)  {
      
      return new Promise((resolve, reject) => {
          const settings = {
              method: 'GET',
              headers: func.WSHeaderTokenCore(),
              timeout: 20000,
          };

          let didTimeOut = false;
          let timeout  = setTimeout(() => {
              didTimeOut = true;
              //console.log("Error de Timeout en getParameter::timeout::getGeograficCountry");
              resolve(false);
          },20000);
          
          fetch(URL.SERVICE_LOAD_SURVEY+idInvestorRequestPk+URL.SERVICE_LOAD_SURVEY_END, settings)
              .then(response => response.json())
              .then(response => { 
                  if(!didTimeOut) {
                    //console.log("JSON.string::response.items::"+JSON.stringify(response.items));
                      clearTimeout(timeout);
                      resolve(response.items)
                  }
          })
          .catch((error) => {
              if(!didTimeOut){
                  resolve(false)
              }else{
                  //console.log("Error de Timeout en getParameter::catch::getGeograficCountry");
              }
          });
      });
      
  }

  async getAllBank()  {
    var bodyJson = {};
    return new Promise((resolve, reject) => {
        const settings = {
            method: 'POST',
            headers: func.WSHeaderTokenCore(),
            body: JSON.stringify(bodyJson),
            timeout: 10000,
        };

        let didTimeOut = false;
        let timeout  = setTimeout(() => {
            didTimeOut = true;
            resolve(false);
        },10000);
        
        fetch(URL.SERVICE_ALL_BANK, settings)
            .then(response => response.json())
            .then(response => { 
                if(!didTimeOut) {
                    //console.log("Entro en sincronizeApp-getAllBank:"+JSON.stringify(response))
                    clearTimeout(timeout);
                    resolve(response)//response.parameter_tables
                }
        })
        .catch((error) => {
            if(!didTimeOut){
                resolve(false)
            }else{
                //console.log("Error de Timeout en sincronizeApp");
            }
        });
    });
        
  }

  async getGeograficCountry ()  {
    
    var bodyJson = {"type": 1};
    return new Promise((resolve, reject) => {
        const settings = {
            method: 'POST',
            headers: func.WSHeaderTokenCore(),
            body: JSON.stringify(bodyJson),
            timeout: 30000,
        };

        let didTimeOut = false;
        let timeout  = setTimeout(() => {
            didTimeOut = true;
            //console.log("Error de Timeout en sincronizeApp::timeout::getGeograficCountry");
            resolve(false);
        },30000);
        
        fetch(URL.SERVICE_GEOGRAFIC_LOCATION, settings)
            .then(response => response.json())
            .then(response => { 
                if(!didTimeOut) {
                    clearTimeout(timeout);
                    resolve(response)
                }
        })
        .catch((error) => {
            if(!didTimeOut){
                resolve(false)
            }else{
                //console.log("Error de Timeout en sincronizeApp::catch::getGeograficCountry");
            }
        });
    });
    
  }

  async getGeograficDepartment (masterTablePk)  {
    
    var bodyJson = {"id":masterTablePk, "type": 2};
    return new Promise((resolve, reject) => {
        const settings = {
            method: 'POST',
            headers: func.WSHeaderTokenCore(),
            body: JSON.stringify(bodyJson),
            timeout: 10000,
        };

        let didTimeOut = false;
        let timeout  = setTimeout(() => {
            didTimeOut = true;
            //console.log("Error de Timeout en sincronizeApp::timeout::getGeograficDepartment");
            resolve(false);
        },10000);
        
        fetch(URL.SERVICE_GEOGRAFIC_LOCATION, settings)
            .then(response => response.json())
            .then(response => { 
                if(!didTimeOut) {
                    clearTimeout(timeout);
                    resolve(response)
                }
        })
        .catch((error) => {
            if(!didTimeOut){
                resolve(false)
            }else{
                //console.log("Error de Timeout en sincronizeApp::catch::getGeograficDepartment");
            }
        });
    });
    
        
  }

  async getGeograficProvince (masterTablePk)  {
    
    var bodyJson = {"id":masterTablePk, "type": 3};
    return new Promise((resolve, reject) => {
        const settings = {
            method: 'POST',
            headers: func.WSHeaderTokenCore(),
            body: JSON.stringify(bodyJson),
            timeout: 10000,
        };

        let didTimeOut = false;
        let timeout  = setTimeout(() => {
            didTimeOut = true;
            //console.log("Error de Timeout en sincronizeApp::timeout::getGeograficProvince");
            resolve(false);
        },10000);
        
        fetch(URL.SERVICE_GEOGRAFIC_LOCATION, settings)
            .then(response => response.json())
            .then(response => { 
                if(!didTimeOut) {
                    clearTimeout(timeout);
                    resolve(response)
                }
        })
        .catch((error) => {
            if(!didTimeOut){
                resolve(false)
            }else{
                //console.log("Error de Timeout en sincronizeApp::catch::getGeograficProvince");
            }
        });
    });

  }

  async getGeograficDistrict (masterTablePk)  {
    
    var bodyJson = {"id":masterTablePk, "type": 4};
    return new Promise((resolve, reject) => {
        const settings = {
            method: 'POST',
            headers: func.WSHeaderTokenCore(),
            body: JSON.stringify(bodyJson),
            timeout: 10000,
        };

        let didTimeOut = false;
        let timeout  = setTimeout(() => {
            didTimeOut = true;
            //console.log("Error de Timeout en sincronizeApp::timeout::getGeograficDistrict");
            resolve(false);
        },10000);
        
        fetch(URL.SERVICE_GEOGRAFIC_LOCATION, settings)
            .then(response => response.json())
            .then(response => { 
                if(!didTimeOut) {
                    clearTimeout(timeout);
                    resolve(response)
                }
        })
        .catch((error) => {
            if(!didTimeOut){
                resolve(false)
            }else{
                //console.log("Error de Timeout en sincronizeApp::catch::getGeograficDistrict");
            }
        });
    });

  }

/*
  public getAllSurvey(){

    let survey:surveyTo = {} as surveyTo;
    let lstSurveyTo:Array<surveyTo> = [] as Array<surveyTo>;

    let surveyDetail:surveyDetailTo = {} as surveyDetailTo;
    let lstSurveyDetail:Array<surveyDetailTo> = [] as Array<surveyDetailTo>;


    
    //SURVEY
    survey = {} as surveyTo;
    survey.id_survey_pk = 1;
    survey.question_content= "Como inversionista, ¿qué categoría de servicio le gustaría recibir de parte de ACRES SAB?";
    survey.survey_type = 311;
    survey.question_type = 312;
    survey.question_note = "";
    survey.question_order = 1;
    survey.status = 1;
    lstSurveyDetail = [] as Array<surveyDetailTo>;

    //SURVEY DETAIL 1
    surveyDetail = {} as surveyDetailTo;
    surveyDetail.id_survey_detail_pk = 1;
    surveyDetail.id_survey_fk = 1;
    surveyDetail.option_label = "Solo ejecución: usted como inversionista toma sus decisiones por cuenta y riesgo propio, sobre la base de sus propias consideraciones";
    surveyDetail.option_order = 1;
    surveyDetail.score = 0;
    surveyDetail.status = 1
    lstSurveyDetail.push(surveyDetail);

    //SURVEY DETAIL 2
    surveyDetail = {} as surveyDetailTo;
    surveyDetail.id_survey_detail_pk = 2;
    surveyDetail.id_survey_fk = 1;
    surveyDetail.option_label = "Asesoría: usted como inversionista toma sus decisiones por cuenta y riesgo propio, sobre la base de sus propias consideraciones y de consideraciones adicionales que puedan brindarle nuestros representantes del agente, según su perfil de inversionista.";
    surveyDetail.option_order = 2;
    surveyDetail.score = 0;
    surveyDetail.status = 1
    lstSurveyDetail.push(surveyDetail);
    
    //add survey detail to survey
    survey.survey_details = lstSurveyDetail;
    //add survey to list survey
    lstSurveyTo.push(survey);

    //SURVEY
    survey = {} as surveyTo;
    survey.id_survey_pk = 2;
    survey.question_content= "¿Qué edad tiene?";
    survey.survey_type = 311;
    survey.question_type = 312;
    survey.question_note = "";
    survey.question_order = 2;
    survey.status = 1;
    lstSurveyDetail = [] as Array<surveyDetailTo>;

    
    //SURVEY DETAIL 1
    surveyDetail = {} as surveyDetailTo;
    surveyDetail.id_survey_detail_pk = 3;
    surveyDetail.id_survey_fk = 2;
    surveyDetail.option_label = "Menos de 30 años";
    surveyDetail.option_order = 1;
    surveyDetail.score = 0;
    surveyDetail.status = 1
    lstSurveyDetail.push(surveyDetail);    

    //SURVEY DETAIL 2
    surveyDetail = {} as surveyDetailTo;
    surveyDetail.id_survey_detail_pk = 4;
    surveyDetail.id_survey_fk = 2;
    surveyDetail.option_label = "Entre 31 y 50 años";
    surveyDetail.option_order = 2;
    surveyDetail.score = 0;
    surveyDetail.status = 1
    lstSurveyDetail.push(surveyDetail); 

    //SURVEY DETAIL 3
    surveyDetail = {} as surveyDetailTo;
    surveyDetail.id_survey_detail_pk = 5;
    surveyDetail.id_survey_fk = 2;
    surveyDetail.option_label = "Entre 51 y 70 años";
    surveyDetail.option_order = 3;
    surveyDetail.score = 0;
    surveyDetail.status = 1;
    lstSurveyDetail.push(surveyDetail);

    //SURVEY DETAIL 3
    surveyDetail = {} as surveyDetailTo;
    surveyDetail.id_survey_detail_pk = 6;
    surveyDetail.id_survey_fk = 2;
    surveyDetail.option_label = "Más de 70 años";
    surveyDetail.option_order = 4;
    surveyDetail.score = 0;
    surveyDetail.status = 1;
    lstSurveyDetail.push(surveyDetail);


    //add survey detail to survey
    survey.survey_details = lstSurveyDetail;
    //add survey to list survey
    lstSurveyTo.push(survey);
    
    //RETURN
    return lstSurveyTo;
  }
  */
  public getParameterDefaultData(masterTablePk:number):Array<parameterTableTo> {
    let parameters:Array<parameterTableTo> = [];
    let parameter:parameterTableTo;

    if (masterTablePk == MASTER_TABLE.SEX){
      parameter = {} as parameterTableTo ;
      parameter.parameter_table_pk = 0;
      parameter.parameter_table_cd = '0';
      parameter.description= 'Masculino';
      parameters.push(parameter);

      parameter = {} as parameterTableTo ;
      parameter.parameter_table_pk = 1;
      parameter.parameter_table_cd = '1';
      parameter.description= 'Femenino';
      parameters.push(parameter);

    }else if (masterTablePk == MASTER_TABLE.YES_NO){
      parameter = {} as parameterTableTo ;
      parameter.parameter_table_pk = 1;
      parameter.parameter_table_cd = '1';
      parameter.description= 'Sí';
      parameters.push(parameter);

      parameter = {} as parameterTableTo ;
      parameter.parameter_table_pk = 0;
      parameter.parameter_table_cd = '0';
      parameter.description= 'No';
      parameters.push(parameter);

    }else if (masterTablePk == MASTER_TABLE.ACCEPT_DONT_ACEPT){
      parameter = {} as parameterTableTo ;
      parameter.parameter_table_pk = 1;
      parameter.parameter_table_cd = '1';
      parameter.description= 'Acepto';
      parameters.push(parameter);

      parameter = {} as parameterTableTo ;
      parameter.parameter_table_pk = 0;
      parameter.parameter_table_cd = '0';
      parameter.description= 'No Acepto';
      parameters.push(parameter);

    }else if (masterTablePk == MASTER_TABLE.SEND_POLICY_BY){
      parameter = {} as parameterTableTo ;
      parameter.parameter_table_pk = 1;
      parameter.parameter_table_cd = '1';
      parameter.description= 'Entrega en la oficina de la SAB';
      parameters.push(parameter);

      parameter = {} as parameterTableTo ;
      parameter.parameter_table_pk = 0;
      parameter.parameter_table_cd = '0';
      parameter.description= 'Correo electrónico';
      parameters.push(parameter);

    }else if (masterTablePk == MASTER_TABLE.INVESTMENTS_OTHER){
          
      parameter = {} as parameterTableTo ;
      parameter.parameter_table_pk = 1;
      parameter.parameter_table_cd = '1';
      parameter.description= 'Renta variable';
      parameters.push(parameter);

      parameter = {} as parameterTableTo ;
      parameter.parameter_table_pk = 2;
      parameter.parameter_table_cd = '2';
      parameter.description= 'Renta fija';
      parameters.push(parameter);

      parameter = {} as parameterTableTo ;
      parameter.parameter_table_pk = 3;
      parameter.parameter_table_cd = '3';
      parameter.description= 'Mercado laboral';
      parameters.push(parameter);

      parameter = {} as parameterTableTo ;
      parameter.parameter_table_pk = 4;
      parameter.parameter_table_cd = '4';
      parameter.description= 'Mercado extranjero';
      parameters.push(parameter);

      parameter = {} as parameterTableTo ;
      parameter.parameter_table_pk = 0;
      parameter.parameter_table_cd = '0';
      parameter.description= 'Otro';
      parameters.push(parameter);

      parameters.push(parameter);
    }

    return parameters;
  }
  
  public getParameterMockUp(masterTablePk:number):Array<parameterTableTo> {
      let parameters:Array<parameterTableTo> = [];
      let parameter:parameterTableTo;

      if(masterTablePk == MASTER_TABLE.DOCUMENT_TYPE){
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 16;
        parameter.parameter_table_cd = '16';
        parameter.description= 'DNI';
        parameters.push(parameter);
        
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 17;
        parameter.parameter_table_cd = '17';
        parameter.description= 'CARNET DE EXTRANJERIA';
        parameters.push(parameter);
        
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 9;
        parameter.parameter_table_cd = '9';
        parameter.description= 'PASAPORTE';
        parameters.push(parameter);

        return parameters;
      }/*
      else if (masterTablePk == MASTER_TABLE.GEO_COUNTRY){
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 1;
        parameter.parameter_table_cd = '1';
        parameter.description= 'ESTADOS UNIDOS';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 2;
        parameter.parameter_table_cd = '2';
        parameter.description= 'PERU';
        parameters.push(parameter);

      }else if (masterTablePk == MASTER_TABLE.GEO_DEPARTMENT){
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 3;
        parameter.parameter_table_cd = '3';
        parameter.id_related_parameter_table_fk = 1;
        parameter.description= 'CALIFORNIA';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 4;
        parameter.parameter_table_cd = '4';
        parameter.id_related_parameter_table_fk = 2;
        parameter.description= 'LIMA';
        parameters.push(parameter);

      }else if (masterTablePk == MASTER_TABLE.GEO_PROVINCE){
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 5;
        parameter.parameter_table_cd = '5';
        parameter.id_related_parameter_table_fk = 3;
        parameter.description= 'LOS ANGELES';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 6;
        parameter.parameter_table_cd = '6';
        parameter.id_related_parameter_table_fk = 4;
        parameter.description= 'LIMA';
        parameters.push(parameter);

      }else if (masterTablePk == MASTER_TABLE.GEO_DISTRICT){
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 7;
        parameter.parameter_table_cd = '7';
        parameter.id_related_parameter_table_fk = 5;
        parameter.description= 'LONG BEACH';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 8;
        parameter.parameter_table_cd = '8';
        parameter.id_related_parameter_table_fk = 6;
        parameter.description= 'CERCADO DE LIMA';
        parameters.push(parameter);

      }*/
      else if (masterTablePk == MASTER_TABLE.SETTLEMENT_TYPE){
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 10;
        parameter.parameter_table_cd = '10';
        parameter.description= 'URBANIZACION';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 11;
        parameter.parameter_table_cd = '11';
        parameter.description= 'AA.HH';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 11;
        parameter.parameter_table_cd = '11';
        parameter.description= 'CONDOMINIO';
        parameters.push(parameter);

      }else if (masterTablePk == MASTER_TABLE.STREET_TYPE){
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 12;
        parameter.parameter_table_cd = '12';
        parameter.description= 'CALLE';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 13;
        parameter.parameter_table_cd = '13';
        parameter.description= 'AVENIDA';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 14;
        parameter.parameter_table_cd = '14';
        parameter.description= 'PASAJE';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 15;
        parameter.parameter_table_cd = '15';
        parameter.description= 'JIRON';
        parameters.push(parameter);

      }else if (masterTablePk == MASTER_TABLE.SEX){
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 18;
        parameter.parameter_table_cd = '18';
        parameter.description= 'MASCULINO';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 19;
        parameter.parameter_table_cd = '19';
        parameter.description= 'FEMENINO';
        parameters.push(parameter);

      }else if (masterTablePk == MASTER_TABLE.YES_NO){
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 20;
        parameter.parameter_table_cd = '20';
        parameter.description= 'YES';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 21;
        parameter.parameter_table_cd = '21';
        parameter.description= 'NO';
        parameters.push(parameter);

      }else if (masterTablePk == MASTER_TABLE.SAB_RELATIONSHIP_TYPE){
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 22;
        parameter.parameter_table_cd = '22';
        parameter.description= 'EMPLEADO';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 23;
        parameter.parameter_table_cd = '23';
        parameter.description= 'ACCIONISTA';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 24;
        parameter.parameter_table_cd = '24';
        parameter.description= 'DIRECTIVO';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 25;
        parameter.parameter_table_cd = '25';
        parameter.description= 'OTROS';
        parameters.push(parameter);

      }else if (masterTablePk == MASTER_TABLE.MARTIAL_STATUS){
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 26;
        parameter.parameter_table_cd = '26';
        parameter.description= 'SOLTERO';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 27;
        parameter.parameter_table_cd = '27';
        parameter.description= 'CASADO';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 28;
        parameter.parameter_table_cd = '28';
        parameter.description= 'CONVIVIENTE';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 29;
        parameter.parameter_table_cd = '29';
        parameter.description= 'DIVORCIADO';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 30;
        parameter.parameter_table_cd = '30';
        parameter.description= 'VIUDO';
        parameters.push(parameter);

      }else if (masterTablePk == MASTER_TABLE.KNOWLEDGE){
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 31;
        parameter.parameter_table_cd = '31';
        parameter.description= 'NINGUNO';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 32;
        parameter.parameter_table_cd = '32';
        parameter.description= 'POCO';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 33;
        parameter.parameter_table_cd = '33';
        parameter.description= 'BUENO';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 34;
        parameter.parameter_table_cd = '34';
        parameter.description= 'EXPERTO';
        parameters.push(parameter);

      }else if (masterTablePk == MASTER_TABLE.INVESTMENT_HORIZON){
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 35;
        parameter.parameter_table_cd = '35';
        parameter.description= '0 - 6';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 36;
        parameter.parameter_table_cd = '36';
        parameter.description= '6 - 12';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 37;
        parameter.parameter_table_cd = '37';
        parameter.description= 'mas de 12';
        parameters.push(parameter);

      }else if (masterTablePk == MASTER_TABLE.SABS){
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 38;
        parameter.parameter_table_cd = '38';
        parameter.description= 'ACRES SAB';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 39;
        parameter.parameter_table_cd = '39';
        parameter.description= 'ADCAP SECURITIES PERU SAB';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 40;
        parameter.parameter_table_cd = '40';
        parameter.description= 'BNB VALORES PERU SAB';
        parameters.push(parameter);

      }else if (masterTablePk == MASTER_TABLE.ACTIVITY_TYPE){
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 41;
        parameter.parameter_table_cd = '41';
        parameter.description= 'DEPENDIENTE';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 42;
        parameter.parameter_table_cd = '42';
        parameter.description= 'INDEPENDIENTE';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 43;
        parameter.parameter_table_cd = '43';
        parameter.description= 'JUBILADO/PENSIONISTA';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 44;
        parameter.parameter_table_cd = '44';
        parameter.description= 'NO LABORA';
        parameters.push(parameter);

      }else if (masterTablePk == MASTER_TABLE.EDUCATION_LEVEL){
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 45;
        parameter.parameter_table_cd = '45';
        parameter.description= 'PRIMARIA';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 46;
        parameter.parameter_table_cd = '46';
        parameter.description= 'SECUNDARIA';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 47;
        parameter.parameter_table_cd = '47';
        parameter.description= 'SUPERIOR';
        parameters.push(parameter);

      }else if (masterTablePk == MASTER_TABLE.MARTIAL_REGIMEN){
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 48;
        parameter.parameter_table_cd = '48';
        parameter.description= 'SOCIEDAD DE GANANCIAS';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 49;
        parameter.parameter_table_cd = '49';
        parameter.description= 'SEPARACION DE BIENES';
        parameters.push(parameter);

      }else if (masterTablePk == MASTER_TABLE.TYPE_PERSON_PEP){
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 50;
        parameter.parameter_table_cd = '50';
        parameter.description= 'CLIENTE/INVERSIONISTA';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 51;
        parameter.parameter_table_cd = '51';
        parameter.description= 'CONYUGE';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 52;
        parameter.parameter_table_cd = '52';
        parameter.description= 'FAMILIAR';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 53;
        parameter.parameter_table_cd = '53';
        parameter.description= 'APODERADO/REPRESENTANTE';
        parameters.push(parameter);

      }else if (masterTablePk == MASTER_TABLE.FAMILIY_RELATIONSHIP){
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 54;
        parameter.parameter_table_cd = '54';
        parameter.description= 'PADRE';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 55;
        parameter.parameter_table_cd = '55';
        parameter.description= 'MADRE';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 56;
        parameter.parameter_table_cd = '56';
        parameter.description= 'HIJO';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 57;
        parameter.parameter_table_cd = '57';
        parameter.description= 'ABUELO LINEA PATERNA';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 58;
        parameter.parameter_table_cd = '58';
        parameter.description= 'ABUELA LINEA PATERNA';
        parameters.push(parameter);


        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 59;
        parameter.parameter_table_cd = '59';
        parameter.description= 'ABUELO LINEA MATERNA';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 60;
        parameter.parameter_table_cd = '60';
        parameter.description= 'ABUELA LINEA MATERNA';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 61;
        parameter.parameter_table_cd = '61';
        parameter.description= 'NIETOS';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 62;
        parameter.parameter_table_cd = '62';
        parameter.description= 'HERMANOS';
        parameters.push(parameter);

      }else if (masterTablePk == MASTER_TABLE.BANK){
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 63;
        parameter.parameter_table_cd = '63';
        parameter.description= 'BCP';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 64;
        parameter.parameter_table_cd = '64';
        parameter.description= 'BBVA';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 65;
        parameter.parameter_table_cd = '65';
        parameter.description= 'INTERBANK';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 66;
        parameter.parameter_table_cd = '66';
        parameter.description= 'SCOTIABANK';
        parameters.push(parameter);

      }else if (masterTablePk == MASTER_TABLE.BANK_ACCOUNT_TYPE){
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 67;
        parameter.parameter_table_cd = '67';
        parameter.description= 'CUENTA CORRIENTE';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 68;
        parameter.parameter_table_cd = '68';
        parameter.description= 'CUENTA AHORRO';
        parameters.push(parameter);

      }else if (masterTablePk == MASTER_TABLE.CURRENCY){
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 69;
        parameter.parameter_table_cd = '69';
        parameter.description= 'SOLES';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 70;
        parameter.parameter_table_cd = '70';
        parameter.description= 'DOLARES';
        parameters.push(parameter);

      }else if (masterTablePk == MASTER_TABLE.SEND_ORDER_BY_STR){
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 71;
        parameter.parameter_table_cd = '71';
        parameter.description= 'VIA TELEFONICA';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 72;
        parameter.parameter_table_cd = '72';
        parameter.description= 'CORREO ELECTRONICO';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 73;
        parameter.parameter_table_cd = '73';
        parameter.description= 'MEDIO ESCRITO';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 74;
        parameter.parameter_table_cd = '74';
        parameter.description= 'MEDIO DIGITAL';
        parameters.push(parameter);

      }else if (masterTablePk == MASTER_TABLE.SEND_POLICY_BY){
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 75;
        parameter.parameter_table_cd = '75';
        parameter.description= 'Entrega en la oficina de la SAB';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 76;
        parameter.parameter_table_cd = '76';
        parameter.description= 'Correo electrónico';
        parameters.push(parameter);

      }else if (masterTablePk == MASTER_TABLE.SEND_ACCOUNT_STATEMENT_BY){
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 77;
        parameter.parameter_table_cd = '77';
        parameter.description= 'Entrega en la oficina de la SAB';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 78;
        parameter.parameter_table_cd = '78';
        parameter.description= 'Correo electrónico';
        parameters.push(parameter);

      }else if (masterTablePk == MASTER_TABLE.PERFORM_OPERATIONS_ABOARD){
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 79;
        parameter.parameter_table_cd = '79';
        parameter.description= 'NO ACEPTO';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 80;
        parameter.parameter_table_cd = '80';
        parameter.description= 'ACEPTO';
        parameters.push(parameter);

      }else if (masterTablePk == MASTER_TABLE.INCOME_COMES_FROM){
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 79;
        parameter.parameter_table_cd = '79';
        parameter.description= 'PERU';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 80;
        parameter.parameter_table_cd = '80';
        parameter.description= 'EXTRANJEROS';
        parameters.push(parameter);
        
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 81;
        parameter.parameter_table_cd = '81';
        parameter.description= 'AMBOS';
        parameters.push(parameter);

      }else if (masterTablePk == MASTER_TABLE.FUNDS_SOURCE_STR){
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 82;
        parameter.parameter_table_cd = '82';
        parameter.description= 'REMUNERACIONES';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 83;
        parameter.parameter_table_cd = '83';
        parameter.description= 'AHORROS';
        parameters.push(parameter);
        
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 84;
        parameter.parameter_table_cd = '84';
        parameter.description= 'PRESTAMOS';
        parameters.push(parameter);
        
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 85;
        parameter.parameter_table_cd = '85';
        parameter.description= 'UTILIDADES / DIVIDENDOS RECIBIDOS';
        parameters.push(parameter);
        
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 86;
        parameter.parameter_table_cd = '86';
        parameter.description= 'VENTA DE ACTIVOS FINANCIEROS';
        parameters.push(parameter);
        
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 87;
        parameter.parameter_table_cd = '87';
        parameter.description= 'VENTA DE INMUEBLES U OTROS ACTIVOS';
        parameters.push(parameter);

        
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 88;
        parameter.parameter_table_cd = '88';
        parameter.description= 'HERENCIAS';
        parameters.push(parameter);

      }else if (masterTablePk == MASTER_TABLE.SUSCRIPTION_MODIFY){
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 89;
        parameter.parameter_table_cd = '89';
        parameter.description= 'CONTRASEÑA';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 90;
        parameter.parameter_table_cd = '90';
        parameter.description= 'TELEFONO';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 91;
        parameter.parameter_table_cd = '91';
        parameter.description= 'CORREO';
        parameters.push(parameter);
      }else if (masterTablePk == MASTER_TABLE.PARAM_ORDER_STATE){
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 1;
        parameter.parameter_table_cd = '1';
        parameter.description= 'REGISTRADO';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 2;
        parameter.parameter_table_cd = '2';
        parameter.description= 'PROCESADO';
        parameters.push(parameter);

        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 3;
        parameter.parameter_table_cd = '3';
        parameter.description= 'PACTADO';
        parameters.push(parameter);
        
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 4;
        parameter.parameter_table_cd = '4';
        parameter.description= 'ANULADO';
        parameters.push(parameter);
        
        parameter = {} as parameterTableTo ;
        parameter.parameter_table_pk = 5;
        parameter.parameter_table_cd = '5';
        parameter.description= 'MODIFICADO';
        parameters.push(parameter);
        
      }
      
      
      return parameters;
      
  }
  
  public getParameterMockUpDependency(masterTablePk:number, id_related_parameter_table_fk:number){
      let parameters:Array<parameterTableTo> = [];
      let parameter:parameterTableTo;

       if (masterTablePk == MASTER_TABLE.GEO_DEPARTMENT){

        if(id_related_parameter_table_fk == 1){
          parameter = {} as parameterTableTo ;
          parameter.parameter_table_pk = 3;
          parameter.parameter_table_cd = '3';
          parameter.id_related_parameter_table_fk = 1;
          parameter.description= 'CALIFORNIA';
          parameters.push(parameter);
        }

        if(id_related_parameter_table_fk == 2){
          parameter = {} as parameterTableTo ;
          parameter.parameter_table_pk = 4;
          parameter.parameter_table_cd = '4';
          parameter.id_related_parameter_table_fk = 2;
          parameter.description= 'LIMA';
          parameters.push(parameter);
        }

      }else if (masterTablePk == MASTER_TABLE.GEO_PROVINCE){

        if(id_related_parameter_table_fk == 3){
          parameter = {} as parameterTableTo ;
          parameter.parameter_table_pk = 5;
          parameter.parameter_table_cd = '5';
          parameter.id_related_parameter_table_fk = 3;
          parameter.description= 'LONG BEACH';
          parameters.push(parameter);
        }

        if(id_related_parameter_table_fk == 4){
          parameter = {} as parameterTableTo ;
          parameter.parameter_table_pk = 6;
          parameter.parameter_table_cd = '6';
          parameter.id_related_parameter_table_fk = 4;
          parameter.description= 'CERCADO DE LIMA';
          parameters.push(parameter);
        }

      }else if (masterTablePk == MASTER_TABLE.GEO_DISTRICT){
        if(id_related_parameter_table_fk == 5){
          parameter = {} as parameterTableTo ;
          parameter.parameter_table_pk = 7;
          parameter.parameter_table_cd = '7';
          parameter.id_related_parameter_table_fk = 5;
          parameter.description= 'LONG BEACH';
          parameters.push(parameter);
        }

        if(id_related_parameter_table_fk == 6){
          parameter = {} as parameterTableTo ;
          parameter.parameter_table_pk = 8;
          parameter.parameter_table_cd = '8';
          parameter.id_related_parameter_table_fk = 6;
          parameter.description= 'CERCADO DE LIMA';
          parameters.push(parameter);
        }

      }

      return parameters;
      
  }
 
}
