import { Injectable } from '@angular/core';
import { LoginService } from '../SessionService/login.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CanActivateViaAuthGuardService {

  constructor(private loginService: LoginService, private router: Router) { }
  
  canActivate() {
    // If the user is not logged in we'll send them back to the home page
    if (!this.loginService.isLogged()) {
        console.log('No estás logueado');
        this.router.navigate(['/']);
        return false;
    }

    return true;
  }
  
}
