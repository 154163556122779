import { Component, OnInit, ViewChild } from '@angular/core'; 
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { ModalService } from 'src/app/configuration/providers/modal';
import { investmentTo } from 'src/app/model/UserModel/investmentTo';
import { userTo } from 'src/app/model/UserModel/userTo';
import { URL } from 'src/app/constants/url';
import { CURRENCY } from 'src/app/constants/CURRENCY';
import { func } from 'src/app/constants/func';
import { responseInvesmentTo } from 'src/app/model/UserModel/responseInvesmentTo';
import { ProductoDetalleTo } from 'src/app/model/UserModel/ProductoDetalleTo';
import { ViewInvestmentMovement } from 'src/app/model/UserModel/ViewInvestmentMovement';
import { SimplemodalComponent } from '../../AppComponents/simplemodal/simplemodal.component';

@Component({
  selector: 'ark-detalle-movimiento',
  templateUrl: './detalle-movimiento.component.html',
  styleUrls: ['./detalle-movimiento.component.css']
})
export class DetalleMovimientoComponent implements OnInit {

  objUserTo = null; 
  @ViewChild(SimplemodalComponent,{static: false}) simplemodalComponent: SimplemodalComponent;

  constructor(private httpClient: HttpClient, private modalService: ModalService,
              private loginService: LoginService, private router: Router,
              private  spinner: NgxSpinnerService) { } 

  // listinvestmentToDolares : Array<investmentTo> = [] as Array<investmentTo>;
  entityProductoDetalleTo : ProductoDetalleTo = {} as ProductoDetalleTo; 
  lstViewInvestmentMovement : Array<ViewInvestmentMovement> = [] as Array<ViewInvestmentMovement>;
 
  ngOnInit() { 
      this.objUserTo = <userTo>this.loginService.currentUser();
      let invIdSecurityCodePk = JSON.parse(sessionStorage.getItem('invIdSecurityCodePk')); 
      this.loadProductDetailBySession(invIdSecurityCodePk); 
      this.loadProductMovement(invIdSecurityCodePk);
  }

  loadProductDetailBySession(invIdSecurityCodePk: String){ 

      var body = {
        "id_security_code_pk":  invIdSecurityCodePk
      }

      this.spinner.show(); 

      this.httpClient.post(URL.SERVICE_API_V1_CORE_GET_VIEW_PRODUCT_DETAIL //URL.SERVICE_API_V1_CORE_GET_VIEW_PRODUCT_DETAIL
                  ,body,        
            { headers: func.WSHeaderTokenCore() })
            . subscribe(
            (response) => { 
              //console.log(JSON.stringify(response));
              let productoDetalle = <ProductoDetalleTo>response; 
              this.entityProductoDetalleTo = productoDetalle; 

            },
            (err: HttpErrorResponse) => {
                console.log(err.error);
                console.log(err.name);
                console.log(err.message);
                console.log(err.status); 
              this.spinner.hide();
              return ;
          },
          () => {
            this.spinner.hide();
          console.log("************* this.httpClient.post ()::" + "The observable is now completed.");
          }
      ); 
  }

  loadProductMovement(invIdSecurityCodePk: String){ 


    var body = {
      "id_security_code_pk":""+ invIdSecurityCodePk,
      "rut":""+this.objUserTo.rut,
    }

    this.spinner.show(); 

    this.httpClient.post(URL.SERVICE_API_V1_CORE_GET_INVESTMENTS_MOVEMENT //URL.SERVICE_API_V1_CORE_GET_VIEW_PRODUCT_DETAIL
                ,body,        
          { headers: func.WSHeaderTokenCore() })
          . subscribe(
          (response) => { 
            console.log(JSON.stringify(response));
            this.lstViewInvestmentMovement = response as Array<ViewInvestmentMovement>; 
            
          },
          (err: HttpErrorResponse) => {
              console.log(err.error);
              console.log(err.name);
              console.log(err.message);
              console.log(err.status); 
            this.spinner.hide();
            return ;
        },
        () => {
          this.spinner.hide();
        console.log("************* this.httpClient.post ()::" + "The observable is now completed.");
        }
    );

}

  gotoPage(page: String){
    this.router.navigate([page]);
  } 

  downloadDetail(){

  
    var body = {
      "security_code":  this.entityProductoDetalleTo.idSecurityCodePk,
      "rut":  ""+this.objUserTo.rut
    }

    console.log("URL::"+URL.SERVICE_API_V1_CORE_GET_DOCUMENT_FILE_CLIENT_SECU_MOV + " body:: " + JSON.stringify(body) );
    
    this.httpClient.post(URL.SERVICE_API_V1_CORE_GET_DOCUMENT_FILE_CLIENT_SECU_MOV 
                , body,       
          { headers: func.WSHeaderTokenCore() ,  responseType: 'blob'  } )
        . subscribe(
        (response) => {    

          var downloadURL = window.URL.createObjectURL(response);
          var link = document.createElement('a');
          link.href = downloadURL;
          link.download = "Movimiento.pdf";
          link.click();    
        },
        (err: HttpErrorResponse) => {
            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);  
            this.spinner.hide();
            this.simplemodalComponent.alertar("Mensaje de Alerta","Ocurrio un error inesperado, contacte con el administrador.");
            return ;
        },
        () => {
          this.spinner.hide();
        }
    );  

  }

  
  gotoPageDetalle(){
    let invIdSecurityCodePk = JSON.parse(sessionStorage.getItem('invIdSecurityCodePk')); 
    sessionStorage.setItem('invIdSecurityCodePk',JSON.stringify(invIdSecurityCodePk));
    this.router.navigate(['/detalleInversion']);
  }

  gotoPageDetCronograma(){
    let invIdSecurityCodePk = JSON.parse(sessionStorage.getItem('invIdSecurityCodePk')); 
    sessionStorage.setItem('invIdSecurityCodePk',JSON.stringify(invIdSecurityCodePk));
    this.router.navigate(['/detalleCronograma']);
  }


  openTerminos(){
    //this.modalService.open('idTerminos');  
    this.simplemodalComponent.openModalInfo();
  }
/*
  closeTerminos(file_code: String){ 
    this.modalService.close('idTerminos');
  }
*/
  


}