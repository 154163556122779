import { Component, OnInit } from '@angular/core'; 
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { ModalService } from 'src/app/configuration/providers/modal';
import { investmentTo } from 'src/app/model/UserModel/investmentTo';
import { userTo } from 'src/app/model/UserModel/userTo';
import { URL } from 'src/app/constants/url';
import { CURRENCY } from 'src/app/constants/CURRENCY';
import { func } from 'src/app/constants/func';
import { responseInvesmentTo } from 'src/app/model/UserModel/responseInvesmentTo';

@Component({
  selector: 'ark-inversiones-detalle',
  templateUrl: './inversiones-detalle.component.html',
  styleUrls: ['./inversiones-detalle.component.css']
})
export class InversionesDetalleComponent implements OnInit {

  objUserTo = null; 

  idSecurityCodePk = null; 

  constructor(private httpClient: HttpClient, private modalService: ModalService,
              private loginService: LoginService, private router: Router,
              private  spinner: NgxSpinnerService) { } 

  // listinvestmentToDolares : Array<investmentTo> = [] as Array<investmentTo>;
 
  ngOnInit() { 
      this.objUserTo = <userTo>this.loginService.currentUser();
      this.idSecurityCodePk = JSON.parse(sessionStorage.getItem('invIdSecurityCodePk'));



      
  }
 

  gotoPage(page: String){
    this.router.navigate([page]);
  } 

  downloadDetail(){
    
  }

  


}