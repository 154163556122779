/*
Este Archivo es de constantes globales
ENVIROMENT es el nombre de la variable
*/

export const ENVIRONMENT = {
    //local
    /*
    SERVICE_CONTEXT_APPCRES: '',///api
    SERVICE_CONTEXT_CSDCORE: '/iBusinessIntegration',
    SERVICE_CONTEXT_CSDCORE_SECURITY: '/iSecurity',
    HOST_BACKEND_APPCRES  : 'http://localhost:8888',//'http://localhost:8888',//  HOST : 'http://52.89.179.248:8888',
    HOST_BACKEND_CSDCORE  : 'http://localhost:8080',//'http://localhost:8888',//  HOST : 'http://52.89.179.248:8888',
    HOST_FRONTEND_APPCRES : 'http://localhost:4200',//'http://simvest.s3-website-us-west-2.amazonaws.com', //http://localhost:4200
    HOST_FRONTEND_WEB     : 'http://web-appcres.s3-website-us-west-2.amazonaws.com/',
    CORS_ANYWHERE: ''
    */
    
    //server    headers.set("X-Requested-With", "XMLHttpRequest");
    SERVICE_CONTEXT_APPCRES: '',
    SERVICE_CONTEXT_CSDCORE: '/iBusinessIntegration',
    SERVICE_CONTEXT_CSDCORE_SECURITY: '/iSecurity',
//    HOST_BACKEND_APPCRES    : 'http://54.215.35.161:8888', 
    HOST_BACKEND_APPCRES    : 'https://portal-sab.acres.com.pe',
    HOST_BACKEND_CSDCORE    : 'http://host-sab.acres.com.pe:8080',
    HOST_FRONTEND_APPCRES   : 'ac-prod-webapp.s3.us-east-1.amazonaws.com', 
    HOST_FRONTEND_WEB       : 'https://portal-sab.acres.com.pe:8080',
    CORS_ANYWHERE: '' 
    
   /*
      //server
      SERVICE_CONTEXT_APPCRES: '',///api
      SERVICE_CONTEXT_CSDCORE: '/iBusinessIntegration',
      SERVICE_CONTEXT_CSDCORE_SECURITY: '/iSecurity',
      HOST_BACKEND_APPCRES    : 'http://52.89.179.248:8888',//'http://localhost:8888',//  HOST : 'http://52.89.179.248:8888',
      HOST_BACKEND_CSDCORE    : 'http://35.81.151.15:8080',//'http://localhost:8888',//  HOST : 'http://52.89.179.248:8888',
      HOST_FRONTEND_APPCRES   : 'http://appcres-htmldesktop.s3-website-us-west-2.amazonaws.com',//'http://localhost:4200', //http://appcres-htmldesktop.s3-website-us-west-2.amazonaws.com
      HOST_FRONTEND_WEB       : 'http://web-appcres.s3-website-us-west-2.amazonaws.com/',//'http://localhost:4200', //
      CORS_ANYWHERE: 'http://52.89.179.248:8099/'//,'https://cors-anywhere.herokuapp.com/'  // DESPLEGADO//
      */
};
