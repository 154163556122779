import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from 'src/app/configuration/providers/modal';
import { URL } from 'src/app/constants/url';
import { func } from 'src/app/constants/func';
import { userAccountTo } from 'src/app/model/UserModel/userAccountTo';
import { responseTo } from 'src/app/model/UserModel/responseTo';
import { USER_ACCOUNT_TYPE } from 'src/app/constants/USER_ACCOUNT_TYPE';
import { responseServiceTo } from 'src/app/model/UserModel/responseServiceTo';
import { SimplemodalComponent } from '../../AppComponents/simplemodal/simplemodal.component';

@Component({
  selector: 'ark-confirmar-suscripcion',
  templateUrl: './confirmar-suscripcion.component.html',
  styleUrls: ['./confirmar-suscripcion.component.css']
})
export class ConfirmarSuscripcionComponent implements OnInit {

  @ViewChild(SimplemodalComponent,{static: false}) simplemodalComponent: SimplemodalComponent;
  mensaje = '';
  mensajeSub = '';

  text1 = new FormControl(null);
  text2 = new FormControl(null);
  text3 = new FormControl(null);
  text4 = new FormControl(null);
  text5 = new FormControl(null);
  text6 = new FormControl(null);

  userId = null;
  fullName = '';
  phone_number:String = '';

  submitted = false;  
  token = new FormControl('', Validators.required);
  email_token = null;
  validation_emailtoken = false;
   
 
  dinamicShow = 1;

  constructor(private httpClient: HttpClient, private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private  spinner: NgxSpinnerService) { }

  ngOnInit() {

    this.dinamicShow = 1;
    this.validate();
    
  }

  validate(){
    
    this.userId = null;
    this.fullName = ''; 

    var emailweb = window.location.search;
    this.email_token = emailweb.replace( "?emailToken=", "" );

    if(this.email_token != null && this.email_token !=""){

      this.spinner.show();
      this.httpClient.get(URL.SERVICE_GET_USER_ACCOUNT_BY_EMAIL_TOKEN + this.email_token,{  headers: func.WSHeader() }). subscribe(
        (response) => {   
          this.spinner.hide();

          let obj : userAccountTo = <userAccountTo>response ;
          
          if(obj.items.length != 0){                
            this.userId = obj.items[0].id_user_pk ;
            this.fullName = obj.items[0].name+', '+obj.items[0].first_lastname;
            this.phone_number = obj.items[0].phone_number;
            this.validation_emailtoken = true;

            if(obj.items[0].status == 12){
              this.gotoLogin();
              return;
            }

          }else{
            this.validation_emailtoken = false;
            this.dinamicShow = 2;
          }
  
        },
        (err: HttpErrorResponse) => {
          this.spinner.hide();
          this.mensajeSub = '';
          this.mensaje = "Ocurrio un error \n " + err.message;
          this.dinamicShow = 2;
  
          this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);
        },
        () => {
          this.spinner.hide();
        }
      );

    }else{
      
      this.validation_emailtoken = false;
      this.dinamicShow = 2;
    }

  }

  
  nextKey(event: any, nextIdInput){
    var tecla = event.target.value;
    if(tecla != null && tecla != "" && tecla != " "){
      if(nextIdInput=="text2"){
        this.text2.setValue(null);
      }
      if(nextIdInput=="text3"){
        this.text3.setValue(null);
      }
      if(nextIdInput=="text4"){
        this.text4.setValue(null);
      }
      if(nextIdInput=="text5"){
        this.text5.setValue(null);
      }
      if(nextIdInput=="text6"){
        this.text6.setValue(null);
      }
      eval("$(function() {  $('#"+nextIdInput+"').focus(); });");
    }
  }
  
  public abrirModalReenvio() {  
      //var r = confirm("REENVÍO DE TOKEN \n ¿Desea generar nuevo token?");
      //if (r == true) {
        this.reenviarToken();
        this.spinner.hide();
      //}
  }

 public reenviarToken() {
 
        this.spinner.show();
        this.httpClient.get(URL.SERVICE_API_V1_USER + this.userId + 
                            URL.SERVICE_GET_USER_TOKEN_OUT_LINE_END + this.email_token,
                              { headers: func.WSHeader() })
              . subscribe(
            (response) => {  

                  let bodyParam = {
                      "phone_token" : true,
                      "email_token" : false
                  }

                  let responseTmp = <responseTo>response; // items[0].user_token_pk;
                  let userTokenId = responseTmp.items[0].id_user_token_pk;

                  // refrescar token
                  //  /api/v1/users/213/token/{userTokenId}/recreate
                  this.httpClient.post(URL.SERVICE_API_V1_USER + this.userId + 
                                       URL.SERVICE_RECREATE_TOKEN_MIDDLE + userTokenId +
                                       URL.SERVICE_RECREATE_TOKEN_END,
                            bodyParam, { headers: func.WSHeader() })
                              . subscribe(
                            (response) => {  
                                console.log(" response :: " + JSON.stringify(response) );
                              
                                //this.modalService.close('idModalReenvio');
                                this.mensajeSub = "Token reenviado";
                                this.mensaje = "Se generó un nuevo token por Email.";

                                this.dinamicShow = 4;
                                
                                this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);
                                this.spinner.hide();
                               // this.modalService.open('idModalSuccesfullSms');
                                
                            },
                            (err: HttpErrorResponse) => {
                              console.log(err.error);
                              console.log(err.name);
                              console.log(err.message);
                              console.log(err.status);
                              
                              this.spinner.hide();
                              this.mensajeSub = '';
                              //this.mensaje = "Ocurrio un error \n " + err.message;
                              this.mensaje = "No se pudo generar un nuevo token por Email. Genere un nuevo token o Contacte con su administrador";
                              
                              this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);
                              // this.modalService.open('idModalErrorfull');                              
                              return ;
                          },
                          () => {
                            this.spinner.hide();
                            console.log("************* this.httpClient.post ()::" + "The confirmar registro de suscripcion POST observable is now completed.");
                          }
                  );

                  // fin refrescar token
                
            },
            (err: HttpErrorResponse) => {
              
              this.spinner.hide();
              this.mensajeSub = '';
              this.mensaje = "Ocurrio un error \n " + err.message;

              this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);             
              return ;
          },
          () => {
            this.spinner.hide();
          }
        );
               
  
  }


  closeModal(id: string) {
     this.modalService.close(id);
  }


  /** public confirmarToken() {
    this.dinamicShow = 5;  
  }**/

  validateToken(){

    if( (this.text1.value == "" &&  this.text1.value == null) && 
        (this.text2.value == "" &&  this.text2.value == null) && 
        (this.text3.value == "" &&  this.text3.value == null) && 
        (this.text4.value == "" &&  this.text4.value == null) && 
        (this.text5.value == "" &&  this.text5.value == null) && 
        (this.text6.value == "" &&  this.text6.value == null) ){
        return false;
    }else{
        return true;
    }

  }
  
  public confirmarToken(){
    this.submitted = true;

    if(!this.validateToken()){
      return;
    }
    this.token.setValue(this.text1.value+""+this.text2.value+""+this.text3.value+""+this.text4.value+""+this.text5.value+""+this.text6.value);

   
    let datosToken = {
      "phone_token": this.token.value,
      "email_token": this.email_token 
    }

    this.spinner.show();
    this.httpClient.post(URL.SERVICE_VERIFY_TOKEN,
           datosToken, { headers: func.WSHeader() })
        . subscribe(
        (response) => {   
  

                  let datos = {
                    status : USER_ACCOUNT_TYPE.CONFIRMADO.ID
                  }

                  let respuesta = <responseServiceTo>response;
                   // 200 OK - 404 NO FOUND
                  if(Number(respuesta.registros) <= 0){
                    this.spinner.hide();
                    this.mensaje = "El Token ingresado es no es valido. Verifique por favor";
                    this.dinamicShow = 2;
                    this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);
                               
                    return; 
                  }
                  
                  this.httpClient.patch(URL.SERVICE_UPDATE_USER_ACOUNT_OUT_LINE + this.userId + 
                                        URL.SERVICE_UPDATE_USER_ACOUNT_OUT_LINE_END,
                              datos, { headers: func.WSHeader() })
                    . subscribe(
                    (response) => {

                          // deshabilita token
                          this.httpClient.patch(URL.SERVICE_DISABLED_USER_TOKEN_OUT_LINE,
                                      datosToken, { headers: func.WSHeader() })
                                . subscribe(
                                (response) => { 
                                  this.spinner.hide();
                                  this.mensajeSub = "Se confirmó la suscripción exitosamente.";
                                  this.mensaje = "";
                                  this.dinamicShow = 5;
                                  //this.modalService.open('idModalSuccesfull');
                                  //return;  

                                 // this.router.navigate(['/suscripcionConfirmsucessComponent']);

                                },
                                (err: HttpErrorResponse) => {
                                
                                  console.log(err.error);
                                  console.log(err.name);
                                  console.log(err.message);
                                  console.log(err.status);
          
                                  this.spinner.hide();
                                  this.mensajeSub = '';
                                  // + err.message;
                                  this.mensaje = "Ocurrio un problema al confirmar la suscripción. Reintente o Contacte con su administrador ";
                                  this.modalService.open('idModalWarning'); 
                                  return;
                                  // this.router.navigate(['/suscripcionConfirmerrorComponent']);
                                },
                                    () => {
                                      this.spinner.hide();
                                      console.log("************* this.httpClient.post ()::" + "The change status to disable in user token is now completed.");
                                }
                          );  
 

                      },
                      (err: HttpErrorResponse) => {
                      
                        console.log(err.error);
                        console.log(err.name);
                        console.log(err.message);
                        console.log(err.status);

                        this.spinner.hide();
                        this.mensajeSub = '';
                        this.dinamicShow = 2;
                        // + err.message;
                        this.mensaje = "Ocurrio un problema en la confirmación de la suscipción. Reintente o Contacte con su administrador ";
                        this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje); 
                        return;
                      },
                      () => {
                        this.spinner.hide();
                      }

                    );   
                  
          },
          (err: HttpErrorResponse) => {
          
            if(err.status == 404) { 
              this.spinner.hide();
              this.mensaje = "el Token de Email no es válido. Verifique por favor";
              this.dinamicShow = 3;
              this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);
            }else {
              this.spinner.hide();
              this.dinamicShow = 2;
              this.mensaje = "Ocurrio un problema en la confirmación de la suscipción. Reintente o Contacte con su administrador";
              this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);
            } 
             
          },
          () => {
            this.spinner.hide();
          }

        );   
  } 

  gotoLogin() {
    this.router.navigate(["/login"]);
  } 

}
