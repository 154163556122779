import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ark-footermenu-session',
  templateUrl: './footermenu-session.component.html',
  styleUrls: ['./footermenu-session.component.css']
})
export class FootermenuSessionComponent implements OnInit {

  
  constructor(private router: Router) { }

  ngOnInit() {
  }

  gotoPage(page: String){
    this.router.navigate([page]); 
  }

}
