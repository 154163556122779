import { documentTo } from '../model/UserModel/documentTo';


 
export const polizaTypeMaping = {

    CONTADO              : 1,
    REPO_PLAZO           : 2,
    REPO_VISTA           : 8,  
    REPO_SECUNDARIO      : 3,
    RENOVACION           : 6,
    CESION_DE_CUPONES    : 7,
    COLOCACION_PRIMARIA  : 4,  
    CANJE                : 5,
    PRESTAMO_DE_VALORES  : 9,

    getPolizaTypeDescription(type: number){ 
        var caracteresPermitidos = new Map(); 
        caracteresPermitidos.set(1, "CONTADO");
        caracteresPermitidos.set(2, "REPO PLAZO");
        caracteresPermitidos.set(8, "REPO A LA VISTA"); 
        caracteresPermitidos.set(3, "REPO SECUNDARIO"); 
        caracteresPermitidos.set(6, "RENOVACION"); 
        caracteresPermitidos.set(7, "CESION DE CUPONES"); 
        caracteresPermitidos.set(4, "COLOCACION PRIMARIA");  
        caracteresPermitidos.set(5, "CANJE"); 
        caracteresPermitidos.set(9, "PRESTAMO DE VALORES"); 

        return caracteresPermitidos.get(type); 
    },

    getListPolizaType(){
       /** let listDoc : Array<documentTo> = [] as Array<documentTo>;
        let doc : documentTo = {} as documentTo;
        doc.id = 1; doc.name = 'CONTADO'; listDoc.push(doc); **/
    }



 }
