import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { URL } from 'src/app/constants/url';

@Component({
  selector: 'ark-newinvestor-confirmsucess',
  templateUrl: './newinvestor-confirmsucess.component.html',
  styleUrls: ['./newinvestor-confirmsucess.component.css']
})
export class NewinvestorConfirmsucessComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
  }

  goToLogin(){
    this.router.navigate(['/login']);
  }

  gotoProducto(){
    window.location.href = URL.GO_TO_WEB_PRODUCTOS;
  }

}
