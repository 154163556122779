import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';  
import { NgxSpinnerService } from 'ngx-spinner';


import { parameterTableTo } from 'src/app/model/UserModel/parameterTableTo';
import { userTo } from 'src/app/model/UserModel/userTo';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { pattern } from 'src/app/constants/pattern';
import { ParameterService } from 'src/app/services/ParameterServices/parameter.service';
import { MASTER_TABLE } from 'src/app/constants/masterTable';
import { constantSimvest } from 'src/app/constants/constantSimvest';
import { func } from 'src/app/constants/func';
import { URL } from 'src/app/constants/url';
import { errorMaping } from 'src/app/constants/errorMaping';
import { documentTypeMaping } from 'src/app/constants/documentTypeMaping';
import { investorRequestStatus } from 'src/app/model/UserModel/investorRequestStatus';
import { SimplemodalComponent } from '../simplemodal/simplemodal.component';
import { MESSAGE_ES } from 'src/app/constants/message_es';
import { storageUsers } from 'src/app/model/UserModel/storageUsers';
import { issuerSession } from 'src/app/model/UserModel/issuerSession';
import { AuthorizeInfo } from 'src/app/model/UserModel/AuthorizeInfo';
import { UserAuditTracking } from 'src/app/model/UserModel/UserAuditTracking';
import { investorRequestRut } from 'src/app/model/UserModel/investorRequestRut';

@Component({
  selector: 'ark-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @ViewChild(SimplemodalComponent,{static: false}) simplemodalComponent: SimplemodalComponent;
  loginType = new FormControl('');
  formulario:{};
  
  authorizeInfo:AuthorizeInfo = null;
  userTo:userTo = null;
  
  documentType = new FormControl('');
  documentNumber = new FormControl('');  
  cellphone = new FormControl('');  
  email = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('');  

  httpClientPost = '';
  fetchPost = '';

  keyboard = false;
  showPassword = false;
  flagRememberme = false;

  paramLoginType:Array<parameterTableTo> = [] as Array<parameterTableTo>;
  paramTipoDocumento:Array<parameterTableTo> = [] as Array<parameterTableTo>;

  mensaje = '';

  emailFormat = pattern.EMAIL;

  documentSize = 0;
  submitted = false;
  miStorageUser:storageUsers = null;
  ip = "127.0.0.1";

  issuerSession:Array<issuerSession> = [] as Array<issuerSession>;

  constructor(private httpClient: HttpClient,
              private loginService: LoginService,
              private router: Router,
              private parameterService: ParameterService,
              private  spinner: NgxSpinnerService
              ) {
    
  }
  
  checkRememberme(){
    this.flagRememberme = !this.flagRememberme;
  }

  setRememberEmail(){
    if(this.flagRememberme){
      this.miStorageUser = {} as storageUsers;
      this.miStorageUser.username = this.email.value;
      sessionStorage.setItem('storageUser', JSON.stringify(this.miStorageUser));
    }else{
      sessionStorage.setItem('storageUser', null);
    }
  }

  getRememberEmail(){
    var miFunction = "";
    this.miStorageUser = (<storageUsers>JSON.parse(sessionStorage.getItem('storageUser')));
    if(this.miStorageUser != null){
      this.email.setValue(this.miStorageUser.username);
      this.password.setValue(null);
      miFunction = "$(function() { $('input[name=rememberme]').prop('checked', 'checked'); });";
      this.flagRememberme = true;
    }else{
      this.email.setValue(null);
      this.password.setValue(null);
      miFunction = "$(function() { $('input[name=rememberme]').prop('checked', ''); });";
      this.flagRememberme = false;
    }
    eval(miFunction);
  }
  
  ngOnInit() {
    this.getIp();
    this.getRememberEmail();
    this.loginType.setValue(2);
    this.loginService.logout();
    this.showPassword = false;
    this.getParameterTable(); 
  }

  async getParameterTable(){
    let listaParametroCountrys  = await this.parameterService.getParameter(MASTER_TABLE.DOCUMENT_TYPE);
    this.paramTipoDocumento     =  listaParametroCountrys as Array<parameterTableTo>; 
    this.documentType.setValue(this.paramTipoDocumento[0].parameter_table_pk);
    this.changeDocument();

    let parameters:Array<parameterTableTo> = [];
    let parameter:parameterTableTo;

    parameter = {} as parameterTableTo ;
    parameter.parameter_table_pk = 1;
    parameter.parameter_table_cd = '1';
    parameter.description= 'Doc de Identidad';
    parameters.push(parameter);
    
    parameter = {} as parameterTableTo ;
    parameter.parameter_table_pk = 2;
    parameter.parameter_table_cd = '2';
    parameter.description= 'Correo';
    parameters.push(parameter);
    
    this.paramLoginType = parameters;

  }

  public closeSession(){
    this.httpClientPost ="";
    this.fetchPost="";
    this.loginService.logout();
  }

  public validationForm(){
    var estado = true;

    if(this.loginType.value == 2){
      this.submitted = true; 
      if (this.email.invalid ) {
        estado = false;
      } 
    } 

    if( this.password.value == '' || 
          (
              ( this.loginType.value == 1 && ( this.documentType.value == '' || this.documentNumber.value == '' ) ) || 
              ( this.loginType.value == 2 &&  this.email.value == '' ) ||    
              ( this.loginType.value == 3 &&  this.cellphone.value == '' ) )
                                            ) {

          this.simplemodalComponent.alertar(MESSAGE_ES.CABECERA.WARNING, MESSAGE_ES.LOGIN.AUTENTICACION_VALIDACION);
          estado = false;
    }

    return estado;
  }

  clearSessionData(){
    sessionStorage.removeItem('listCarritoDetalleTo');
  }

  public iniciarSession(){ 

    if(!this.validationForm){  return;  }

    this.setRememberEmail();

    this.clearSessionData();

    var body = {
      "client_key"      : constantSimvest.CLIENT_KEY,
      //"document_type"   : this.documentType.value,
      //"document_number" : this.documentNumber.value,
      "email"           : this.email.value,
      //"phone_number"    : this.cellphone.value,
      "password"        : this.password.value
    }

    this.userTo = null;
    
        this.spinner.show();
        this.httpClient.post(URL.SERVICE_LOGIN, body, { headers: func.WSHeader() } )
          .subscribe(
              (response) => {
                  this.authorizeInfo = <AuthorizeInfo>response;
                  this.createAllSessions();
                  this.getDataUserAccount();
              },
              (err: HttpErrorResponse) => {
                if(err.status == errorMaping.CODE_417){ 
                  this.simplemodalComponent.alertar(MESSAGE_ES.CABECERA.WARNING, "La cuenta no ha sido activada. Verifique por favor");
                  this.spinner.hide();
                  return;
                }else if(err.status == errorMaping.CODE_409){ 
                  this.simplemodalComponent.alertar(MESSAGE_ES.CABECERA.WARNING, MESSAGE_ES.LOGIN.AUTENTICACION_CREDENCIALES_ERROR);
                  this.spinner.hide();
                  return;
                }
              },
              () => {
                  this.spinner.hide();
              }
          );
  }

  public getIp(){
    this.httpClient.get("http://api.ipify.org/?format=json").subscribe((res:any)=>{  
      this.ip=res.ip;  
    });  
  }


  public getDataUserAccount(){
    
      this.httpClient.get(URL.SERVICE_API_V1_USER + this.authorizeInfo.id_user_pk + URL.SERVICE_GET_USER_ACCOUNT_END, {  headers: func.WSHeader() })
          .subscribe(
            (response) => {   

                this.userTo = <userTo>response ;
                this.tracking();
                if( this.userTo.rut_code != null  && this.userTo.rut_code != ""){
                  this.userTo.fullname = this.userTo.name + ', ' +  this.userTo.first_lastname ;//+ '. RUT: ' + this.userTo.rut_code
                  //this.getInvestorRequestStatusCORE();
                  this.endLoginAction();
                }else{
                  this.userTo.email = this.email.value;

                  this.getInvestorRutCORE();
                  /*
                  this.simplemodalComponent.alertar(MESSAGE_ES.CABECERA.WARNING, "La cuenta no tiene un RUT. Contactese con Acres por favor.");
                  this.spinner.hide();
                  return;
                  */
                  //this.userTo.fullname = this.userTo.name + ', ' +  this.userTo.first_lastname + '. RUT: No tiene un RUT';
                  //this.getInvestorRequestStatusCORE();
                }
                
            },
            (err: HttpErrorResponse) => {

            },
            () => {
              this.spinner.hide();
            }
      );  

  }

  public async tracking(){ 
    
    var body:userTo       = {} as userTo;
    body.id_user_pk       = this.authorizeInfo.id_user_pk;
    body.login_user       = this.authorizeInfo.access_token;
    body.name             = window.location.href;
    body.first_lastname   = "";
    body.second_lastname  = this.ip;


    console.log("URL.SERVICE_LOGIN_TRACKING::"+JSON.stringify(body));

        this.spinner.show();
        this.httpClient.post(URL.SERVICE_LOGIN_TRACKING, body, { headers: func.WSHeader() } )
          .subscribe(
              (response) => {
                  
                  var userAuditTracking:UserAuditTracking = response as UserAuditTracking;
                  console.log("URL.SERVICE_LOGIN_TRACKING::tracking::response:: "+JSON.stringify(response));
                  localStorage.setItem("UserAuditTracking",JSON.stringify(userAuditTracking));
                  
              },
              (err: HttpErrorResponse) => {
                if(err.status == errorMaping.CODE_417){ 
                  this.simplemodalComponent.alertar(MESSAGE_ES.CABECERA.WARNING, "El usuario aun no fue confirmado. Verifique por favor");
                  this.spinner.hide();
                  return;
                }else if(err.status == errorMaping.CODE_409){ 
                  this.simplemodalComponent.alertar(MESSAGE_ES.CABECERA.WARNING, MESSAGE_ES.LOGIN.AUTENTICACION_CREDENCIALES_ERROR);
                  this.spinner.hide();
                  return;
                }

              },
              () => {
                  this.spinner.hide();
              }
          );
  }


  
  public getInvestorRequestStatusCORE(){
    
    var body = {
      "document_type": ""+ this.userTo.document_type,
      "document_number": ""+this.userTo.document_number,
    }

    this.httpClient.post(URL.SERVICE_API_V1_CORE_GET_INVESTOR_REQUEST_STATUS, body , {  headers: func.WSHeaderTokenCore() })
      .subscribe(
          (response) => {  
              var investorRequestStatus:investorRequestStatus = response as  investorRequestStatus;
              this.userTo.evaluationFlow = investorRequestStatus.evaluationFlow;
              this.userTo.status = investorRequestStatus.status;
              this.userTo.idInvestorRequestPk = investorRequestStatus.idInvestorRequestPk;
              
              if(investorRequestStatus.rutCode!=null){
                this.userTo.rut_code = investorRequestStatus.rutCode;
                this.userTo.rut = Number(investorRequestStatus.rutCode);
                this.userTo.fullname = this.userTo.name + ', ' +  this.userTo.first_lastname ;//+ ' - RUT: ' + this.userTo.rut
              }
              //this.settSessionRutData();    

              this.spinner.hide();
              this.endLoginAction();

          },
          (err: HttpErrorResponse) => {
            this.endLoginAction();    
            this.spinner.hide();     
          },
          () => {
            
          }
      ); 
  }

  public getInvestorRutCORE(){
    
    var body = {
      "document_type": ""+ this.userTo.document_type,
      "document_number": this.userTo.document_number,
      "email": this.userTo.email,
    }

    this.httpClient.post(URL.SERVICE_API_V1_CORE_POST_INVESTOR_RUT, body , {  headers: func.WSHeaderTokenCore() })
      .subscribe(
          (response) => {  
            var rutCode: String = "";
            var fullname: String = "";
              var investorRequestRut: Array<investorRequestRut> = response as Array<investorRequestRut>;
              for(var i=0; i < investorRequestRut.length; i++){
                rutCode += (i == 0)?""+investorRequestRut[i].rut:","+investorRequestRut[i].rut;
                fullname = investorRequestRut[i].fullname;
              }
              
              if(rutCode!=null && rutCode!=""){
                this.userTo.rut_code = rutCode;
                this.userTo.fullname = fullname ;
              }else{
                this.simplemodalComponent.alertar(MESSAGE_ES.CABECERA.WARNING, "Por favor para poder continuar primero debe registrarse como cliente en el siguiente link https://forms.acres.com.pe/acceso-clientes ");
                this.spinner.hide();
                return;
              }
              this.settSessionRutData();    
          },
          (err: HttpErrorResponse) => {
            console.log(err.message);
            this.endLoginAction();    
            this.spinner.hide();     
          },
          () => {
            
          }
      ); 
  }

  

  
  public settSessionRutData(){ 

        var body = {
          "rut_code": this.userTo.rut_code
        }

        this.spinner.show();
        this.httpClient.patch(URL.SERVICE_UPDATE_USER_ACOUNT_OUT_LINE + this.userTo.id_user_pk + URL.SERVICE_UPDATE_USER_ACOUNT_OUT_LINE_END, body, { headers: func.WSHeader() } )
          .subscribe(
            (response) => {
              
                this.spinner.hide();
                this.endLoginAction();
                //this.settIssuerSessionData();

            },
            (err: HttpErrorResponse) => {
              this.endLoginAction();
            },
            () => {
                this.spinner.hide();
            }
          );

  }

  public settIssuerSessionData(){ 

    var body = {}

    this.spinner.show();
    this.httpClient.post(URL.SERVICE_API_V1_CORE_POST_ISSUER_SESSION, body, { headers: func.WSHeaderTokenCore() } )
      .subscribe(
        (response) => {

            this.issuerSession =  response as Array<issuerSession>; 
            console.log("URL.SERVICE_API_V1_CORE_POST_ISSUER_SESSION::"+JSON.stringify(this.issuerSession));
            sessionStorage.setItem("issuerSession", JSON.stringify(this.issuerSession));

            //this.spinner.hide();
            this.endLoginAction();
        },
        (err: HttpErrorResponse) => {
          this.endLoginAction();
        },
        () => {
            this.spinner.hide();
        }
      );

  }


  public endLoginAction(){
    
    sessionStorage.setItem('loginUser', JSON.stringify(this.userTo));
    this.loginService.login(this.userTo); 
    if( this.userTo.rut_code != null && this.userTo.rut_code != "" ){
      this.router.navigate(['/reportEstadoCuenta']);//home  
    } else {
      this.router.navigate(['/login']);//newinvestor
    } 

  }
  
  public createAllSessions(){
    //CREAR TODOS LOS SESSION CON DATOS NULOS PARA QUE NO OCURRA CAIDAS
    //-----------------------------------------------------------------
    //sessionStorage.setItem('loginUser', JSON.stringify({}));
    sessionStorage.setItem('modalTerms', "true");
  }

  public estadoSession(){
    let user = this.loginService.currentUser();
    this.httpClientPost = JSON.stringify(user);
    this.fetchPost = JSON.stringify(user);
  }

  changeLoginType(tipo){
    this.loginType = tipo;
    this.documentType.setValue('');   
    this.documentNumber.setValue('');  
    this.cellphone.setValue('');
    this.email.setValue('');
  }

 
  openKeyboard(){
    this.keyboard = true;
  }

  virtualKeyBoardPushed(keyValue){
    if(this.password.value == null){
      this.password.setValue('');
    }
      this.password.setValue(this.password.value + keyValue); 
  }

  clearPassword() {
    this.password.setValue('');
  }
  
  visibleKeyboard:boolean = false;
  pressVisibleKeyboard() {
    this.visibleKeyboard = true;
  }

  minuscula:boolean = false;
  mayusculaMinuscula() {
    this.minuscula = !this.minuscula;
  }

  number:boolean = false;
  numberCharacteres(){
    this.number = !this.number;
    this.minuscula = true;
  }

  toggleShowPassword() {
      this.showPassword = !this.showPassword;
  }

  gotoPage(page: String){
    this.router.navigate([page]); 
  }

  changeDocument(){

    this.documentSize = 0;
    this.documentNumber = new FormControl('');

    if(this.documentType.value != null && this.documentType.value == documentTypeMaping.DNI){
      this.documentSize = 8;
    }else if( this.documentType.value != null && 
            ( this.documentType.value == documentTypeMaping.CARNET_EXTRANJERIA || this.documentType.value == documentTypeMaping.PASAPORTE) ){
      this.documentSize = 14;
    }
    
  }

  onKeyDocumentNumber(event: any){  
    
    if(this.documentType.value == documentTypeMaping.DNI){
       this.documentNumber.setValue(func.validateImputDni(event.target.value));
    }else if(this.documentType.value == documentTypeMaping.CARNET_EXTRANJERIA){
       this.documentNumber.setValue(func.validateCarnetYPasaporte(event.target.value)); 
    }else if(this.documentType.value == documentTypeMaping.PASAPORTE){
      this.documentNumber.setValue(func.validateCarnetYPasaporte(event.target.value)); 
    }

  }

}
