import { Component, OnInit, ViewChild, AfterViewInit  } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { IfStmt } from '@angular/compiler';
import { Router } from '@angular/router';
import { InvestorRequest } from 'src/app/model/UserModel/InvestorRequest';
import { InvReqPerson } from 'src/app/model/UserModel/InvReqPerson';
import { parameterTableTo } from 'src/app/model/UserModel/parameterTableTo';
import { InvReqBankAccount } from 'src/app/model/UserModel/InvReqBankAccount';
import { InvReqNpAdditionalInformationTo } from 'src/app/model/UserModel/InvReqNpAdditionalInformationTo';
import { InvestorRequestPepTo } from 'src/app/model/UserModel/InvestorRequestPepTo';
import { InvestorRequestSurveyTo } from 'src/app/model/UserModel/InvestorRequestSurveyTo';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { ModalService } from 'src/app/configuration/providers/modal';
import { ParameterService } from 'src/app/services/ParameterServices/parameter.service';
import { userTo } from 'src/app/model/UserModel/userTo';
import { InvestorRequestLog } from 'src/app/model/UserModel/InvestorRequestLog';
import { InvReqRelated } from 'src/app/model/UserModel/InvReqRelated';
import { func } from 'src/app/constants/func';
import { URL } from 'src/app/constants/url';
import { errorMaping } from 'src/app/constants/errorMaping';
import { Input, Output,EventEmitter } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';  
import { FormBuilder, FormArray } from '@angular/forms';  
import { FormGroup } from '@angular/forms';  
import { InvestorRequestFatca } from 'src/app/model/UserModel/InvestorRequestFatca';
import { MASTER_TABLE } from 'src/app/constants/masterTable';
import { PARAMETER_TABLE_YES_NO } from 'src/app/constants/PARAMETER_TABLE_YES_NO';
import { surveyResponse } from 'src/app/model/UserModel/surveyResponse';
import { surveyTo } from 'src/app/model/UserModel/surveyTo';
import { InvestorRequestReturn } from 'src/app/model/UserModel/InvestorRequestReturn';
import { entity } from 'src/app/constants/entity';
import { DatosanexopepComponent } from 'src/app/components/InvestorComponents/datosanexopep/datosanexopep.component' 
import { DatosanexopepConyugeComponent } from '../datosanexopepConyuge/datosanexopepConyuge.component';
import { DatosanexopepFamiliaComponent } from '../datosanexopepFamilia/datosanexopepFamilia.component';
import { SimplemodalComponent } from '../../AppComponents/simplemodal/simplemodal.component';
import { ResponseInvestorRequest } from 'src/app/model/UserModel/ResponseInvestorRequest';
import { step6 } from 'src/app/model/UserModel/step6';
import { DatosanexosComponent } from '../datosanexos/datosanexos.component';
import { InvReqPersonReturn } from 'src/app/model/UserModel/InvReqPersonReturn';
import { countryMaping } from 'src/app/constants/countryMaping';
import { step5 } from 'src/app/model/UserModel/step5';
import { step4 } from 'src/app/model/UserModel/step4';
import { step3 } from 'src/app/model/UserModel/step3';
import { step2 } from 'src/app/model/UserModel/step2';
import { step1 } from 'src/app/model/UserModel/step1';
import { DatospersonalesComponent } from '../datospersonales/datospersonales.component';
import { DatosperfilriesgoComponent } from '../datosperfilriesgo/datosperfilriesgo.component';
import { DatosactividadinstruccionComponent } from '../datosactividadinstruccion/datosactividadinstruccion.component';
import { DatoscuentasbancariasComponent } from '../datoscuentasbancarias/datoscuentasbancarias.component';
import { DatosorigenfondosComponent } from '../datosorigenfondos/datosorigenfondos.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { InvestorRequestSurveyToReturn } from 'src/app/model/UserModel/InvestorRequestSurveyToReturn';
import { InvReqBankAccountReturn } from 'src/app/model/UserModel/InvReqBankAccountReturn';
import { InvestorRequestPepToReturn } from 'src/app/model/UserModel/InvestorRequestPepToReturn';
import { step3CurrentPep } from 'src/app/model/UserModel/step3CurrentPep';
import { step3ConyugePep } from 'src/app/model/UserModel/step3ConyugePep';
import { step3FamilyPep } from 'src/app/model/UserModel/step3FamilyPep';
import { InvestorRequestLogReturn } from 'src/app/model/UserModel/InvestorRequestLogReturn';
import { InvReqRelatedReturn } from 'src/app/model/UserModel/InvReqRelatedReturn';
import { InvestorRequestSearch } from 'src/app/model/UserModel/InvestorRequestSearch';


@Component({
  selector: 'ark-newinvestor',
  templateUrl: './newinvestor.component.html',
  styleUrls: ['./newinvestor.component.css']
})
export class NewinvestorComponent implements OnInit, AfterViewInit {

    /*
      Estado de la solicitud
    */
    paramInvestorRequestStates:Array<parameterTableTo>    = [] as Array<parameterTableTo>;
    /*
        parametros
    */
    paramCountry:Array<parameterTableTo>                  = [] as Array<parameterTableTo>;
    paramDepartment:Array<parameterTableTo>               = [] as Array<parameterTableTo>;
    paramProvince:Array<parameterTableTo>                 = [] as Array<parameterTableTo>;
    paramDistrict:Array<parameterTableTo>                 = [] as Array<parameterTableTo>;
    paramSettlementType:Array<parameterTableTo>           = [] as Array<parameterTableTo>;
    paramStreetType:Array<parameterTableTo>               = [] as Array<parameterTableTo>;
    paramDocumentType:Array<parameterTableTo>             = [] as Array<parameterTableTo>;
    paramSex:Array<parameterTableTo>                      = [] as Array<parameterTableTo>;
    paramYesNo:Array<parameterTableTo>                    = [] as Array<parameterTableTo>;
    paramMartialStatus:Array<parameterTableTo>            = [] as Array<parameterTableTo>;
    paramMartialRegime:Array<parameterTableTo>            = [] as Array<parameterTableTo>;
    paramActivityType:Array<parameterTableTo>             = [] as Array<parameterTableTo>;
    paramEducationLeve:Array<parameterTableTo>            = [] as Array<parameterTableTo>;
    paramCommercialActivity:Array<parameterTableTo>       = [] as Array<parameterTableTo>;
    paramBankAccountType:Array<parameterTableTo>          = [] as Array<parameterTableTo>;
    paramBank:Array<parameterTableTo>                     = [] as Array<parameterTableTo>;
    paramCurrency:Array<parameterTableTo>                 = [] as Array<parameterTableTo>;
    paramSendOrderByStr:Array<parameterTableTo>           = [] as Array<parameterTableTo>;        
    paramSendPolicyBy:Array<parameterTableTo>             = [] as Array<parameterTableTo>;           
    paramSendAccountStatementBy:Array<parameterTableTo>   = [] as Array<parameterTableTo>;
    paramPerformOperationsAbroad:Array<parameterTableTo>  = [] as Array<parameterTableTo>;
    paramIncomeComesFrom:Array<parameterTableTo>          = [] as Array<parameterTableTo>;   
    paramFundsSourceStr:Array<parameterTableTo>           = [] as Array<parameterTableTo>;   
    paramPatrimonialAmount:Array<parameterTableTo>        = [] as Array<parameterTableTo>;     
    paramTypePersonPep:Array<parameterTableTo>            = [] as Array<parameterTableTo>;     
    paramFamilyRelationship:Array<parameterTableTo>       = [] as Array<parameterTableTo>;     
  
    
    paramInvestmentOther:Array<parameterTableTo>          = [] as Array<parameterTableTo>;
    allSurvey:Array<surveyTo>                             = [] as Array<surveyTo>;

    /*
        variables no form 
     */
    showForm     : String;
    tipoAccion   : String;
    objUserTo    : userTo;
    enabledForm  : boolean = true;
    
    /*
        variables de entidades 
     */
    investorRequest: InvestorRequestReturn  = {} as InvestorRequestReturn;

    entity_InvReqPerson             : InvReqPersonReturn = {} as InvReqPersonReturn; 
    entity_InvReqPerson_conyuge     : InvReqPersonReturn = {} as InvReqPersonReturn; 
    allInvestorRequestSurvey        : Array<InvestorRequestSurveyToReturn> = [] as Array<InvestorRequestSurveyToReturn>;
    entity_datosCuentasBancarias    : InvReqBankAccountReturn = {} as InvReqBankAccountReturn;

    currentInvestorRequestPepTo     : InvestorRequestPepToReturn = {} as InvestorRequestPepToReturn;
    conyugeInvestorRequestPepTo     : InvestorRequestPepToReturn = {} as InvestorRequestPepToReturn; 
    lstFamilyInvestorRequestPepTo   : Array<InvestorRequestPepToReturn> = [] as Array<InvestorRequestPepToReturn>;

    lstInvestorRequestPepTo         : Array<InvestorRequestPepToReturn> = [] as Array<InvestorRequestPepToReturn>;         
    

    dataStep1 :step1 = {} as step1;
    dataStep2 :step2 = {} as step2;
    dataStep3 :step3 = {} as step3;
    dataStep4 :step4 = {} as step4;
    dataStep5 :step5 = {} as step5;
    dataStep6 :step6 = {} as step6;
    
    dataStep3CurrentPep :step3CurrentPep  = {} as step3CurrentPep;
    dataStep3ConyugePep :step3ConyugePep  = {} as step3ConyugePep;
    dataStep3FamilyPep  :step3FamilyPep   = {} as step3FamilyPep;

    /*
        variables de control a components externos
    */
    @ViewChild(SimplemodalComponent,{static: false})                simplemodalComponent  : SimplemodalComponent;
    @ViewChild(DatospersonalesComponent,{static: false})            step1                 : DatospersonalesComponent;
    @ViewChild(DatosperfilriesgoComponent,{static: false})          step2                 : DatosperfilriesgoComponent;
    @ViewChild(DatosactividadinstruccionComponent,{static: false})  step3                 : DatosactividadinstruccionComponent;
    @ViewChild(DatoscuentasbancariasComponent,{static: false})      step4                 : DatoscuentasbancariasComponent;
    @ViewChild(DatosorigenfondosComponent,{static: false})          step5                 : DatosorigenfondosComponent;
    @ViewChild(DatosanexosComponent,{static: false})                step6                 : DatosanexosComponent;

    @ViewChild(DatosanexopepComponent,{static: false})              step3CurrentPep       : DatosanexopepComponent;
    @ViewChild(DatosanexopepConyugeComponent,{static: false})       step3ConyugePep       : DatosanexopepConyugeComponent;
    @ViewChild(DatosanexopepFamiliaComponent,{static: false})       step3FamilyPep        : DatosanexopepFamiliaComponent;

  //constructor
  constructor(private formBuilder: FormBuilder, private httpClient: HttpClient, private parameterService: ParameterService, private  spinner: NgxSpinnerService, private router: Router, private loginService: LoginService,private modalService: ModalService) { }

  ngAfterViewInit() {
  
  }

  async ngOnInit() {
    this.spinner.show();

    await this.initSessionData();
    await this.initParameterTales();
    await this.initTicketRequest();
    await this.initDefaultData();
    
    this.spinner.hide();
  }

  async initSessionData(){
    this.objUserTo = <userTo>this.loginService.currentUser();
    this.entity_InvReqPerson.name = this.objUserTo.name
  }

  async initDefaultData(){
    this.showForm = "";
    if(this.tipoAccion == null || this.tipoAccion == 'R')
    {
      this.enabledForm = true;
      this.entity_InvReqPerson.name                     = this.objUserTo.name;
      this.entity_InvReqPerson.firstLastname            = this.objUserTo.first_lastname;
      this.entity_InvReqPerson.secondLastname           = this.objUserTo.second_lastname;
      this.entity_InvReqPerson.documentType             = this.objUserTo.document_type;
      this.entity_InvReqPerson.documentNumber           = this.objUserTo.document_number;
      this.entity_InvReqPerson.mobileNumber             = this.objUserTo.phone_number;
      this.entity_InvReqPerson.email                    = this.objUserTo.email;
      this.entity_InvReqPerson.isResident               = 1;
      this.entity_InvReqPerson.birthCountry             = this.localCountry;
      this.entity_InvReqPerson.nationalityCountry1      = this.localCountry;

      this.entity_InvReqPerson.equitiesInvestment       = 1;
      this.entity_InvReqPerson.fixedRentInvestment      = 1;
      this.entity_InvReqPerson.localMarketInvestment    = 1;
      this.entity_InvReqPerson.foreignMarketInvestment  = 1;

      
      this.goToStep1();
    }else
    {
      //buscar ficha
      var body = {
        "document_type": ""+ this.objUserTo.document_type,
        "document_number": ""+this.objUserTo.document_number,
      }
      await this.buscarFicha(body);
      this.enabledForm = false;
    }

  }

  initDefaultDataPep(){

     if(this.tipoAccion == null || this.tipoAccion == 'R')
     {
      this.currentInvestorRequestPepTo.name             = this.entity_InvReqPerson.name;
      this.currentInvestorRequestPepTo.firstLastname    = this.entity_InvReqPerson.firstLastname;
      this.currentInvestorRequestPepTo.secondLastname   = this.entity_InvReqPerson.secondLastname;    
      this.currentInvestorRequestPepTo.documentType     = this.entity_InvReqPerson.documentType;    
      this.currentInvestorRequestPepTo.documentNumber   = this.entity_InvReqPerson.documentNumber;    
      this.currentInvestorRequestPepTo.jobCountry       = this.localCountry
      this.currentInvestorRequestPepTo.typePersonPep    = 298;//persona Pep'

      //this.currentInvestorRequestPepTo.typePersonPep    = 300;//Familiar Pep <- es una lista

      this.conyugeInvestorRequestPepTo.name             = this.entity_InvReqPerson_conyuge.name;
      this.conyugeInvestorRequestPepTo.firstLastname    = this.entity_InvReqPerson_conyuge.firstLastname;
      this.conyugeInvestorRequestPepTo.secondLastname   = this.entity_InvReqPerson_conyuge.secondLastname;    
      this.conyugeInvestorRequestPepTo.documentType     = this.entity_InvReqPerson_conyuge.documentType;    
      this.conyugeInvestorRequestPepTo.documentNumber   = this.entity_InvReqPerson_conyuge.documentNumber;    
      this.conyugeInvestorRequestPepTo.jobCountry       = this.localCountry
      this.conyugeInvestorRequestPepTo.typePersonPep    = 299;//cónyuge Pep
     }else{

     }
  }

  async initTicketRequest(){
    await this.workflowStates();
    await this.workflowActionForm();
  }

  async initParameterTales(){
/*
    this.paramCountry                 = await this.parameterService.getGeograficCountry() as Array<parameterTableTo>;
    this.paramDepartment              = [];
    this.paramProvince                = [];
    this.paramDistrict                = [];
    this.paramSettlementType          = await this.parameterService.getParameter(MASTER_TABLE.SETTLEMENT_TYPE) as Array<parameterTableTo>;
    this.paramStreetType              = await this.parameterService.getParameter(MASTER_TABLE.STREET_TYPE) as Array<parameterTableTo>;
    this.paramDocumentType            = await this.parameterService.getParameter(MASTER_TABLE.DOCUMENT_TYPE) as Array<parameterTableTo>;
    this.paramSex                     = this.parameterService.getParameterDefaultData(MASTER_TABLE.SEX);    
    this.paramYesNo                   = this.parameterService.getParameterDefaultData(MASTER_TABLE.YES_NO); 
    this.paramMartialStatus           = await this.parameterService.getParameter(MASTER_TABLE.MARTIAL_STATUS) as Array<parameterTableTo>;
    this.paramMartialRegime           = await this.parameterService.getParameter(MASTER_TABLE.MARTIAL_REGIMEN) as Array<parameterTableTo>;
    this.paramActivityType            = await this.parameterService.getParameter(MASTER_TABLE.ACTIVITY_TYPE) as Array<parameterTableTo>;
    this.paramEducationLeve           = await this.parameterService.getParameter(MASTER_TABLE.EDUCATION_LEVEL) as Array<parameterTableTo>;
    this.paramCommercialActivity      = await this.parameterService.getParameter(MASTER_TABLE.COMERCIAL_ACTIVITY) as Array<parameterTableTo>;
    this.paramBank                    = await this.parameterService.getAllBank() as Array<parameterTableTo>;
    this.paramBankAccountType         = await this.parameterService.getParameter(MASTER_TABLE.BANK_ACCOUNT_TYPE) as Array<parameterTableTo>;
    this.paramCurrency                = await this.parameterService.getParameter(MASTER_TABLE.CURRENCY) as Array<parameterTableTo>;
    this.paramIncomeComesFrom         = await this.parameterService.getParameter(MASTER_TABLE.INCOME_COMES_FROM) as Array<parameterTableTo>;   
    this.paramSendOrderByStr          = await this.parameterService.getParameter(MASTER_TABLE.SEND_ORDER_BY_STR) as Array<parameterTableTo>;   
    this.paramSendPolicyBy            = this.parameterService.getParameterDefaultData(MASTER_TABLE.SEND_POLICY_BY) as Array<parameterTableTo>;           
    this.paramSendAccountStatementBy  = this.parameterService.getParameterDefaultData(MASTER_TABLE.SEND_POLICY_BY) as Array<parameterTableTo>; 
    this.paramPerformOperationsAbroad = this.parameterService.getParameterDefaultData(MASTER_TABLE.ACCEPT_DONT_ACEPT);
    this.paramFundsSourceStr          = await this.parameterService.getParameter(MASTER_TABLE.FUNDS_SOURCE_STR) as Array<parameterTableTo>; 
    this.paramIncomeComesFrom         = await this.parameterService.getParameter(MASTER_TABLE.INCOME_COMES_FROM) as Array<parameterTableTo>;
    this.paramCountry                 = await this.parameterService.getGeograficCountry() as Array<parameterTableTo>;
    this.paramFundsSourceStr          = await this.parameterService.getParameter(MASTER_TABLE.FUNDS_SOURCE_STR) as Array<parameterTableTo>; 
    this.paramPatrimonialAmount       = await this.parameterService.getParameter(MASTER_TABLE.PATRIMONIAL_AMOUNT) as Array<parameterTableTo>; 
    this.paramInvestmentOther         = this.parameterService.getParameterDefaultData(MASTER_TABLE.INVESTMENTS_OTHER);
    this.allSurvey                    = await this.parameterService.getAllSurvey(1) as Array<surveyTo>;  //obtener del core                 
    this.paramTypePersonPep           = await this.parameterService.getParameter(MASTER_TABLE.TYPE_PERSON_PEP)  as Array<parameterTableTo>;
    this.paramFamilyRelationship      = await this.parameterService.getParameter(MASTER_TABLE.FAMILIY_RELATIONSHIP)  as Array<parameterTableTo>;
*/
    
    this.paramCountry                 = await this.parameterService.getGeograficCountry() as Array<parameterTableTo>;
    this.paramDepartment              = [];
    this.paramProvince                = [];
    this.paramDistrict                = [];

    this.paramSettlementType          = await this.parameterService.getParameter(MASTER_TABLE.SETTLEMENT_TYPE) as Array<parameterTableTo>;
    this.paramStreetType              = await this.parameterService.getParameter(MASTER_TABLE.STREET_TYPE) as Array<parameterTableTo>;
    this.paramDocumentType            = await this.parameterService.getParameter(MASTER_TABLE.DOCUMENT_TYPE) as Array<parameterTableTo>;
    
    this.paramSex                     = this.parameterService.getParameterDefaultData(MASTER_TABLE.SEX);    
    this.paramYesNo                   = this.parameterService.getParameterDefaultData(MASTER_TABLE.YES_NO); 
    this.paramMartialStatus           = await this.parameterService.getParameter(MASTER_TABLE.MARTIAL_STATUS) as Array<parameterTableTo>;
    this.paramMartialRegime           = await this.parameterService.getParameter(MASTER_TABLE.MARTIAL_REGIMEN) as Array<parameterTableTo>;
    
    this.paramSendPolicyBy            = this.parameterService.getParameterDefaultData(MASTER_TABLE.SEND_POLICY_BY) as Array<parameterTableTo>;           
    this.paramSendAccountStatementBy  = this.parameterService.getParameterDefaultData(MASTER_TABLE.SEND_POLICY_BY) as Array<parameterTableTo>; 
    this.paramPerformOperationsAbroad = this.parameterService.getParameterDefaultData(MASTER_TABLE.ACCEPT_DONT_ACEPT);
    this.paramInvestmentOther         = this.parameterService.getParameterDefaultData(MASTER_TABLE.INVESTMENTS_OTHER);

    this.allSurvey                    = await this.parameterService.getAllSurvey(1) as Array<surveyTo>;  //obtener del core                 


    this.paramActivityType            = null;
    this.paramEducationLeve           = null;
    this.paramCommercialActivity      = null;
    this.paramBank                    = null;
    this.paramBankAccountType         = null;
    this.paramCurrency                = null;
    this.paramIncomeComesFrom         = null;   
    this.paramSendOrderByStr          = null;   
    this.paramFundsSourceStr          = null;
    this.paramIncomeComesFrom         = null;
    this.paramFundsSourceStr          = null;
    this.paramPatrimonialAmount       = null;
    this.paramTypePersonPep           = null;
    this.paramFamilyRelationship      = null;


    if(this.paramDocumentType != null && this.paramDocumentType.length > 0){
      var tmpDocumentType:Array<parameterTableTo> = [] as Array<parameterTableTo>;
      for(var document of this.paramDocumentType){  if(document.short_integer == 1){  tmpDocumentType.push(document); } }
      this.paramDocumentType = tmpDocumentType;
    }

  }

  editInvestor(){
    this.enabledForm = true;
    if(this.showForm == "datospersonales"){
      this.step1.enabled_inputs_registrar_ficha();
      this.step1.initActionsPostForm();
    }else if(this.showForm == "perfilRiesgo"){
      this.step2.enabled_inputs_registrar_ficha();
      this.step2.initActionsPostForm();
    }else if(this.showForm == "datosactividadinstruccion"){
      this.step3.enabled_inputs_registrar_ficha();
      this.step3.initActionsPostForm();
    }else if(this.showForm == "datosExposicionPolitica"){
      if(this.entity_InvReqPerson.isPep){
        this.step3CurrentPep.enabled_inputs_registrar_ficha();
        this.step3CurrentPep.initActionsPostForm();
      }
      if(this.entity_InvReqPerson.isSpousePep){
        this.step3ConyugePep.enabled_inputs_registrar_ficha();
        this.step3ConyugePep.initActionsPostForm();
      }
      if(this.entity_InvReqPerson.hasRelativePep){
        this.step3FamilyPep.enabled_inputs_registrar_ficha();
        this.step3FamilyPep.initActionsPostForm();
      }
    }else if(this.showForm == "datoscuentasbancarias"){
      this.step4.enabled_inputs_registrar_ficha();
      this.step4.initActionsPostForm();

    }else if(this.showForm == "datosorigenfondos"){
      this.step5.enabled_inputs_registrar_ficha();
      this.step5.initActionsPostForm();

    }else if(this.showForm == "datosanexos"){
      this.step6.enabled_inputs_registrar_ficha();
      this.step6.initActionsPostForm();

    }

  }


  /* step1 */
  onSubmitFormDatosPersonales()
  {
    this.step1.saveSetp1();
  }
  outputDataDatosPersonales($event:step1){
    this.dataStep1 = $event;
    this.entity_InvReqPerson = this.dataStep1.entity_InvReqPerson;
    this.entity_InvReqPerson_conyuge = this.dataStep1.entity_InvReqPerson_conyuge;

   /*
    this.paramDepartment = this.dataStep1.paramDepartment;
    this.paramProvince = this.dataStep1.paramProvince;
    this.paramDistrict = this.dataStep1.paramDistrict;

    this.paramMartialStatus = this.dataStep1.paramMartialStatus;
    this.paramMartialRegime = this.dataStep1.paramMartialRegime;

    this.paramSettlementType = this.dataStep1.paramSettlementType;
    this.paramStreetType = this.dataStep1.paramStreetType;
    this.paramDocumentType = this.dataStep1.paramDocumentType;
    this.paramSex = this.dataStep1.paramSex;
    this.paramYesNo = this.dataStep1.paramYesNo;
    */


    this.goToStep2();
  }

  /* step2 */
  onSubmitFormDatosPerfilRiesgo()
  {
    this.step2.saveSetp2();
  }
  onSubmitFormDatosPerfilRiesgoRegresar()
  {
    this.step2.setDataSetp2();
    this.goToStep1();
  }
  outputDataPerfilRiesgo($event:step2){
    this.dataStep2                = $event;
    this.entity_InvReqPerson      = this.dataStep2.entity_InvReqPerson;
    this.allInvestorRequestSurvey = this.dataStep2.allInvestorRequestSurvey;
    
    /*
    this.allSurvey = this.dataStep2.allSurvey;
    this.paramInvestmentOther = this.paramInvestmentOther;
    */

    this.goToStep3();
  }

  /* step3 */
  onSubmitFormDatosActividadInstruccion()
  {
    this.step3.saveSetp3();
  }
  onSubmitFormDatosActividadInstruccionRegresar()
  {
    this.step3.setDataSetp3();
    this.goToStep2();
  }
  outputDataActividadInstruccion($event:step3){
    this.dataStep3 = $event;
    this.entity_InvReqPerson      = this.dataStep3.entity_InvReqPerson;

    if(this.entity_InvReqPerson.isPep || this.entity_InvReqPerson.isSpousePep || this.entity_InvReqPerson.hasRelativePep){      
      this.goToStep3Pep();
      return;
    }

    this.goToStep4();
  }

  /* step3Pep */
  onSubmitDataPep()
  {
    var isValidCurrentPep = (this.entity_InvReqPerson.isPep)? this.step3CurrentPep.onValidationFormDatosAnexoPep() : true;
    var isValidConyugePep = (this.entity_InvReqPerson.isSpousePep)? this.step3ConyugePep.onValidationFormDatosAnexoPep() : true;
    var isValidFamilyPep =  (this.entity_InvReqPerson.hasRelativePep)? this.step3FamilyPep.onValidationForm() : true;

    if(isValidCurrentPep && isValidConyugePep && isValidFamilyPep){
      if(this.entity_InvReqPerson.isPep){
        this.step3CurrentPep.setDataAnexoPep();
        this.step3CurrentPep.saveAnexoPep();
      }
      if(this.entity_InvReqPerson.isSpousePep){
        this.step3ConyugePep.setDataAnexoPep();
        this.step3ConyugePep.saveAnexoPep();
      }
      if(this.entity_InvReqPerson.hasRelativePep){
        this.step3FamilyPep.setDataAnexoPep();
        this.step3FamilyPep.saveAnexoPep();
      }

      this.goToStep4();
    }else{
      
      this.alertEventSimplemodalComponent("Completar los datos obligatorios. Verifique por favor.");
    }

  }
  onSubmitDataPepRegresar()
  {
    if(this.entity_InvReqPerson.isPep){
      this.step3CurrentPep.setDataAnexoPep();
      this.step3CurrentPep.saveAnexoPep();
    }
    if(this.entity_InvReqPerson.isSpousePep){
      this.step3ConyugePep.setDataAnexoPep();
      this.step3ConyugePep.saveAnexoPep();
    }
    if(this.entity_InvReqPerson.hasRelativePep){
      this.step3FamilyPep.setDataAnexoPep();
      this.step3FamilyPep.saveAnexoPep();
    }    
    this.goToStep3();
  }
  outputDataPep(){
    this.showForm='datoscuentasbancarias';
  }

  outputDataCurrentPep($event:step3CurrentPep){
    this.dataStep3CurrentPep = $event;
    this.currentInvestorRequestPepTo      = this.dataStep3CurrentPep.currentInvestorRequestPepTo;
  }
  outputDataConyugePep($event:step3ConyugePep){
    this.dataStep3ConyugePep = $event;
    this.conyugeInvestorRequestPepTo                  = this.dataStep3ConyugePep.conyugeInvestorRequestPepTo;

    if(this.dataStep3ConyugePep.conyugeInvestorRequestPepTo != null){
      if(this.dataStep3ConyugePep.conyugeInvestorRequestPepTo.name != null){
        this.entity_InvReqPerson_conyuge.name             = this.dataStep3ConyugePep.conyugeInvestorRequestPepTo.name;
      }
      if(this.dataStep3ConyugePep.conyugeInvestorRequestPepTo.firstLastname != null){
        this.entity_InvReqPerson_conyuge.firstLastname    = this.dataStep3ConyugePep.conyugeInvestorRequestPepTo.firstLastname;
      }
      if(this.dataStep3ConyugePep.conyugeInvestorRequestPepTo.secondLastname != null){
        this.entity_InvReqPerson_conyuge.secondLastname   = this.dataStep3ConyugePep.conyugeInvestorRequestPepTo.secondLastname;
      }
      if(this.dataStep3ConyugePep.conyugeInvestorRequestPepTo.fullname != null){
        this.entity_InvReqPerson_conyuge.fullname         = this.dataStep3ConyugePep.conyugeInvestorRequestPepTo.fullname;
      }
      if(this.dataStep3ConyugePep.conyugeInvestorRequestPepTo.documentType != null){
        this.entity_InvReqPerson_conyuge.documentType     = this.dataStep3ConyugePep.conyugeInvestorRequestPepTo.documentType;
      }
      if(this.dataStep3ConyugePep.conyugeInvestorRequestPepTo.documentNumber != null){
        this.entity_InvReqPerson_conyuge.documentNumber   = this.dataStep3ConyugePep.conyugeInvestorRequestPepTo.documentNumber;
      }
    }
  }
  outputDataFamilyPep($event:step3FamilyPep){
    this.dataStep3FamilyPep = $event;
    this.lstFamilyInvestorRequestPepTo      = this.dataStep3FamilyPep.lstFamilyInvestorRequestPepTo;
  }


  /* step4 */
  onSubmitFormDatosCuentasBancarias()
  {
    this.step4.saveSetp4();
  }
  onSubmitFormDatosCuentasBancariasRegresar()
  {
    this.step4.setDataSetp4();

    if(this.entity_InvReqPerson.isPep || this.entity_InvReqPerson.isSpousePep || this.entity_InvReqPerson.hasRelativePep){      
      this.goToStep3Pep();
      return;
    }else{
      this.goToStep3();
    }

  }
  outputDataCuentasBancarias($event:step4){
    this.dataStep4 = $event;
    this.entity_InvReqPerson = this.dataStep4.entity_InvReqPerson;
    this.entity_datosCuentasBancarias = this.dataStep4.entity_datosCuentasBancarias;
    this.goToStep5();
  }

  /* step5 */
  onSubmitFormDatosOrigenFondos()
  {
    this.step5.saveSetp5();
  }
  onSubmitFormDatosOrigenFondosRegresar()
  {
    this.step5.setDataSetp5();
    this.goToStep4();
  }
  outputDataOrigenFondos($event:step5){
    this.dataStep5 = $event; 
    this.goToStep6();
  }

  /* step6 */
  onSubmitAnexos()
  {
    this.step6.saveSetp6();
  }
  onSubmitAnexosRegresar()
  {
    this.goToStep5();
  }
  outputDataDatosanexos($event:step6){
    this.dataStep6 = $event;
    this.goToSaveAllFormData(); 
  }

  /*
    acciones de los formularios
  */

    goToSaveAllFormData(){

      var investorRequestLog:InvestorRequestLogReturn = {} as InvestorRequestLogReturn;
      investorRequestLog.status = 180;  //registrado
      investorRequestLog.evaluationFlow = 888;  //registrado

      if(this.entity_InvReqPerson.maritalStatus == 171){//tiene conyuge
        let invReqRelated: InvReqRelatedReturn = {} as InvReqRelatedReturn;
        invReqRelated.relatedType = 761;// conyuge
        invReqRelated.maritalRegime	= 	this.entity_InvReqPerson.maritalRegime;
        invReqRelated.isPep = this.entity_InvReqPerson.isSpousePep;
        this.entity_InvReqPerson_conyuge.invReqRelated = invReqRelated;
        this.entity_InvReqPerson_conyuge.isPrincipal = 0;
      }
      
      if(this.allInvestorRequestSurvey != null){
        let scoreTotal:number = 0;
        for(var i = 0; i< this.allInvestorRequestSurvey.length;i++ ){
          scoreTotal += this.allInvestorRequestSurvey[0].score;
        }
        this.entity_InvReqPerson.totalScoreInvestor = scoreTotal;
      }

      this.lstInvestorRequestPepTo = [] as Array<InvestorRequestPepToReturn>;

      if(this.entity_InvReqPerson.isPep && this.currentInvestorRequestPepTo != null){
        this.lstInvestorRequestPepTo.push(this.currentInvestorRequestPepTo);
      }
      if(this.entity_InvReqPerson.isSpousePep && this.conyugeInvestorRequestPepTo != null){
        this.lstInvestorRequestPepTo.push(this.conyugeInvestorRequestPepTo);
      }

      if(this.entity_InvReqPerson.hasRelativePep && this.lstFamilyInvestorRequestPepTo != null && this.lstFamilyInvestorRequestPepTo.length > 0){
        for(var familyPep of this.lstFamilyInvestorRequestPepTo){
          if( familyPep.name != ""){
            this.lstInvestorRequestPepTo.push(familyPep);
          }
        }
      }
      
      this.entity_InvReqPerson.isPrincipal = 1;
      /* Seteando datos */
      
      let investorRequestSubtmi: InvestorRequestSearch  = {} as InvestorRequestSearch;

      if(this.investorRequest != null && this.investorRequest.idInvestorRequestPk != null && this.investorRequest.idInvestorRequestPk > 0 ){
        investorRequestSubtmi.idInvestorRequestPk	= this.investorRequest.idInvestorRequestPk;
      }
      
      if(this.entity_InvReqPerson.maritalStatus == 171){
        investorRequestSubtmi.invReqPersonSpouse          = this.entity_InvReqPerson_conyuge;
      }
      investorRequestSubtmi.invReqPerson                = this.entity_InvReqPerson;
      investorRequestSubtmi.listInvReqBankAccount       = this.entity_datosCuentasBancarias.listInvReqBankAccount;
      investorRequestSubtmi.listInvestorRequestSurvey   = this.allInvestorRequestSurvey;
      
      if(this.entity_InvReqPerson.investorRequestFatca   != null && this.entity_InvReqPerson.investorRequestFatca.length > 0){
        investorRequestSubtmi.listInvestorRequestFatca    = this.entity_InvReqPerson.investorRequestFatca;
      }

      if(this.lstInvestorRequestPepTo != null && this.lstInvestorRequestPepTo.length > 0){
        investorRequestSubtmi.listInvestorRequestPep      =  this.lstInvestorRequestPepTo;
      }

      this.registrarFicha(investorRequestSubtmi);
    }

    registrarFicha(datosGuardar) {

        console.log(JSON.stringify(datosGuardar));

        this.spinner.show();
        this.httpClient.post(URL.SERVICE_API_V1_CORE_SAVE_INVESTOR_REQUEST,
                datosGuardar, { headers: func.WSHeaderTokenCore() })
                . subscribe(
              (response) => {  

                  this.spinner.hide();  
                  console.log("registrarFicha::response::\n"+ JSON.stringify(response));
                  this.router.navigate(['/confirmarNewInvestor']);

              },
              (err: HttpErrorResponse) => {
                console.log(err.error);
                console.log(err.name);
                console.log(err.message);
                console.log(err.status);

                if(err.status == errorMaping.CODE_400) {
                  this.spinner.hide();
                  var mensaje = "Existe un usuario ya registrado con la información ingresada.";
                  this.simplemodalComponent.alertar("Mensaje de Alerta",mensaje);
                  return;
                }
                else {
                  this.spinner.hide();
                  var mensaje = "Ocurrio un error: "+err.message;
                  this.simplemodalComponent.alertar("Mensaje de Alerta",mensaje);
                  return ;
                }
          },
          () => {
            this.spinner.hide();
          }
        );
    
    }

    
  async buscarFicha(datosBusqueda) {

    this.spinner.show();
    this.httpClient.post(URL.SERVICE_API_V1_CORE_GET_INVESTOR_REQUEST_EDIT,
      datosBusqueda, { headers: func.WSHeaderTokenCore() })
            .subscribe(
          (response) => {
            let investorRequestSearch: InvestorRequestSearch = response as InvestorRequestSearch;

            
            this.investorRequest = investorRequestSearch.investorRequest;
            this.entity_InvReqPerson = investorRequestSearch.invReqPerson;
            this.entity_InvReqPerson_conyuge = investorRequestSearch.invReqPersonSpouse;
            
            if(this.entity_datosCuentasBancarias == null){
              this.entity_datosCuentasBancarias = {} as InvReqBankAccountReturn;
              if(this.entity_datosCuentasBancarias.listInvReqBankAccount == null){
                this.entity_datosCuentasBancarias.listInvReqBankAccount = [];
              }
            }
            this.entity_datosCuentasBancarias.listInvReqBankAccount = investorRequestSearch.listInvReqBankAccount;
            
            this.lstInvestorRequestPepTo = investorRequestSearch.listInvestorRequestPep;

            if(this.lstInvestorRequestPepTo != null && this.lstInvestorRequestPepTo.length > 0){
              for(var obj of this.lstInvestorRequestPepTo){
                if(obj.typePersonPep == 298){
                  this.currentInvestorRequestPepTo = obj;
                }else if(obj.typePersonPep == 299){
                  this.conyugeInvestorRequestPepTo = obj;
                }else if(obj.typePersonPep == 300){
                  this.lstFamilyInvestorRequestPepTo.push(obj);
                }
              }
            } 
            

            this.allInvestorRequestSurvey = investorRequestSearch.listInvestorRequestSurvey;
            this.entity_InvReqPerson.investorRequestFatca = investorRequestSearch.listInvestorRequestFatca;

            this.dataStep6                                             = {} as step6;
            this.dataStep6.varPersonasPep                              = true;
            this.dataStep6.varDeclaJuradaODLicito                      = true;
            this.dataStep6.varExoneracionGK                            = true;
            this.dataStep6.varLavadoActivosTerrorismo                  = true;
            this.dataStep6.varPrecisionesLegales                       = true;
            this.dataStep6.varServIntermedOpValExtranjero              = true;
            this.dataStep6.varProteccionDatPersonales                  = true;
            this.dataStep6.anexoDeclaracionJuaradaExoneracionCapital   = true;
            this.dataStep6.anexoDeclaracionJuaradaOrigenDestinoLicito  = true;
            this.dataStep6.flagEnabledAnexoPep    = (this.entity_InvReqPerson.isPep || this.entity_InvReqPerson.isSpousePep || this.entity_InvReqPerson.hasRelativePep)?true:false;
            this.dataStep6.flagEnabledAnexoFatca  = (this.entity_InvReqPerson.investorRequestFatca != null && this.entity_InvReqPerson.investorRequestFatca.length >0)?true:false;
            
            this.goToStep1();

          },
          (err: HttpErrorResponse) => {

            var mensaje = "Hubo problemas al buscar su ficha, reintente por favor.";
            this.simplemodalComponent.alertar("Mensaje de Alerta",mensaje);
      },
      () => {
        this.spinner.hide();
      }
    );
 
  }


    goToStep1(){
      this.dataStep1.entity_InvReqPerson    = this.entity_InvReqPerson;
      this.dataStep1.entity_InvReqPerson_conyuge    = this.entity_InvReqPerson_conyuge;
      this.dataStep1.paramCountry           = this.paramCountry;
      this.dataStep1.paramDepartment        = this.paramDepartment;
      this.dataStep1.paramProvince          = this.paramProvince;
      this.dataStep1.paramDistrict          = this.paramDistrict;
      this.dataStep1.paramSettlementType    = this.paramSettlementType;
      this.dataStep1.paramStreetType        = this.paramStreetType;
      this.dataStep1.paramDocumentType      = this.paramDocumentType;
      this.dataStep1.paramSex               = this.paramSex;
      this.dataStep1.paramYesNo             = this.paramYesNo;
      this.dataStep1.paramMartialStatus     = this.paramMartialStatus;
      this.dataStep1.paramMartialRegime     = this.paramMartialRegime;
      this.dataStep1.action                 = this.tipoAccion;
      
      this.dataStep1.enabledForm            = this.enabledForm;

      this.showForm                         = "datospersonales";
    }

    goToStep2(){
      this.dataStep2.entity_InvReqPerson        = this.entity_InvReqPerson;
      this.dataStep2.allInvestorRequestSurvey   = this.allInvestorRequestSurvey
      this.dataStep2.allSurvey                  = this.allSurvey;
      this.paramInvestmentOther                 = this.paramInvestmentOther

      this.dataStep2.action                     = this.tipoAccion;
      this.dataStep2.enabledForm                = this.enabledForm;
      this.showForm                             = "perfilRiesgo";
    }

    goToStep3(){
      this.dataStep3.entity_InvReqPerson        = this.entity_InvReqPerson;
      this.dataStep3.paramActivityType          = this.paramActivityType;
      this.dataStep3.paramEducationLeve         = this.paramEducationLeve;
      this.dataStep3.paramCommercialActivity    = this.paramCommercialActivity;
      this.dataStep3.paramYesNo                 = this.paramYesNo;

      this.dataStep3.action                     = this.tipoAccion;
      this.dataStep3.enabledForm                = this.enabledForm;
      this.showForm                             = "datosactividadinstruccion";
    }

    goToStep3Pep(){

      this.initDefaultDataPep();

      if(this.entity_InvReqPerson.isPep){
        if(this.currentInvestorRequestPepTo == null || this.currentInvestorRequestPepTo.documentNumber == null || this.currentInvestorRequestPepTo.documentNumber == '' ){
          this.currentInvestorRequestPepTo = {} as InvestorRequestPepToReturn;
          this.currentInvestorRequestPepTo.name = this.entity_InvReqPerson.name;
          this.currentInvestorRequestPepTo.firstLastname = this.entity_InvReqPerson.firstLastname;
          this.currentInvestorRequestPepTo.secondLastname = this.entity_InvReqPerson.secondLastname;
          this.currentInvestorRequestPepTo.documentType = this.entity_InvReqPerson.documentType;
          this.currentInvestorRequestPepTo.documentNumber = this.entity_InvReqPerson.documentNumber;
        }
        this.dataStep3CurrentPep.currentInvestorRequestPepTo  = this.currentInvestorRequestPepTo;
        this.dataStep3CurrentPep.paramYesNo                   = this.paramYesNo;
        this.dataStep3CurrentPep.paramCountry                 = this.paramCountry;
        this.dataStep3CurrentPep.paramDocumentType            = this.paramDocumentType;
        this.dataStep3CurrentPep.paramTypePersonPep           = this.paramTypePersonPep;
        this.dataStep3CurrentPep.paramFamilyRelationship      = this.paramFamilyRelationship;
        
        this.dataStep3CurrentPep.action                 = this.tipoAccion;
        this.dataStep3CurrentPep.enabledForm            = this.enabledForm;
      }
      
      if(this.entity_InvReqPerson.isSpousePep){
        if(this.conyugeInvestorRequestPepTo == null){
          this.conyugeInvestorRequestPepTo = {} as InvestorRequestPepToReturn;
        }
        this.dataStep3ConyugePep.conyugeInvestorRequestPepTo  = this.conyugeInvestorRequestPepTo;
    
        //si ya existen datos en el conyuge y en el pep conyuge
        if(this.entity_InvReqPerson_conyuge != null && this.entity_InvReqPerson_conyuge.name != null && 
          this.dataStep3ConyugePep.conyugeInvestorRequestPepTo != null && this.dataStep3ConyugePep.conyugeInvestorRequestPepTo.name != null){
          this.dataStep3ConyugePep.conyugeInvestorRequestPepTo.name = this.entity_InvReqPerson_conyuge.name;
          this.dataStep3ConyugePep.conyugeInvestorRequestPepTo.firstLastname = this.entity_InvReqPerson_conyuge.firstLastname;
          this.dataStep3ConyugePep.conyugeInvestorRequestPepTo.secondLastname = this.entity_InvReqPerson_conyuge.secondLastname;
          this.dataStep3ConyugePep.conyugeInvestorRequestPepTo.fullname = this.entity_InvReqPerson_conyuge.fullname;
          this.dataStep3ConyugePep.conyugeInvestorRequestPepTo.documentType = this.entity_InvReqPerson_conyuge.documentType;
          this.dataStep3ConyugePep.conyugeInvestorRequestPepTo.documentNumber = this.entity_InvReqPerson_conyuge.documentNumber;

          this.dataStep3ConyugePep.action                 = this.tipoAccion;
          this.dataStep3ConyugePep.enabledForm            = this.enabledForm;
        }


        this.dataStep3ConyugePep.paramYesNo                   = this.paramYesNo;
        this.dataStep3ConyugePep.paramCountry                 = this.paramCountry;
        this.dataStep3ConyugePep.paramDocumentType            = this.paramDocumentType;
        this.dataStep3ConyugePep.paramTypePersonPep           = this.paramTypePersonPep;
      }

      if(this.entity_InvReqPerson.hasRelativePep){
        this.dataStep3FamilyPep.lstFamilyInvestorRequestPepTo  = this.lstFamilyInvestorRequestPepTo;
        this.dataStep3FamilyPep.paramYesNo                   = this.paramYesNo;
        this.dataStep3FamilyPep.paramCountry                 = this.paramCountry;
        this.dataStep3FamilyPep.paramDocumentType            = this.paramDocumentType;
        this.dataStep3FamilyPep.paramTypePersonPep           = this.paramTypePersonPep;
        this.dataStep3FamilyPep.paramFamilyRelationship      = this.paramFamilyRelationship;

        this.dataStep3FamilyPep.action                 = this.tipoAccion;
        this.dataStep3FamilyPep.enabledForm            = this.enabledForm;
      }

      this.showForm                                           = "datosExposicionPolitica";
    }

    goToStep4(){
      this.dataStep4.entity_InvReqPerson                  = this.entity_InvReqPerson;
      this.dataStep4.entity_datosCuentasBancarias         = this.entity_datosCuentasBancarias;
      this.dataStep4.paramBank                            = this.paramBank;
      this.dataStep4.paramBankAccountType                 = this.paramBankAccountType;
      this.dataStep4.paramCurrency                        = this.paramCurrency;
      this.dataStep4.paramYesNo                           = this.paramYesNo;
      this.dataStep4.paramPerformOperationsAbroad         = this.paramPerformOperationsAbroad;

      this.dataStep4.action                 = this.tipoAccion;
      this.dataStep4.enabledForm                          = this.enabledForm;

      this.showForm                                       = "datoscuentasbancarias";
    }

    goToStep5(){
      this.dataStep5.entity_InvReqPerson        = this.entity_InvReqPerson;
      this.dataStep5.paramIncomeComesFrom       = this.paramIncomeComesFrom;
      this.dataStep5.paramCountry               = this.paramCountry;
      this.dataStep5.paramPatrimonialAmount     = this.paramPatrimonialAmount;
      this.dataStep5.paramYesNo                 = this.paramYesNo;

      this.dataStep5.action                 = this.tipoAccion;
      this.dataStep5.enabledForm                = this.enabledForm;
      this.showForm                             = "datosorigenfondos";
    }

    //accion al formulario del paso 6 (datosanexos)
    async goToStep6(){
      this.dataStep6.entity_InvReqPerson                         = this.entity_InvReqPerson;
      this.dataStep6.paramCountry                                = this.paramCountry;
      this.dataStep6.paramDepartment        = await this.parameterService.getGeograficDepartment(this.entity_InvReqPerson.nationalityCountry1) as Array<parameterTableTo>;
      this.dataStep6.paramProvince          = await this.parameterService.getGeograficProvince(this.entity_InvReqPerson.residenceDepartment) as Array<parameterTableTo>;
      this.dataStep6.paramDistrict          = await this.parameterService.getGeograficDistrict(this.entity_InvReqPerson.residenceProvince) as Array<parameterTableTo>;
      

      this.dataStep6.varPersonasPep                              = this.defaultNullObject(this.dataStep6.varPersonasPep,false);
      this.dataStep6.varDeclaJuradaODLicito                      = this.defaultNullObject(this.dataStep6.varDeclaJuradaODLicito,false);
      this.dataStep6.varExoneracionGK                            = this.defaultNullObject(this.dataStep6.varExoneracionGK,false);
      this.dataStep6.varLavadoActivosTerrorismo                  = this.defaultNullObject(this.dataStep6.varLavadoActivosTerrorismo,false);
      this.dataStep6.varPrecisionesLegales                       = this.defaultNullObject(this.dataStep6.varPrecisionesLegales,false);
      this.dataStep6.varServIntermedOpValExtranjero              = this.defaultNullObject(this.dataStep6.varServIntermedOpValExtranjero,false);
      this.dataStep6.varProteccionDatPersonales                  = this.defaultNullObject (this.dataStep6.varProteccionDatPersonales,false);
      this.dataStep6.anexoDeclaracionJuaradaExoneracionCapital   = this.defaultNullObject (this.dataStep6.anexoDeclaracionJuaradaExoneracionCapital,false);
      this.dataStep6.anexoDeclaracionJuaradaOrigenDestinoLicito  = this.defaultNullObject (this.dataStep6.anexoDeclaracionJuaradaOrigenDestinoLicito,false);
      this.dataStep6.flagEnabledAnexoPep  = this.defaultNullObject (this.dataStep6.flagEnabledAnexoPep,false);
      this.dataStep6.flagEnabledAnexoFatca  = this.defaultNullObject (this.dataStep6.flagEnabledAnexoFatca,false);

      
      this.dataStep6.action                                      = this.tipoAccion;
      this.dataStep6.enabledForm                                 = this.enabledForm;
      this.showForm                                              = "datosanexos";
    }

  /* 
      funciones para los estados de la solicitud
  */
  async workflowStates(){
    
    this.paramInvestorRequestStates = [] as Array<parameterTableTo>;
    var parameterTable:parameterTableTo = {} as parameterTableTo;
    parameterTable.parameter_table_pk = 888;  // min evaluation flow
    parameterTable.short_integer = 888;       // max evaluation flow
    parameterTable.short_number  = 180;       // status
    parameterTable.description = 'Registrado';
    parameterTable.text1='pending';
    this.paramInvestorRequestStates.push(parameterTable);

    parameterTable = {} as parameterTableTo;
    parameterTable.parameter_table_pk = 889;  // min evaluation flow
    parameterTable.short_integer = 895;       // max evaluation flow
    parameterTable.short_number  = 181;       // status
    parameterTable.description = 'En evaluación';
    parameterTable.text1='pending';
    this.paramInvestorRequestStates.push(parameterTable);

    parameterTable = {} as parameterTableTo;
    parameterTable.parameter_table_pk = 896;
    parameterTable.short_integer = 896;
    parameterTable.short_number  = 180;       // status
    parameterTable.description = 'Solicitud de firma cliente';
    parameterTable.text1='pending';
    this.paramInvestorRequestStates.push(parameterTable);

    parameterTable = {} as parameterTableTo;
    parameterTable.parameter_table_pk = 897;
    parameterTable.short_integer = 900;
    parameterTable.short_number  = 180;       // status
    parameterTable.description = 'Firmado por el cliente';
    parameterTable.text1='pending';
    this.paramInvestorRequestStates.push(parameterTable);

    parameterTable = {} as parameterTableTo;
    parameterTable.parameter_table_pk = 901;
    parameterTable.short_integer = 1391;
    parameterTable.short_number  = 180;       // status
    parameterTable.description = 'RUT asociado';
    parameterTable.text1='pending';
    this.paramInvestorRequestStates.push(parameterTable);

    
    if(this.objUserTo.status != null && (this.objUserTo.status == 184 || this.objUserTo.evaluationFlow == 890) ) { //solicitud Anulada
      this.paramInvestorRequestStates = [] as Array<parameterTableTo>;
      parameterTable = {} as parameterTableTo;
      parameterTable.parameter_table_pk = 890;
      parameterTable.short_integer = 890;
      parameterTable.description = 'SU FICHA FUE ANULADA, POR FAVOR VUELVA A INGRESAR SUS DATOS ';
      parameterTable.text1='pending';
      this.paramInvestorRequestStates.push(parameterTable);
    }else if (this.objUserTo.status != null && this.objUserTo.status != 184 && this.objUserTo.evaluationFlow != 890 ) {  //Todos los flujos
      console.log("this.objUserTo.evaluationFlow::"+this.objUserTo.evaluationFlow);
      console.log("this.objUserTo.status::"+this.objUserTo.status);
      for(var i=0; i< this.paramInvestorRequestStates.length; i++){
        if( this.objUserTo.evaluationFlow >= this.paramInvestorRequestStates[i].parameter_table_pk  ||
            this.objUserTo.evaluationFlow >= this.paramInvestorRequestStates[i].short_integer  ){
          this.paramInvestorRequestStates[i].text1='current';
        }
      }

    }

  }

  async workflowActionForm(){
    this.tipoAccion = ( this.objUserTo.idInvestorRequestPk > 0 )? 'M':'R';
  }

  /*
      public constants
  */

  get yes() { return PARAMETER_TABLE_YES_NO.YES.ID; }
  get no()  { return PARAMETER_TABLE_YES_NO.NO.ID; }
  get localCountry(){ return countryMaping.PERU;}
 
  /*
      generic functions
  */

  openModalEvent($event){
    this.modalService.open($event);  
  }
  closeModalEvent($event){ 
    this.modalService.close($event);
  }

  alertEventSimplemodalComponent($event:String){
    var alert:String = $event;
    this.simplemodalComponent.alertar("Mensaje de Alerta",alert);
  }
  defaultNullObject(inputVar, defaultData){
    return (inputVar != null) ? inputVar : defaultData;
  }
  openModal(page1){
    this.modalService.open(page1);  
  }
  openCloseModal(page1,page2){
    this.modalService.close(page1);
    this.modalService.open(page2);  
  }
  closeModal(page){ 
    this.modalService.close(page);
  }

}
