//import * as CryptoJS from 'crypto-js';
import md5 from "react-native-md5";
import base64 from 'base-64';
import { userTo } from '../model/UserModel/userTo';
import { claseInteresType } from './claseInteresType';
import { issuerSession } from '../model/UserModel/issuerSession';
//var CryptoJS = require("crypto-js");

export const func = {

  WSHeader(){
    return {
   
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
      'Access-Control-Allow-Origin': '*',       
      // 'Authorization': 'Basic ' + this.convertirBase64('basic:password'),
      // 'client_key' : '1942-417873-123-654354-213',
      'client_key' : '1234',
      'Content-Type' : 'application/json'
    };
  },

  WSHeaderTokenCore(){
    return {
      /*'Access-Control-Allow-Origin': '*', 
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Methods': 'POST, GET, HEAD, OPTIONS',
      'Access-Control-Allow-Headers': 'Origin, Accept, x-auth-token, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
      */
      'Content-Type' : 'application/json'
    };
  },

  WSHeaderCore(token){
    return {
   
      /*'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
      'Access-Control-Allow-Origin': '*', */
      'Content-Type' : 'application/json',
      'Authorization' : 'Bearer '+token
    };
  },

  WSHeaderToken(){
    return {
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
      'Access-Control-Allow-Origin': '*',
      // 'Authorization': 'Basic ' + this.convertirBase64('basic:password'),
      'acces_token' : <userTo>JSON.parse(sessionStorage.getItem('loginUser')).accessToken,
      'Content-Type' : 'application/json'
    };
  },

  replaceAll(str:string, find:string, replace:string) {
    return str.replace(new RegExp(find, 'g'), replace);
  },

  withoutUnderline(json: String){
    
    var mapa = this.mapUnderlines();

    for(var key of mapa.keys()) {
      json = json.split(key).join(mapa.get(key));
    }
    
    return ""+json;
  },

  
  withUnderline(json){
    /*
    for (var key in json) {
      if (json.hasOwnProperty(key)) {
          console.log(key + " -> " + json[key]);
      }
    }
   for(var i in json){

   }
    */
    for(var key in json) {
      //console.log("key:"+myKey+", value:"+json[myKey]);


      var mapa = this.mapCaseUnderlines();
      var keyNew = null;
      var keyOld = key;
      var valueKey = json[key];
      for(var keyStr of mapa.keys()) {
        key = key.split(keyStr).join(mapa.get(keyStr));
      }
      keyNew = key;
      //console.log(key, json[key]);
      json[keyNew] = valueKey;
      delete json[keyOld];

    }

/*
    var mapa = this.mapCaseUnderlines();
    Object.keys(json).forEach(function(key) {
      var keyNew = null;
      var keyOld = key;
      var valueKey = json[key];
      for(var keyStr of mapa.keys()) {
        keyNew = keyOld.split(keyStr).join(mapa.get(keyStr));
      }
      //console.log(key, json[key]);
      json[keyNew] = valueKey;
      delete json[keyOld];
    });
    */


    /*
    var mapa = this.mapCaseUnderlines();

    for(var keyStr of mapa.keys()) {
      json[key] = json[key].split(keyStr).join(mapa.get(keyStr));
    }*/
    
    return json;
  },


  mapUnderlines(){
    var map_underlines = new Map([
      ["_a", "A"], 
      ["_b", "B"], 
      ["_c", "C"], 
      ["_d", "D"], 
      ["_e", "E"], 
      ["_f", "F"], 
      ["_g", "G"], 
      ["_h", "H"], 
      ["_i", "I"], 
      ["_j", "J"], 
      ["_k", "K"], 
      ["_l", "L"], 
      ["_m", "M"], 
      ["_n", "N"], 
      ["_o", "O"], 
      ["_p", "P"], 
      ["_q", "Q"], 
      ["_r", "R"], 
      ["_s", "S"], 
      ["_t", "T"], 
      ["_u", "U"], 
      ["_v", "V"], 
      ["_w", "W"], 
      ["_x", "X"], 
      ["_y", "Y"], 
      ["_z", "Z"], 
      ["_1", "1"], 
      ["_2", "2"], 
      ["_3", "3"], 
      ["_4", "4"], 
      ["_5", "5"], 
      ["_6", "6"], 
      ["_7", "7"], 
      ["_8", "8"], 
      ["_9", "9"], 
                                ]); 
    return map_underlines;
  },

  mapCaseUnderlines(){
    var map_underlines = new Map([
      ["A","_a"], 
      ["B","_b"], 
      ["C","_c"], 
      ["D","_d"], 
      ["E","_e"], 
      ["F","_f"], 
      ["G","_g"], 
      ["H","_h"], 
      ["I","_i"], 
      ["J","_j"], 
      ["K","_k"], 
      ["L","_l"], 
      ["M","_m"], 
      ["N","_n"], 
      ["O","_o"], 
      ["P","_p"], 
      ["Q","_q"], 
      ["R","_r"], 
      ["S","_s"], 
      ["T","_t"], 
      ["U","_u"], 
      ["V","_v"], 
      ["W","_w"], 
      ["X","_x"], 
      ["Y","_y"], 
      ["Z","_z"],
                                ]); 
    return map_underlines;
  },

  arraySimbole(){
    var array = [ "!", 
                  ",", 
                  "#", 
                  "$", 
                  ";", 
                  "^", 
                  "-", 
                  "+",
                  "_",
                  "/"
                ]; 
    return array;
  },
  arrayNumber(){
    var array = [ "1", 
                  "2", 
                  "3", 
                  "4", 
                  "5", 
                  "6", 
                  "7", 
                  "8", 
                  "9", 
                  "0"
                  ]; 
    return array;
  },
  arrayLetter(){
    var array = [ "a", 
                  "b", 
                  "c", 
                  "d", 
                  "e", 
                  "f", 
                  "g", 
                  "h", 
                  "i", 
                  "j", 
                  "k", 
                  "l", 
                  "m", 
                  "n", 
                  "o", 
                  "p", 
                  "q", 
                  "r", 
                  "s", 
                  "t", 
                  "u", 
                  "v", 
                  "w", 
                  "x", 
                  "y", 
                  "z"
                  ]; 
    return array;
  },
  arrayCaseLetter(){
    var array = [ "A", 
                  "B", 
                  "C", 
                  "D", 
                  "E", 
                  "F", 
                  "G", 
                  "H", 
                  "I", 
                  "J", 
                  "K", 
                  "L", 
                  "M", 
                  "N", 
                  "O", 
                  "P", 
                  "Q", 
                  "R", 
                  "S", 
                  "T", 
                  "U", 
                  "V", 
                  "W", 
                  "X", 
                  "Y", 
                  "Z"
                  ]; 
    return array;
  },

  encriptacionLogeo(valor:String) {
    let hex_md5v = md5.hex_md5(valor);
    return hex_md5v.toUpperCase();
  },
  convertirBase64(valor:String) {
      let data = base64.encode(valor)
      return data;
  },
  decodeBase64(valor:String) {
      let data = base64.decode(valor)
      return data;
  },

  currentDate() {
      var d = new Date;
      let mes = d.getMonth()+1;
      let str_mes = this.strPadLeft(mes.toString());

      let dia = d.getDate();
      let str_dia = this.strPadLeft(dia.toString());

      let dformat = [
          str_dia,
          str_mes,
          d.getFullYear()].join('/');
      return dformat;
  },

  formatFecha() {
    let d = new Date;
      
      let mes = d.getMonth()+1;
      let str_mes = this.strPadLeft(mes.toString());

      let dia = d.getDate();
      let str_dia = this.strPadLeft(dia.toString());

      let hora = d.getHours();
      let str_hora = this.strPadLeft(hora.toString());

      let minuto = d.getMinutes();
      let str_minuto = this.strPadLeft(minuto.toString());

      let segundo = d.getSeconds();
      let str_segundo = this.strPadLeft(segundo.toString());

      let dformat = [
          str_dia,
          str_mes,
          d.getFullYear()].join('/')+' '+
          [str_hora,
          str_minuto,
          str_segundo].join(':');
      return dformat;
  },

  strPadLeft(base:String){
      let  len = (base.length==1)? '0'+base:base;
      return len;
  },

  formatFechaJS(date:String) {
      let dia = date.substr(0,2);
      let mes = date.substr(3,2)
      let anio = date.substr(6,4)
      let fecha = anio + "-" + mes + "-" + dia;
      return fecha;
  },


  isEmptyString(object:String){
    if(object === '' || object === 'NaN' || object === null || object === 'undefined'){
        return true;
    }
    return false;
  },

  isNotEmptyString(object:String) {
    if(object !== '' && object !== 'NaN' && object !== null && object !== 'undefined'){
        return true;
    }
    return false;
  },

  convertIntToStr(number:number){
    return number+"'";
  },

  convertDecimalToStr(number:number){
    return number+"'";
  },

  formatQuantity(num) { // solo enteros
    if(num!=null){
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }else{
      return 0;
    }
  },

  formatNumber(num) {  // para decimales de 2 digitos+
    if(num!=null){
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }else{
      return "0.00";
    }
  },

  formatDecimalToNumner(decimal){
    return this.replaceAll(decimal,',','');
  },
  
  formatNumberFourDecimal(number){  
    let num = "000.0000";
    if(number != '' && number != null){

      let tasa = ""+number;
      let cadena = tasa.split('.'); 
      let tam = cadena.length;
      let unidad  = func.formatQuantity(cadena[0]); 
      let decimalAux = "0000";

      if(tam > 1){
        let tamDecimal = cadena[1].length;
        if(tamDecimal >= 4){
          decimalAux = cadena[1].substr(0,4);
        }else{
          decimalAux = cadena[1];
        } 
      } 
      num = unidad+"."+decimalAux;
    } 
    
    return num;
  }, 

  formatDateCore(date){

    if(date!=null){
      let anio = date.year;
      let dia  = (date.dayOfMonth<9)?'0'+date.dayOfMonth:date.dayOfMonth;
      let mes  = (date.monthValue<9)?'0'+date.monthValue:date.monthValue;
      let fecha = dia + "/" + mes + "/" +anio;
      return fecha;
    }else{
      return '__/__/____';
    }

  },

  formatFechaDBtoUi(date:String) { // 2019-10-20
    if(date!=null){
      let anio = date.substr(0,4);
      let dia  = date.substr(8,2);
      let mes  = date.substr(5,2);
      let fecha = dia + "/" + mes + "/" +anio;
      return fecha;
    }else{
      return '__/__/____';
    }
    
  },
  
  formatFechaDBtoUi_line(date:String) { // 2019-10-20
    if(date!=null){
      let anio = date.substr(0,4);
      let dia  = date.substr(8,2);
      let mes  = date.substr(5,2);
      let fecha = dia + "/" + mes + "/" +anio;
      return fecha;
    }else{
      return '__-__-____';
    }
    
  },


  getYearFromDate(date:String) { // 2019-10-20
    if(date!=null){ 
      return date.substr(0,4);  ;
    }else{
      return '0';
    }    
  },

  getMonthFromDate(date:String) { // 2019-10-20
    if(date!=null){ 
      return date.substr(5,2);  ;
    }else{
      return '0';
    }    
  },

  getMonthNameFromDate(date:String) { // 2019-10-20
    var month = this.getMonthFromDate(date);  
    return this.getMonthName(month);
  },

  getMonthName(month:String){

    if(month == '01' || month == '1'){
      return 'Enero';
    }else if(month == '02' || month == '2'){
      return 'Febrero';
    }else if(month == '03' || month == '3'){
      return 'Marzo';
    }else if(month == '04' || month == '4'){
      return 'Abril';
    }else if(month == '05' || month == '5'){
      return 'Mayo';
    }else if(month == '06' || month == '6'){
      return 'Junio';
    }else if(month == '07' || month == '7'){
      return 'Julio';
    }else if(month == '08' || month == '8'){
      return 'Agosto';
    }else if(month == '09' || month == '9'){
      return 'Septiembre';
    }else if(month == '10' || month == '10'){
      return 'Octubre';
    }else if(month == '11' || month == '11'){
      return 'Noviembre';
    }else if(month == '12' || month == '12'){
      return 'Diciembre';
    }else{
      return 'Fuera de Rango';
    }

  },

  validateImputTelefono(dni){
    var caracteresPermitidos = new Map();
     
    caracteresPermitidos.set('1',true);
    caracteresPermitidos.set('2',true);
    caracteresPermitidos.set('3',true);
    caracteresPermitidos.set('4',true);
    caracteresPermitidos.set('5',true);
    caracteresPermitidos.set('6',true);
    caracteresPermitidos.set('7',true);
    caracteresPermitidos.set('8',true);
    caracteresPermitidos.set('9',true);
    caracteresPermitidos.set('0',true); 

    var devuelve = '';
    for(var  i=0 ; i < dni.length ; i++) {
      var texto = dni.charAt(i);  
      if(caracteresPermitidos.get(texto)){
          devuelve = devuelve + texto;
      } 
    } 
    return devuelve; 
  },

  validateImputDni(dni){
    var caracteresPermitidos = new Map();
     
    caracteresPermitidos.set('1',true);
    caracteresPermitidos.set('2',true);
    caracteresPermitidos.set('3',true);
    caracteresPermitidos.set('4',true);
    caracteresPermitidos.set('5',true);
    caracteresPermitidos.set('6',true);
    caracteresPermitidos.set('7',true);
    caracteresPermitidos.set('8',true);
    caracteresPermitidos.set('9',true);
    caracteresPermitidos.set('0',true); 

    var devuelve = '';
    for(var  i=0 ; i < dni.length ; i++) {
      var texto = dni.charAt(i);  
      if(caracteresPermitidos.get(texto)){
          devuelve = devuelve + texto;
      } 
    } 
    return devuelve; 
  },

  validateImputEntero(numero){
    var caracteresPermitidos = new Map();
     
    caracteresPermitidos.set('1',true);
    caracteresPermitidos.set('2',true);
    caracteresPermitidos.set('3',true);
    caracteresPermitidos.set('4',true);
    caracteresPermitidos.set('5',true);
    caracteresPermitidos.set('6',true);
    caracteresPermitidos.set('7',true);
    caracteresPermitidos.set('8',true);
    caracteresPermitidos.set('9',true);
    caracteresPermitidos.set('0',true); 

    var devuelve = '';
    for(var  i=0 ; i < numero.length ; i++) {
      var texto = numero.charAt(i);  
      if(caracteresPermitidos.get(texto)){
          devuelve = devuelve + texto;
      } 
    } 
    return devuelve; 
  },

  validateCarnetYPasaporte(documento){
    var caracteresPermitidos = new Map();
    caracteresPermitidos.set('A',true);
    caracteresPermitidos.set('B',true);
    caracteresPermitidos.set('C',true);
    caracteresPermitidos.set('D',true);
    caracteresPermitidos.set('E',true);
    caracteresPermitidos.set('F',true);
    caracteresPermitidos.set('G',true);
    caracteresPermitidos.set('H',true);
    caracteresPermitidos.set('I',true);
    caracteresPermitidos.set('J',true);
    caracteresPermitidos.set('J',true);
    caracteresPermitidos.set('K',true);
    caracteresPermitidos.set('L',true);
    caracteresPermitidos.set('M',true);
    caracteresPermitidos.set('N',true);
    caracteresPermitidos.set('Ñ',true);
    caracteresPermitidos.set('O',true);
    caracteresPermitidos.set('P',true);
    caracteresPermitidos.set('Q',true);
    caracteresPermitidos.set('R',true);
    caracteresPermitidos.set('S',true);
    caracteresPermitidos.set('T',true);
    caracteresPermitidos.set('U',true);
    caracteresPermitidos.set('V',true);
    caracteresPermitidos.set('W',true);
    caracteresPermitidos.set('X',true);
    caracteresPermitidos.set('Y',true);
    caracteresPermitidos.set('Z',true);
    caracteresPermitidos.set('1',true);
    caracteresPermitidos.set('2',true);
    caracteresPermitidos.set('3',true);
    caracteresPermitidos.set('4',true);
    caracteresPermitidos.set('5',true);
    caracteresPermitidos.set('6',true);
    caracteresPermitidos.set('7',true);
    caracteresPermitidos.set('8',true);
    caracteresPermitidos.set('9',true);
    caracteresPermitidos.set('0',true);
    caracteresPermitidos.set('-',true); 

    var devuelve = '';
    for(var  i=0 ; i < documento.length ; i++) {
      var texto = documento.charAt(i);  
      if(caracteresPermitidos.get(texto)){
          devuelve = devuelve + texto;
      } 
    } 
    return devuelve; 
  },

  validateImputAmount(amount){ 
    let pointCount = 0;
    var caracteresPermitidos = new Map();
     
    caracteresPermitidos.set('1',true);
    caracteresPermitidos.set('2',true);
    caracteresPermitidos.set('3',true);
    caracteresPermitidos.set('4',true);
    caracteresPermitidos.set('5',true);
    caracteresPermitidos.set('6',true);
    caracteresPermitidos.set('7',true);
    caracteresPermitidos.set('8',true);
    caracteresPermitidos.set('9',true);
    caracteresPermitidos.set('0',true); 
    caracteresPermitidos.set('.',true);

    var devuelve = '';
    // console.log('tamano length' + amount.length);
    for(var  i=0 ; i < amount.length ; i++) {
      var texto = amount.charAt(i);  
      if(texto == '.'){
        pointCount = pointCount + 1; 
       // console.log('puntos' +pointCount);
      }
      if(pointCount <= 1 && caracteresPermitidos.get(texto)){
          devuelve = devuelve + texto;
      }else{
        return '';
      } 
    } 
    return devuelve; 

  },

  currentYear(){
    return new Date().getFullYear();
  },

  currentMonth(){
    var d = new Date();
    var n = d.getMonth();
    return n+1;
  },

  convertParameterListInMap1(list){ // parameterTableTo
    var mapReturn = new Map(); 
    for(var  i=0 ; i < list.length ; i++) {
      var id              = list[i].parameter_table_pk;  
      var decimal_amount1 = list[i].decimal_amount1;  
      mapReturn.set(id, decimal_amount1);
    } 
    return mapReturn; 
  },

  isValidDataRUC(ruc){
    for (var suma = -(ruc%10<2), i = 0; i<11; i++, ruc = ruc/10|0){
      suma += (ruc % 10) * (i % 7 + (i/7|0) + 1);
    }
    if(suma % 11 === 0){
      return true;
    }
    return false;
  },
  
  inputBlurPassword(documento){
    var caracteresPermitidos = new Map();
    caracteresPermitidos.set('A',true);
    caracteresPermitidos.set('B',true);
    caracteresPermitidos.set('C',true);
    caracteresPermitidos.set('D',true);
    caracteresPermitidos.set('E',true);
    caracteresPermitidos.set('F',true);
    caracteresPermitidos.set('G',true);
    caracteresPermitidos.set('H',true);
    caracteresPermitidos.set('I',true);
    caracteresPermitidos.set('J',true);
    caracteresPermitidos.set('K',true);
    caracteresPermitidos.set('L',true);
    caracteresPermitidos.set('M',true);
    caracteresPermitidos.set('N',true);
    caracteresPermitidos.set('O',true);
    caracteresPermitidos.set('P',true);
    caracteresPermitidos.set('Q',true);
    caracteresPermitidos.set('R',true);
    caracteresPermitidos.set('S',true);
    caracteresPermitidos.set('T',true);
    caracteresPermitidos.set('U',true);
    caracteresPermitidos.set('V',true);
    caracteresPermitidos.set('W',true);
    caracteresPermitidos.set('X',true);
    caracteresPermitidos.set('Y',true);
    caracteresPermitidos.set('Z',true);
	
    caracteresPermitidos.set('0',true);
    caracteresPermitidos.set('1',true);
    caracteresPermitidos.set('2',true);
    caracteresPermitidos.set('3',true);
    caracteresPermitidos.set('4',true);
    caracteresPermitidos.set('5',true);
    caracteresPermitidos.set('6',true);
    caracteresPermitidos.set('7',true);
    caracteresPermitidos.set('8',true);
    caracteresPermitidos.set('9',true);
	
    caracteresPermitidos.set('!',true);
    caracteresPermitidos.set(',',true);
    caracteresPermitidos.set('#',true);
    caracteresPermitidos.set('$',true);
    caracteresPermitidos.set(';',true);
    caracteresPermitidos.set('^',true);
    caracteresPermitidos.set('-',true);
    caracteresPermitidos.set('+',true);
    caracteresPermitidos.set('_',true);
    caracteresPermitidos.set('/',true);

    caracteresPermitidos.set('a',true);
    caracteresPermitidos.set('b',true);
    caracteresPermitidos.set('c',true);
    caracteresPermitidos.set('d',true);
    caracteresPermitidos.set('e',true);
    caracteresPermitidos.set('f',true);
    caracteresPermitidos.set('g',true);
    caracteresPermitidos.set('h',true);
    caracteresPermitidos.set('i',true);
    caracteresPermitidos.set('j',true);
    caracteresPermitidos.set('k',true);
    caracteresPermitidos.set('l',true);
    caracteresPermitidos.set('m',true);
    caracteresPermitidos.set('n',true);
    caracteresPermitidos.set('o',true);
    caracteresPermitidos.set('p',true);
    caracteresPermitidos.set('q',true);
    caracteresPermitidos.set('r',true);
    caracteresPermitidos.set('s',true);
    caracteresPermitidos.set('t',true);
    caracteresPermitidos.set('u',true);
    caracteresPermitidos.set('v',true);
    caracteresPermitidos.set('w',true);
    caracteresPermitidos.set('x',true);
    caracteresPermitidos.set('y',true);
    caracteresPermitidos.set('z',true);
	
    var devuelve = '';
    for(var  i=0 ; i < documento.length ; i++) {
      var texto = documento.charAt(i);  
      if(caracteresPermitidos.get(texto)){
          devuelve = devuelve + texto;
      } 
    } 
    return devuelve; 
  },
  
  inputAlphaNumeric(documento){
    var caracteresPermitidos = new Map();
    caracteresPermitidos.set('A',true);
    caracteresPermitidos.set('B',true);
    caracteresPermitidos.set('C',true);
    caracteresPermitidos.set('D',true);
    caracteresPermitidos.set('E',true);
    caracteresPermitidos.set('F',true);
    caracteresPermitidos.set('G',true);
    caracteresPermitidos.set('H',true);
    caracteresPermitidos.set('I',true);
    caracteresPermitidos.set('J',true);
    caracteresPermitidos.set('J',true);
    caracteresPermitidos.set('K',true);
    caracteresPermitidos.set('L',true);
    caracteresPermitidos.set('M',true);
    caracteresPermitidos.set('N',true);
    caracteresPermitidos.set('Ñ',true);
    caracteresPermitidos.set('O',true);
    caracteresPermitidos.set('P',true);
    caracteresPermitidos.set('Q',true);
    caracteresPermitidos.set('R',true);
    caracteresPermitidos.set('S',true);
    caracteresPermitidos.set('T',true);
    caracteresPermitidos.set('U',true);
    caracteresPermitidos.set('V',true);
    caracteresPermitidos.set('W',true);
    caracteresPermitidos.set('X',true);
    caracteresPermitidos.set('Y',true);
    caracteresPermitidos.set('Z',true);
    caracteresPermitidos.set('1',true);
    caracteresPermitidos.set('2',true);
    caracteresPermitidos.set('3',true);
    caracteresPermitidos.set('4',true);
    caracteresPermitidos.set('5',true);
    caracteresPermitidos.set('6',true);
    caracteresPermitidos.set('7',true);
    caracteresPermitidos.set('8',true);
    caracteresPermitidos.set('9',true);
    caracteresPermitidos.set('0',true);

    var devuelve = '';
    for(var  i=0 ; i < documento.length ; i++) {
      var texto = documento.charAt(i);  
      if(caracteresPermitidos.get(texto)){
          devuelve = devuelve + texto;
      } 
    } 
    return devuelve; 
  },

  inputAlphaNumericSpace(documento){
    var caracteresPermitidos = new Map();
    caracteresPermitidos.set('A',true);
    caracteresPermitidos.set('B',true);
    caracteresPermitidos.set('C',true);
    caracteresPermitidos.set('D',true);
    caracteresPermitidos.set('E',true);
    caracteresPermitidos.set('F',true);
    caracteresPermitidos.set('G',true);
    caracteresPermitidos.set('H',true);
    caracteresPermitidos.set('I',true);
    caracteresPermitidos.set('J',true);
    caracteresPermitidos.set('J',true);
    caracteresPermitidos.set('K',true);
    caracteresPermitidos.set('L',true);
    caracteresPermitidos.set('M',true);
    caracteresPermitidos.set('N',true);
    caracteresPermitidos.set('Ñ',true);
    caracteresPermitidos.set('O',true);
    caracteresPermitidos.set('P',true);
    caracteresPermitidos.set('Q',true);
    caracteresPermitidos.set('R',true);
    caracteresPermitidos.set('S',true);
    caracteresPermitidos.set('T',true);
    caracteresPermitidos.set('U',true);
    caracteresPermitidos.set('V',true);
    caracteresPermitidos.set('W',true);
    caracteresPermitidos.set('X',true);
    caracteresPermitidos.set('Y',true);
    caracteresPermitidos.set('Z',true);
    caracteresPermitidos.set('1',true);
    caracteresPermitidos.set('2',true);
    caracteresPermitidos.set('3',true);
    caracteresPermitidos.set('4',true);
    caracteresPermitidos.set('5',true);
    caracteresPermitidos.set('6',true);
    caracteresPermitidos.set('7',true);
    caracteresPermitidos.set('8',true);
    caracteresPermitidos.set('9',true);
    caracteresPermitidos.set('0',true);
    caracteresPermitidos.set(' ',true);

    var devuelve = '';
    for(var  i=0 ; i < documento.length ; i++) {
      var texto = documento.charAt(i);  
      if(caracteresPermitidos.get(texto)){
          devuelve = devuelve + texto;
      } 
    } 
    return devuelve; 
  },

  inputAlphaNumericSimple(documento){
    var caracteresPermitidos = new Map();
    caracteresPermitidos.set('A',true);
    caracteresPermitidos.set('B',true);
    caracteresPermitidos.set('C',true);
    caracteresPermitidos.set('D',true);
    caracteresPermitidos.set('E',true);
    caracteresPermitidos.set('F',true);
    caracteresPermitidos.set('G',true);
    caracteresPermitidos.set('H',true);
    caracteresPermitidos.set('I',true);
    caracteresPermitidos.set('J',true);
    caracteresPermitidos.set('J',true);
    caracteresPermitidos.set('K',true);
    caracteresPermitidos.set('L',true);
    caracteresPermitidos.set('M',true);
    caracteresPermitidos.set('N',true);
    caracteresPermitidos.set('Ñ',true);
    caracteresPermitidos.set('O',true);
    caracteresPermitidos.set('P',true);
    caracteresPermitidos.set('Q',true);
    caracteresPermitidos.set('R',true);
    caracteresPermitidos.set('S',true);
    caracteresPermitidos.set('T',true);
    caracteresPermitidos.set('U',true);
    caracteresPermitidos.set('V',true);
    caracteresPermitidos.set('W',true);
    caracteresPermitidos.set('X',true);
    caracteresPermitidos.set('Y',true);
    caracteresPermitidos.set('Z',true);
    caracteresPermitidos.set('1',true);
    caracteresPermitidos.set('2',true);
    caracteresPermitidos.set('3',true);
    caracteresPermitidos.set('4',true);
    caracteresPermitidos.set('5',true);
    caracteresPermitidos.set('6',true);
    caracteresPermitidos.set('7',true);
    caracteresPermitidos.set('8',true);
    caracteresPermitidos.set('9',true);
    caracteresPermitidos.set('0',true);
    caracteresPermitidos.set('_',true);
    caracteresPermitidos.set('-',true); 
    caracteresPermitidos.set('.',true); 
    caracteresPermitidos.set(' ',true); 

    var devuelve = '';
    for(var  i=0 ; i < documento.length ; i++) {
      var texto = documento.charAt(i);  
      if(caracteresPermitidos.get(texto)){
          devuelve = devuelve + texto;
      } 
    } 
    return devuelve; 
  },

  inputAlphaNumericSpecial(documento){
    var caracteresPermitidos = new Map();
    caracteresPermitidos.set('A',true);
    caracteresPermitidos.set('B',true);
    caracteresPermitidos.set('C',true);
    caracteresPermitidos.set('D',true);
    caracteresPermitidos.set('E',true);
    caracteresPermitidos.set('F',true);
    caracteresPermitidos.set('G',true);
    caracteresPermitidos.set('H',true);
    caracteresPermitidos.set('I',true);
    caracteresPermitidos.set('J',true);
    caracteresPermitidos.set('J',true);
    caracteresPermitidos.set('K',true);
    caracteresPermitidos.set('L',true);
    caracteresPermitidos.set('M',true);
    caracteresPermitidos.set('N',true);
    caracteresPermitidos.set('Ñ',true);
    caracteresPermitidos.set('O',true);
    caracteresPermitidos.set('P',true);
    caracteresPermitidos.set('Q',true);
    caracteresPermitidos.set('R',true);
    caracteresPermitidos.set('S',true);
    caracteresPermitidos.set('T',true);
    caracteresPermitidos.set('U',true);
    caracteresPermitidos.set('V',true);
    caracteresPermitidos.set('W',true);
    caracteresPermitidos.set('X',true);
    caracteresPermitidos.set('Y',true);
    caracteresPermitidos.set('Z',true);
    caracteresPermitidos.set('1',true);
    caracteresPermitidos.set('2',true);
    caracteresPermitidos.set('3',true);
    caracteresPermitidos.set('4',true);
    caracteresPermitidos.set('5',true);
    caracteresPermitidos.set('6',true);
    caracteresPermitidos.set('7',true);
    caracteresPermitidos.set('8',true);
    caracteresPermitidos.set('9',true);
    caracteresPermitidos.set('0',true);
    caracteresPermitidos.set('_',true);
    caracteresPermitidos.set('-',true); 
    caracteresPermitidos.set('.',true); 
    caracteresPermitidos.set(':',true); 
    caracteresPermitidos.set(';',true); 
    caracteresPermitidos.set(',',true); 
    caracteresPermitidos.set('<',true); 
    caracteresPermitidos.set('>',true); 
    caracteresPermitidos.set('+',true); 
    caracteresPermitidos.set('-',true); 
    caracteresPermitidos.set('*',true); 
    caracteresPermitidos.set('/',true); 
    caracteresPermitidos.set('=',true); 
    caracteresPermitidos.set('(',true); 
    caracteresPermitidos.set(')',true); 
    caracteresPermitidos.set('{',true); 
    caracteresPermitidos.set('}',true); 
    caracteresPermitidos.set('[',true); 
    caracteresPermitidos.set(']',true); 
    caracteresPermitidos.set('"',true); 
    caracteresPermitidos.set("'",true); 
    caracteresPermitidos.set("?",true); 
    caracteresPermitidos.set("!",true); 
    caracteresPermitidos.set("#",true); 
    caracteresPermitidos.set("$",true); 
    caracteresPermitidos.set("%",true); 
    caracteresPermitidos.set("&",true); 
    caracteresPermitidos.set("@",true); 
    caracteresPermitidos.set(' ',true); 
    caracteresPermitidos.set('  ',true); 

    var devuelve = '';
    for(var  i=0 ; i < documento.length ; i++) {
      var texto = documento.charAt(i);  
      if(caracteresPermitidos.get(texto)){
          devuelve = devuelve + texto;
      } 
    } 
    return devuelve; 
  },

  inputOnliDecimal(dni){
    var caracteresPermitidos = new Map();
     
    caracteresPermitidos.set('1',true);
    caracteresPermitidos.set('2',true);
    caracteresPermitidos.set('3',true);
    caracteresPermitidos.set('4',true);
    caracteresPermitidos.set('5',true);
    caracteresPermitidos.set('6',true);
    caracteresPermitidos.set('7',true);
    caracteresPermitidos.set('8',true);
    caracteresPermitidos.set('9',true);
    caracteresPermitidos.set('0',true); 
    caracteresPermitidos.set('.',true); 
    //caracteresPermitidos.set(',',true); 

    var devuelve = '';
    for(var  i=0 ; i < dni.length ; i++) {
      var texto = dni.charAt(i);  
      if(caracteresPermitidos.get(texto)){
          devuelve = devuelve + texto;
      } 
    } 
    return devuelve; 
  },

  inputOnliNumeric(dni){
    var caracteresPermitidos = new Map();
     
    caracteresPermitidos.set('1',true);
    caracteresPermitidos.set('2',true);
    caracteresPermitidos.set('3',true);
    caracteresPermitidos.set('4',true);
    caracteresPermitidos.set('5',true);
    caracteresPermitidos.set('6',true);
    caracteresPermitidos.set('7',true);
    caracteresPermitidos.set('8',true);
    caracteresPermitidos.set('9',true);
    caracteresPermitidos.set('0',true); 

    var devuelve = '';
    for(var  i=0 ; i < dni.length ; i++) {
      var texto = dni.charAt(i);  
      if(caracteresPermitidos.get(texto)){
          devuelve = devuelve + texto;
      } 
    } 
    return devuelve; 
  },

  inputOnliText(documento){
    var caracteresPermitidos = new Map();
    caracteresPermitidos.set('A',true);
    caracteresPermitidos.set('B',true);
    caracteresPermitidos.set('C',true);
    caracteresPermitidos.set('D',true);
    caracteresPermitidos.set('E',true);
    caracteresPermitidos.set('F',true);
    caracteresPermitidos.set('G',true);
    caracteresPermitidos.set('H',true);
    caracteresPermitidos.set('I',true);
    caracteresPermitidos.set('J',true);
    caracteresPermitidos.set('J',true);
    caracteresPermitidos.set('K',true);
    caracteresPermitidos.set('L',true);
    caracteresPermitidos.set('M',true);
    caracteresPermitidos.set('N',true);
    caracteresPermitidos.set('Ñ',true);
    caracteresPermitidos.set('O',true);
    caracteresPermitidos.set('P',true);
    caracteresPermitidos.set('Q',true);
    caracteresPermitidos.set('R',true);
    caracteresPermitidos.set('S',true);
    caracteresPermitidos.set('T',true);
    caracteresPermitidos.set('U',true);
    caracteresPermitidos.set('V',true);
    caracteresPermitidos.set('W',true);
    caracteresPermitidos.set('X',true);
    caracteresPermitidos.set('Y',true);
    caracteresPermitidos.set('Z',true);

    var devuelve = '';
    for(var  i=0 ; i < documento.length ; i++) {
      var texto = documento.charAt(i);  
      if(caracteresPermitidos.get(texto)){
          devuelve = devuelve + texto;
      } 
    } 
    return devuelve; 
  },

  inputOnliTextAndSpace(documento){
    var caracteresPermitidos = new Map();
    caracteresPermitidos.set('A',true);
    caracteresPermitidos.set('B',true);
    caracteresPermitidos.set('C',true);
    caracteresPermitidos.set('D',true);
    caracteresPermitidos.set('E',true);
    caracteresPermitidos.set('F',true);
    caracteresPermitidos.set('G',true);
    caracteresPermitidos.set('H',true);
    caracteresPermitidos.set('I',true);
    caracteresPermitidos.set('J',true);
    caracteresPermitidos.set('J',true);
    caracteresPermitidos.set('K',true);
    caracteresPermitidos.set('L',true);
    caracteresPermitidos.set('M',true);
    caracteresPermitidos.set('N',true);
    caracteresPermitidos.set('Ñ',true);
    caracteresPermitidos.set('O',true);
    caracteresPermitidos.set('P',true);
    caracteresPermitidos.set('Q',true);
    caracteresPermitidos.set('R',true);
    caracteresPermitidos.set('S',true);
    caracteresPermitidos.set('T',true);
    caracteresPermitidos.set('U',true);
    caracteresPermitidos.set('V',true);
    caracteresPermitidos.set('W',true);
    caracteresPermitidos.set('X',true);
    caracteresPermitidos.set('Y',true);
    caracteresPermitidos.set('Z',true);
    caracteresPermitidos.set(' ',true);

    var devuelve = '';
    for(var  i=0 ; i < documento.length ; i++) {
      var texto = documento.charAt(i);  
      if(caracteresPermitidos.get(texto)){
          devuelve = devuelve + texto;
      } 
    } 
    return devuelve; 
  },
  
  inputPassword(documento){
    var caracteresPermitidos = new Map();
    caracteresPermitidos.set('A',true);
    caracteresPermitidos.set('B',true);
    caracteresPermitidos.set('C',true);
    caracteresPermitidos.set('D',true);
    caracteresPermitidos.set('E',true);
    caracteresPermitidos.set('F',true);
    caracteresPermitidos.set('G',true);
    caracteresPermitidos.set('H',true);
    caracteresPermitidos.set('I',true);
    caracteresPermitidos.set('J',true);
    caracteresPermitidos.set('J',true);
    caracteresPermitidos.set('K',true);
    caracteresPermitidos.set('L',true);
    caracteresPermitidos.set('M',true);
    caracteresPermitidos.set('N',true);
    caracteresPermitidos.set('Ñ',true);
    caracteresPermitidos.set('O',true);
    caracteresPermitidos.set('P',true);
    caracteresPermitidos.set('Q',true);
    caracteresPermitidos.set('R',true);
    caracteresPermitidos.set('S',true);
    caracteresPermitidos.set('T',true);
    caracteresPermitidos.set('U',true);
    caracteresPermitidos.set('V',true);
    caracteresPermitidos.set('W',true);
    caracteresPermitidos.set('X',true);
    caracteresPermitidos.set('Y',true);
    caracteresPermitidos.set('Z',true);
    caracteresPermitidos.set('@',true);
    caracteresPermitidos.set('-',true);
    caracteresPermitidos.set('_',true);
    caracteresPermitidos.set('.',true);
    caracteresPermitidos.set('0',true);
    caracteresPermitidos.set('1',true);
    caracteresPermitidos.set('2',true);
    caracteresPermitidos.set('3',true);
    caracteresPermitidos.set('4',true);
    caracteresPermitidos.set('5',true);
    caracteresPermitidos.set('6',true);
    caracteresPermitidos.set('7',true);
    caracteresPermitidos.set('8',true);
    caracteresPermitidos.set('9',true);

    var devuelve = '';
    for(var  i=0 ; i < documento.length ; i++) {
      var texto = documento.charAt(i);  
      if(caracteresPermitidos.get(texto)){
          devuelve = devuelve + texto;
      } 
    } 
    return devuelve; 
  },

  inputEmail(documento){
    var caracteresPermitidos = new Map();
    caracteresPermitidos.set('A',true);
    caracteresPermitidos.set('B',true);
    caracteresPermitidos.set('C',true);
    caracteresPermitidos.set('D',true);
    caracteresPermitidos.set('E',true);
    caracteresPermitidos.set('F',true);
    caracteresPermitidos.set('G',true);
    caracteresPermitidos.set('H',true);
    caracteresPermitidos.set('I',true);
    caracteresPermitidos.set('J',true);
    caracteresPermitidos.set('J',true);
    caracteresPermitidos.set('K',true);
    caracteresPermitidos.set('L',true);
    caracteresPermitidos.set('M',true);
    caracteresPermitidos.set('N',true);
    caracteresPermitidos.set('Ñ',true);
    caracteresPermitidos.set('O',true);
    caracteresPermitidos.set('P',true);
    caracteresPermitidos.set('Q',true);
    caracteresPermitidos.set('R',true);
    caracteresPermitidos.set('S',true);
    caracteresPermitidos.set('T',true);
    caracteresPermitidos.set('U',true);
    caracteresPermitidos.set('V',true);
    caracteresPermitidos.set('W',true);
    caracteresPermitidos.set('X',true);
    caracteresPermitidos.set('Y',true);
    caracteresPermitidos.set('Z',true);
    caracteresPermitidos.set('@',true);
    caracteresPermitidos.set('-',true);
    caracteresPermitidos.set('_',true);
    caracteresPermitidos.set('.',true);
    caracteresPermitidos.set('0',true);
    caracteresPermitidos.set('1',true);
    caracteresPermitidos.set('2',true);
    caracteresPermitidos.set('3',true);
    caracteresPermitidos.set('4',true);
    caracteresPermitidos.set('5',true);
    caracteresPermitidos.set('6',true);
    caracteresPermitidos.set('7',true);
    caracteresPermitidos.set('8',true);
    caracteresPermitidos.set('9',true);

    var devuelve = '';
    for(var  i=0 ; i < documento.length ; i++) {
      var texto = documento.charAt(i);  
      if(caracteresPermitidos.get(texto)){
          devuelve = devuelve + texto;
      } 
    } 
    return devuelve; 
  },

  getSizeDocumentType(documentType: number){
    if(documentType == 1){//DNI
      return 8;
    }
    return 12;
  },
 
  getClaseInteres(cashNominal: number){
    let claseInteres = "";
    if(cashNominal != null){
      claseInteres = claseInteresType.getClaseInteresTypeDescription(cashNominal);
    }
    return claseInteres;
  },

  getImgWithIssuerCode(issuerCode: String){
    var listIssuerSession:Array<issuerSession> = JSON.parse(sessionStorage.getItem("issuerSession")) as Array<issuerSession>;
    if(listIssuerSession != null && listIssuerSession.length >0){
      for(var issuerSession of listIssuerSession){
        if(issuerSession.issuerCode == issuerCode){
          return <String>issuerSession.imgBase64;
        }
      }
    }
    return "";
  }

}