import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/AppComponents/login/login.component';
import { HomeComponent } from './components/AppComponents/home/home.component';
import { CanActivateViaAuthGuardService } from './configuration/providers/CanActivateViaAuthGuardService/can-activate-via-auth-guard.service';
import { NewinvestorConfirmsucessComponent } from './components/UserComponents/newinvestor-confirmsucess/newinvestor-confirmsucess.component';
import { ConfirmarSuscripcionComponent } from './components/UserComponents/confirmar-suscripcion/confirmar-suscripcion.component';
import { ConfirmarActualizarContrasenaComponent } from './components/UserComponents/confirmar-actualizar-contrasena/confirmar-actualizar-contrasena.component';
import { ConfirmarModificarSuscripcionComponent } from './components/UserComponents/confirmar-modificar-suscripcion/confirmar-modificar-suscripcion.component';
import { SuscripcionConfirmsucessComponent } from './components/UserComponents/suscripcion-confirmsucess/suscripcion-confirmsucess.component';
import { SuscripcionConfirmerrorComponent } from './components/UserComponents/suscripcion-confirmerror/suscripcion-confirmerror.component';
import { RecuperarComponent } from './components/UserComponents/recuperar/recuperar.component';
import { NewinvestorComponent } from './components/InvestorComponents/newinvestor/newinvestor.component';
import { ProductoComponent } from './components/ProductsComponents/producto/producto.component';
import { ProductoInformacionComponent } from './components/ProductsComponents/productoinformacion/producto-informacion.component';
import { SuscripcionComponent } from './components/UserComponents/suscripcion/suscripcion.component';
import { SuscripcionSucessComponent } from './components/UserComponents/suscripcion-sucess/suscripcion-sucess.component';
import { MicuentaComponent } from './components/UserComponents/mi-cuenta/mi-cuenta.component';
import { ProductoPlantillasComponent } from './components/ProductsComponents/productoPlantillas/productoPlantillas.component';
import { TodasMisInversionesComponent } from './components/InvestmentsComponents/todas-mis-inversiones/todas-mis-inversiones.component';
import { DetalleInversionComponent } from './components/ProductsComponents/detalle-inversion/detalle-inversion.component';
import { MisMovimientosComponent } from './components/CashAccountComponents/mis-movimientos/mis-movimientos.component';
import { MisRendimientosComponent } from './components/CashAccountComponents/mis-rendimientos/mis-rendimientos.component';
import { CuentaBancariaComponent } from './components/UserComponents/cuentaBancaria/cuentaBancaria.component';
import { MisCuentasBancariasComponent } from './components/UserComponents/mis-cuentasBancarias/mis-cuentasBancarias.component';
import { MisEfectivosComponent } from './components/CashAccountComponents/mis-efectivos/mis-efectivos.component';
import { PreguntasComponent } from './components/AppComponents/preguntas/preguntas.component';
import { TerminosComponent } from './components/AppComponents/terminos/terminos.component';
import { ReportCertificadoRetencionComponent } from './components/ReportsComponents/report-certificado-retencion/report-certificado-retencion.component';
import { ReportPolizaComponent } from './components/ReportsComponents/report-poliza/report-poliza.component';
import { ReportEstadoCuentaComponent } from './components/ReportsComponents/report-estado-cuenta/report-estado-cuenta.component';
import { NotificacionesComponent } from './components/AppComponents/notificaciones/notificaciones.component';
import { CronogramaComponent } from './components/InvestmentsComponents/cronograma/cronograma.component';
import { ComprarComponent } from './components/ShoppingCartComponents/comprar/comprar.component';
import { CarritoComponent } from './components/ShoppingCartComponents/carrito/carrito.component';
import { MisNotificacionesComponent } from './components/NotificationsComponents/mis-notificaciones/mis-notificaciones.component';
import { PoliticasComponent } from './components/AppComponents/politicas/politicas.component';
import { LibroComponent } from './components/AppComponents/libro/libro.component';
import { InversionesDetalleComponent } from './components/InvestmentsComponents/inversiones-detalle/inversiones-detalle.component';
import { InversionesAnterioresComponent } from './components/InvestmentsComponents/inversiones-anteriores/inversiones-anteriores.component';
import { DetalleCronogramaComponent } from './components/InvestmentsComponents/detalle-cronograma/detalle-cronograma.component';
import { DetalleMovimientoComponent } from './components/InvestmentsComponents/detalle-movimiento/detalle-movimiento.component';
import { ArkDetalleProductoComponent } from './components/ProductsComponents/ark-detalle-producto/ark-detalle-producto.component';
import { ReportOrdenesComponent } from './components/ReportsComponents/report-ordenes/report-ordenes.component';
import { TodasMisInversionesAnterioresComponent } from './components/InvestmentsComponents/todas-mis-inversiones-anteriores/todas-mis-inversiones-anteriores.component';
import { ResendEmailAndToken } from './components/UserComponents/resendEmailAndToken/resendEmailAndToken.component';


const routes: Routes = [
  
  { path: '',  redirectTo: 'login', pathMatch: "full" },
  
  { path: 'login', component: LoginComponent, pathMatch: "full" },
  { path: 'libroReclamanciones', component: LibroComponent, pathMatch: "full" },
  { path: 'politicaPrivacidad', component: PoliticasComponent, pathMatch: "full" },
  { path: 'preguntas', component: PreguntasComponent, pathMatch: "full" },
  { path: 'terminos', component: TerminosComponent, pathMatch: "full" },

  { path: 'confirmarNewInvestor', component: NewinvestorConfirmsucessComponent, pathMatch: "full" , canActivate: [CanActivateViaAuthGuardService] },
  { path: 'confirmarSuscripcion', component: ConfirmarSuscripcionComponent, pathMatch: "full" },
  { path: 'confirmarActualizarContrasena', component: ConfirmarActualizarContrasenaComponent, pathMatch: "full"},
  { path: 'confirmarModificarSuscripcion', component: ConfirmarModificarSuscripcionComponent, pathMatch: "full"},
  
  { path: 'suscripcionConfirmsucessComponent', component: SuscripcionConfirmsucessComponent, pathMatch: "full" },
  { path: 'suscripcionConfirmerrorComponent', component: SuscripcionConfirmerrorComponent, pathMatch: "full" },
  //{ path: 'suscripcionConfirmresendtokenComponent', component: SuscripcionConfirmresendtokenComponent, pathMatch: "full" },
  { path: 'recuperar', component: RecuperarComponent, pathMatch: "full" },
  { path: 'resendEmailAndToken', component: ResendEmailAndToken, pathMatch: "full" },
  /* --------------------------------------------------------------------------------------------------------------------------------------------------- */
  { path: 'home', component: HomeComponent, pathMatch: "full", canActivate: [CanActivateViaAuthGuardService] }, /* ,  canActivate: [CanActivateViaAuthGuardService] */
  { path: 'newinvestor', component: NewinvestorComponent, pathMatch: "full"  },  /*,  canActivate: [CanActivateViaAuthGuardService] */
  { path: 'products', component: ProductoComponent, pathMatch: "full" , canActivate: [CanActivateViaAuthGuardService]},
  { path: 'productInformacion', component: ProductoInformacionComponent, pathMatch: "full" , canActivate: [CanActivateViaAuthGuardService]},
  //{ path: 'loanCalculator', component: LoanCalculatorLoggedComponent, pathMatch: "full" , canActivate: [CanActivateViaAuthGuardService]},
  { path: 'suscripcion', component: SuscripcionComponent, pathMatch: "full" },
  { path: 'suscripcion-sucess', component: SuscripcionSucessComponent, pathMatch: "full" },
  { path: 'miCuenta', component: MicuentaComponent, pathMatch: "full" , canActivate: [CanActivateViaAuthGuardService]},
  { path: 'productoPlantillas', component: ProductoPlantillasComponent, pathMatch: "full" , canActivate: [CanActivateViaAuthGuardService]},
  { path: 'todasMisInversiones', component: TodasMisInversionesComponent, pathMatch: "full" , canActivate: [CanActivateViaAuthGuardService]},
  { path: 'todasMisInversionesAnteriores', component: TodasMisInversionesAnterioresComponent, pathMatch: "full" , canActivate: [CanActivateViaAuthGuardService]},
  { path: 'inversionesAnteriores', component: InversionesAnterioresComponent, pathMatch: "full" , canActivate: [CanActivateViaAuthGuardService]},
  { path: 'inversionesDetalle', component: InversionesDetalleComponent, pathMatch: "full" , canActivate: [CanActivateViaAuthGuardService]},
  { path: 'detalleInversion', component: DetalleInversionComponent, pathMatch: "full" , canActivate: [CanActivateViaAuthGuardService]},
  { path: 'detalleCronograma', component: DetalleCronogramaComponent, pathMatch: "full" , canActivate: [CanActivateViaAuthGuardService]},
  { path: 'detalleMovimiento', component: DetalleMovimientoComponent, pathMatch: "full" , canActivate: [CanActivateViaAuthGuardService]},
  { path: 'arkDetalleProducto', component: ArkDetalleProductoComponent, pathMatch: "full" , canActivate: [CanActivateViaAuthGuardService]},
  { path: 'misMovimientos', component: MisMovimientosComponent, pathMatch: "full" , canActivate: [CanActivateViaAuthGuardService]},
  { path: 'misRendimientos', component: MisRendimientosComponent, pathMatch: "full" , canActivate: [CanActivateViaAuthGuardService]},
  { path: 'cuentaBancaria', component: CuentaBancariaComponent, pathMatch: "full" , canActivate: [CanActivateViaAuthGuardService]},
  { path: 'misCuentasBancarias', component: MisCuentasBancariasComponent, pathMatch: "full" , canActivate: [CanActivateViaAuthGuardService]},
  { path: 'misEfectivos', component: MisEfectivosComponent, pathMatch: "full" , canActivate: [CanActivateViaAuthGuardService]},
  { path: 'reportCertificadoRetencion', component: ReportCertificadoRetencionComponent, pathMatch: "full", canActivate: [CanActivateViaAuthGuardService]},
  { path: 'reportPoliza', component: ReportPolizaComponent, pathMatch: "full", canActivate: [CanActivateViaAuthGuardService]},
  { path: 'reportEstadoCuenta', component: ReportEstadoCuentaComponent, pathMatch: "full" , canActivate: [CanActivateViaAuthGuardService]},
  { path: 'reportOrdenes', component: ReportOrdenesComponent, pathMatch: "full" , canActivate: [CanActivateViaAuthGuardService]},
  { path: 'notificaciones', component: NotificacionesComponent, pathMatch: "full", canActivate: [CanActivateViaAuthGuardService]},
  { path: 'cronograma', component: CronogramaComponent, pathMatch: "full", canActivate: [CanActivateViaAuthGuardService]},
  { path: 'comprar', component: ComprarComponent, pathMatch: "full", canActivate: [CanActivateViaAuthGuardService]},
  { path: 'carrito', component: CarritoComponent, pathMatch: "full", canActivate: [CanActivateViaAuthGuardService]},
  { path: 'misNotificaciones', component: MisNotificacionesComponent, pathMatch: "full" , canActivate: [CanActivateViaAuthGuardService]},

  // otherwise redirect to home
  { path: '**', redirectTo: '' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
