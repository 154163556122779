import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeadermenuSessionComponent } from './components/TemplateComponents/headermenu-session/headermenu-session.component';
import { LateralmenuSessionComponent } from './components/TemplateComponents/lateralmenu-session/lateralmenu-session.component';
import { FootermenuSessionComponent } from './components/TemplateComponents/footermenu-session/footermenu-session.component';
import { HeadermenuWhitoutsessionComponent } from './components/TemplateComponents/headermenu-whitoutsession/headermenu-whitoutsession.component';
import { FootermenuWhitoutsessionComponent } from './components/TemplateComponents/footermenu-whitoutsession/footermenu-whitoutsession.component';
import { LoginComponent } from './components/AppComponents/login/login.component';
import { HomeComponent } from './components/AppComponents/home/home.component';
import { HttpClientModule } from  '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms'; 
import { NgxSpinnerModule } from "ngx-spinner"; 
import { ModalModule } from './configuration/providers/modal';
import { NewinvestorComponent } from './components/InvestorComponents/newinvestor/newinvestor.component';
import { RecuperarComponent } from './components/UserComponents/recuperar/recuperar.component';
import { DatosanexopepComponent } from './components/InvestorComponents/datosanexopep/datosanexopep.component';
import { DatosanexopepConyugeComponent } from './components/InvestorComponents/datosanexopepConyuge/datosanexopepConyuge.component';
import { DatosanexopepFamiliaComponent } from './components/InvestorComponents/datosanexopepFamilia/datosanexopepFamilia.component';
import { ProductoComponent } from './components/ProductsComponents/producto/producto.component';
import { SuscripcionComponent } from './components/UserComponents/suscripcion/suscripcion.component';
import { ConfirmarSuscripcionComponent } from './components/UserComponents/confirmar-suscripcion/confirmar-suscripcion.component';
import { MicuentaComponent } from './components/UserComponents/mi-cuenta/mi-cuenta.component';
import { ConfirmarActualizarContrasenaComponent } from './components/UserComponents/confirmar-actualizar-contrasena/confirmar-actualizar-contrasena.component';
import { ConfirmarModificarSuscripcionComponent } from './components/UserComponents/confirmar-modificar-suscripcion/confirmar-modificar-suscripcion.component';
import { ProductohomeComponent } from './components/ProductsComponents/productohome/productohome.component';
import { ProductofinancieroComponent } from './components/ProductsComponents/productofinanciero/productofinanciero.component';
import { ProductovigenteComponent } from './components/ProductsComponents/productovigente/productovigente.component';
import { ProductoInformacionComponent } from './components/ProductsComponents/productoinformacion/producto-informacion.component';
import { ProductoinversionesComponent } from './components/ProductsComponents/productoinversiones/productoinversiones.component';
import { ProductodetalleComponent } from './components/ProductsComponents/productodetalle/productodetalle.component';
import { ArkDetalleProductoComponent } from './components/ProductsComponents/ark-detalle-producto/ark-detalle-producto.component';
import { MisinversionesComponent } from './components/AppComponents/misinversiones/misinversiones.component';
import { MiefectivoComponent } from './components/AppComponents/miefectivo/miefectivo.component';
import { DondeinvertirComponent } from './components/AppComponents/dondeinvertir/dondeinvertir.component';
import { ProductoPlantillasComponent } from './components/ProductsComponents/productoPlantillas/productoPlantillas.component';
import { TodasMisInversionesComponent } from './components/InvestmentsComponents/todas-mis-inversiones/todas-mis-inversiones.component';
import { DetalleInversionComponent } from './components/ProductsComponents/detalle-inversion/detalle-inversion.component';
import { MovimientosComponent } from './components/ProductsComponents/movimientos/movimientos.component';
import { NewinvestorConfirmsucessComponent } from './components/UserComponents/newinvestor-confirmsucess/newinvestor-confirmsucess.component';
import { SuscripcionConfirmerrorComponent } from './components/UserComponents/suscripcion-confirmerror/suscripcion-confirmerror.component';
import { SuscripcionConfirmsucessComponent } from './components/UserComponents/suscripcion-confirmsucess/suscripcion-confirmsucess.component';
import { SuscripcionSucessComponent } from './components/UserComponents/suscripcion-sucess/suscripcion-sucess.component';
import { MisNotificacionesComponent } from './components/NotificationsComponents/mis-notificaciones/mis-notificaciones.component';
import { CarritoComponent } from './components/ShoppingCartComponents/carrito/carrito.component';
import { ComprarComponent } from './components/ShoppingCartComponents/comprar/comprar.component';
import { CronogramaComponent } from './components/InvestmentsComponents/cronograma/cronograma.component';
import { NotificacionesComponent } from './components/AppComponents/notificaciones/notificaciones.component';
import { EstadoCuentaComponent } from './components/ReportsComponents/estado-cuenta/estado-cuenta.component';
import { ReportEstadoCuentaComponent } from './components/ReportsComponents/report-estado-cuenta/report-estado-cuenta.component';
import { PolizaComponent } from './components/ReportsComponents/poliza/poliza.component';
import { ReportPolizaComponent } from './components/ReportsComponents/report-poliza/report-poliza.component';
import { CertificadoRetencionComponent } from './components/ReportsComponents/certificado-retencion/certificado-retencion.component';
import { ReportCertificadoRetencionComponent } from './components/ReportsComponents/report-certificado-retencion/report-certificado-retencion.component';
import { TerminosComponent } from './components/AppComponents/terminos/terminos.component';
import { PreguntasComponent } from './components/AppComponents/preguntas/preguntas.component';
import { MiefectivoSolesComponent } from './components/AppComponents/miefectivo-soles/miefectivo-soles.component';
import { MiefectivoDolaresComponent } from './components/AppComponents/miefectivo-dolares/miefectivo-dolares.component';
import { MisEfectivosComponent } from './components/CashAccountComponents/mis-efectivos/mis-efectivos.component';
import { MisCuentasBancariasComponent } from './components/UserComponents/mis-cuentasBancarias/mis-cuentasBancarias.component';
import { CuentaBancariaComponent } from './components/UserComponents/cuentaBancaria/cuentaBancaria.component';
import { MisRendimientosComponent } from './components/CashAccountComponents/mis-rendimientos/mis-rendimientos.component';
import { RendimientosComponent } from './components/ProductsComponents/redimientos/rendimientos.component';
import { MisMovimientosComponent } from './components/CashAccountComponents/mis-movimientos/mis-movimientos.component';
import { TwoDigitDecimaNumberDirective } from './configuration/directive/TwoDigitDecimaNumberDirective';
import { SortPipe } from './configuration/pipes/sort.pipe';
import { NumberDirective } from './configuration/directive/numbers-only.directive';
import { CanActivateViaAuthGuardService } from './configuration/providers/CanActivateViaAuthGuardService/can-activate-via-auth-guard.service';
import { LibroComponent } from './components/AppComponents/libro/libro.component';
import { PoliticasComponent } from './components/AppComponents/politicas/politicas.component';
import { InversionesAnterioresComponent } from './components/InvestmentsComponents/inversiones-anteriores/inversiones-anteriores.component';
import { InversionesDetalleComponent } from './components/InvestmentsComponents/inversiones-detalle/inversiones-detalle.component';
import { DetalleCronogramaComponent } from './components/InvestmentsComponents/detalle-cronograma/detalle-cronograma.component';
import { DetalleMovimientoComponent } from './components/InvestmentsComponents/detalle-movimiento/detalle-movimiento.component';
import { ReportOrdenesComponent } from './components/ReportsComponents/report-ordenes/report-ordenes.component';
import { SimplemodalComponent } from './components/AppComponents/simplemodal/simplemodal.component';
import { TodasMisInversionesAnterioresComponent } from './components/InvestmentsComponents/todas-mis-inversiones-anteriores/todas-mis-inversiones-anteriores.component';
import { DatosanexosComponent } from './components/InvestorComponents/datosanexos/datosanexos.component';
import { DatosorigenfondosComponent } from './components/InvestorComponents/datosorigenfondos/datosorigenfondos.component';
import { DatoscuentasbancariasComponent } from './components/InvestorComponents/datoscuentasbancarias/datoscuentasbancarias.component';
import { DatosactividadinstruccionComponent } from './components/InvestorComponents/datosactividadinstruccion/datosactividadinstruccion.component';
import { DatosperfilriesgoComponent } from './components/InvestorComponents/datosperfilriesgo/datosperfilriesgo.component';
import { DatospersonalesComponent } from './components/InvestorComponents/datospersonales/datospersonales.component';
import { TourmodalComponent } from './components/AppComponents/tourmodal/tourmodal.component';
import { ResendEmailAndToken } from './components/UserComponents/resendEmailAndToken/resendEmailAndToken.component';

@NgModule({
  declarations: [
    AppComponent,
    HeadermenuSessionComponent,
    LateralmenuSessionComponent,
    FootermenuSessionComponent,
    HeadermenuWhitoutsessionComponent,
    FootermenuWhitoutsessionComponent,
    LoginComponent,
    HomeComponent,
    NewinvestorComponent,  
    RecuperarComponent, 
    ResendEmailAndToken, 
    DatosanexopepComponent,
    DatosanexopepConyugeComponent,
    DatosanexopepFamiliaComponent,
    ProductoComponent, 
    SuscripcionComponent,    
    ConfirmarSuscripcionComponent, 
    MicuentaComponent, 
    ConfirmarActualizarContrasenaComponent,
    ConfirmarModificarSuscripcionComponent,
    ProductohomeComponent, 
    ProductofinancieroComponent, 
    ProductovigenteComponent, 
    ProductoInformacionComponent,
    ProductoinversionesComponent, 
    ProductodetalleComponent, 
    MisinversionesComponent, 
    MiefectivoComponent, 
    DondeinvertirComponent,
    ProductoPlantillasComponent,
    TodasMisInversionesComponent, 
    InversionesAnterioresComponent,
    InversionesDetalleComponent,
    DetalleInversionComponent, 
    MovimientosComponent, 
    ArkDetalleProductoComponent,
    DetalleCronogramaComponent,
    DetalleMovimientoComponent,
    MisMovimientosComponent, 
    RendimientosComponent, 
    MisRendimientosComponent,
    CuentaBancariaComponent, 
    MisCuentasBancariasComponent, 
    MisEfectivosComponent,
    NumberDirective, 
    MiefectivoDolaresComponent, 
    MiefectivoSolesComponent, 
    PreguntasComponent,
    TerminosComponent, 
    ReportCertificadoRetencionComponent, 
    CertificadoRetencionComponent,
    ReportPolizaComponent, 
    PolizaComponent,
    ReportEstadoCuentaComponent, 
    ReportOrdenesComponent,
    EstadoCuentaComponent,
    NotificacionesComponent,   
    CronogramaComponent, 
    ComprarComponent,
    TwoDigitDecimaNumberDirective, 
    CarritoComponent,
    MisNotificacionesComponent, 
    SortPipe,
    SuscripcionSucessComponent,
    SuscripcionConfirmsucessComponent,
    SuscripcionConfirmerrorComponent,
    NewinvestorConfirmsucessComponent,
    LibroComponent,
    PoliticasComponent,
    SimplemodalComponent,
    TourmodalComponent,
    TodasMisInversionesAnterioresComponent,
    DatosanexosComponent,
    DatosorigenfondosComponent,
    DatoscuentasbancariasComponent,
    DatosactividadinstruccionComponent,
    DatosperfilriesgoComponent,
    DatospersonalesComponent,
  ],

  
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule, 
    FormsModule, 
    ReactiveFormsModule, 
    ModalModule, 
    BrowserAnimationsModule,
    NgxSpinnerModule 
  ],
  providers: [CanActivateViaAuthGuardService, SortPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }