import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MASTER_TABLE } from 'src/app/constants/masterTable';
import { ParameterService } from 'src/app/services/ParameterServices/parameter.service';
import { parameterTableTo } from 'src/app/model/UserModel/parameterTableTo';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { func } from 'src/app/constants/func';
import { SimplemodalComponent } from '../simplemodal/simplemodal.component';
import { MESSAGE_ES } from 'src/app/constants/message_es';

@Component({
  selector: 'ark-libro',
  templateUrl: './libro.component.html',
  styleUrls: ['./libro.component.css']
})
export class LibroComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
              private parameterService: ParameterService,
              private router: Router,
              private  spinner: NgxSpinnerService) { }

  paramTipoDocumento:Array<parameterTableTo> = [] as Array<parameterTableTo>;
  paramTipoMoneda:Array<parameterTableTo> = [] as Array<parameterTableTo>;
  paramViaRespuesta:Array<parameterTableTo> = [] as Array<parameterTableTo>;
  formLibro: FormGroup;
  tipoInconveniente:number = null;
  submitted = false;
  @ViewChild(SimplemodalComponent,{static: false}) simplemodalComponent: SimplemodalComponent;
  
  get form() { return this.formLibro.controls; }
  
  initValuesForm(){

    this.formLibro = this.formBuilder.group({
      fechaSolicitud:                      [null, Validators.required],
      nombres:                             [null, Validators.required],
      apellidos:                           [null, Validators.required],
      tipoDocumento:                       [null, Validators.required],
      nroDocumento:                        [null, Validators.required],
      telefono:                            [null, Validators.required],
      correo:                              [null, Validators.required],
      productoRelacionado:                 [null],
      moneda:                              [null],
      montoRelacionado:                    [null],
      detalles:                            [null, Validators.required],
      viaRespuesta:                        [null, Validators.required], 
      disclaimer1:                         [null, Validators.required],
      disclaimer2:                         [null, Validators.required],
    });
  }

  ngOnInit() {
    this.spinner.show();
    this.initValuesForm();
    this.getParameterTable();
    this.spinner.hide();
  }

  gotoPage(page: String){
    this.router.navigate([page]); 
  }

  setTipoInconveniente(tipoInconveniente){
    this.tipoInconveniente = tipoInconveniente;
  }

  async getParameterTable(){

    let listaParameterDocType  = await this.parameterService.getParameter(MASTER_TABLE.DOCUMENT_TYPE);
    this.paramTipoDocumento     =  listaParameterDocType as Array<parameterTableTo>; 

    let listaParameterCurrencyType  = await this.parameterService.getParameter(MASTER_TABLE.CURRENCY);
    this.paramTipoMoneda     =  listaParameterCurrencyType as Array<parameterTableTo>; 
    
    let parameters:Array<parameterTableTo> = [];
    let parameter:parameterTableTo;

    parameter = {} as parameterTableTo ;
    parameter.parameter_table_pk = 1;
    parameter.parameter_table_cd = '1';
    parameter.description= 'Telefono';
    parameters.push(parameter);
    
    parameter = {} as parameterTableTo ;
    parameter.parameter_table_pk = 2;
    parameter.parameter_table_cd = '2';
    parameter.description= 'Correo';
    parameters.push(parameter);
    this.paramViaRespuesta = parameters;

  }

  sendMessage(){

    this.submitted = true;
    if(this.formLibro.invalid){
      return;
    }else{
      this.simplemodalComponent.alertar(MESSAGE_ES.CABECERA.WARNING , MESSAGE_ES.LIBRO.REGISTRO_EXITOSO);
    }
    
  }

  onKeyUpEmail(event: any, object:FormControl){
    object.setValue(func.inputEmail(event.target.value));
  }

  onKeyUpOnliNumeric(event: any, object:FormControl){
    object.setValue(func.inputOnliNumeric(event.target.value));
  }

  onKeyUpOnliText(event: any, object:FormControl){
    object.setValue(func.inputOnliText(event.target.value));
  }

  onKeyUpAplhaNumeric(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumeric(event.target.value));
  }

  onKeyUpAplhaNumericSpace(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumericSpace(event.target.value));
  }

  onKeyUpAplhaNumericSimple(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumericSimple(event.target.value));
  }

  onKeyUpAplhaNumericSpecial(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumericSpecial(event.target.value));
  }
}
