import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router'; 
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from 'src/app/configuration/providers/modal';
import { URL } from 'src/app/constants/url';
import { func } from 'src/app/constants/func';
import { userAccountTo } from 'src/app/model/UserModel/userAccountTo';
import { responseTo } from 'src/app/model/UserModel/responseTo';
import { userAccountRequestTo } from 'src/app/model/UserModel/userAccountRequestTo';


@Component({
  selector: 'ark-confirmar-modificar-suscripcion',
  templateUrl: './confirmar-modificar-suscripcion.component.html',
  styleUrls: ['./confirmar-modificar-suscripcion.component.css']
})
export class ConfirmarModificarSuscripcionComponent implements OnInit {

  mensaje = '';
  mensajeSub = '';
  userId = null;
  fullName = '';

  submitted = false;  //send parameters

  token = new FormControl('', Validators.required);

  email_token = '';

  userAccountRequestTo = <userAccountRequestTo>{};

  constructor(private httpClient: HttpClient, private modalService: ModalService, 
              private router: Router, private  spinner: NgxSpinnerService) { }

  ngOnInit() { 

    this.userId = null;
    this.fullName = '';  
    
        var emailweb = window.location.search;
        this.email_token = emailweb.replace( "?emailToken=", "" );
        console.log("el web tkn :: "+ this.email_token); 
 
        // llamar al servicio de consulta para traer el nombre del usuario, parametro de envio email_token
        this.spinner.show();
        this.httpClient.get(URL.SERVICE_GET_USER_ACCOUNT_BY_EMAIL_TOKEN + this.email_token,
               {  headers: func.WSHeader() })
            . subscribe(
          (response) => {    

              this.spinner.hide();

              let obj : userAccountTo = <userAccountTo>response ;
              if(obj.items.length != 0){                
                this.userId = obj.items[0].id_user_pk ;
                this.fullName = obj.items[0].name+', '+obj.items[0].first_lastname; // response
              }else{
                this.mensajeSub = '';
                this.mensaje = "Alerta, verifique el email.";
                this.modalService.open('idModalWarning');  
              }


          },
          (err: HttpErrorResponse) => {
            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);
            
            this.spinner.hide();
            this.mensajeSub = '';
            this.mensaje = "Ocurrio un error \n " + err.message;
            this.modalService.open('idModalErrorfull');
            
          },
          () => {
          this.spinner.hide();
          console.log("************* this.httpClient.post ()::" + "The confirmar registro de suscripcion POST observable is now completed.");
          }
        ); 


  }

  public abrirModalReenvio() {
    this.modalService.open('idModalReenvio');    
  }

  reenviarToken() {
      let userTokenId = '';
      // se indica que se va actualizar el token sms

      this.spinner.show();
        this.httpClient.get(URL.SERVICE_API_V1_USER + this.userId + 
                            URL.SERVICE_GET_USER_TOKEN_OUT_LINE_END + this.email_token,
                              { headers: func.WSHeader() })
              . subscribe(
            (response) => {  

                  let bodyParam = {
                      "phone_token" : true,
                      "email_token" : false
                  }

                  let responseTmp = <responseTo>response; // items[0].user_token_pk;
                  let userTokenId = responseTmp.items[0].id_user_token_pk;

                  // refrescar token
                  //  /api/v1/users/213/token/{userTokenId}/recreate
                  this.httpClient.post(URL.SERVICE_API_V1_USER + this.userId + 
                                       URL.SERVICE_RECREATE_TOKEN_MIDDLE + userTokenId +
                                       URL.SERVICE_RECREATE_TOKEN_END,
                            bodyParam, { headers: func.WSHeader() })
                              . subscribe(
                            (response) => {  
                                console.log(" response :: " + JSON.stringify(response) );
                              
                                this.modalService.close('idModalReenvio');
                                this.mensajeSub = "Token reenviado";
                                this.mensaje = "Se generó un nuevo token por Email.";
                                this.modalService.open('idModalSuccesfullSms');
                                
                            },
                            (err: HttpErrorResponse) => {
                              console.log(err.error);
                              console.log(err.name);
                              console.log(err.message);
                              console.log(err.status);
                              
                              this.spinner.hide();
                              this.mensajeSub = '';
                              this.mensaje = "Ocurrio un error \n " + err.message;
                              this.modalService.open('idModalErrorfull');                              
                              return ;
                          },
                          () => {
                            this.spinner.hide();
                            console.log("************* this.httpClient.post ()::" + "The confirmar registro de suscripcion POST observable is now completed.");
                          }
                  );

                  // fin refrescar token
                
            },
            (err: HttpErrorResponse) => {
              console.log(err.error);
              console.log(err.name);
              console.log(err.message);
              console.log(err.status);
              
              this.spinner.hide();
              this.mensajeSub = '';
              this.mensaje = "Ocurrio un error \n " + err.message;
              this.modalService.open('idModalErrorfull');              
              return ;
          },
          () => {
            this.spinner.hide();
            console.log("************* this.httpClient.post ()::" + "The confirmar registro de suscripcion POST observable is now completed.");
          }
        );
            

  }


  public closeModal(id: string) {
     this.modalService.close(id);
  }

    
  onSubmit() {   // rehacer

    this.submitted = true;
    if(this.token.invalid) {
      return;
    }

    // ws que devuelve el user account request
    this.userAccountRequestTo = null;
    this.spinner.show();
     
    this.httpClient.get(URL.SERVICE_OBTENER_USER_ACCOUNT_REQUEST + this.email_token + 
                        URL.SERVICE_OBTENER_USER_ACCOUNT_REQUEST_END,
                 { headers: func.WSHeader() })
          . subscribe(
          (response) => {    

            let responseTmp = <responseTo>response; // items[0].user_token_pk;
            let id_user_account_request_fk = responseTmp.items[0].id_user_account_request_fk;
                
            // llamar al servcio de confirmacion
            let datos = {
              "phone_token" : this.token.value,
              "id_user_request_pk" : id_user_account_request_fk
            } 

            //      /api/v1/users/{userId}/request/confirm 
            this.httpClient.post(URL.SERVICE_API_V1_USER + this.userId + URL.SERVICE_CONFIRM_MODIFY_SUSC_END ,
                         datos, { headers: func.WSHeader() })
                . subscribe(
                (response) => {   
          
                    console.log(response);
          
                    this.mensajeSub = '';
                    this.mensaje = 'Confirmación realizada correctamente.';
                    this.modalService.open('idModalSuccesfull');

                },
                (err: HttpErrorResponse) => {
                  
                    console.log(err.error);
                    console.log(err.name);
                    console.log(err.message);
                    console.log(err.status);

                    if(err.error.code == 404) {
                      this.spinner.hide();
                      this.mensajeSub = '';
                      this.mensaje = "Token de Email no encontrado, verifique";
                      this.modalService.open('idModalWarning');
                    }else {
                      this.spinner.hide();
                      this.mensajeSub = '';
                      this.mensaje = "Ocurrio un problema en la confirmación de la suscipción, reintente \n " + err.message;
                      this.modalService.open('idModalWarning');
                    } 
                },
                () => {
                  this.spinner.hide();
                  console.log("************* this.httpClient.post ()::" + "The confirmar token recuperar POST observable is now completed.");
                  // this.mensaje = "Ocurrio un error";
                }
          );   
          // fin llamar al servcio de confirmacion  

        },
        (err: HttpErrorResponse) => {
              
            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);  

            this.token.setValue(null);
            this.spinner.hide();
            this.mensajeSub = '';
            this.mensaje = "Ocurrio un problema en la confirmación de modificación de la suscipcion, reintente \n " + err.message;
            this.modalService.open('idModalErrorfull');

        },
        () => {
          this.spinner.hide();
          console.log("************* this.httpClient.post ()::" + "The confirmar token recuperar POST observable is now completed.");
        // this.mensaje = "Ocurrio un error";
        }

    ); 
    // fin ws que devuelve el user account request
 

  }


  gotoLogin() {
    this.router.navigate(["/login"]);
  }

}
