import { Component, OnInit, Input, ViewChild } from '@angular/core'; 
import { HttpClient, HttpErrorResponse } from '@angular/common/http'; 
import { NgxSpinnerService } from 'ngx-spinner';   
import { Router } from '@angular/router';  
import { estadoCuentaTo } from 'src/app/model/UserModel/estadoCuentaTo';
import { ModalService } from 'src/app/configuration/providers/modal';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { userTo } from 'src/app/model/UserModel/userTo';
import { URL } from 'src/app/constants/url';
import { reportsMaping } from 'src/app/constants/reportsMaping';
import { responseReporteTo } from 'src/app/model/UserModel/responseReporteTo';
import { func } from 'src/app/constants/func';
import { FormControl } from '@angular/forms';
import { SimplemodalComponent } from '../../AppComponents/simplemodal/simplemodal.component';

@Component({
  selector: 'ark-estado-cuenta',
  templateUrl: './estado-cuenta.component.html',
  styleUrls: ['./estado-cuenta.component.css']
})
export class EstadoCuentaComponent implements OnInit {

  @ViewChild(SimplemodalComponent,{static: false}) simplemodalComponent: SimplemodalComponent;
  @Input() entity : estadoCuentaTo = {} as estadoCuentaTo;   
  objUserTo = {} as userTo;
  fileDownload = null;
  modalFileCode : String;
   
  tamDocNumber : Number;

  text1 = new FormControl('');
  text2 = new FormControl('');
  text3 = new FormControl('');
  text4 = new FormControl('');
  text5 = new FormControl('');
  text6 = new FormControl('');
  text7 = new FormControl('');
  text8 = new FormControl('');
  text9 = new FormControl('');
  text10 = new FormControl('');
  text11 = new FormControl('');
  text12 = new FormControl('');
  text13 = new FormControl('');
  text14 = new FormControl(''); 
 

  constructor(private modalService: ModalService, 
              private httpClient: HttpClient,
              private loginService: LoginService,
              private  spinner: NgxSpinnerService,
              private router: Router) { }

  ngOnInit() {  
    this.objUserTo = <userTo>this.loginService.currentUser(); 
    this.tamDocNumber = this.objUserTo.document_number.length;  
  }   

  descargarPdf(){

    this.modalService.close('idModalDownload');

    let documentNumber = (this.text1.value+""+this.text2.value+""+this.text3.value+""+
                          this.text4.value+""+this.text5.value+""+this.text6.value+""+
                          this.text7.value+""+this.text8.value+""+this.text9.value+""+
                          this.text10.value+""+this.text11.value+""+this.text12.value+""+
                          this.text13.value+""+this.text14.value);
                          
    if(documentNumber ==''){
      this.simplemodalComponent.alertar("Mensaje de Alerta","Debe ingresar el número de documento");
      this.cleanModalImputs();

    }else{

      if(documentNumber != this.objUserTo.document_number){
        this.simplemodalComponent.alertar("Mensaje de Alerta","El número de documento ingresado es incorrecto.");
        this.cleanModalImputs();

      }else{

        this.spinner.show(); 
        this.httpClient.post(URL.SERVICE_API_V1_USER + this.objUserTo.id_user_pk + 
                            URL.REPORT_PATH_FILE + this.modalFileCode +
                            URL.REPORT_PATH_TYPE_FILE + reportsMaping.ACCOUNT_STATEMENTS ,
              {  headers: func.WSHeader() })
          . subscribe(
                (response) => { 
                    
                    let responseBd = <responseReporteTo>response;  
                    this.fileDownload = responseBd.file_path;
                    this.router.navigate([]).then(result => {  window.open(this.fileDownload, '_blank'); });
                  
                    this.cleanModalImputs();
                },
                (err: HttpErrorResponse) => {
                  console.log(err.error);
                  console.log(err.name);
                  console.log(err.message);
                  console.log(err.status);
                  
                  this.spinner.hide();  
              },
              () => {
                this.spinner.hide();
                this.cleanModalImputs();
              }
        );   
      } 
    }

  }

  formatCurrentDate(parameter_date){

    var year = func.getYearFromDate(parameter_date);
    var month = func.getMonthNameFromDate(parameter_date);
    
    return month+' '+year;
  }
 
  openDownloadModal(file_code: String){
    this.cleanModalImputs(); 
    if(file_code == ''){
      this.simplemodalComponent.alertar("Mensaje de Alerta","El periodo seleccionado no tiene estado de cuenta.");
    }else{
      this.modalFileCode = file_code; 
      this.modalService.open('idModalDownload');
    }    
  }

  cleanModalImputs(){
    this.text1.setValue('');
    this.text2.setValue('');
    this.text3.setValue('');
    this.text4.setValue('');
    this.text5.setValue('');
    this.text6.setValue('');
    this.text7.setValue('');
    this.text8.setValue('');
    this.text9.setValue('');
    this.text10.setValue('');
    this.text11.setValue('');
    this.text12.setValue('');
    this.text13.setValue('');
    this.text14.setValue('');
  }
  
  


}
