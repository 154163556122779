import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LateralmenuSessionComponent } from './components/TemplateComponents/lateralmenu-session/lateralmenu-session.component';
import { LoginService } from './configuration/providers/SessionService/login.service';
import { errorMaping } from './constants/errorMaping';
import { func } from './constants/func';
import { URL } from './constants/url';
import { globalScreen } from './model/UserModel/globalScreen';
import { UserAuditTracking } from './model/UserModel/UserAuditTracking';

@Component({
  selector: 'ark-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  
  // constructor(private  spinner: NgxSpinnerService){}
  public email_token = false
  public isMailToken = false;
 
  
  public menuSession = false;
  public menuSessionLeft = false;
 
  @ViewChild(LateralmenuSessionComponent,{static: false}) lateralmenuSessionComponent: LateralmenuSessionComponent;

  constructor(private router: Router,private httpClient: HttpClient,private loginService: LoginService) {
    
  }
  
   changeOfRoutes(){
     
 
     if(this.loginService.isLogged() && !this.loginService.pageWithoutMenu()){
       this.isMailToken = true;
       this.menuSession = true;

       this.tracking();

     }else{
       this.isMailToken = false;
       this.menuSession = false;
     }
 
     
    if(this.loginService.isLoggedAndRut() && !this.loginService.pageWithoutMenu()){
      this.menuSessionLeft = true;
      this.menuOptionSelected();
    }else{
      this.menuSessionLeft = true;
    }


   }

   public tracking(){ 
    
    var userAuditTracking:UserAuditTracking       = JSON.parse(localStorage.getItem("UserAuditTracking")) as UserAuditTracking;

    if(userAuditTracking!=null){
      userAuditTracking.url = window.location.href;

      this.httpClient.post(URL.SERVICE_TRACKING, userAuditTracking, { headers: func.WSHeader() } )
          .subscribe(
            (response) => { console.log(response) },
            (err: HttpErrorResponse) => { console.log("tracking()::"+JSON.stringify(errorMaping)); }, () => { }
          );
    }

  }
   
   menuOptionSelected(){
     
    var url = window.location.href;
    var lateral = "";

    if(this.lateralmenuSessionComponent == null){
      return;
    }
    
          if( url.includes( "todasMisInversiones") ||
              url.includes( "detalleCronograma") ||
              url.includes( "detalleMovimiento")
    ){
         lateral = "inversiones";
         this.lateralmenuSessionComponent.setLatertal(lateral);
    }else if( url.includes( "products") ||
              url.includes( "productInformacion")
    ){
         lateral = "productos";
         this.lateralmenuSessionComponent.setLatertal(lateral);
    }else if( url.includes( "misEfectivos") )
    {
         lateral = "efectivo";
         this.lateralmenuSessionComponent.setLatertal(lateral);
    }else if(  url.includes( "reportEstadoCuenta") ||
               url.includes( "reportPoliza") ||
               url.includes( "reportOrdenes") ||
               url.includes( "reportCertificadoRetencion") )
    {
         lateral = "micuentasab";
         this.lateralmenuSessionComponent.setLatertal(lateral);
    }else if( url.includes( "home") )
    {
         lateral = "home";
         this.lateralmenuSessionComponent.setLatertal(lateral);
    }else{
      lateral = " ";
      this.lateralmenuSessionComponent.setLatertal(lateral);
    }
    
   }
   

   ngAfterViewInit(){

  }

   ngOnInit() {
 
     if (!this.loginService.isLogged()) {
       this.isMailToken = false;
     }else{
       this.isMailToken = true;
     }
     
     var url = window.location.href;
     this.email_token = url.includes( "?emailToken=");
     if(this.email_token){
         this.isMailToken = false;
         this.menuSession = false;
     }
 
     this.changeOfRoutes();
   }
 
   dinamicHeight()  {
     if(this.isMailToken){
       return {'min-height':(screen.height - 243)+'px'};
     }else{
       return {'min-height':(screen.height)+'px'};
     }
   }
 
   outputDataMenu($event:globalScreen){
     if($event!=null){
      var evento:globalScreen = $event;
      this.lateralmenuSessionComponent.setLatertal(evento.lateral);
     }
   }
 
 
}
