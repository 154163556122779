import { Component, OnInit } from '@angular/core';  
import { ModalService } from 'src/app/configuration/providers/modal';
import { userTo } from 'src/app/model/UserModel/userTo';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';

@Component({
  selector: 'ark-tourmodal',
  templateUrl: './tourmodal.component.html',
  styleUrls: ['./tourmodal.component.css']
}) 

export class TourmodalComponent implements OnInit { 

 
  constructor(private modalService: ModalService,private loginService: LoginService) { }

  userTo:userTo = this.loginService.currentUser();
  headText:String = '';
  bodyText:String = '';


  ngOnInit() {

  }

  openModal(page1){
    this.modalService.open(page1+"");  
  }

  closeModalHome(){ 
    this.closeModal('idModalHome');
  }

  openCloseModal(page1,page2){
    this.modalService.close(page1);
    this.modalService.open(page2);  
  }

  closeModal(page){ 
    this.modalService.close(page);
  }
  
}
 