import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { step4 } from 'src/app/model/UserModel/step4';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { func } from 'src/app/constants/func';
import { ParameterService } from 'src/app/services/ParameterServices/parameter.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PARAMETER_TABLE_YES_NO } from 'src/app/constants/PARAMETER_TABLE_YES_NO';
import { countryMaping } from 'src/app/constants/countryMaping';
import { InvReqBankAccountReturn } from 'src/app/model/UserModel/InvReqBankAccountReturn';
import { InvReqPersonReturn } from 'src/app/model/UserModel/InvReqPersonReturn';
import { parameterTableTo } from 'src/app/model/UserModel/parameterTableTo';
import { MASTER_TABLE } from 'src/app/constants/masterTable';

@Component({
  selector: 'ark-datoscuentasbancarias',
  templateUrl: './datoscuentasbancarias.component.html',
  styleUrls: ['./datoscuentasbancarias.component.css']
})
export class DatoscuentasbancariasComponent implements OnInit {

  @Input()  inputData:step4   	= {} as step4;
  @Output() outputDataEvent     = new EventEmitter<step4>();
  @Output() alertEvent        	= new EventEmitter<String>();
  @Output() modalEvent          = new EventEmitter<String>();
  
  
  submitted_datosCuentasBancarias:boolean = false;
  currentData:step4   = {} as step4;
  formDatosCuentasBancarias: FormGroup; 
  
  submitAddList_datosCuentasBancarias: boolean;
  listInvInvReqBankAccount: Array<InvReqBankAccountReturn> = [] as Array<InvReqBankAccountReturn>;

  bankOrigin:number = 882;//nacional
  
  constructor(private formBuilder: FormBuilder, private  spinner: NgxSpinnerService,private parameterService: ParameterService) { }

  ngOnInit() {
    this.initVariables();
    this.loadParameters();
    this.setDefaultData();
    this.initValuesForm_datosCuentasBancarias();
    this.initActionsPostForm();
  }

  initVariables(){
    this.currentData = this.inputData;
    if(this.currentData.entity_datosCuentasBancarias == null){ 
        this.currentData.entity_datosCuentasBancarias = {} as InvReqBankAccountReturn; 
    }else{
      this.listInvInvReqBankAccount = this.currentData.entity_datosCuentasBancarias.listInvReqBankAccount; 
    }
    if(this.currentData.entity_InvReqPerson == null){ this.currentData.entity_InvReqPerson = {} as InvReqPersonReturn; }

  }
  
  async loadParameters(){

    if(this.currentData.paramBank == null){
      this.currentData.paramBank = await this.parameterService.getAllBank() as Array<parameterTableTo>;
    }
    if(this.currentData.paramBankAccountType == null){
      this.currentData.paramBankAccountType = await this.parameterService.getParameter(MASTER_TABLE.BANK_ACCOUNT_TYPE) as Array<parameterTableTo>;
    }
    if(this.currentData.paramCurrency == null){
      this.currentData.paramCurrency = await this.parameterService.getParameter(MASTER_TABLE.CURRENCY) as Array<parameterTableTo>;
    }

  }

  setDefaultData(){
    if(this.currentData.action == null || this.currentData.action == 'R'){
      
    }else{//M

    }
  }

  nullObject(object){
    return (object == null)?'':object;
  }

  initValuesForm_datosCuentasBancarias(){
    //console.log("this.entity.send_order_by_str:: "+this.entity.send_order_by_str);

    this.formDatosCuentasBancarias = this.formBuilder.group({
      id_bank_fk:                      [this.nullObject(this.currentData.entity_datosCuentasBancarias.idBankFk), Validators.required], //ok
      bank_account_type:               [this.nullObject(this.currentData.entity_datosCuentasBancarias.bankAccountType), Validators.required],//ok
      currency:                        [this.nullObject(this.currentData.entity_datosCuentasBancarias.currency), Validators.required],//ok
      bank_account_number:             [this.nullObject(this.currentData.entity_datosCuentasBancarias.bankAccountNumber), Validators.required],//ok
      bank_account_cci:                [this.nullObject(this.currentData.entity_datosCuentasBancarias.bankAccountCci), Validators.required],//ok    
      is_default_payment_account:      [this.nullObject(this.currentData.entity_datosCuentasBancarias.isDefaultPaymentAccount), Validators.required],
      send_order_by_str:               [this.nullObject(this.currentData.entity_InvReqPerson.sendOrderByStr), Validators.required],//revisar
      send_policy_by:                  [this.nullObject(this.currentData.entity_InvReqPerson.sendPolicyBy), Validators.required],
      send_account_statement_by:       [this.nullObject(this.currentData.entity_InvReqPerson.sendAccountStatementBy), Validators.required],
      perform_operations_abroad:       [this.nullObject(this.currentData.entity_InvReqPerson.performOperationsAbroad), Validators.required],
    });

  }

  initActionsPostForm(){
    this.loadMediosComunicacion();
    this.loadEnvioPoliza();
    this.loadEstadoCuenta();
    
    if(this.currentData.action == null || this.currentData.action == 'M' && !this.currentData.enabledForm){
      this.disabled_inputs_registrar_ficha()
    }

  }
  
  disabled_inputs_registrar_ficha(){
    eval('$(function(){$("#newInvestorDiv :input").prop("disabled", true); });');
    eval('$(function(){$(".navigateButton").prop("disabled", false); });');
  }
  
  enabled_inputs_registrar_ficha(){
    this.currentData.enabledForm = true;
    eval('$(function(){$("#newInvestorDiv :input").prop("disabled", false); });');
  }

  saveSetp4(){
    this.submitted_datosCuentasBancarias = true;

    if(this.onValidation_datosCuentasBancarias()){
      this.setDataSetp4();
    }else{
      this.alertar("Completar los datos obligatorios. Verifique por favor.");
      return;
    }
  }

  setDataSetp4(){
    this.setEntityData_datosCuentasBancarias();
    this.inputData = this.currentData;
    this.outputDataEvent.emit(this.inputData);
  }

  alertar(mensaje:String){
    this.alertEvent.emit(mensaje);
    return;
  }

  openModal(mensaje:String)
  {
    this.modalEvent.emit(mensaje);
    return;
  }

  
  /*
      form functions
  */

  onValidation_datosCuentasBancarias(){
    if(  this.formDatosCuentasBancarias.controls.send_order_by_str.errors
      || this.formDatosCuentasBancarias.controls.send_policy_by.errors
      || this.formDatosCuentasBancarias.controls.send_account_statement_by.errors
      || this.formDatosCuentasBancarias.controls.perform_operations_abroad.errors
      || this.listInvInvReqBankAccount == null
      || 0 >= this.listInvInvReqBankAccount.length
    ){
      return false;
    }
    return true;
  }

setEntityData_datosCuentasBancarias(){
    this.currentData.entity_datosCuentasBancarias.idBankFk              = this.formDatosCuentasBancarias.value.id_bank_fk ;
    this.currentData.entity_datosCuentasBancarias.bankAccountType       = this.formDatosCuentasBancarias.value.bank_account_type;
    this.currentData.entity_datosCuentasBancarias.currency              = this.formDatosCuentasBancarias.value.currency;
    this.currentData.entity_datosCuentasBancarias.bankAccountNumber     = this.formDatosCuentasBancarias.value.bank_account_number ;
    this.currentData.entity_datosCuentasBancarias.bankAccountCci        = this.formDatosCuentasBancarias.value.bank_account_cci ;
    this.currentData.entity_datosCuentasBancarias.listInvReqBankAccount = this.listInvInvReqBankAccount;

    this.currentData.entity_InvReqPerson.sendOrderByStr           = this.formDatosCuentasBancarias.value.send_order_by_str;
    this.currentData.entity_InvReqPerson.sendPolicyBy             = this.formDatosCuentasBancarias.value.send_policy_by;
    this.currentData.entity_InvReqPerson.sendAccountStatementBy   = this.formDatosCuentasBancarias.value.send_account_statement_by;
    this.currentData.entity_InvReqPerson.performOperationsAbroad  = this.formDatosCuentasBancarias.value.perform_operations_abroad;

}


  get form_datosCuentasBancarias() { return this.formDatosCuentasBancarias.controls; }

  loadMediosComunicacion(){
    
    var miFunction = "$(function() { ";
    
      if(this.formDatosCuentasBancarias.controls.send_order_by_str.value != null){
        var initString = ""+this.formDatosCuentasBancarias.controls.send_order_by_str.value;
        var pos0 =  initString.charAt(0);
        var pos1 =  initString.charAt(1);
        var pos2 =  initString.charAt(2);
        var pos3 =  initString.charAt(3);

        if(pos0=="1"){
          miFunction += " $('input[name=medios0]').prop('checked', 'checked'); ";
        }
        if(pos1=="1"){
          miFunction += " $('input[name=medios1]').prop('checked', 'checked'); ";
        }
        if(pos2=="1"){
          miFunction += " $('input[name=medios2]').prop('checked', 'checked'); ";
        }
        if(pos3=="1"){
          miFunction += " $('input[name=medios3]').prop('checked', 'checked'); ";
        }

      }
      miFunction += "});";
      eval(miFunction);
  }

  loadEnvioPoliza(){
    console.log("this.formDatosCuentasBancarias.controls.send_policy_by.value::"+this.formDatosCuentasBancarias.controls.send_policy_by.value);

    var miFunction = "$(function() { ";
    
      if(this.formDatosCuentasBancarias.controls.send_policy_by.value != null){
        var initString = ""+this.formDatosCuentasBancarias.controls.send_policy_by.value;
        var pos0 =  initString.charAt(0);
        var pos1 =  initString.charAt(1);

        if(pos0=="1"){
          miFunction += " $('input[name=mediosEnvio0]').prop('checked', 'checked'); ";
        }
        if(pos1=="1"){
          miFunction += " $('input[name=mediosEnvio1]').prop('checked', 'checked'); ";
        }
      }
      
    miFunction += "});";
    console.log("miFunction:"+miFunction);
    eval(miFunction);
  }

  loadEstadoCuenta(){
    console.log("this.formDatosCuentasBancarias.controls.send_account_statement_by.value::"+this.formDatosCuentasBancarias.controls.send_account_statement_by.value);
    var miFunction = "$(function() { ";
    
      if(this.formDatosCuentasBancarias.controls.send_account_statement_by.value != null){
        var initString = ""+this.formDatosCuentasBancarias.controls.send_account_statement_by.value;
        var pos0 =  initString.charAt(0);
        var pos1 =  initString.charAt(1);

        if(pos0=="1"){
          miFunction += " $('input[name=mediosCuenta0]').prop('checked', 'checked'); ";
        }
        if(pos1=="1"){
          miFunction += " $('input[name=mediosCuenta1]').prop('checked', 'checked'); ";
        }
      }
      
    miFunction += "});";
    console.log("miFunction:"+miFunction);
    eval(miFunction);
  }




 /*******/
  mediosComunicacionChecked(posicion:number){

    var initString = "0000";
      if(this.formDatosCuentasBancarias.controls.send_order_by_str.value == null || 
          this.formDatosCuentasBancarias.controls.send_order_by_str.value == 'undefined' || 
          this.formDatosCuentasBancarias.controls.send_order_by_str.value == 'Nan'){
        this.formDatosCuentasBancarias.controls.send_order_by_str.setValue("0000");
      }else{
        initString = this.formDatosCuentasBancarias.controls.send_order_by_str.value;
      }

    var miFunction = "";
    
    var pos0 =  (posicion!=null && posicion==0)?((initString.charAt(0)=="0")?"1": "0"):initString.charAt(0);
    var pos1 =  (posicion!=null && posicion==1)?((initString.charAt(1)=="0")?"1": "0"):initString.charAt(1);
    var pos2 =  (posicion!=null && posicion==2)?((initString.charAt(2)=="0")?"1": "0"):initString.charAt(2);
    var pos3 =  (posicion!=null && posicion==3)?((initString.charAt(3)=="0")?"1": "0"):initString.charAt(3);

    this.formDatosCuentasBancarias.controls.send_order_by_str.setValue(pos0+pos1+pos2+pos3);
    
    if(posicion!=null && posicion==3){
      if(pos3 == '1'){
        miFunction = "$(function() { $('input[name=medios3]').prop('checked', 'checked'); });";
        eval(miFunction);
      }else{
        miFunction = "$(function() { $('input[name=medios3]').prop('checked', ''); });";
        eval(miFunction);
      }
    }

    if(posicion!=null && posicion==2){
      if(pos2 == '1'){
        miFunction = "$(function() { $('input[name=medios2]').prop('checked', 'checked'); });";
        eval(miFunction);
      }else{
        miFunction = "$(function() { $('input[name=medios2]').prop('checked', ''); });";
        eval(miFunction);
      }
    }

    if(posicion!=null && posicion==1){
      if(pos1 == '1'){
        miFunction = "$(function() { $('input[name=medios1]').prop('checked', 'checked'); });";
        eval(miFunction);
      }else{
        miFunction = "$(function() { $('input[name=medios1]').prop('checked', ''); });";
        eval(miFunction);
      }
    }

    if(posicion!=null && posicion==0){
      if(pos0 == '1'){
        miFunction = "$(function() { $('input[name=medios0]').prop('checked', 'checked'); });";
        eval(miFunction);
      }else{
        miFunction = "$(function() { $('input[name=medios0]').prop('checked', ''); });";
        eval(miFunction);
      }
    }

  }

  
  mediosEnvioPolizaChecked(posicion:number){
    
    var initString = "00";
      if(this.formDatosCuentasBancarias.controls.send_policy_by.value == null || 
          this.formDatosCuentasBancarias.controls.send_policy_by.value == 'undefined' || 
          this.formDatosCuentasBancarias.controls.send_policy_by.value == 'Nan'){
        this.formDatosCuentasBancarias.controls.send_policy_by.setValue("00");
      }else{
        initString = this.formDatosCuentasBancarias.controls.send_policy_by.value;
      }

    var miFunction = "";
    
    var pos0 =  (posicion!=null && posicion==0)?((initString.charAt(0)=="0")?"1": "0"):initString.charAt(0);
    var pos1 =  (posicion!=null && posicion==1)?((initString.charAt(1)=="0")?"1": "0"):initString.charAt(1);
    
    this.formDatosCuentasBancarias.controls.send_policy_by.setValue(pos0+pos1);
    
    if(posicion!=null && posicion==1){
      if(pos1 == '1'){
        miFunction = "$(function() { $('input[name=mediosEnvio1]').prop('checked', 'checked'); });";
        eval(miFunction);
      }else{
        miFunction = "$(function() { $('input[name=mediosEnvio1]').prop('checked', ''); });";
        eval(miFunction);
      }
    }
    if(posicion!=null && posicion==0){
      if(pos0 == '1'){
        miFunction = "$(function() { $('input[name=mediosEnvio0]').prop('checked', 'checked'); });";
        eval(miFunction);
      }else{
        miFunction = "$(function() { $('input[name=mediosEnvio0]').prop('checked', ''); });";
        eval(miFunction);
      }
    }
    
  }

 changeBankOrigin(){
   //console.log("this.form_datosCuentasBancarias.id_bank_fk.value::"+this.form_datosCuentasBancarias.id_bank_fk.value);
   for(var current of this.currentData.paramBank){
     if(this.form_datosCuentasBancarias.id_bank_fk.value == current.parameterTablePk){
       this.bankOrigin = current.shortInteger;
       if(current.shortInteger==883){//internacional

       }else{//nacional

       }
     }
   }
 }
 

 validateMaxsizeBankCci(){
   var cci:string = this.formDatosCuentasBancarias.controls.bank_account_cci.value;
   if(cci != null && cci != "" && cci.length < 20){
     this.alertar("El número de CCI debe de ser de 20 caracteres, verifique.");
     this.form_datosCuentasBancarias.bank_account_cci.setValue(null);
   }
 }

 mediosEstadoCuentaChecked(posicion:number){
   
   var initString = "00";
     if(this.formDatosCuentasBancarias.controls.send_account_statement_by.value == null || 
        this.formDatosCuentasBancarias.controls.send_account_statement_by.value == 'undefined' || 
        this.formDatosCuentasBancarias.controls.send_account_statement_by.value == 'Nan'){
       this.formDatosCuentasBancarias.controls.send_account_statement_by.setValue("00");
     }else{
       initString = this.formDatosCuentasBancarias.controls.send_account_statement_by.value;
     }

   var miFunction = "";
   
   var pos0 =  (posicion!=null && posicion==0)?((initString.charAt(0)=="0")?"1": "0"):initString.charAt(0);
   var pos1 =  (posicion!=null && posicion==1)?((initString.charAt(1)=="0")?"1": "0"):initString.charAt(1);
   
   this.formDatosCuentasBancarias.controls.send_account_statement_by.setValue(pos0+pos1);
   
   if(posicion!=null && posicion==1){
     if(pos1 == '1'){
       miFunction = "$(function() { $('input[name=mediosCuenta1]').prop('checked', 'checked'); });";
       eval(miFunction);
     }else{
       miFunction = "$(function() { $('input[name=mediosCuenta1]').prop('checked', ''); });";
       eval(miFunction);
     }
   }
   if(posicion!=null && posicion==0){
     if(pos0 == '1'){
       miFunction = "$(function() { $('input[name=mediosCuenta0]').prop('checked', 'checked'); });";
       eval(miFunction);
     }else{
       miFunction = "$(function() { $('input[name=mediosCuenta0]').prop('checked', ''); });";
       eval(miFunction);
     }
   }
   
 }

  onValidForDatosCuentasBancarias_datosCuentasBancarias(){
    if ( this.formDatosCuentasBancarias.controls.bank_account_number.errors 
      || this.formDatosCuentasBancarias.controls.currency.errors 
      || this.formDatosCuentasBancarias.controls.bank_account_type.errors 
      || this.formDatosCuentasBancarias.controls.id_bank_fk.errors
      || this.formDatosCuentasBancarias.controls.is_default_payment_account.errors
    ){
      return false;
    }
    return true;
  }

  onSubmitFormDatosCuentasBancarias_datosCuentasBancarias(){
    this.submitAddList_datosCuentasBancarias = true;

    if(this.onValidForDatosCuentasBancarias_datosCuentasBancarias()){

      var currentPush:InvReqBankAccountReturn = {} as InvReqBankAccountReturn;
      currentPush.isDefaultPaymentAccount     = this.formDatosCuentasBancarias.controls.is_default_payment_account.value;
      currentPush.currency                    = this.formDatosCuentasBancarias.controls.currency.value;
      currentPush.bankAccountNumber           = this.formDatosCuentasBancarias.controls.bank_account_number.value;
      currentPush.idBankFk                    = this.formDatosCuentasBancarias.controls.id_bank_fk.value;
      currentPush.bankAccountCci              = this.formDatosCuentasBancarias.controls.bank_account_cci.value;
      currentPush.bankAccountType              = this.formDatosCuentasBancarias.controls.bank_account_type.value;

      if( this.listInvInvReqBankAccount != null){

        for(let current of this.listInvInvReqBankAccount){
          if( current.isDefaultPaymentAccount == this.yes && 
              currentPush.isDefaultPaymentAccount == this.yes && 
              current.currency == currentPush.currency){
            this.alertar("Existe una cuenta de pago con el tipo de moneda seleccionado. Verifique por favor");
            return;
          }
          
          if( current.bankAccountNumber == currentPush.bankAccountNumber && 
              current.bankAccountCci != null &&  currentPush.bankAccountCci != null &&
              current.bankAccountCci != ""   &&  currentPush.bankAccountCci != "" &&
              current.bankAccountCci == currentPush.bankAccountCci
              ){
            this.alertar("Existe una cuenta con el mismo número de cuenta bancaria y CCI. Verifique por favor");
            return;
          }

          if( current.bankAccountNumber == currentPush.bankAccountNumber){
            this.alertar("Existe una cuenta con el mismo número de cuenta bancaria. Verifique por favor");
            return;
          }
          if( current.bankAccountCci != null &&  currentPush.bankAccountCci != null &&
              current.bankAccountCci != ""   &&  currentPush.bankAccountCci != "" &&
              current.bankAccountCci == currentPush.bankAccountCci){
            this.alertar("Existe una cuenta con el mismo CCI. Verifique por favor");
            return;
          }
        }

      }else{
        this.listInvInvReqBankAccount = [] as Array<InvReqBankAccountReturn>;
      }
      this.listInvInvReqBankAccount.push(currentPush);

      this.formDatosCuentasBancarias.controls.bank_account_number.reset(); 
      this.formDatosCuentasBancarias.controls.currency.reset(); 
      this.formDatosCuentasBancarias.controls.bank_account_type.reset(); 
      this.formDatosCuentasBancarias.controls.id_bank_fk.reset();
      this.formDatosCuentasBancarias.controls.bank_account_cci.reset();
      this.formDatosCuentasBancarias.controls.is_default_payment_account.reset();
      this.submitAddList_datosCuentasBancarias = false;
      
    }

  }

  /*
      public constants
  */

  getBankDesc(parameter_table_pk:number){
    for( let parameterTableDesc of this.currentData.paramBank ){
      if( parameterTableDesc.parameterTablePk == parameter_table_pk ){
        return parameterTableDesc.description;
      }
    }
    return '';
  }

  getBankAccountTypeDesc(parameter_table_pk:number){
    for( let parameterTableDesc of this.currentData.paramBankAccountType ){
      if( parameterTableDesc.parameter_table_pk == parameter_table_pk ){
        return parameterTableDesc.description;
      }
    }
    return '';
  }

  getCurrencyDesc(parameter_table_pk:number){
    for( let parameterTableDesc of this.currentData.paramCurrency ){
      if( parameterTableDesc.parameter_table_pk == parameter_table_pk ){
        return parameterTableDesc.indicator1;
      }
    }
    return '';
  }

  removeInvReqBankAccount_datosCuentasBancarias(object:InvReqBankAccountReturn){
    var idx = this.listInvInvReqBankAccount.indexOf(object);
    if (idx !== -1) {
      this.listInvInvReqBankAccount.splice(idx,1);
    }
  }

  get yes() { return PARAMETER_TABLE_YES_NO.YES.ID; }
  get no()  { return PARAMETER_TABLE_YES_NO.NO.ID; }
  get localCountry(){ return countryMaping.PERU;}

  /*
      generic functions
  */

  existInList(lista:Array<number>, id:number){
    for(var current of lista){
      if(current == id){
        return true;
      }
    }
    return false;
  }

  onKeyUpDNI(event: any, object:FormControl){
    var documentNumner = event.target.value;
    if(documentNumner.length != 8){
      object.setValue("");
      this.alertar("El DNI debe tener 8 caracteres.");//El DNI debe tener 8 dígitos
    }
  }

  onKeyUpOnliDecimal(event: any, object:FormControl){
    object.setValue(func.inputOnliDecimal(event.target.value));
  }

  onKeyOutOnlyNumericDecimal(event: any,object:FormControl){
    var valor:number = Number(event.target.value);
    var valorFormateado = func.formatNumber(valor);
    if( valorFormateado == "NaN" || valorFormateado == null){
      object.setValue("0.00");
    }else{
      object.setValue(valorFormateado);
    }
  }

  onKeyUpEmail(event: any, object:FormControl){
    object.setValue(func.inputEmail(event.target.value));
  }

  onKeyUpOnliNumeric(event: any, object:FormControl){
    object.setValue(func.inputOnliNumeric(event.target.value));
  }

  onKeyUpOnliText(event: any, object:FormControl){
    object.setValue(func.inputOnliText(event.target.value));
  }

  onKeyUpAplhaNumeric(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumeric(event.target.value));
  }

  onKeyUpAplhaNumericSpace(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumericSpace(event.target.value));
  }

  onKeyUpAplhaNumericSimple(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumericSimple(event.target.value));
  }

  onKeyUpAplhaNumericSpecial(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumericSpecial(event.target.value));
  }

  onKeyUpOnliTextAndSpace(event: any, object:FormControl){
    object.setValue(func.inputOnliTextAndSpace(event.target.value));
  }

  setMaxDateBirth() { 
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = (today.getFullYear()-18);
    
    this.currentData.maxDateBirth = yyyy + '-' + mm + '-' + dd ;
  }

  setMinDateBirth() { 
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = (today.getFullYear()-110);
    
    this.currentData.minDateBirth = yyyy + '-' + mm + '-' + dd ;
  }

  setMaxDateToday() { 
    var today = new Date();
    var dd = String(today.getDate()-1).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = (today.getFullYear());
    
    this.currentData.maxDateToday = yyyy + '-' + mm + '-' + dd ;
  }

}
