import { Component, OnInit, Input, ɵConsole, ViewChild } from '@angular/core'; 
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http'; 
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from 'src/app/configuration/providers/modal';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { userTo } from 'src/app/model/UserModel/userTo';
import { URL } from 'src/app/constants/url';
import { func } from 'src/app/constants/func';
import { certificadoRetencionTo } from 'src/app/model/UserModel/certificadoRetencionTo';
import { responseReporteCertifRetencionTo } from 'src/app/model/UserModel/responseReporteCertifRetencionTo';
import { PurchaseSaleOrderTo } from 'src/app/model/UserModel/PurchaseSaleOrderTo';
import { parameterTableTo } from 'src/app/model/UserModel/parameterTableTo';
import { ParameterService } from 'src/app/services/ParameterServices/parameter.service';
import { MASTER_TABLE } from 'src/app/constants/masterTable';
import { Router } from '@angular/router';
import { PurchaseSaleOrderReponse } from 'src/app/model/UserModel/PurchaseSaleOrderReponse';
import { SimplemodalComponent } from '../../AppComponents/simplemodal/simplemodal.component';


@Component({
  selector: 'ark-report-ordenes',
  templateUrl: './report-ordenes.component.html',
  styleUrls: ['./report-ordenes.component.css']
})
export class ReportOrdenesComponent implements OnInit {
 
  @ViewChild(SimplemodalComponent,{static: false}) simplemodalComponent: SimplemodalComponent;
  listCertificadoRetencion : Array<certificadoRetencionTo> = [] as Array<certificadoRetencionTo>; 
  listPurchaseSaleOrderTo : Array<PurchaseSaleOrderReponse> = [] as Array<PurchaseSaleOrderReponse>; 
  objUserTo = null; 
  paramPurchaseSales:Array<parameterTableTo> = [] as Array<parameterTableTo>; 
  paramState:Array<parameterTableTo> = [] as Array<parameterTableTo>; 

  constructor(private modalService: ModalService, 
              private httpClient: HttpClient,
              private loginService: LoginService, 
              private router: Router,
              private parameterService: ParameterService,
              private  spinner: NgxSpinnerService) { }

  async ngOnInit() { 
     this.objUserTo = <userTo>this.loginService.currentUser();
     await this.initParameterTales();
     await this.loadDocumentos();  

  } 
  
  gotoPage(page: String){
    this.router.navigate([page]); 
  }
  
  formatNumber(input){
    if(input != null && input != "" && input !=0 && input !="undefined"){
      return func.formatNumber(input);
    }
    return input;
  }

  async initParameterTales(){

    this.paramState  =  this.parameterService.getParameterMockUp(MASTER_TABLE.PARAM_ORDER_STATE) as Array<parameterTableTo>;
    this.paramPurchaseSales  = await this.parameterService.getParameter(MASTER_TABLE.CONFIGURACION_ORDEN_PORTAL) as Array<parameterTableTo>;
    console.log("this.paramState::"+ JSON.stringify(this.paramState));
  }

  getModality(modalidad){
    for(var obj of this.paramPurchaseSales){
      if(obj.short_integer == modalidad && obj.description=='ID_NEGOTIATION_MODALITY_FK'){
        return obj.text1;
      }
    }
  }
  
  stateDescription(data:number){
    for(var obj of this.paramState){
      if(obj.parameter_table_pk == data){
        return obj.description;
      }
    }
  }

  async loadDocumentos(){

    var currentShoppinCart:PurchaseSaleOrderTo ;
    currentShoppinCart = {} as PurchaseSaleOrderTo; 
    currentShoppinCart.rut_code=this.objUserTo.rut;


    let datosGuardar = currentShoppinCart;
    

    this.spinner.show();
    this.httpClient.post(URL.SERVICE_GET_PURCHASE_SALE_ORDER,
             datosGuardar, { headers: func.WSHeaderTokenCore() })
            . subscribe(
          (response) => {  
            
              this.listPurchaseSaleOrderTo = response as Array<PurchaseSaleOrderReponse>;
              console.log(JSON.stringify(this.listPurchaseSaleOrderTo));
              this.spinner.hide();   
          },
          (err: HttpErrorResponse) => {
            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);

              this.spinner.hide();
      },
      () => {
        this.spinner.hide();
      }
    );

  }

  /*
  loadDocumentos(){  

    this.spinner.show(); 
    this.httpClient.post(URL.SERVICE_API_V1_USER + this.objUserTo.id_user_pk + 
                        URL.PATH_CERTIFICATE_END , body,
            {  headers: func.WSHeader() })
        . subscribe(
              (response) => {  
                  console.log(" response :: " + JSON.stringify(response) ); 
                  
                  let responseBd = <responseReporteCertifRetencionTo>response;  
                  this.listCertificadoRetencion = responseBd.items;                         
              },
              (err: HttpErrorResponse) => {
                console.log(err.error);
                console.log(err.name);
                console.log(err.message);
                console.log(err.status);
                
                this.spinner.hide();  
            },
            () => {
              this.spinner.hide();
              console.log("************* this.httpClient.post ()::" + "The get certificado retencion now completed.");
            }
      ); 
  }
  */

  formatDate(date){
    return func.formatDateCore(date);
  }


  public closeModal(id: string) {
    this.modalService.close(id);
  }

  openTerminos(){
    this.simplemodalComponent.openModalInfo();
    //this.modalService.open('idTerminos');  
  }
/*
  closeTerminos(file_code: String){ 
    this.modalService.close('idTerminos');
  }
*/
}
