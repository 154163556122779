import { Component, OnInit, Input, ViewChild } from '@angular/core'; 
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from 'src/app/configuration/providers/modal';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { userTo } from 'src/app/model/UserModel/userTo';
import { polizaTo } from 'src/app/model/UserModel/polizaTo';
import { URL } from 'src/app/constants/url'; 
import { func } from 'src/app/constants/func';
import { HttpErrorResponse } from '@angular/common/http'; 
import { responseReportePolizaTo } from 'src/app/model/UserModel/responseReportePolizaTo';
import { polizaTypeMaping } from 'src/app/constants/polizaTypeMaping';
import { Router } from '@angular/router';
import { SimplemodalComponent } from '../../AppComponents/simplemodal/simplemodal.component';



@Component({
  selector: 'ark-report-poliza',
  templateUrl: './report-poliza.component.html',
  styleUrls: ['./report-poliza.component.css']
})
export class ReportPolizaComponent implements OnInit { 
  
  @ViewChild(SimplemodalComponent,{static: false}) simplemodalComponent: SimplemodalComponent;
  listpolizaTo : Array<polizaTo> = [] as Array<polizaTo>; 
  objUserTo = null; 
  hasPoliza = false;

  constructor(private modalService: ModalService, 
              private httpClient: HttpClient,
              private loginService: LoginService,
              private router: Router,
              private  spinner: NgxSpinnerService) { }

  ngOnInit() { 
     this.objUserTo = <userTo>this.loginService.currentUser();
     this.loadDocumentos();  

  } 

  gotoPage(page: String){
    this.router.navigate([page]); 
  }

  loadDocumentos(){  

    this.spinner.show(); 
/**
    var currentPolizaTo:polizaTo = {} as polizaTo;
    currentPolizaTo.file_code = 'Colocacion Primaria';
    currentPolizaTo.policy_number = '00000';
    currentPolizaTo.operation_date = '26/01/2020';
    currentPolizaTo.settlement_date = '26/02/2020';
    currentPolizaTo.policy_type = 5;
    currentPolizaTo.rut = 6;
    currentPolizaTo.isin_code = 'abc44757';
    var currentListPolizaTo:Array<polizaTo> = [] as Array<polizaTo>; 
    currentListPolizaTo.push(currentPolizaTo);
    this.listpolizaTo = currentListPolizaTo; 
    this.spinner.hide(); 
*/

    this.hasPoliza = false;
    this.httpClient.get(URL.SERVICE_API_V1_USER + this.objUserTo.id_user_pk + 
                        URL.POLICY_END ,
            {  headers: func.WSHeader() })
        . subscribe(
              (response) => {  
                  console.log(" response :: " + JSON.stringify(response) ); 

                  this.hasPoliza = false;
                  let listPolizasTmp  : Array<polizaTo> = [] as Array<polizaTo>;                  
                  let responseBd = <responseReportePolizaTo>response;  
                  //this.listpolizaTo = responseBd.items;
                
                  if(responseBd.items.length >0){
                    for(var i=0 ; i < responseBd.items.length ; i++){
                      let detalle : polizaTo;
                      detalle = {} as polizaTo ;
                      
                      detalle = responseBd.items[i];
                      detalle.parImpar         = this.parImpar(responseBd.items[i].order);
                      detalle.operation_date   = func.formatFechaDBtoUi(responseBd.items[i].operation_date); 
                      detalle.settlement_date  = func.formatFechaDBtoUi(responseBd.items[i].settlement_date); 

                      detalle.policy_type_str = polizaTypeMaping.getPolizaTypeDescription(responseBd.items[i].order);

                      listPolizasTmp.push(detalle);
                    }
                    this.listpolizaTo = listPolizasTmp;          
                    this.hasPoliza = true;
                  }
                  
              },
              (err: HttpErrorResponse) => {
                console.log(err.error);
                console.log(err.name);
                console.log(err.message);
                console.log(err.status);
                
                this.spinner.hide();  
            },
            () => {
              this.spinner.hide();
              console.log("************* this.httpClient.post ()::" + "The get poliza now completed.");
            }
      ); 
   
  }
 
  parImpar(numero) {
    if(numero % 2 == 0) {
      return "par";
    }
    else {
      return "impar";
    }
  } 
 

  /***********/
  public closeModal(id: string) {
    this.modalService.close(id);
  }

  
  openTerminos(){
    //this.modalService.open('idTerminos');  
    this.simplemodalComponent.openModalInfo();
  }
  /*
  closeTerminos(file_code: String){ 
    this.modalService.close('idTerminos');
  }
  */

}
