import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ProductoFinancieroTo } from 'src/app/model/UserModel/ProductoFinancieroTo';
import { func } from 'src/app/constants/func';
import { produtOriginMaping } from 'src/app/constants/produtOriginMaping';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'ark-productofinanciero',
  templateUrl: './productofinanciero.component.html',
  styleUrls: ['./productofinanciero.component.css']
})
export class ProductofinancieroComponent implements OnInit {

  @Input() entity : ProductoFinancieroTo = {} as ProductoFinancieroTo;  //parametros
  @Input() flagDetails : number;  //parametros

  constructor(private router: Router, private domSanitizer: DomSanitizer) { }

  ngOnInit() {
    if(this.flagDetails == null){
      this.flagDetails = 1;
    }
  }

  formatFecha(date:String){
    return func.formatFechaDBtoUi(date); 
  }

  formatNumber(number){
    return func.formatNumber(number);
  }
 
  gotoProductoDetalleInversion(product: ProductoFinancieroTo){
    sessionStorage.setItem('menuOrigin', JSON.stringify(produtOriginMaping.INVERSIONES));
    sessionStorage.setItem('product',JSON.stringify(product));
    this.router.navigate(["/productInformacion"]); // /detalleInversion
  }
 
  gotoProductoCalculadora(product: ProductoFinancieroTo){
    sessionStorage.setItem('menuOrigin', JSON.stringify(produtOriginMaping.INVERSIONES));
    sessionStorage.setItem('product',JSON.stringify(product));
    this.router.navigate(["/comprar"]);
  }

  safeImagen(imagenBase64){ 
    return this.domSanitizer.bypassSecurityTrustUrl(imagenBase64);
  }

  getClaseInteres(cashNominal: number){
    return func.getClaseInteres(cashNominal); 
  }
  
}
