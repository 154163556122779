import { Component, OnInit, Input, ViewChild } from '@angular/core'; 
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http'; 
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from 'src/app/configuration/providers/modal';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { userTo } from 'src/app/model/UserModel/userTo';
import { URL } from 'src/app/constants/url';
import { func } from 'src/app/constants/func';
import { certificadoRetencionTo } from 'src/app/model/UserModel/certificadoRetencionTo';
import { responseReporteCertifRetencionTo } from 'src/app/model/UserModel/responseReporteCertifRetencionTo';
import { Router } from '@angular/router';
import { SimplemodalComponent } from '../../AppComponents/simplemodal/simplemodal.component';
 

@Component({
  selector: 'ark-report-certificado-retencion',
  templateUrl: './report-certificado-retencion.component.html',
  styleUrls: ['./report-certificado-retencion.component.css']
})
export class ReportCertificadoRetencionComponent implements OnInit {
  @ViewChild(SimplemodalComponent,{static: false}) simplemodalComponent: SimplemodalComponent;
  listCertificadoRetencion : Array<certificadoRetencionTo> = [] as Array<certificadoRetencionTo>; 
  objUserTo = null; 

  constructor(private modalService: ModalService, 
              private httpClient: HttpClient,
              private loginService: LoginService,
              private router: Router,
              private  spinner: NgxSpinnerService) { }

  ngOnInit() { 
     this.objUserTo = <userTo>this.loginService.currentUser();
     this.loadDocumentos();  

  } 

  gotoPage(page: String){
    this.router.navigate([page]); 
  }
  
  loadDocumentos(){  

    this.spinner.show(); 
    this.httpClient.get(URL.SERVICE_API_V1_USER + this.objUserTo.id_user_pk + 
                        URL.PATH_CERTIFICATE_END ,
            {  headers: func.WSHeader() })
        . subscribe(
              (response) => {  
                  console.log(" response :: " + JSON.stringify(response) ); 
                  
                  let responseBd = <responseReporteCertifRetencionTo>response;  
                  this.listCertificadoRetencion = responseBd.items;                         
              },
              (err: HttpErrorResponse) => {
                console.log(err.error);
                console.log(err.name);
                console.log(err.message);
                console.log(err.status);
                
                this.spinner.hide();  
            },
            () => {
              this.spinner.hide();
              console.log("************* this.httpClient.post ()::" + "The get certificado retencion now completed.");
            }
      ); 
  }


  openModalCertificado(){
    this.modalService.open('idModalCertificado'); 
  }

  closeModalCertificado(file_code: String){ 
    this.modalService.close('idModalCertificado');
  }
  
  openTerminos(){
    //this.modalService.open('idTerminos');  
    this.simplemodalComponent.openModalInfo();
  }
/*
  closeTerminos(file_code: String){ 
    this.modalService.close('idTerminos');
  }
*/
}
