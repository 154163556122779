import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ark-suscripcion-sucess',
  templateUrl: './suscripcion-sucess.component.html',
  styleUrls: ['./suscripcion-sucess.component.css']
})
export class SuscripcionSucessComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  gotoPage(page: String){
    this.router.navigate([page]); 
  }
}
