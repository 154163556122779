import { Component, OnInit, Input, Output,EventEmitter, ViewChild } from '@angular/core';
import { step1 } from 'src/app/model/UserModel/step1';
import { Validators, FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { func } from 'src/app/constants/func';
import { PARAMETER_TABLE_YES_NO } from 'src/app/constants/PARAMETER_TABLE_YES_NO';
import { countryMaping } from 'src/app/constants/countryMaping';
import { NgxSpinnerService } from 'ngx-spinner';
import { ParameterService } from 'src/app/services/ParameterServices/parameter.service';
import { parameterTableTo } from 'src/app/model/UserModel/parameterTableTo';
import { InvReqPersonReturn } from 'src/app/model/UserModel/InvReqPersonReturn';
import { InvestorRequestFatcaToReturn } from 'src/app/model/UserModel/InvestorRequestFatcaToReturn';

@Component({
  selector: 'ark-datospersonales',
  templateUrl: './datospersonales.component.html',
  styleUrls: ['./datospersonales.component.css']
})
export class DatospersonalesComponent implements OnInit {

  @Input()  inputData:step1       = {} as step1;
  @Output() outputDataEvent       = new EventEmitter<step1>();
  @Output() alertEvent            = new EventEmitter<String>();
  @Output() modalEvent            = new EventEmitter<String>();

  currentData               : step1     = {} as step1;
  formDatosPersonales       : FormGroup = null; 
  submittedDatospersonales  : boolean   = false;

  validationFatca           :boolean    = true;  
  arrayCountryFatca                     = [] as Array<number>;
  fatca_nationality_country             = new FormArray([]);
  fatca_document_type                   = new FormArray([]);
  fatca_document_number                 = new FormArray([]);
  fatcaTable:Array<InvestorRequestFatcaToReturn> = [] as Array<InvestorRequestFatcaToReturn>;

  submittedDatosConyuge     : boolean    = false;
  formDatosConyuge          : FormGroup; //formulario

  constructor(private formBuilder: FormBuilder, private  spinner: NgxSpinnerService,private parameterService: ParameterService) { }

  ngOnInit() 
  {
    this.initVariables();
    this.loadParameters();
    this.setDefaultData();
    this.initFormDatosPersonales();
    this.initFormDatosConyuge();
    this.initActionsPostForm();
  }

  initVariables()
  {
    
    this.currentData = this.inputData;
    this.setMaxDateBirth();
    this.setMinDateBirth();
    if(this.currentData.entity_InvReqPerson == null){ this.currentData.entity_InvReqPerson = {} as InvReqPersonReturn; }
    this.submittedDatospersonales = false;
  }
  
  loadParameters()
  {
    this.initArrayCountryFatca();
  }

  setDefaultData()
  {

  }

  nullObject(object){
    return (object == null)?'':object;
  }

  initFormDatosPersonales()
  {
    this.formDatosPersonales = this.formBuilder.group({
      name:                           [this.nullObject(this.currentData.entity_InvReqPerson.name)                  , Validators.required],
      first_lastname:                 [this.nullObject(this.currentData.entity_InvReqPerson.firstLastname)         , Validators.required],
      second_lastname:                [this.nullObject(this.currentData.entity_InvReqPerson.secondLastname)        , Validators.required],
      document_type:                  [this.nullObject(this.currentData.entity_InvReqPerson.documentType)          , Validators.required],
      document_number:                [this.nullObject(this.currentData.entity_InvReqPerson.documentNumber)        , Validators.required],
      birthdate:                      [this.nullObject(this.currentData.entity_InvReqPerson.birthdate)             , Validators.required],
      birthplace:                     [this.nullObject(this.currentData.entity_InvReqPerson.birthplace)            , Validators.required],
      birth_country:                  [this.nullObject(this.currentData.entity_InvReqPerson.birthCountry)          , Validators.required],
      nationality_country1:           [this.nullObject(this.currentData.entity_InvReqPerson.nationalityCountry1)   , Validators.required],
      nationality_country2:           [this.nullObject(this.currentData.entity_InvReqPerson.nationalityCountry2)                        ],
      nationality_country3:           [this.nullObject(this.currentData.entity_InvReqPerson.nationalityCountry3)                        ],
      is_resident:                    [this.nullObject(this.currentData.entity_InvReqPerson.isResident)            , Validators.required],	
      legal_address:                  [this.nullObject(this.currentData.entity_InvReqPerson.legalAddress)          , Validators.required],	
      residence_country:              [this.nullObject(this.currentData.entity_InvReqPerson.residenceCountry)      , Validators.required],	
      residence_department:           [this.nullObject(this.currentData.entity_InvReqPerson.residenceDepartment)   , Validators.required],	
      residence_province:             [this.nullObject(this.currentData.entity_InvReqPerson.residenceProvince)     , Validators.required],	
      residence_district:             [this.nullObject(this.currentData.entity_InvReqPerson.residenceDistrict)     , Validators.required],	
      residence_state_province:       [this.nullObject(this.currentData.entity_InvReqPerson.residenceStateProvince), Validators.required],	
      residence_city:                 [this.nullObject(this.currentData.entity_InvReqPerson.residenceCity)         , Validators.required],	
      home_phone_number:              [this.nullObject(this.currentData.entity_InvReqPerson.homePhoneNumber)                            ],	
      mobile_number:                  [this.nullObject(this.currentData.entity_InvReqPerson.mobileNumber)          , Validators.required],	  
      email:                          [this.nullObject(this.currentData.entity_InvReqPerson.email)                 , Validators.required],	
      investor_linked_group:          [this.nullObject(this.currentData.entity_InvReqPerson.investorLinkedGroup)   , Validators.required],	
      grp_relationship_company:       [this.nullObject(this.currentData.entity_InvReqPerson.grpRelationshipCompany)                     ],	
      grp_relationship_position:      [this.nullObject(this.currentData.entity_InvReqPerson.grpRelationshipPosition)                    ],	
      marital_status:                 [this.nullObject(this.currentData.entity_InvReqPerson.maritalStatus)         , Validators.required]
    });


  }

  initActionsPostForm(){
    //this.isResidentload_datospersonales();
    this.isResiden_datospersonales();
    /**
     * 
      this.loadParamDepartment(this.formDatosPersonales.value.residence_country);

     */
    if(this.currentData.entity_InvReqPerson.residenceDepartment != null){
      this.formDatosPersonales.controls.residence_department.setValue(this.currentData.entity_InvReqPerson.residenceDepartment);
      this.loadParamProvince(this.currentData.entity_InvReqPerson.residenceDepartment);
    }
    
    if(this.currentData.entity_InvReqPerson.residenceProvince != null){
      this.formDatosPersonales.controls.residence_province.setValue(this.currentData.entity_InvReqPerson.residenceProvince);
      this.loadParamDistrict(this.currentData.entity_InvReqPerson.residenceProvince);
    }
    
    if(this.currentData.entity_InvReqPerson.residenceDistrict != null){
      this.formDatosPersonales.controls.residence_district.setValue(this.currentData.entity_InvReqPerson.residenceDistrict);
    }

    this.revertFatcaTableToForm();

    
    if(this.currentData.action == null || this.currentData.action == 'R'){
      
    }else{//M
      if(!this.currentData.enabledForm){
        this.disabled_inputs_registrar_ficha();
      }
    }
  }

  disabled_inputs_registrar_ficha(){
    eval('$(function(){$("#newInvestorDiv :input").prop("disabled", true); });')
    eval('$(function(){$(".navigateButton").prop("disabled", false); });')
  }

  enabled_inputs_registrar_ficha(){
    this.currentData.enabledForm = true;
    eval('$(function(){$("#newInvestorDiv :input").prop("disabled", false); });');
    eval('$(function(){$(".inputNotEdit").prop("disabled", true); });');
  }

  
  get getControlFormDatosPersonales() { return this.formDatosPersonales.controls; }
  
  saveSetp1()
  {
    this.submittedDatospersonales   = true;
    this.submittedDatosConyuge      = true;

    if( this.isSucessDatosPersonales() && this.isSucessDatosFatca() && this.isSucessDatosConyuge() ){
      if( this.isSucessDatosPersonales() )
      {
        this.setDataDatosPersonales();
      }
      if( this.isSucessDatosFatca() )
      {
        this.setDataDatosFatca();
      }
      if( this.isSucessDatosConyuge() )
      {
        this.setDataDatosConyuge();
      }
      
      this.inputData = this.currentData; 
      this.outputDataEvent.emit(this.inputData);  
    }else{
      this.alertar("Completar los datos obligatorios. Verifique por favor.");
      return;
    }
  }

  alertar(mensaje:String)
  {
    this.alertEvent.emit(mensaje);
    return;
  }

  openModal(mensaje:String)
  {
    this.modalEvent.emit(mensaje);
    return;
  }

   /*
      form functions
  */
 async loadParamDepartmentWithoutCountry(parameterTablePk:number)
 {
   this.spinner.show();
   this.formDatosPersonales.controls.residence_department.setValue('');
   this.formDatosPersonales.controls.residence_province.setValue('');
   this.formDatosPersonales.controls.residence_district.setValue('');
   this.currentData.paramDepartment = await this.parameterService.getGeograficDepartment(parameterTablePk) as Array<parameterTableTo>; 
   this.toogle_residencountry_validation();
   this.spinner.hide();
 }

  async loadParamDepartment(parameterTablePk:number)
  {
    this.spinner.show();
    this.formDatosPersonales.controls.residence_department.setValue('');
    this.formDatosPersonales.controls.residence_province.setValue('');
    this.formDatosPersonales.controls.residence_district.setValue('');
    this.currentData.paramDepartment = await this.parameterService.getGeograficDepartment(parameterTablePk) as Array<parameterTableTo>; 
    this.toogle_residencountry_validation();
    this.spinner.hide();
    this.addCountryNoRepeat();
  }

  async loadParamProvince(parameterTablePk:number)
  {
    this.spinner.show();
    this.formDatosPersonales.controls.residence_province.setValue('');
    this.formDatosPersonales.controls.residence_district.setValue('');
    this.currentData.paramProvince =  await this.parameterService.getGeograficProvince(parameterTablePk) as Array<parameterTableTo>; 
    this.spinner.hide();
  }

  async loadParamDistrict(parameterTablePk:number)
  {
    this.spinner.show();
    this.formDatosPersonales.controls.residence_district.setValue('');
    this.currentData.paramDistrict =  await this.parameterService.getGeograficDistrict(parameterTablePk) as Array<parameterTableTo>;
    this.spinner.hide();
  }

  toogel_investor_linked_group(){
    this.formDatosPersonales.controls.grp_relationship_company.setValue('');
    this.formDatosPersonales.controls.grp_relationship_position.setValue('');
    if(this.formDatosPersonales.controls.investor_linked_group.value==this.yes){
      this.formDatosPersonales.controls['grp_relationship_company'].setValidators([Validators.required]);
      this.formDatosPersonales.controls['grp_relationship_position'].setValidators([Validators.required]);
      this.formDatosPersonales.controls["grp_relationship_company"].updateValueAndValidity();      
      this.formDatosPersonales.controls["grp_relationship_position"].updateValueAndValidity();      
    }else{
      this.formDatosPersonales.controls['grp_relationship_company'].clearValidators();
      this.formDatosPersonales.controls['grp_relationship_position'].clearValidators();
      this.formDatosPersonales.controls["grp_relationship_company"].updateValueAndValidity();      
      this.formDatosPersonales.controls["grp_relationship_position"].updateValueAndValidity();   
    }
  }

  toogle_residencountry_validation()
  {
    if(this.formDatosPersonales.controls.residence_country.value==null || this.formDatosPersonales.controls.residence_country.value==this.localCountry){
      //enabled
      this.formDatosPersonales.controls['residence_department'].setValidators([Validators.required]);
      this.formDatosPersonales.controls["residence_department"].updateValueAndValidity();
      this.formDatosPersonales.controls['residence_province'].setValidators([Validators.required]);
      this.formDatosPersonales.controls["residence_province"].updateValueAndValidity();
      this.formDatosPersonales.controls['residence_district'].setValidators([Validators.required]); 
      this.formDatosPersonales.controls["residence_district"].updateValueAndValidity();             
      //disabled
      this.formDatosPersonales.controls["residence_state_province"].clearValidators();
      this.formDatosPersonales.controls["residence_state_province"].updateValueAndValidity();
      this.formDatosPersonales.controls["residence_city"].clearValidators();
      this.formDatosPersonales.controls["residence_city"].updateValueAndValidity();
    }else{
      //enabled
      this.formDatosPersonales.controls['residence_department'].clearValidators();
      this.formDatosPersonales.controls["residence_department"].updateValueAndValidity();
      this.formDatosPersonales.controls['residence_province'].clearValidators();
      this.formDatosPersonales.controls["residence_province"].updateValueAndValidity();
      this.formDatosPersonales.controls['residence_district'].clearValidators();   
      this.formDatosPersonales.controls["residence_district"].updateValueAndValidity();           
      //disabled
      this.formDatosPersonales.controls['residence_state_province'].setValidators([Validators.required]);
      this.formDatosPersonales.controls["residence_state_province"].updateValueAndValidity();             
      this.formDatosPersonales.controls['residence_city'].setValidators([Validators.required]);  
      this.formDatosPersonales.controls["residence_city"].updateValueAndValidity();
    }
  }

  changeMartialStatus()
  {
    this.submittedDatosConyuge      = false;
    this.resetConyuge();
    if(this.formDatosPersonales.controls.marital_status.value == 171){
      this.formDatosConyuge.controls.birth_country.setValue(this.localCountry);
      this.formDatosConyuge.controls.nationality_country1.setValue(this.localCountry);
      this.formDatosConyuge.controls.marital_regime.setValue(296);//sociedad gananciales
      this.formDatosConyuge.controls.document_type.setValue(1);
    }
  }

  selectedCountry_datospersonales()
  {
    this.formDatosPersonales.patchValue({nationality_country1: this.formDatosPersonales.value.birth_country});
    this.addCountryNoRepeat();
  }

  selectResidentCountry(){
    if(this.formDatosPersonales.value.is_resident == this.no){
      if(this.formDatosPersonales.controls.residence_country.value == this.localCountry ){
        this.formDatosPersonales.patchValue({residence_country: ''});
      }
      this.addCountryNoRepeat();
    }
  }

  isResidentload_datospersonales(){
    if(this.formDatosPersonales.value.is_resident == this.yes){ // es obligatorio el departamento/provincia/distrito
      this.formDatosPersonales.patchValue({residence_country: this.localCountry});
      this.loadParamDepartmentWithoutCountry(this.formDatosPersonales.value.residence_country);
      
      this.formDatosPersonales.get('residence_department').setValidators([Validators.required]);
      this.formDatosPersonales.get('residence_department').updateValueAndValidity();
      this.formDatosPersonales.get('residence_province').setValidators([Validators.required]);
      this.formDatosPersonales.get('residence_province').updateValueAndValidity();
      this.formDatosPersonales.get('residence_district').setValidators([Validators.required]);
      this.formDatosPersonales.get('residence_district').updateValueAndValidity();

      this.formDatosPersonales.get('residence_state_province').clearValidators();
      this.formDatosPersonales.get('residence_state_province').updateValueAndValidity();
      this.formDatosPersonales.get('residence_city').clearValidators();
      this.formDatosPersonales.get('residence_city').updateValueAndValidity();
    }else{
      
      this.formDatosPersonales.patchValue({residence_country: 0});
      this.formDatosPersonales.get('residence_state_province').setValidators([Validators.required]);
      this.formDatosPersonales.get('residence_state_province').updateValueAndValidity();
      this.formDatosPersonales.get('residence_city').setValidators([Validators.required]);
      this.formDatosPersonales.get('residence_city').updateValueAndValidity();

      this.formDatosPersonales.get('residence_department').clearValidators();
      this.formDatosPersonales.get('residence_department').updateValueAndValidity();
      this.formDatosPersonales.get('residence_province').clearValidators();
      this.formDatosPersonales.get('residence_province').updateValueAndValidity();
      this.formDatosPersonales.get('residence_district').clearValidators();
      this.formDatosPersonales.get('residence_district').updateValueAndValidity();
    }
  }

  isResiden_datospersonales()
  {
    if(this.formDatosPersonales.value.is_resident == this.yes){ // es obligatorio el departamento/provincia/distrito
      this.formDatosPersonales.patchValue({residence_country: this.localCountry});
      //this.loadParamDepartment(this.formDatosPersonales.value.residence_country);
      this.loadParamDepartmentWithoutCountry(this.formDatosPersonales.value.residence_country);

      this.formDatosPersonales.get('residence_department').setValidators([Validators.required]);
      this.formDatosPersonales.get('residence_department').updateValueAndValidity();
      this.formDatosPersonales.get('residence_province').setValidators([Validators.required]);
      this.formDatosPersonales.get('residence_province').updateValueAndValidity();
      this.formDatosPersonales.get('residence_district').setValidators([Validators.required]);
      this.formDatosPersonales.get('residence_district').updateValueAndValidity();

      this.formDatosPersonales.get('residence_state_province').clearValidators();
      this.formDatosPersonales.get('residence_state_province').updateValueAndValidity();
      this.formDatosPersonales.get('residence_city').clearValidators();
      this.formDatosPersonales.get('residence_city').updateValueAndValidity();

    }else{  // es obligatorio el state province / city
      
      this.formDatosPersonales.patchValue({residence_country: ''});
      this.formDatosPersonales.get('residence_state_province').setValidators([Validators.required]);
      this.formDatosPersonales.get('residence_state_province').updateValueAndValidity();
      this.formDatosPersonales.get('residence_city').setValidators([Validators.required]);
      this.formDatosPersonales.get('residence_city').updateValueAndValidity();

      this.formDatosPersonales.get('residence_department').clearValidators();
      this.formDatosPersonales.get('residence_department').updateValueAndValidity();
      this.formDatosPersonales.get('residence_province').clearValidators();
      this.formDatosPersonales.get('residence_province').updateValueAndValidity();
      this.formDatosPersonales.get('residence_district').clearValidators();
      this.formDatosPersonales.get('residence_district').updateValueAndValidity();
        
    }

  }

  changeNationality(nationality:number){
    if(nationality == 1){
      if(this.formDatosPersonales.value.nationality_country1 == null || this.formDatosPersonales.value.nationality_country1 == "" ){
        this.formDatosPersonales.controls.nationality_country2.setValue('');
        this.formDatosPersonales.controls.nationality_country3.setValue('');
      }
      if(this.formDatosPersonales.value.nationality_country2 != null && this.formDatosPersonales.value.nationality_country2 != "" && (this.formDatosPersonales.value.nationality_country2 == this.formDatosPersonales.value.nationality_country1 ))
      {
         this.formDatosPersonales.controls.nationality_country2.setValue('');
      }
      if(this.formDatosPersonales.value.nationality_country3 != null && this.formDatosPersonales.value.nationality_country3 != "" && (this.formDatosPersonales.value.nationality_country3 == this.formDatosPersonales.value.nationality_country1 ))
      {
        this.formDatosPersonales.controls.nationality_country3.setValue('');
      }
    }else if(nationality == 2){
      if(this.formDatosPersonales.value.nationality_country2 == null || this.formDatosPersonales.value.nationality_country2 == "" ){
        //this.formDatosPersonales.controls.nationality_country1.setValue('');
        this.formDatosPersonales.controls.nationality_country3.setValue('');
      }
      if(this.formDatosPersonales.value.nationality_country1 != null && this.formDatosPersonales.value.nationality_country1 != "" && (this.formDatosPersonales.value.nationality_country1 == this.formDatosPersonales.value.nationality_country2 ))
      {
        this.formDatosPersonales.controls.nationality_country2.setValue('');
      }
      if(this.formDatosPersonales.value.nationality_country3 != null && this.formDatosPersonales.value.nationality_country3 != "" && (this.formDatosPersonales.value.nationality_country3 == this.formDatosPersonales.value.nationality_country2 ))
      {
        this.formDatosPersonales.controls.nationality_country3.setValue('');
      }
    } else if(nationality == 3){
      if(this.formDatosPersonales.value.nationality_country3 == null || this.formDatosPersonales.value.nationality_country3 == "" ){
        //this.formDatosPersonales.controls.nationality_country2.setValue('');
        //this.formDatosPersonales.controls.nationality_country1.setValue('');
      }
      if(this.formDatosPersonales.value.nationality_country1 != null && this.formDatosPersonales.value.nationality_country1 != "" && (this.formDatosPersonales.value.nationality_country1 == this.formDatosPersonales.value.nationality_country3 ))
      {
        this.formDatosPersonales.controls.nationality_country3.setValue('');
      }
      if(this.formDatosPersonales.value.nationality_country2 != null && this.formDatosPersonales.value.nationality_country2 != "" && (this.formDatosPersonales.value.nationality_country2 == this.formDatosPersonales.value.nationality_country3 ))
      {
        this.formDatosPersonales.controls.nationality_country3.setValue('');
      }
    }
    
    this.addCountryNoRepeat();
  }

  /*
  
  changeNationality(nationality:number){
    if(nationality == 1){
      if(this.formDatosPersonales.value.nationality_country1 == null || this.formDatosPersonales.value.nationality_country1 == "" ){
        this.formDatosPersonales.controls.nationality_country2.setValue('');
        this.formDatosPersonales.controls.nationality_country3.setValue('');
      }
      if(this.formDatosPersonales.value.nationality_country2 != null && this.formDatosPersonales.value.nationality_country2 != "" && (this.formDatosPersonales.value.nationality_country2 == this.formDatosPersonales.value.nationality_country1 ))
      {
         this.formDatosPersonales.controls.nationality_country1.setValue('');
      }
      if(this.formDatosPersonales.value.nationality_country3 != null && this.formDatosPersonales.value.nationality_country3 != "" && (this.formDatosPersonales.value.nationality_country3 == this.formDatosPersonales.value.nationality_country1 ))
      {
        this.formDatosPersonales.controls.nationality_country1.setValue('');
      }
    }else if(nationality == 2){
      if(this.formDatosPersonales.value.nationality_country2 == null || this.formDatosPersonales.value.nationality_country2 == "" ){
        this.formDatosPersonales.controls.nationality_country1.setValue('');
        this.formDatosPersonales.controls.nationality_country3.setValue('');
      }
      if(this.formDatosPersonales.value.nationality_country1 != null && this.formDatosPersonales.value.nationality_country1 != "" && (this.formDatosPersonales.value.nationality_country1 == this.formDatosPersonales.value.nationality_country2 ))
      {
        this.formDatosPersonales.controls.nationality_country2.setValue('');
      }
      if(this.formDatosPersonales.value.nationality_country3 != null && this.formDatosPersonales.value.nationality_country3 != "" && (this.formDatosPersonales.value.nationality_country3 == this.formDatosPersonales.value.nationality_country2 ))
      {
        this.formDatosPersonales.controls.nationality_country1.setValue('');
      }
    } else if(nationality == 3){
      if(this.formDatosPersonales.value.nationality_country3 == null || this.formDatosPersonales.value.nationality_country3 == "" ){
        this.formDatosPersonales.controls.nationality_country2.setValue('');
        this.formDatosPersonales.controls.nationality_country1.setValue('');
      }
      if(this.formDatosPersonales.value.nationality_country1 != null && this.formDatosPersonales.value.nationality_country1 != "" && (this.formDatosPersonales.value.nationality_country1 == this.formDatosPersonales.value.nationality_country3 ))
      {
        this.formDatosPersonales.controls.nationality_country3.setValue('');
      }
      if(this.formDatosPersonales.value.nationality_country2 != null && this.formDatosPersonales.value.nationality_country2 != "" && (this.formDatosPersonales.value.nationality_country2 == this.formDatosPersonales.value.nationality_country3 ))
      {
        this.formDatosPersonales.controls.nationality_country3.setValue('');
      }
    }
    
    this.addCountryNoRepeat();
  }
  */
  
  /*
      fatca
  */
  revertFatcaTableToForm(){

    this.fatcaTable = this.currentData.entity_InvReqPerson.investorRequestFatca;
    if(this.fatcaTable != null && this.fatcaTable.length > 0){
      this.fatca_nationality_country  = new FormArray([]);
      this.fatca_document_type        = new FormArray([]);
      this.fatca_document_number      = new FormArray([]);

      for(var i = 0; i < this.fatcaTable.length; i++){
        this.fatca_nationality_country.push(new FormControl(this.fatcaTable[i].nationalityCountry));
        this.fatca_document_type.push(new FormControl(this.fatcaTable[i].documentType,[Validators.required]));
        this.fatca_document_number.push(new FormControl(this.fatcaTable[i].documentNumber,[Validators.required]));
      }

      //console.log("revertFatcaTableToForm()::this.fatcaTable"+JSON.stringify(this.fatcaTable));
    }
  }

  isFatcaCountry(country){
    for(var fatca of this.arrayCountryFatca){  if(fatca == country){  return true;  }  }
    return false;
  }

  addCountryNoRepeat(){
      
    this.validationFatca  = true;
    var arrayCountry      = [] as Array<number>;
    var min1fatca         = false;
    var country1          = this.formDatosPersonales.value.residence_country;
    var country2          = this.formDatosPersonales.value.birth_country;
    var country3          = this.formDatosPersonales.value.nationality_country1;
    var country4          = this.formDatosPersonales.value.nationality_country2;
    var country5          = this.formDatosPersonales.value.nationality_country3;
    
    if(country1!=null && country1>0){
      if(!this.existInList(arrayCountry,country1)){  arrayCountry.push(country1);  }
      if(this.isFatcaCountry(country1)){  min1fatca = true; }
    }
    if(country2!=null && country2>0){
      if(!this.existInList(arrayCountry,country2)){  arrayCountry.push(country2);  }
      if(this.isFatcaCountry(country2)){  min1fatca = true; }
    }
    if(country3!=null && country3>0){
      if(!this.existInList(arrayCountry,country3)){  arrayCountry.push(country3);  }
      if(this.isFatcaCountry(country3)){  min1fatca = true; }
    }
    if(country4!=null && country4>0){
      if(arrayCountry.length==0){
        if(!this.existInList(arrayCountry,country4)){  arrayCountry.push(country4);  }
      }else{
        var agregar = true;
        for(var country of arrayCountry){  if(country == country4){  agregar = false;  break;  }  }
        if(agregar){
          if(!this.existInList(arrayCountry,country4)){  arrayCountry.push(country4);  }
          if(this.isFatcaCountry(country4)){  min1fatca = true; }
        }
      }
    }
    if(country5!=null && country5>0){
      if(arrayCountry.length==0){
        if(!this.existInList(arrayCountry,country5)){   arrayCountry.push(country5);  }
      }else{
        var agregar = true;
        for(var country of arrayCountry){   if(country == country5){  agregar = false;  break;  } }
        if(agregar){
          if(!this.existInList(arrayCountry,country5)){  arrayCountry.push(country5);  }
          if(this.isFatcaCountry(country5)){  min1fatca = true; }
        }
      }
    }
    
    this.fatcaTable                             = [] as Array<InvestorRequestFatcaToReturn>;
    var fatcaRow:InvestorRequestFatcaToReturn   = {} as InvestorRequestFatcaToReturn;
    var country_peru:number                     = this.localCountry;
    
    this.fatca_nationality_country              = new FormArray([]);
    this.fatca_document_type                    = new FormArray([]);
    this.fatca_document_number                  = new FormArray([]);

    if(min1fatca){
      if(arrayCountry != null){
        for(var i=0; i<arrayCountry.length; i++){
          fatcaRow = {} as InvestorRequestFatcaToReturn;
          fatcaRow.paramIndex = i;
          fatcaRow.nationalityCountry = arrayCountry[i];
          this.fatcaTable.push(fatcaRow);
        
          if(arrayCountry[i] == country_peru){
            this.fatca_nationality_country.push(new FormControl(arrayCountry[i]));
            this.fatca_document_type.push(new FormControl(1,[Validators.required]));
            if(this.formDatosPersonales.value.document_type == 1){
              this.fatca_document_number.push(new FormControl(this.nullObject(this.formDatosPersonales.value.document_number),[Validators.required]));
            }else{
              this.fatca_document_number.push(new FormControl('',[Validators.required]));
            }
          }else{
            this.fatca_nationality_country.push(new FormControl(arrayCountry[i]));
            this.fatca_document_type.push(new FormControl(3,[Validators.required]));
            this.fatca_document_number.push(new FormControl('',[Validators.required]));
          }
        }
      }

      //console.log("addCountryNoRepeat()::min1fatca::this.fatcaTable"+JSON.stringify(this.fatcaTable));
    }

  }

  changeCleanDocumentNumberFatca(object:FormControl){
    object.setValue('');
  }

  initArrayCountryFatca(){
    this.arrayCountryFatca.push(64);
    this.arrayCountryFatca.push(183);
    this.arrayCountryFatca.push(110);
    this.arrayCountryFatca.push(117);
    this.arrayCountryFatca.push(80);
    //this.arrayCountryFatca.push(196); //SAMOA
    this.arrayCountryFatca.push(197);// SAMOA AMERICANA
  }

  /*
      conyuge
  */
  
  initFormDatosConyuge(){
    if(this.currentData.entity_InvReqPerson_conyuge == null){
      this.currentData.entity_InvReqPerson_conyuge = {} as InvReqPersonReturn;
    }
    this.formDatosConyuge = this.formBuilder.group({
      name: 				              [this.nullObject(this.currentData.entity_InvReqPerson_conyuge.name)                  , Validators.required],
      first_lastname: 		        [this.nullObject(this.currentData.entity_InvReqPerson_conyuge.firstLastname)         , Validators.required], 
      second_lastname: 		        [this.nullObject(this.currentData.entity_InvReqPerson_conyuge.secondLastname)        , Validators.required], 
      document_type: 		          [this.nullObject(this.currentData.entity_InvReqPerson_conyuge.documentType)          , Validators.required], 
      document_number: 		        [this.nullObject(this.currentData.entity_InvReqPerson_conyuge.documentNumber)        , Validators.required], 
      birthdate: 			            [this.nullObject(this.currentData.entity_InvReqPerson_conyuge.birthdate)             , Validators.required],	
      birthplace: 			          [this.nullObject(this.currentData.entity_InvReqPerson_conyuge.birthplace)            , Validators.required], 
      birth_country: 		          [this.nullObject(this.currentData.entity_InvReqPerson_conyuge.birthCountry)          , Validators.required], 
      nationality_country1:	      [this.nullObject(this.currentData.entity_InvReqPerson_conyuge.nationalityCountry1)   , Validators.required], 
      nationality_country2:	      [this.nullObject(this.currentData.entity_InvReqPerson_conyuge.nationalityCountry2)                        ], 
      nationality_country3:	      [this.nullObject(this.currentData.entity_InvReqPerson_conyuge.nationalityCountry3)                        ],
      marital_regime:             [this.nullObject(this.currentData.entity_InvReqPerson_conyuge.maritalRegime)                              ]
    });

  }

  get getformDatosConyuge() { return this.formDatosConyuge.controls; }

  changeNationalityConyuge(nationality:number){
    if(nationality == 1){
      if(this.formDatosConyuge.value.nationality_country1 == null || this.formDatosConyuge.value.nationality_country1 == "" ){
        this.formDatosConyuge.controls.nationality_country2.setValue('');
        this.formDatosConyuge.controls.nationality_country3.setValue('');
      }
      if(this.formDatosConyuge.value.nationality_country2 != null && this.formDatosConyuge.value.nationality_country2 != "" && (this.formDatosConyuge.value.nationality_country2 == this.formDatosConyuge.value.nationality_country1 ) ){
        this.formDatosConyuge.controls.nationality_country2.setValue('');
      }
      if(this.formDatosConyuge.value.nationality_country3 != null && this.formDatosConyuge.value.nationality_country3 != "" && (this.formDatosConyuge.value.nationality_country3 == this.formDatosConyuge.value.nationality_country1 )){
        this.formDatosConyuge.controls.nationality_country3.setValue('');
      }
    }else if(nationality == 2){
      if(this.formDatosConyuge.value.nationality_country2 == null || this.formDatosConyuge.value.nationality_country2 == "" ){
        //this.formDatosConyuge.controls.nationality_country1.setValue('');
        this.formDatosConyuge.controls.nationality_country3.setValue('');
      }
      if(this.formDatosConyuge.value.nationality_country1 != null && this.formDatosConyuge.value.nationality_country1 != "" && (this.formDatosConyuge.value.nationality_country1 == this.formDatosConyuge.value.nationality_country2 )){
        this.formDatosConyuge.controls.nationality_country2.setValue('');
      }
      if(this.formDatosConyuge.value.nationality_country3 != null && this.formDatosConyuge.value.nationality_country3 != "" && (this.formDatosConyuge.value.nationality_country3 == this.formDatosConyuge.value.nationality_country2 )){
        this.formDatosConyuge.controls.nationality_country3.setValue('');
      }
    }else if(nationality == 3){
      if(this.formDatosConyuge.value.nationality_country3 == null || this.formDatosConyuge.value.nationality_country3 == "" ){
        //this.formDatosConyuge.controls.nationality_country1.setValue('');
        //this.formDatosConyuge.controls.nationality_country2.setValue('');
      }
      if(this.formDatosConyuge.value.nationality_country1 != null && this.formDatosConyuge.value.nationality_country1 != "" && (this.formDatosConyuge.value.nationality_country1 == this.formDatosConyuge.value.nationality_country3 )){
        this.formDatosConyuge.controls.nationality_country3.setValue('');
      }
      if(this.formDatosConyuge.value.nationality_country2 != null && this.formDatosConyuge.value.nationality_country2 != "" && (this.formDatosConyuge.value.nationality_country2 == this.formDatosConyuge.value.nationality_country3 )){
        this.formDatosConyuge.controls.nationality_country3.setValue('');
      }
    }
  }
/**
 
  changeNationalityConyuge(nationality:number){
    if(nationality == 1){
      if(this.formDatosConyuge.value.nationality_country1 == null || this.formDatosConyuge.value.nationality_country1 == "" ){
        this.formDatosConyuge.controls.nationality_country2.setValue('');
        this.formDatosConyuge.controls.nationality_country3.setValue('');
      }
      if(this.formDatosConyuge.value.nationality_country2 != null && this.formDatosConyuge.value.nationality_country2 != "" && (this.formDatosConyuge.value.nationality_country2 == this.formDatosConyuge.value.nationality_country1 ) ){
        this.formDatosConyuge.controls.nationality_country1.setValue('');
      }
      if(this.formDatosConyuge.value.nationality_country3 != null && this.formDatosConyuge.value.nationality_country3 != "" && (this.formDatosConyuge.value.nationality_country3 == this.formDatosConyuge.value.nationality_country1 )){
        this.formDatosConyuge.controls.nationality_country1.setValue('');
      }
    }else if(nationality == 2){
      if(this.formDatosConyuge.value.nationality_country2 == null || this.formDatosConyuge.value.nationality_country2 == "" ){
        this.formDatosConyuge.controls.nationality_country1.setValue('');
        this.formDatosConyuge.controls.nationality_country3.setValue('');
      }
      if(this.formDatosConyuge.value.nationality_country1 != null && this.formDatosConyuge.value.nationality_country1 != "" && (this.formDatosConyuge.value.nationality_country1 == this.formDatosConyuge.value.nationality_country2 )){
        this.formDatosConyuge.controls.nationality_country2.setValue('');
      }
      if(this.formDatosConyuge.value.nationality_country3 != null && this.formDatosConyuge.value.nationality_country3 != "" && (this.formDatosConyuge.value.nationality_country3 == this.formDatosConyuge.value.nationality_country2 )){
        this.formDatosConyuge.controls.nationality_country1.setValue('');
      }
    }else if(nationality == 3){
      if(this.formDatosConyuge.value.nationality_country3 == null || this.formDatosConyuge.value.nationality_country3 == "" ){
        this.formDatosConyuge.controls.nationality_country1.setValue('');
        this.formDatosConyuge.controls.nationality_country2.setValue('');
      }
      if(this.formDatosConyuge.value.nationality_country1 != null && this.formDatosConyuge.value.nationality_country1 != "" && (this.formDatosConyuge.value.nationality_country1 == this.formDatosConyuge.value.nationality_country3 )){
        this.formDatosConyuge.controls.nationality_country3.setValue('');
      }
      if(this.formDatosConyuge.value.nationality_country2 != null && this.formDatosConyuge.value.nationality_country2 != "" && (this.formDatosConyuge.value.nationality_country2 == this.formDatosConyuge.value.nationality_country3 )){
        this.formDatosConyuge.controls.nationality_country3.setValue('');
      }
    }
  }
 */
  resetConyuge(){
    this.formDatosConyuge.reset();
  }

  changeCleanDocumentNumber(){
    this.formDatosConyuge.controls.document_number.setValue('');
  }
  
  selectedCountry_datosConyuge(){
    this.formDatosConyuge.patchValue({nationality_country1: this.formDatosConyuge.value.birth_country});
  }

  existInList(lista:Array<number>, id:number){
    for(var current of lista){
		  if(current == id){
			  return true;
		  }
	  }
	  return false;
  }

  /*
      public constants
  */

  get yes() { return PARAMETER_TABLE_YES_NO.YES.ID; }
  get no()  { return PARAMETER_TABLE_YES_NO.NO.ID; }
  get localCountry(){ return countryMaping.PERU;}

  /*****************/
  setDataDatosPersonales(){
    this.currentData.entity_InvReqPerson.name                       = this.formDatosPersonales.value.name;
    this.currentData.entity_InvReqPerson.firstLastname              = this.formDatosPersonales.value.first_lastname;
    this.currentData.entity_InvReqPerson.secondLastname             = this.formDatosPersonales.value.second_lastname;
    this.currentData.entity_InvReqPerson.documentType               = this.formDatosPersonales.value.document_type;
    this.currentData.entity_InvReqPerson.documentNumber             = this.formDatosPersonales.value.document_number;
    this.currentData.entity_InvReqPerson.birthdate                  = this.formDatosPersonales.value.birthdate;
    this.currentData.entity_InvReqPerson.birthplace                 = this.formDatosPersonales.value.birthplace;
    this.currentData.entity_InvReqPerson.birthCountry               = this.formDatosPersonales.value.birth_country;
    this.currentData.entity_InvReqPerson.nationalityCountry1        = this.formDatosPersonales.value.nationality_country1;
    this.currentData.entity_InvReqPerson.nationalityCountry2        = this.formDatosPersonales.value.nationality_country2;
    this.currentData.entity_InvReqPerson.nationalityCountry3        = this.formDatosPersonales.value.nationality_country3;
    this.currentData.entity_InvReqPerson.streetType                 = this.formDatosPersonales.value.street_type;
    this.currentData.entity_InvReqPerson.streetName                 = this.formDatosPersonales.value.street_name;
    this.currentData.entity_InvReqPerson.streetNumber               = this.formDatosPersonales.value.street_number;
    this.currentData.entity_InvReqPerson.apartmentInteriorNumber    = this.formDatosPersonales.value.apartment_interior_number;
    this.currentData.entity_InvReqPerson.settlementType             = this.formDatosPersonales.value.settlement_type;
    this.currentData.entity_InvReqPerson.settlementName             = this.formDatosPersonales.value.settlement_name;
    this.currentData.entity_InvReqPerson.sectorStageNumber          = this.formDatosPersonales.value.sector_stage_number;
    this.currentData.entity_InvReqPerson.blockLetter                = this.formDatosPersonales.value.block_letter;
    this.currentData.entity_InvReqPerson.lotNumber                  = this.formDatosPersonales.value.lot_number;
    this.currentData.entity_InvReqPerson.homePhoneNumber            = this.formDatosPersonales.value.home_phone_number;
    this.currentData.entity_InvReqPerson.mobileNumber               = this.formDatosPersonales.value.mobile_number;
    this.currentData.entity_InvReqPerson.email                      = this.formDatosPersonales.value.email;
    this.currentData.entity_InvReqPerson.investorLinkedGroup        = this.formDatosPersonales.value.investor_linked_group;
    this.currentData.entity_InvReqPerson.grpRelationshipCompany     = this.formDatosPersonales.value.grp_relationship_company;
    this.currentData.entity_InvReqPerson.grpRelationshipPosition    = this.formDatosPersonales.value.grp_relationship_position;
    this.currentData.entity_InvReqPerson.maritalStatus              = this.formDatosPersonales.value.marital_status;
    this.currentData.entity_InvReqPerson.personalDataProtIndicator  = this.formDatosPersonales.value.personal_data_prot_indicator;
    this.currentData.entity_InvReqPerson.personalDataLegalIndicator = this.formDatosPersonales.value.personal_data_legal_indicator;
    this.currentData.entity_InvReqPerson.legalAddress               = this.formDatosPersonales.value.legal_address;
    this.currentData.entity_InvReqPerson.maritalRegime              = this.formDatosPersonales.value.marital_regime;

    this.currentData.entity_InvReqPerson.isResident                 = this.formDatosPersonales.value.is_resident;
    this.currentData.entity_InvReqPerson.residenceCountry           = this.formDatosPersonales.value.residence_country;

    this.currentData.entity_InvReqPerson.residenceDepartment        = this.formDatosPersonales.value.residence_department;
    this.currentData.entity_InvReqPerson.residenceProvince          = this.formDatosPersonales.value.residence_province;
    this.currentData.entity_InvReqPerson.residenceDistrict          = this.formDatosPersonales.value.residence_district;

    this.currentData.entity_InvReqPerson.residenceStateProvince     = this.formDatosPersonales.value.residence_state_province
    this.currentData.entity_InvReqPerson.residenceCity              = this.formDatosPersonales.value.residence_city;
    
  }

  setDataDatosConyuge(){
    this.currentData.entity_InvReqPerson_conyuge.name                 = this.formDatosConyuge.value.name;			
    this.currentData.entity_InvReqPerson_conyuge.firstLastname        = this.formDatosConyuge.value.first_lastname; 		    
    this.currentData.entity_InvReqPerson_conyuge.secondLastname       = this.formDatosConyuge.value.second_lastname; 		  
    this.currentData.entity_InvReqPerson_conyuge.fullname             = this.formDatosConyuge.value.name + this.formDatosConyuge.value.first_lastname + this.formDatosConyuge.value.second_lastname; 			     
    this.currentData.entity_InvReqPerson_conyuge.documentType         = this.formDatosConyuge.value.document_type; 		    
    this.currentData.entity_InvReqPerson_conyuge.documentNumber       = this.formDatosConyuge.value.document_number; 		  
    this.currentData.entity_InvReqPerson_conyuge.birthdate            = this.formDatosConyuge.value.birthdate; 			      
    this.currentData.entity_InvReqPerson_conyuge.birthplace           = this.formDatosConyuge.value.birthplace; 			    
    this.currentData.entity_InvReqPerson_conyuge.birthCountry         = this.formDatosConyuge.value.birth_country; 		    
    this.currentData.entity_InvReqPerson_conyuge.nationalityCountry1  = this.formDatosConyuge.value.nationality_country1;	
    this.currentData.entity_InvReqPerson_conyuge.nationalityCountry2  = this.formDatosConyuge.value.nationality_country2;	
    this.currentData.entity_InvReqPerson_conyuge.nationalityCountry3  = this.formDatosConyuge.value.nationality_country3;	
    this.currentData.entity_InvReqPerson_conyuge.maritalRegime        = this.formDatosConyuge.value.marital_regime; 
  }

  setDataDatosFatca(){
    if(this.fatcaTable != null){
      for(var i = 0; i < this.fatcaTable.length; i++){
        this.fatcaTable[i].documentNumber = this.fatca_document_number.controls[i].value;
        this.fatcaTable[i].documentType = this.fatca_document_type.controls[i].value;
        this.fatcaTable[i].nationalityCountry = this.fatca_nationality_country.controls[i].value;
      }
      this.currentData.entity_InvReqPerson.investorRequestFatca = this.fatcaTable;
    }
  }

  isSucessDatosPersonales()
  {
    var isSucess = false;
    if(!this.formDatosPersonales.invalid){
      isSucess = true;
    }
    return isSucess;
  }

  isSucessDatosConyuge()
  {
    var isSucess = false;
    if( this.formDatosPersonales.controls.marital_status.value==171 ){
      if(!this.formDatosConyuge.invalid){  isSucess = true;  }
    }else{
      isSucess = true;
    }
    return isSucess;
  }

  isSucessDatosFatca()
  {
    var isSucess = false;
    if(this.fatcaTable !=null && this.fatcaTable.length > 0){
      isSucess = true;
      for(var documentType of this.fatca_document_type.controls){
        if(documentType.invalid){   isSucess = false; break;  }
      }
      for(var documentNumber of this.fatca_document_number.controls){
        if(documentNumber.invalid){   isSucess = false; break;  }
      }
    }else{
      isSucess = true;
    }
    return isSucess;
  }

  /*
      generic functions
  */

  onKeyUpDNI(event: any, object:FormControl){
      var documentNumner = event.target.value;
      if(documentNumner.length != 8){
        object.setValue("");
        this.alertar("El DNI debe tener 8 caracteres.");//El DNI debe tener 8 dígitos
      }
  }

  onKeyUpOnliDecimal(event: any, object:FormControl){
    object.setValue(func.inputOnliDecimal(event.target.value));
  }

  onKeyOutOnlyNumericDecimal(event: any,object:FormControl){
    var valor:number = Number(event.target.value);
    var valorFormateado = func.formatNumber(valor);
    if( valorFormateado == "NaN" || valorFormateado == null){
      object.setValue("0.00");
    }else{
      object.setValue(valorFormateado);
    }
  }

  onKeyUpEmail(event: any, object:FormControl){
    object.setValue(func.inputEmail(event.target.value));
  }

  onKeyUpOnliNumeric(event: any, object:FormControl){
    object.setValue(func.inputOnliNumeric(event.target.value));
  }

  onKeyUpOnliText(event: any, object:FormControl){
    object.setValue(func.inputOnliText(event.target.value));
  }

  onKeyUpAplhaNumeric(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumeric(event.target.value));
  }

  onKeyUpAplhaNumericSpace(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumericSpace(event.target.value));
  }

  onKeyUpAplhaNumericSimple(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumericSimple(event.target.value));
  }

  onKeyUpAplhaNumericSpecial(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumericSpecial(event.target.value));
  }

  onKeyUpOnliTextAndSpace(event: any, object:FormControl){
    object.setValue(func.inputOnliTextAndSpace(event.target.value));
  }

  setMaxDateBirth() { 
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = (today.getFullYear()-18);
    
    this.currentData.maxDateBirth = yyyy + '-' + mm + '-' + dd ;
  }

  setMinDateBirth() { 
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = (today.getFullYear()-110);
    
    this.currentData.minDateBirth = yyyy + '-' + mm + '-' + dd ;
  }

  setMaxDateToday() { 
    var today = new Date();
    var dd = String(today.getDate()-1).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = (today.getFullYear());
    
    this.currentData.maxDateToday = yyyy + '-' + mm + '-' + dd ;
  }

}
