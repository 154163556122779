import { Component, OnInit } from '@angular/core';  
import { Router } from '@angular/router';
import { ModalService } from 'src/app/configuration/providers/modal';


@Component({
  selector: 'ark-simplemodal',
  templateUrl: './simplemodal.component.html',
  styleUrls: ['./simplemodal.component.css']
}) 

export class SimplemodalComponent implements OnInit { 

 
  constructor(private modalService: ModalService,private router: Router) { }
 
  headText:String = '';
  bodyText:String = '';
  showModalInfo:Boolean = true; 

  defaultHeadeText='Mensaje de Alerta';

  alertar(head,body){
    this.headText = (head != null)?head:this.defaultHeadeText;
    this.bodyText = body;  
    this.modalService.open('alertar');
  }

  close(id:String){
    this.modalService.close(id+"");
  }


  ngOnInit() {
    var modalTermsSession = sessionStorage.getItem('modalTerms');
    if(modalTermsSession != null && modalTermsSession != "undefined" && modalTermsSession != ""){
      this.showModalInfo = (modalTermsSession === "true"); 
    }
  }

  gotoPage(page: String){
    this.router.navigate([page]); 
  }

  closeModalInfoAndNotShow(){
    this.showModalInfo = false;
    sessionStorage.setItem('modalTerms', JSON.stringify(this.showModalInfo));
    this.modalService.close('idInfo');
  }

  openModalInfo(){
    if(this.showModalInfo){
      this.modalService.open('idInfo');
    }else{
      this.openTerminos();
    }
  }

  openTerminosAndCloseInfo(){
    this.closeModalInfo();
    this.openTerminos();
  }

  closeModalInfo(){ 
    this.modalService.close('idInfo');
  }
  
  openTerminos(){
    this.modalService.open('idTerminos');  
  }

  closeTerminos(){ 
    this.modalService.close('idTerminos');
  }


}
 