import { Component, OnInit, Input } from '@angular/core';  
import { Router } from '@angular/router';

@Component({
  selector: 'ark-mis-cuentasBancarias',
  templateUrl: './mis-cuentasBancarias.component.html',
  styleUrls: ['./mis-cuentasBancarias.component.css']
})
export class MisCuentasBancariasComponent implements OnInit {
 
  constructor(private router: Router) { }

  ngOnInit() { 

  } 

  gotoPage(page: String){
    this.router.navigate([page]); 
  }

}
