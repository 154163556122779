import { Component, OnInit, Input } from '@angular/core';
import { responseRendimientoTo } from 'src/app/model/UserModel/responseRendimientoTo';
import { rendimientoDetalleTo } from 'src/app/model/UserModel/rendimientoDetalleTo';
import { ModalService } from 'src/app/configuration/providers/modal';

@Component({
  selector: 'ark-rendimientos',
  templateUrl: './rendimientos.component.html',
  styleUrls: ['./rendimientos.component.css']
})
export class RendimientosComponent implements OnInit {

  @Input() entity : responseRendimientoTo = {} as responseRendimientoTo;  //parametros
  detail : rendimientoDetalleTo = {} as rendimientoDetalleTo; 
  security_serial = null; 

  constructor(private modalService: ModalService) { }

  ngOnInit() {
    this.security_serial = JSON.parse(sessionStorage.getItem('serialCode')); 
  }

  closeModal(id: string) {
    this.modalService.close(id);
 }

 verDetalleRendinmiento(prm: rendimientoDetalleTo) {
    this.modalService.open('idModalDetail');
    this.detail = prm;
 }


}
