import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core'; 
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { cashAccountTo } from 'src/app/model/UserModel/cashAccountTo';
import { ModalService } from 'src/app/configuration/providers/modal';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { userTo } from 'src/app/model/UserModel/userTo';
import { URL } from 'src/app/constants/url';
import { CURRENCY } from 'src/app/constants/CURRENCY';
import { func } from 'src/app/constants/func';
import { viewAllCashAccountTo } from 'src/app/model/UserModel/viewAllCashAccountTo';
import { parameterTableTo } from 'src/app/model/UserModel/parameterTableTo';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'ark-miefectivo-dolares',
  templateUrl: './miefectivo-dolares.component.html',
  styleUrls: ['./miefectivo-dolares.component.css']
})
export class MiefectivoDolaresComponent implements OnInit {
  
  @Input() entity : Array<cashAccountTo> = [] as Array<cashAccountTo>;  //parametros 

  operation_date = null;
  total_balance = 0;
  objUserTo = null;
  
  
  listEfectivo     : Array<cashAccountTo> = [] as Array<cashAccountTo>;
  listEfectivoAux  : Array<cashAccountTo> = [] as Array<cashAccountTo>;
  listCashAccountType  : Array<parameterTableTo> = [] as Array<parameterTableTo>;
  cashAccountType = new FormControl('');

  constructor(private modalService: ModalService, 
    private httpClient: HttpClient,
    private loginService: LoginService,
    private  spinner: NgxSpinnerService) { }

  async ngOnInit() {     
    this.objUserTo = <userTo>this.loginService.currentUser(); 

    await this.loadEfectivosMovimiento();
 
    if(this.listCashAccountType != null && this.listCashAccountType.length>0){
      this.cashAccountType.setValue(this.listCashAccountType[0].parameter_table_pk);
      this.cashAccountTypeSelected();
    }

  }

  cashAccountTypeSelected(){

      this.listEfectivoAux = [];
      
      if(this.listEfectivo != null && this.listEfectivo.length > 0){  
        for(var i = 0; i < this.listEfectivo.length; i++){
          if(this.cashAccountType.value == this.listEfectivo[i].accountType || this.cashAccountType.value == 0){

            let cashAccountTo : cashAccountTo;
            cashAccountTo = {} as cashAccountTo ;
            cashAccountTo.operation_nro           = func.formatQuantity(this.listEfectivo[i].operationNro);
            cashAccountTo.operation_date          = this.listEfectivo[i].strOperationDate;//func.formatFechaDBtoUi(this.listEfectivo[i].operationDate);
            cashAccountTo.operation_description   = this.listEfectivo[i].operationDescription;
            cashAccountTo.accountType             = this.listEfectivo[i].accountType;
            cashAccountTo.accountTypeDescription  = this.listEfectivo[i].accountTypeDescription;
            cashAccountTo.total_balance           = func.formatNumber(this.listEfectivo[i].totalBalance);
            cashAccountTo.bankCurrency            = this.listEfectivo[i].bankCurrency;
            cashAccountTo.bankNemonic             = this.listEfectivo[i].bankNemonic;
            cashAccountTo.bankAccountNumber       = this.listEfectivo[i].bankAccountNumber;
            cashAccountTo.bankCurrencyNemonic     = this.listEfectivo[i].bankCurrencyNemonic;
            this.listEfectivoAux.push(cashAccountTo); 

          }
        }
      }
    
  }
  
  async loadEfectivosMovimiento(){

    this.spinner.show(); 

    let urlViewCashAccount = URL.SERVICE_API_V1_CORE_GET_CASH_ACCOUNT_MOVEMENTS;
    var body = {
      "rut": ""+this.objUserTo.rut,
      "currency": ""+CURRENCY.DOLARES.ID,
    }

    this.httpClient.post(urlViewCashAccount, body , {  headers: func.WSHeaderTokenCore() })
        . subscribe(
              (response) => {
                
                let lstResponse:Array<viewAllCashAccountTo> = [] as Array<viewAllCashAccountTo>;
                lstResponse =  response as Array<viewAllCashAccountTo>; 

                let cashAccountTo               : cashAccountTo;
                let listcashAccountMovement     : Array<cashAccountTo> = [] as Array<cashAccountTo>;

                if( lstResponse != null && lstResponse.length > 0 ){
                  for(var i=0; i<lstResponse.length; i++){
                    cashAccountTo = {} as cashAccountTo ;
                    cashAccountTo.operationNro = ""+lstResponse[i].idCashAccountPk;
                    cashAccountTo.strOperationDate = lstResponse[i].strOperationDate;
                    cashAccountTo.operationDescription = lstResponse[i].operationDescription;
                    cashAccountTo.totalBalance = lstResponse[i].totalBalance;
                    cashAccountTo.accountType = lstResponse[i].accountType;
                    cashAccountTo.accountTypeDescription = lstResponse[i].accountTypeDescription;
                    
                    cashAccountTo.bankCurrency = lstResponse[i].bankCurrency;
                    cashAccountTo.bankNemonic = lstResponse[i].bankNemonic;
                    cashAccountTo.bankAccountNumber = lstResponse[i].bankAccountNumber;
                    cashAccountTo.bankCurrencyNemonic = lstResponse[i].bankCurrencyNemonic;

                    cashAccountTo.movementClass = lstResponse[i].movementClass;
                    if(lstResponse[i].movementClass == 0)
                    {
                      cashAccountTo.styleClass = 'transitColumn';
                    }
                    else if(lstResponse[i].movementClass == 1)
                    {
                      cashAccountTo.styleClass = 'inColumn';
                    }
                    else if(lstResponse[i].movementClass == 2)
                    {
                      cashAccountTo.styleClass = 'outColumn';
                    }
                    

                    listcashAccountMovement.push(cashAccountTo);

                    var parameterTable = {} as parameterTableTo;
                    parameterTable.parameter_table_pk = lstResponse[i].accountType;
                    parameterTable.description = lstResponse[i].accountTypeDescription;
                    
                    var existRow = false;
                    for(var parame of this.listCashAccountType){
                      if(parame.parameter_table_pk == parameterTable.parameter_table_pk){
                        existRow = true;
                      }
                    }
                    if(!existRow){
                      this.listCashAccountType.push(parameterTable);
                    }

                  }

                    this.listEfectivo = listcashAccountMovement;
                    //console.log("this.listEfectivo::"+JSON.stringify(this.listEfectivo));

                    if(this.listEfectivo != null && this.listEfectivo.length > 0){
                      for(var i = 0; i < this.listEfectivo.length; i++){
                        let cashAccountTo : cashAccountTo;
                        cashAccountTo = {} as cashAccountTo ;
                        cashAccountTo.operation_nro           = func.formatQuantity(this.listEfectivo[i].operationNro);
                        cashAccountTo.operation_date          = this.listEfectivo[i].strOperationDate;//func.formatFechaDBtoUi(this.listEfectivo[i].operationDate);
                        cashAccountTo.operation_description   = this.listEfectivo[i].operationDescription;
                        cashAccountTo.accountType             = this.listEfectivo[i].accountType;
                        cashAccountTo.accountTypeDescription  = this.listEfectivo[i].accountTypeDescription;
                        cashAccountTo.total_balance           = func.formatNumber(this.listEfectivo[i].totalBalance);
                        
                        cashAccountTo.bankCurrency            = this.listEfectivo[i].bankCurrency;
                        cashAccountTo.bankNemonic             = this.listEfectivo[i].bankNemonic;
                        cashAccountTo.bankAccountNumber       = this.listEfectivo[i].bankAccountNumber;
                        cashAccountTo.bankCurrencyNemonic     = this.listEfectivo[i].bankCurrencyNemonic;
                        cashAccountTo.movementClass           = this.listEfectivo[i].movementClass;
                        cashAccountTo.styleClass              = this.listEfectivo[i].styleClass;

                        this.listEfectivoAux.push(cashAccountTo); 
                      }
                    }

                }
              
              },
              (err: HttpErrorResponse) => {
                
                let listEfectivo     : Array<cashAccountTo> = [] as Array<cashAccountTo>;

                this.spinner.hide();
                
            },
            () => {
              this.spinner.hide();
            }
      ); 

  }
  

}
