import { Component, OnInit, Input } from '@angular/core'; 
import { HttpClient, HttpErrorResponse } from '@angular/common/http';  
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { homeDondeImvertirTo } from 'src/app/model/UserModel/homeDondeImvertirTo';
import { ProductoFinancieroHomeTo } from 'src/app/model/UserModel/ProductoFinancieroHomeTo';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { ModalService } from 'src/app/configuration/providers/modal';
import { userTo } from 'src/app/model/UserModel/userTo';
import { URL } from 'src/app/constants/url';
import { func } from 'src/app/constants/func';


@Component({
  selector: 'ark-dondeinvertir',
  templateUrl: './dondeinvertir.component.html',
  styleUrls: ['./dondeinvertir.component.css']
})
export class DondeinvertirComponent implements OnInit {


   objUserTo = null;
   // listProductoFinancieroHomeTo : Array<ProductoFinancieroHomeTo> = [] as Array<ProductoFinancieroHomeTo>;
   entityHomeDondeImvertirTo : homeDondeImvertirTo = {} as homeDondeImvertirTo;

   itemsResult :  Array<ProductoFinancieroHomeTo> =  [] as Array<ProductoFinancieroHomeTo>;
   mensaje = '';

   
   arrProducts:Array<String> = [];//"imageProduct0","imageProduct1","imageProduct2"
   renderProduct:Array<boolean> = [];
   posicion:number = 0;

  constructor(private httpClient: HttpClient,
              private loginService: LoginService, private router: Router,
              private  spinner: NgxSpinnerService,
              private modalService: ModalService) { }

              
  eventoProduct(){
    this.arrProducts = [];
    this.arrProducts.length;
    for(var i=0; i<this.itemsResult.length; i++){
      this.arrProducts.push("imageProduct"+i);
      if(i>1){
        this.renderProduct.push(false);
      }else{
        this.renderProduct.push(true);
      }
    }
    this.posicion = 0;
  }            

  nextProduct(){
    this.posicion = (this.posicion==this.renderProduct.length-1)?0:this.posicion+1;
    for(var i=0; i<this.renderProduct.length; i++){
      if(this.posicion == i){
        this.renderProduct[i] = false;
      }else{
        this.renderProduct[i] = true;
      }
        
    }
    
  }

  prevProduct(){
    this.posicion = (this.posicion==0)?this.renderProduct.length-1:this.posicion-1;

    for(var i=0; i<this.renderProduct.length; i++){
      if(this.posicion == i){
        this.renderProduct[i] = false;
      }else{
        this.renderProduct[i] = true;
      }
        
    }
/*
    
    for(var obj of this.renderProduct){
        obj = true;
    }
    this.renderProduct[this.posicion] = false;
    */
  }

  ngOnInit() {

    this.callService();//descomentar esto
   
    /*
    let producto:ProductoFinancieroHomeTo = {} as ProductoFinancieroHomeTo;
    producto.id_security_code_pk = "1";
    producto.img_base64 = "";
    producto.issuance_date = "2020/01/26";
    producto.issuance_name = "Emision 01";
    producto.nominal_value = 5000;
    producto.serial_code = "SE001COD301";
    producto.tax = 18;

    let tmpData:homeDondeImvertirTo =  {} as homeDondeImvertirTo ; 
    tmpData.items_per_page = 1;
    tmpData.page = 1;
    tmpData.total_count = 1;
    tmpData.items = [] as Array<ProductoFinancieroHomeTo>;
    tmpData.items.push(producto);

    producto = {} as ProductoFinancieroHomeTo;
    producto.id_security_code_pk = "2";
    producto.img_base64 = "";
    producto.issuance_date = "2020/01/22";
    producto.issuance_name = "Emision 02";
    producto.nominal_value = 4000;
    producto.serial_code = "SE001COD302";
    producto.tax = 15;
    tmpData.items.push(producto);

    this.itemsResult = []
    this.itemsResult.push(tmpData.items[0]);*/
  }

  callService(){
    
    this.objUserTo = <userTo>this.loginService.currentUser();
    
    var body = {
      "currency": "10"
    }

    this.spinner.show();
    /*this.httpClient.get(URL.SERVICE_API_V1_USER + this.objUserTo.id_user_pk + 
                        URL.ERVICE_LOAD_PRODUCTS_HOME_END,*/
    //console.log("<homeDondeImvertirTo>response :: URL.SERVICE_API_V1_CORE_GET_PRODUCTS_HOME :: " + URL.SERVICE_API_V1_CORE_GET_PRODUCTS_HOME);
    this.httpClient.post(URL.SERVICE_API_V1_CORE_GET_PRODUCTS_HOME,body,
            { headers: func.WSHeaderTokenCore() })
          . subscribe(
          (response) => { 

            console.log("<homeDondeImvertirTo>response :: datos:: " + JSON.stringify(response));
            let productos = <homeDondeImvertirTo>response; 

            if(productos.items != null && productos.items.length > 3){

              this.itemsResult = []
              this.itemsResult.push(productos.items[0]);
              if(productos.items[1] != null){
                this.itemsResult.push(productos.items[1]);
              }
              if(productos.items[2] != null){
                this.itemsResult.push(productos.items[2]); 
              }

            }else{
              this.itemsResult = productos.items; 
            }
            
            //eventos para el slider
            //this.eventoProduct();

          },
          (err: HttpErrorResponse) => {
              console.log("<homeDondeImvertirTo>response :: datos:: "+err.error);
              console.log("<homeDondeImvertirTo>response :: datos:: "+err.name);
              console.log("<homeDondeImvertirTo>response :: datos:: "+err.message);
              console.log("<homeDondeImvertirTo>response :: datos:: "+err.status);
              
              // this.mensaje = "Ocurrio un error donde invertir \n " + err.message;
            //  this.modalService.open('idModalErrorfull');
            this.spinner.hide();
            return ;
        },
        () => {
          this.spinner.hide();
        console.log("************* this.httpClient.post ()::" + "The observable is now completed.");
        }
    );

  }


  gotoPage(page: String){
    this.router.navigate([page]);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }


}
