import { documentTo } from '../model/UserModel/documentTo';


 
export const documentTypeMaping = {

    DNI                     : 1,
    CARNET_EXTRANJERIA      : 3,
    PASAPORTE               : 4,  
 
    getDocumentTypeDescription(type: number){ 
        var caracteresPermitidos = new Map(); 
        caracteresPermitidos.set(1,"DNI");
        caracteresPermitidos.set(3,"CARNET EXTRANJERIA");
        caracteresPermitidos.set(4, "PASAPORTE"); 
        return caracteresPermitidos.get(type); 
    },

    getListDocumentType(){
        let listDoc : Array<documentTo> = [] as Array<documentTo>;
        let doc : documentTo = {} as documentTo;
        doc.id = 1; doc.name = 'DNI'; listDoc.push(doc);



    }



 }
