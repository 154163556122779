import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ark-suscripcion-confirmsucess',
  templateUrl: './suscripcion-confirmsucess.component.html',
  styleUrls: ['./suscripcion-confirmsucess.component.css']
})
export class SuscripcionConfirmsucessComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
  }

  goToLogin(){
    this.router.navigate(['/login']);
  }

  goToContratoIntermediacion(){
    this.router.navigate(['/newinvestor']);
  }

}
