import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ParameterService } from 'src/app/services/ParameterServices/parameter.service';
import { func } from 'src/app/constants/func';
import { step3FamilyPep } from 'src/app/model/UserModel/step3FamilyPep';
import { InvestorRequestPepToReturn } from 'src/app/model/UserModel/InvestorRequestPepToReturn';
import { PARAMETER_TABLE_YES_NO } from 'src/app/constants/PARAMETER_TABLE_YES_NO';
import { countryMaping } from 'src/app/constants/countryMaping';
import { parameterTableTo } from 'src/app/model/UserModel/parameterTableTo';
import { MASTER_TABLE } from 'src/app/constants/masterTable';


@Component({
  selector: 'ark-datosanexopepFamilia',
  templateUrl: './datosanexopepFamilia.component.html',
  styleUrls: ['./datosanexopepFamilia.component.css']
})
export class DatosanexopepFamiliaComponent implements OnInit {



  @Input()  inputData:step3FamilyPep   	= {} as step3FamilyPep;
  @Output() outputDataEvent               = new EventEmitter<step3FamilyPep>();
  @Output() alertEvent        	          = new EventEmitter<String>();
  @Output() modalEvent                    = new EventEmitter<String>();

  submitted:boolean = false;
  
  formDatosAnexoPep: FormGroup;

  listFamilyPep : Array<InvestorRequestPepToReturn> = [] as Array<InvestorRequestPepToReturn>; //parametros

  currentData:step3FamilyPep   = {} as step3FamilyPep;
  
  constructor(private formBuilder: FormBuilder, private  spinner: NgxSpinnerService,private parameterService: ParameterService) { }

  ngOnInit() {
    this.initVariables();
    this.loadParameters();
    this.setDefaultData();
    this.initForm();
    this.initActionsPostForm();
  }

  initVariables(){
    this.currentData        = this.inputData;
    if(this.currentData.paramFamilyRelationship != null && this.currentData.paramFamilyRelationship.length > 0){
      var listTmp = [];
      for(var currentFam of this.currentData.paramFamilyRelationship){
        if(currentFam.shortInteger == 1){
          listTmp.push(currentFam);
        }
      }
      this.currentData.paramFamilyRelationship = listTmp;
    }
    this.listFamilyPep      = this.currentData.lstFamilyInvestorRequestPepTo;
    this.setMaxDateToday();
  }
  
  async loadParameters(){

    
    if(this.currentData.paramTypePersonPep == null){
      this.currentData.paramTypePersonPep = await this.parameterService.getParameter(MASTER_TABLE.TYPE_PERSON_PEP)  as Array<parameterTableTo>;
    }
    
    if(this.currentData.paramFamilyRelationship == null){
      this.currentData.paramFamilyRelationship = await this.parameterService.getParameter(MASTER_TABLE.FAMILIY_RELATIONSHIP)  as Array<parameterTableTo>;
    }
    
  }

  setDefaultData(){
    if(this.currentData.action == null || this.currentData.action == 'R'){
      
    }else{//M

    }
  }

  nullObject(object){
    return (object == null)?'':object;
  }
  
  initForm(){
    this.formDatosAnexoPep = this.formBuilder.group({
      type_person_pep: 				          [null     , Validators.required],
      family_relationship: 		          [null     , Validators.required], 
      name: 		                        [null     , Validators.required],
      first_lastname: 		              [null     , Validators.required],
      second_lastname: 		              [null                          ],
      document_type: 		                [null     , Validators.required],
      document_number: 		              [null     , Validators.required],
      job_role:	 		                    [null     , Validators.required],                  
      job_entity:	               		    [null     , Validators.required],
      job_country:                      [null     , Validators.required],
      job_date_start:	           		    [null     , Validators.required],
      job_date_end: 		                [null     , Validators.required],
      involves_resource_management: 	  [null     , Validators.required]
    });

  }

  initActionsPostForm(){
    this.formDatosAnexoPep.patchValue({type_person_pep: 300});
    if(this.currentData.action == null || this.currentData.action == 'R'){
      this.formDatosAnexoPep.patchValue({document_type: 1});
      this.formDatosAnexoPep.patchValue({job_country: this.localCountry});
    }else{//M
      if(this.currentData.action == null || this.currentData.action == 'M' && !this.currentData.enabledForm){
        this.disabled_inputs_registrar_ficha()
      }
    }
  }

  disabled_inputs_registrar_ficha(){
    eval('$(function(){$("#newInvestorDiv :input").prop("disabled", true); });');
    eval('$(function(){$(".navigateButton").prop("disabled", false); });');
  }
  
  enabled_inputs_registrar_ficha(){
    this.currentData.enabledForm = true;
    eval('$(function(){$("#newInvestorDiv :input").prop("disabled", false); });');
    eval('$(function(){$(".inputNotEdit").prop("disabled", true); });');
  }
  
  test(){
    this.setDataAnexoPep();
    this.saveAnexoPep();
    console.log("this.currentData.lstFamilyInvestorRequestPepTo::"+JSON.stringify(this.currentData.lstFamilyInvestorRequestPepTo));
    alert("this.currentData.lstFamilyInvestorRequestPepTo::"+JSON.stringify(this.currentData.lstFamilyInvestorRequestPepTo));
  }
  
  setDataAnexoPep(){
    this.currentData.lstFamilyInvestorRequestPepTo = this.listFamilyPep;
  }

  saveAnexoPep(){
    this.inputData = this.currentData;
    this.outputDataEvent.emit(this.inputData);
  }

  alertar(mensaje:String){
    this.alertEvent.emit(mensaje);
    return;
  }

  openModal(mensaje:String)
  {
    this.modalEvent.emit(mensaje);
    return;
  }

  setMinDateCese(){
    /*var today = new Date();
    var dd = String(today.getDate()-1).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = (today.getFullYear());
    */
   //console.log("this.formDatosAnexoPep.controls.job_date_start.value::"+JSON.stringify(this.formDatosAnexoPep.controls.job_date_start.value));
    this.currentData.minDateCese = this.formDatosAnexoPep.controls.job_date_start.value;
    this.formDatosAnexoPep.controls.job_date_end.setValue(null);
  };

  changeCleanDocumentNumber(){
    this.formDatosAnexoPep.patchValue({document_number:null});
  }


  /*
      functions
  */
onReset() { 
  this.submitted = false;
  
  this.formDatosAnexoPep.reset();
  this.formDatosAnexoPep.patchValue({type_person_pep: 300});
  this.formDatosAnexoPep.patchValue({document_type: 1});
  this.formDatosAnexoPep.patchValue({job_country: this.localCountry});

}

onValidationForm(){
  if( this.listFamilyPep != null && this.listFamilyPep.length > 0 ){
    return true;
  }
  return false;
}

addToFamilyPep(){

  this.submitted = true;

  if( !this.formDatosAnexoPep.invalid ){


    var family:InvestorRequestPepToReturn = {} as InvestorRequestPepToReturn;
    family.typePersonPep              = this.formDatosAnexoPep.value.type_person_pep;
    family.familyRelationship         = this.formDatosAnexoPep.value.family_relationship;
    family.name                       = this.formDatosAnexoPep.value.name;
    family.firstLastname              = this.formDatosAnexoPep.value.first_lastname;
    family.secondLastname             = this.formDatosAnexoPep.value.second_lastname;
    family.documentType               = this.formDatosAnexoPep.value.document_type;
    family.documentNumber             = this.formDatosAnexoPep.value.document_number;
    family.jobRole                    = this.formDatosAnexoPep.value.job_role;
    family.jobEntity                  = this.formDatosAnexoPep.value.job_entity;
    family.jobCountry                 = this.formDatosAnexoPep.value.job_country;
    family.jobDateStart               = this.formDatosAnexoPep.value.job_date_start;
    family.jobDateEnd                 = this.formDatosAnexoPep.value.job_date_end;
    family.involvesResourceManagement = this.formDatosAnexoPep.value.involves_resource_management;

    for(var current of this.listFamilyPep){
      if(current.familyRelationship == family.familyRelationship){
        this.alertEvent.emit("Existe un familiar con el mismo parentesco. Verifique por favor.");
        return;
      }

      if(current.documentType == family.documentType && current.documentNumber == family.documentNumber){
        this.alertEvent.emit("Existe un familiar con el mismo documento de identidad. Verifique por favor.");
        return;
      }
    }
    this.listFamilyPep.push(family);

    this.onReset();
  }else{
    //this.simplemodalComponent.alertar("Mensaje de Alerta","aun hay datos pendientes por ingresar");
    this.alertEvent.emit("Complete los datos pendientes.");
  }

}

  editFamily(family:InvestorRequestPepToReturn){
      
    this.formDatosAnexoPep.controls.type_person_pep.setValue(family.typePersonPep);
    this.formDatosAnexoPep.controls.family_relationship.setValue(family.familyRelationship);
    this.formDatosAnexoPep.controls.name.setValue(family.name);
    this.formDatosAnexoPep.controls.first_lastname.setValue(family.firstLastname);
    this.formDatosAnexoPep.controls.second_lastname.setValue(family.secondLastname);
    this.formDatosAnexoPep.controls.document_type.setValue(family.documentType);
    this.formDatosAnexoPep.controls.document_number.setValue(family.documentNumber);
    this.formDatosAnexoPep.controls.job_role.setValue(family.jobRole);
    this.formDatosAnexoPep.controls.job_entity.setValue(family.jobEntity);
    this.formDatosAnexoPep.controls.job_country.setValue(family.jobCountry);
    this.formDatosAnexoPep.controls.job_date_start.setValue(family.jobDateStart);
    this.formDatosAnexoPep.controls.job_date_end.setValue(family.jobDateEnd);
    this.formDatosAnexoPep.controls.involves_resource_management.setValue(family.involvesResourceManagement);
    
    this.removeFamily(family);
  }

  removeFamily(family:InvestorRequestPepToReturn){

    var newList:Array<InvestorRequestPepToReturn> = [] as Array<InvestorRequestPepToReturn>;
    for(var list of this.listFamilyPep){
      if(list.documentNumber != family.documentNumber){
        newList.push(list);
      }
    }

    this.listFamilyPep = newList;
  }


  get form() { return this.formDatosAnexoPep.controls; }

  getDescparamFamilyRelationship(current){
    for(var param of this.currentData.paramFamilyRelationship){
      if(current == param.parameter_table_pk){
        return param.description;
      }
    }
    return "";
  }
  getDescparamDocumentType(current){
    for(var param of this.currentData.paramDocumentType){
      if(current == param.parameter_table_pk){
        return param.indicator1;
      }
    }
    return "";
  }
  getDescparamCountry(current){
    for(var param of this.currentData.paramCountry){
      if(current == param.parameter_table_pk){
        return param.description;
      }
    }
    return "";
  }
  getDescpparamYesNo(current){
    for(var param of this.currentData.paramYesNo){
      if(current == param.parameter_table_pk){
        return param.description;
      }
    }
    return "";
  }

  getTypeRelativePep(parameter_table_pk:number){
    for( let parameterTableDesc of this.currentData.paramFamilyRelationship ){
      if( parameterTableDesc.parameter_table_pk == parameter_table_pk ){
        return parameterTableDesc.description;
      }
    }
    return '';
  }



  /*
      public constants
  */

 get yes() { return PARAMETER_TABLE_YES_NO.YES.ID; }
 get no()  { return PARAMETER_TABLE_YES_NO.NO.ID; }
 get localCountry(){ return countryMaping.PERU;}

 /*
     generic functions
 */

 existInList(lista:Array<number>, id:number){
   for(var current of lista){
     if(current == id){
       return true;
     }
   }
   return false;
 }

 onKeyUpDNI(event: any, object:FormControl){
   var documentNumner = event.target.value;
   if(documentNumner.length != 8){
     object.setValue("");
     this.alertar("El DNI debe tener 8 caracteres.");//El DNI debe tener 8 dígitos
   }
 }

 onKeyUpOnliDecimal(event: any, object:FormControl){
   object.setValue(func.inputOnliDecimal(event.target.value));
 }

 onKeyOutOnlyNumericDecimal(event: any,object:FormControl){
   var valor:number = Number(event.target.value);
   var valorFormateado = func.formatNumber(valor);
   if( valorFormateado == "NaN" || valorFormateado == null){
     object.setValue("0.00");
   }else{
     object.setValue(valorFormateado);
   }
 }

 onKeyUpEmail(event: any, object:FormControl){
   object.setValue(func.inputEmail(event.target.value));
 }

 onKeyUpOnliNumeric(event: any, object:FormControl){
   object.setValue(func.inputOnliNumeric(event.target.value));
 }

 onKeyUpOnliText(event: any, object:FormControl){
   object.setValue(func.inputOnliText(event.target.value));
 }

 onKeyUpAplhaNumeric(event: any, object:FormControl){
   object.setValue(func.inputAlphaNumeric(event.target.value));
 }

 onKeyUpAplhaNumericSpace(event: any, object:FormControl){
   object.setValue(func.inputAlphaNumericSpace(event.target.value));
 }

 onKeyUpAplhaNumericSimple(event: any, object:FormControl){
   object.setValue(func.inputAlphaNumericSimple(event.target.value));
 }

 onKeyUpAplhaNumericSpecial(event: any, object:FormControl){
   object.setValue(func.inputAlphaNumericSpecial(event.target.value));
 }

 onKeyUpOnliTextAndSpace(event: any, object:FormControl){
   object.setValue(func.inputOnliTextAndSpace(event.target.value));
 }

 setMaxDateBirth() { 
   var today = new Date();
   var dd = String(today.getDate()).padStart(2, '0');
   var mm = String(today.getMonth() + 1).padStart(2, '0');
   var yyyy = (today.getFullYear()-18);
   
   this.currentData.maxDateBirth = yyyy + '-' + mm + '-' + dd ;
 }

 setMinDateBirth() { 
   var today = new Date();
   var dd = String(today.getDate()).padStart(2, '0');
   var mm = String(today.getMonth() + 1).padStart(2, '0');
   var yyyy = (today.getFullYear()-110);
   
   this.currentData.minDateBirth = yyyy + '-' + mm + '-' + dd ;
 }

 setMaxDateToday() { 
   var today = new Date();
   var dd = String(today.getDate()-1).padStart(2, '0');
   var mm = String(today.getMonth() + 1).padStart(2, '0');
   var yyyy = (today.getFullYear());
   
   this.currentData.maxDateToday = yyyy + '-' + mm + '-' + dd ;
   this.currentData.minDateCese = yyyy + '-' + mm + '-' + dd ;
 }

}
