import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import { HttpErrorResponse } from '@angular/common/http'; 
import { NgxSpinnerService } from 'ngx-spinner';   
import { Router } from '@angular/router';  
import { ProductoDetalleTo } from 'src/app/model/UserModel/ProductoDetalleTo';
import { ProductoFinancieroTo } from 'src/app/model/UserModel/ProductoFinancieroTo';
import { documentsProductTo } from 'src/app/model/UserModel/documentsProductTo';
import { responseDocumentsProductTo } from 'src/app/model/UserModel/responseDocumentsProductTo';
import { ModalService } from 'src/app/configuration/providers/modal';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { userTo } from 'src/app/model/UserModel/userTo';
import { func } from 'src/app/constants/func';
import { URL } from 'src/app/constants/url';
import { reportsMaping } from 'src/app/constants/reportsMaping';
import { responseReporteTo } from 'src/app/model/UserModel/responseReporteTo';


@Component({
  selector: 'ark-productodetalle',
  templateUrl: './productodetalle.component.html',
  styleUrls: ['./productodetalle.component.css']
})
export class ProductodetalleComponent implements OnInit {

  @Input() entity : ProductoDetalleTo = {} as ProductoDetalleTo;  //parametros
  
  menuOrigin : String;

  productPrm : ProductoFinancieroTo = {} as ProductoFinancieroTo;
/*
  de_issuance_ammount = null;
  de_issuance_date = null;
  de_expiration_date = null;
  ds_nominal_value_initial = null;
  ds_nominal_value_current = null;
  ds_investments_min = null;
  ds_investments_max = null;
  ds_issuance_date = null;
  ds_inscription_date = null;
  cp_initial_date = null;
  cp_expiration_date = null;
  i_annual_tax_percent = null;
  i_expiration_date_coupon1 = null;
  a_amortization_factor_percent = null;
*/
  listDocumentos : Array<documentsProductTo> = [] as Array<documentsProductTo>;
  rdocumentsProductDb : responseDocumentsProductTo = {} as responseDocumentsProductTo;
  objUserTo = null;
  fileDownload = null;

  constructor(private modalService: ModalService, 
              private httpClient: HttpClient,
              private loginService: LoginService,
              private  spinner: NgxSpinnerService,
              private router: Router) { }

  ngOnInit() {

    this.productPrm = JSON.parse(sessionStorage.getItem('product'));

    // this.menuOrigin = this.entity.menuOrigin; // varible para saber de que menu se consulta el producto

    this.objUserTo = <userTo>this.loginService.currentUser();

    //this.nominal_value = func.formatNumber(this.entity.nominal_value);
    //this.tax = func.formatNumber(this.entity.tax);
    //this.issuance_date = func.formatFechaDBtoUi(this.entity.issuance_date);
 
    /** 
    let documentsProductTo : documentsProductTo = {} as documentsProductTo;
    documentsProductTo.document_name = 'Información de Clasificación de Riesgo';
    documentsProductTo.publication_date = '10/02/2019';
    documentsProductTo.file_code = 'AAA';
    this.listDocumentos.push(documentsProductTo);

    documentsProductTo = {} as documentsProductTo;
    documentsProductTo.document_name = 'Prospecto Marco';
    documentsProductTo.publication_date = '10/03/2019';
    documentsProductTo.file_code = 'BBB';
    this.listDocumentos.push(documentsProductTo);

    documentsProductTo = {} as documentsProductTo;
    documentsProductTo.document_name = 'Prospecto ComplementariO';
    documentsProductTo.publication_date = '10/04/2019';
    documentsProductTo.file_code = 'CCC';
    this.listDocumentos.push(documentsProductTo);  **/
/*
    this.de_issuance_ammount            = this.entity.de_issuance_ammount;//func.formatNumber(this.entity.de_issuance_ammount);
    this.de_issuance_date               = this.entity.de_issuance_date;//func.formatFechaDBtoUi(this.entity.de_issuance_date);
    this.de_expiration_date             = this.entity.de_expiration_date;//func.formatFechaDBtoUi(this.entity.de_expiration_date);
    this.ds_nominal_value_initial       = this.entity.ds_nominal_value_initial;//func.formatNumber(this.entity.ds_nominal_value_initial);
    this.ds_nominal_value_current       = this.entity.ds_nominal_value_current//func.formatNumber(this.entity.ds_nominal_value_current);
    this.ds_investments_min             = this.entity.ds_investments_min//func.formatNumber(this.entity.ds_investments_min);
    this.ds_investments_max             = this.entity.ds_investments_max//func.formatNumber(this.entity.ds_investments_max);
    this.ds_issuance_date               = this.entity.ds_issuance_date//func.formatFechaDBtoUi(this.entity.ds_issuance_date);
    this.ds_inscription_date            = this.entity.ds_inscription_date//func.formatFechaDBtoUi(this.entity.ds_inscription_date);
    this.cp_initial_date                = this.entity.cp_initial_date//func.formatFechaDBtoUi(this.entity.cp_initial_date);
    this.cp_expiration_date             = this.entity.cp_expiration_date//func.formatFechaDBtoUi(this.entity.cp_expiration_date);
    this.i_annual_tax_percent           = this.entity.i_annual_tax_percent//func.formatNumber(this.entity.i_annual_tax_percent);
    this.i_expiration_date_coupon1      = this.entity.i_expiration_date_coupon1//func.formatFechaDBtoUi(this.entity.i_expiration_date_coupon1);
    this.a_amortization_factor_percent  = this.entity.a_amortization_factor_percent//func.formatNumber(this.entity.a_amortization_factor_percent);
*/
   
    this.loadDocumentos();
  }


  formatNumber(number){
    return func.formatNumber(number);
  }

  formatDate(date:String){
    return func.formatFechaDBtoUi(date);
  }

  formatDate_line(date:String){
    return func.formatFechaDBtoUi_line(date);
  }


  loadDocumentos(){

    let isinCode = this.productPrm.securitySerial;
    this.spinner.show(); 
    this.httpClient.get(URL.SERVICE_RELEVANT_INFORMATION + isinCode,      // /api/v1/relevant/information/
          {  headers: func.WSHeader() })
      . subscribe(
            (response) => {  
                console.log(" response :: " + JSON.stringify(response) ); 
                
                let responseBd = <responseDocumentsProductTo>response;  
                this.listDocumentos = responseBd.items;

              
            },
            (err: HttpErrorResponse) => {
              console.log(err.error);
              console.log(err.name);
              console.log(err.message);
              console.log(err.status);
              
              this.spinner.hide();  
          },
          () => {
            this.spinner.hide();
            console.log("************* this.httpClient.post ()::" + "The get pdf poliza now completed.");
          }
    ); 

  }


  // ARREGLAR METEDO PARA ESTE CASO
  descargarPdf(file_code: String){
    
    this.spinner.show(); 
    this.httpClient.post(URL.SERVICE_API_V1_USER + this.objUserTo.id_user_pk + 
                         URL.REPORT_PATH_FILE + file_code +
                         URL.REPORT_PATH_TYPE_FILE + reportsMaping.POLICY ,
          {  headers: func.WSHeader() })
      . subscribe(
            (response) => {  
                console.log(" response :: " + JSON.stringify(response) ); 
                
                let responseBd = <responseReporteTo>response;  
                this.fileDownload = responseBd.file_path;
                this.router.navigate([]).then(result => {  window.open(this.fileDownload, '_blank'); });

              
            },
            (err: HttpErrorResponse) => {
              console.log(err.error);
              console.log(err.name);
              console.log(err.message);
              console.log(err.status);
              
              this.spinner.hide();  
          },
          () => {
            this.spinner.hide();
            console.log("************* this.httpClient.post ()::" + "The get pdf poliza now completed.");
          }
    ); 
  
  }



}
