import { Component, OnInit } from '@angular/core';
import { ProductoInversionesTo } from 'src/app/model/UserModel/ProductoInversionesTo';
import { ProductoFinancieroTo } from 'src/app/model/UserModel/ProductoFinancieroTo';
import { ProductoDetalleTo } from 'src/app/model/UserModel/ProductoDetalleTo';
 //import { DatoTestTo } from 'src/app/portalTo/DatoTestTo';


@Component({
  selector: 'ark-productoPlantillas',
  templateUrl: './productoPlantillas.component.html',
  styleUrls: ['./productoPlantillas.component.css']
})
export class ProductoPlantillasComponent implements OnInit {

  entityProductoInversionesTo : ProductoInversionesTo = {} as ProductoInversionesTo;
  entityProductoFinancieroTo : ProductoFinancieroTo = {} as ProductoFinancieroTo;
  entityProductoDetalleTo : ProductoDetalleTo = {} as ProductoDetalleTo;
  //entityMovimientoTo : movimientoTo = {} as movimientoTo;
  
constructor() { }

//  lista: Array<DatoTestTo> = [] as Array<DatoTestTo>;

// arrayDatosTest: DatoTestTo = {} as DatoTestTo;


  ngOnInit() {
    
    //------------
    this.entityProductoInversionesTo.imgbase64					  = "";
    this.entityProductoInversionesTo.nombreEmision				= "Primera Emisión 2019 Serie A xyz";
    this.entityProductoInversionesTo.serie						    = "AX1S56W89T48";
    this.entityProductoInversionesTo.cantidadTotal				= "9.00";
    this.entityProductoInversionesTo.valorNominal				  = "1,000.00";
    this.entityProductoInversionesTo.Inversion					  = "9,000.00";
    this.entityProductoInversionesTo.FechaInversion				= "23/03/2019";
    this.entityProductoInversionesTo.Rendimiento				  = "10.00%";
    this.entityProductoInversionesTo.moneda					  	  = "SOL";
    this.entityProductoInversionesTo.codigoEmisionMovimientos	= 1;
    this.entityProductoInversionesTo.codigoEmisionRendimiento	= 1;
    
    //------------

    /**
    this.entityProductoFinancieroTo.imgbase64					  = "";
    this.entityProductoFinancieroTo.nombreEmision				= "Primera Emisión 2019 Serie A abc";
    this.entityProductoFinancieroTo.nombreEmisor				= "ACRES";
    this.entityProductoFinancieroTo.valorNominal				= "3,000.00";
    this.entityProductoFinancieroTo.moneda				      = "DOL";
    this.entityProductoFinancieroTo.tasaInteres				  = "5.74%";
    this.entityProductoFinancieroTo.descripcionEmision		= "Quinta Emisión de Instrumentos de Deuda para financiar operaciones de Factoring Electrónico";
    this.entityProductoFinancieroTo.fechaEmision				  = "23/03/2019";
    this.entityProductoFinancieroTo.plazoEmision				  = "5 años";
    this.entityProductoFinancieroTo.codigoMasInformacion	= 1;  */

    /** 
    this.entityProductoDetalleTo.dpe_serie					="COBLP1234567";
    this.entityProductoDetalleTo.dpe_emisor					="APDR - CO393 - EMPRESA PRUEBA S.A.";
    this.entityProductoDetalleTo.dpe_emision					="GTADFYIPF0125475";
    this.entityProductoDetalleTo.dpe_motonEmitido					="30,000,000.00";
    this.entityProductoDetalleTo.dpe_fechaEmision					="10/05/2019";
    this.entityProductoDetalleTo.dpe_paisEmision					="Perú";
    this.entityProductoDetalleTo.dpe_claseValor					="BONOS DE LARGO PLAZO";
    this.entityProductoDetalleTo.dpe_modena					="Nuevos Soles";
    this.entityProductoDetalleTo.dpe_fechaVencimiento					="30/12/2019";
    this.entityProductoDetalleTo.dpe_clasificacion					="AAA";

    this.entityProductoDetalleTo.dps_descripcionValor					="APDRCO393514";
    this.entityProductoDetalleTo.dps_nemonico					="PE32";
    this.entityProductoDetalleTo.dps_serieValor					="A";
    this.entityProductoDetalleTo.dps_valorNominalInicial					="10,000.00";
    this.entityProductoDetalleTo.dps_inversionMinima					="1,000.00";
    this.entityProductoDetalleTo.dps_fechaEmision					="11/06/2019";
    this.entityProductoDetalleTo.dps_valorNominalActual					="10,000.00";
    this.entityProductoDetalleTo.dps_inversionMaxima					="10,000.00";
    this.entityProductoDetalleTo.dps_fechaInscripcion					="11/06/2019";

    this.entityProductoDetalleTo.racp_fechaInicio					="30/09/2014";
    this.entityProductoDetalleTo.racp_nDiasValor					="360";
    this.entityProductoDetalleTo.racp_plazoValor					="Fijo";
    this.entityProductoDetalleTo.racp_fechaVencimiento					="30/09/2019";
    this.entityProductoDetalleTo.racp_nMesesValor					="12";
    this.entityProductoDetalleTo.racp_plazoExtendido					="NO";

    this.entityProductoDetalleTo.rai_tipoInteres					="Fijo";
    this.entityProductoDetalleTo.rai_formaPago					="Por Cupón";
    this.entityProductoDetalleTo.rai_periodoPago					="Trimestral";
    this.entityProductoDetalleTo.rai_claseInteres					="Efectivo";
    this.entityProductoDetalleTo.rai_tipoCalendario					="Comercial";
    this.entityProductoDetalleTo.rai_fVencimientoCupon1					="01/01/2019";
    this.entityProductoDetalleTo.rai_rendimiento					="---";
    this.entityProductoDetalleTo.rai_tasaAnualInteres					="10.00";
    this.entityProductoDetalleTo.rai_periodicidadDias					="---";
    this.entityProductoDetalleTo.rai_tipoMes					="30";
    this.entityProductoDetalleTo.rai_diasCalendario					="360";
    this.entityProductoDetalleTo.rai_nCupones					="5";

    this.entityProductoDetalleTo.raa_formaPago					="Al Vencimiento";
    this.entityProductoDetalleTo.raa_periodoPago					="Anual";
    this.entityProductoDetalleTo.raa_factorAmortizacion					="100.00";
    this.entityProductoDetalleTo.raa_nPagos					="1";
    this.entityProductoDetalleTo.raa_tipoAmortizacion					="---";
    this.entityProductoDetalleTo.raa_periodicidadDias					="360";
    this.entityProductoDetalleTo.raa_amortizacionSobre					="Capital";

    this.entityProductoDetalleTo.crod_tipoInteres					="Fijo";
    this.entityProductoDetalleTo.crod_claseInteres					="Efectivo";
    this.entityProductoDetalleTo.crod_periodoPago					="Trimestral";
    this.entityProductoDetalleTo.crod_tipoCalendario					="Comercial";
    this.entityProductoDetalleTo.crod_formaPago						="Por Cupón";
    this.entityProductoDetalleTo.crod_rendimiento					="---";
    this.entityProductoDetalleTo.crod_tasaAnualInteres					="10.00";
    this.entityProductoDetalleTo.crod_periodoRevisionTasa					="---";
    this.entityProductoDetalleTo.crod_diasCalendario					="360";
    this.entityProductoDetalleTo.crod_periodicidadDias					="---";  */
    //------------
    
  }

}