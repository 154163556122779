import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ark-footermenu-whitoutsession',
  templateUrl: './footermenu-whitoutsession.component.html',
  styleUrls: ['./footermenu-whitoutsession.component.css']
})
export class FootermenuWhitoutsessionComponent implements OnInit {


  constructor(private router: Router) { }

  ngOnInit() {
  }

  gotoPage(page: String){
    this.router.navigate([page]); 
  }

}
