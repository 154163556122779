import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core'; 
import { Router } from '@angular/router';
import { investmentTo } from 'src/app/model/UserModel/investmentTo';
import { func } from 'src/app/constants/func';

@Component({
  selector: 'ark-productoinversiones',
  templateUrl: './productoinversiones.component.html',
  styleUrls: ['./productoinversiones.component.css']
})
export class ProductoinversionesComponent implements OnInit {

  @Input() entity : investmentTo = {} as investmentTo;  //parametros

  quantity = 0;
  nominal_value = 0;
  investment_amount = 0;
  cost_efficiency = 0;
  investment_date = null;


  constructor(
    private router: Router) { }

  ngOnInit() {
    this.quantity = func.formatQuantity(this.entity.quantity);
    this.nominal_value = func.formatNumber(this.entity.nominalValue);
    this.investment_amount = func.formatNumber(this.entity.investmentAmount);
    this.cost_efficiency = func.formatNumber(this.entity.costEfficiency);
    this.investment_date = func.formatFechaDBtoUi(this.entity.investmentDate);
  }

  gotoProductoDetalleInversion(serialCode: String){
    sessionStorage.setItem('serialCode',JSON.stringify(serialCode));
    this.router.navigate(["/detalleInversion"]);
  }
  
  gotoPage(page: String, serialCode: String){  
    sessionStorage.setItem('serialCode', JSON.stringify(serialCode));
    this.router.navigate([page]); // go to page
  }

}
