import { Component, OnInit, Input, Output,EventEmitter, ViewChild } from '@angular/core';
import { step6 } from 'src/app/model/UserModel/step6';
import { FormControl } from '@angular/forms';
import { func } from 'src/app/constants/func';
import { InvReqPerson } from 'src/app/model/UserModel/InvReqPerson';
import { MASTER_TABLE } from 'src/app/constants/masterTable';
import { parameterTableTo } from 'src/app/model/UserModel/parameterTableTo';
import { countryMaping } from 'src/app/constants/countryMaping';

@Component({
  selector: 'ark-datosanexos',
  templateUrl: './datosanexos.component.html',
  styleUrls: ['./datosanexos.component.css']
})
export class DatosanexosComponent implements OnInit {

  @Input()  inputData:step6       = {} as step6;
  @Output() outputDataEvent       = new EventEmitter<step6>();
  @Output() alertEvent            = new EventEmitter<String>();
  
  
  currentData:step6   = {} as step6;
  get localCountry(){ return countryMaping.PERU;}
  
  constructor() { }

  ngOnInit() {
    eval(" $(function() { $('[data=hide]').hide(); $('[data=show]').show(); }); ");
    this.initVariables();
    this.loadParameters();
    this.setDefaultData();
    this.initActionsPostForm();
  }

  initVariables(){
    this.currentData = this.inputData;
  }
  
  async loadParameters(){
/*
    if(this.currentData.paramCountry == null){
      this.currentData.paramCountry = await this.parameterService.getParameter(MASTER_TABLE.ACTIVITY_TYPE) as Array<parameterTableTo>;
    }
    
    if(this.currentData.paramDepartment == null){
      this.currentData.paramDepartment = await this.parameterService.getParameter(MASTER_TABLE.ACTIVITY_TYPE) as Array<parameterTableTo>;
    }
    
    if(this.currentData.paramProvince == null){
      this.currentData.paramProvince = await this.parameterService.getParameter(MASTER_TABLE.ACTIVITY_TYPE) as Array<parameterTableTo>;
    }
    
    if(this.currentData.paramDistrict == null){
      this.currentData.paramDistrict = await this.parameterService.getParameter(MASTER_TABLE.ACTIVITY_TYPE) as Array<parameterTableTo>;
    }
*/

  }

  /*
      form functions
  */
  fileToUpload: File = null;
  handleFileInput(files: FileList){
    this.fileToUpload = files.item(0);
  }

  setDefaultData(){
    if(this.currentData.investorRequest != null && this.currentData.investorRequest.id_investor_request_pk!=null){
      this.btnProteccionDatPersonales();
      this.btnServIntermedOpValExtranjero();
      this.btnPrecisionesLegales();
      this.btnLavadoActivosTerrorismo();
      this.btnExoneracionGK();
      this.btnDeclaJuradaODLicito();
      this.btnPersonasPep();
    }
    this.enabledAnexoPep();
    this.enabledAnexoFatca();
  }

  
  initActionsPostForm(){
    
    if(this.currentData.action == null || this.currentData.action == 'M' && !this.currentData.enabledForm){
      this.disabled_inputs_registrar_ficha()
    }

  }

  disabled_inputs_registrar_ficha(){
    eval('$(function(){$("#newInvestorDiv :input").prop("disabled", true); });');
    eval('$(function(){$(".navigateButton").prop("disabled", false); });');
  }
  
  enabled_inputs_registrar_ficha(){
    this.currentData.enabledForm = true;
    eval('$(function(){$("#newInvestorDiv :input").prop("disabled", false); });');
  }
  
  saveSetp6(){
    if(this.isSucessAnexos()){
      //this.currentData.fileToUpload = this.fileToUpload;
      this.inputData = this.currentData;
      this.outputDataEvent.emit(this.inputData);
    }else{
      this.alertar("Debe Aceptar todos los anexos para poder enviar el contrato. Verifique por favor");
    }
  }

  alertar(mensaje:String){
    this.alertEvent.emit(mensaje);
    return;
  }

  getCountryName(idCountry:number){
    if(this.currentData != null && this.currentData.paramCountry != null){
      for(var country of this.currentData.paramCountry){
        if(country.parameter_table_pk == idCountry){
          return country.description;
        }
      }
    }
    return ""
  }

  getDepartmentName(idDepartment:number){
    if(this.currentData != null && this.currentData.paramDepartment != null){
      for(var department of this.currentData.paramDepartment){
        if(department.parameter_table_pk == idDepartment){
          return department.description;
        }
      }
    }
    return ""
  }

  getProvinceName(idProvince:number){
    if(this.currentData != null && this.currentData.paramProvince != null){
      for(var province of this.currentData.paramProvince){
        if(province.parameter_table_pk == idProvince){
          return province.description;
        }
      }
    }
    return ""
  }

  getDistrictName(idDistrict:number){
    if(this.currentData != null && this.currentData.paramDistrict != null){
      for(var district of this.currentData.paramDistrict){
        if(district.parameter_table_pk == idDistrict){
          return district.description;
        }
      }
    }
    return ""
  }

  isSucessAnexos()
  {
    if(this.currentData.varPersonasPep            == true && this.currentData.varDeclaJuradaODLicito          == true && 
      this.currentData.varExoneracionGK           == true && this.currentData.varLavadoActivosTerrorismo      == true && 
      this.currentData.varPrecisionesLegales      == true && this.currentData.varServIntermedOpValExtranjero  == true && 
      this.currentData.varProteccionDatPersonales == true){
      return true;
    }
    return false;
  }
  
  btnPersonasPep(){
    this.currentData.varPersonasPep = true;
  }
  btnPersonasPepDenied(){
    this.currentData.varPersonasPep = false;
  }
  btnDeclaJuradaODLicito(){
    this.currentData.varDeclaJuradaODLicito = true;
  }
  btnDeclaJuradaODLicitoDenied(){
    this.currentData.varDeclaJuradaODLicito = false;
  }
  btnExoneracionGK(){
    this.currentData.varExoneracionGK = true;
  }
  btnExoneracionGKDenied(){
    this.currentData.varExoneracionGK = false;
  }
  btnLavadoActivosTerrorismo(){
    this.currentData.varLavadoActivosTerrorismo = true;
  }
  btnLavadoActivosTerrorismoDenied(){
    this.currentData.varLavadoActivosTerrorismo = false;
  }
  btnPrecisionesLegales(){
    this.currentData.varPrecisionesLegales = true;
  }
  btnPrecisionesLegalesDenied(){
    this.currentData.varPrecisionesLegales = false;
  }
  btnServIntermedOpValExtranjero(){
    this.currentData.varServIntermedOpValExtranjero = true;
  }
  btnServIntermedOpValExtranjeroDenied(){
    this.currentData.varServIntermedOpValExtranjero = false;
  }
  btnProteccionDatPersonales(){
    this.currentData.varProteccionDatPersonales = true;
  }
  btnProteccionDatPersonalesDenied(){
    this.currentData.varProteccionDatPersonales = false;
  }
  enabledAnexoPep(){
    if(this.currentData.entity_InvReqPerson.isPep || this.currentData.entity_InvReqPerson.isSpousePep || this.currentData.entity_InvReqPerson.hasRelativePep){
      this.currentData.flagEnabledAnexoPep = true;
    }else{
      this.currentData.flagEnabledAnexoPep = false;
    }
  }
  enabledAnexoFatca(){
    if(this.currentData.entity_InvReqPerson.investorRequestFatca != null && this.currentData.entity_InvReqPerson.investorRequestFatca.length > 0 ){
      this.currentData.flagEnabledAnexoFatca = true;
    }else{
      this.currentData.flagEnabledAnexoFatca = false;
    }
  }
  

  /*
      generic functions
  */

  onKeyUpOnliDecimal(event: any, object:FormControl){
    object.setValue(func.inputOnliDecimal(event.target.value));
  }

  onKeyOutOnlyNumericDecimal(event: any,object:FormControl){
    var valor:number = Number(event.target.value);
    var valorFormateado = func.formatNumber(valor);
    if( valorFormateado == "NaN" || valorFormateado == null){
      object.setValue("0.00");
    }else{
      object.setValue(valorFormateado);
    }
  }

  onKeyUpEmail(event: any, object:FormControl){
    object.setValue(func.inputEmail(event.target.value));
  }

  onKeyUpOnliNumeric(event: any, object:FormControl){
    object.setValue(func.inputOnliNumeric(event.target.value));
  }

  onKeyUpOnliText(event: any, object:FormControl){
    object.setValue(func.inputOnliText(event.target.value));
  }

  onKeyUpAplhaNumeric(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumeric(event.target.value));
  }

  onKeyUpAplhaNumericSpace(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumericSpace(event.target.value));
  }

  onKeyUpAplhaNumericSimple(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumericSimple(event.target.value));
  }

  onKeyUpAplhaNumericSpecial(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumericSpecial(event.target.value));
  }

  onKeyUpOnliTextAndSpace(event: any, object:FormControl){
    object.setValue(func.inputOnliTextAndSpace(event.target.value));
  }

  setMaxDateBirth() { 
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = (today.getFullYear()-18);
    
    this.currentData.maxDateBirth = yyyy + '-' + mm + '-' + dd ;
  }

  setMinDateBirth() { 
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = (today.getFullYear()-110);
    
    this.currentData.minDateBirth = yyyy + '-' + mm + '-' + dd ;
  }

  setMaxDateToday() { 
    var today = new Date();
    var dd = String(today.getDate()-1).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = (today.getFullYear());
    
    this.currentData.maxDateToday = yyyy + '-' + mm + '-' + dd ;
  }

}
