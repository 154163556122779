import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ark-lateralmenu-session',
  templateUrl: './lateralmenu-session.component.html',
  styleUrls: ['./lateralmenu-session.component.css']
})
export class LateralmenuSessionComponent implements OnInit {

  lateral:String = "";
  constructor(private router: Router) { }

  ngOnInit() {
    eval(" $(function() { $('[data=hide]').hide(); $('[data=show]').show(); }); ");

    
    var url = window.location.href;
    
    if(url.includes("/home")){
      this.lateral = "home";
    }else if(url.includes("/products")){
      this.lateral = "productos"
    }else if(url.includes("/todasMisInversiones")){
      this.lateral = "inversiones"
    }else if(url.includes("/todasMisInversionesAnteriores")){
      this.lateral = "inversiones"
    }else if(url.includes("/misEfectivos")){
      this.lateral = "efectivo"
    }else if(url.includes("/reportEstadoCuenta")){
      this.lateral = "micuentasab"
    }else if(url.includes("/reportPoliza")){
      this.lateral = "micuentasab"
    }else if(url.includes("/reportOrdenes")){
      this.lateral = "micuentasab"
    }else if(url.includes("/reportCertificadoRetencion")){
      this.lateral = "micuentasab"
    }
  }

  gotoPage(page: String){
    this.router.navigate([page]); 
  }

  setLatertal(page){
    //console.log("execute::setLatertal()");
    this.lateral = page;
  }
  


}
