import { HttpClient } from '@angular/common/http';
import { Component, OnInit, VERSION, Directive, ElementRef, HostListener, ViewChild, EventEmitter, Output } from '@angular/core';
import { Validators } from '@angular/forms';  //se agrego para usar el Ejemplo Formulario 1 , 2 , 3
import { FormGroup, FormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';;
import { NgxSpinnerService } from 'ngx-spinner';
import { AVATAR } from 'src/app/constants/avatar';
import { parameterTableTo } from 'src/app/model/UserModel/parameterTableTo'; 
import { countryMaping } from 'src/app/constants/countryMaping';
import { ParameterService } from 'src/app/services/ParameterServices/parameter.service';
import { ModalService } from 'src/app/configuration/providers/modal';
import { MASTER_TABLE } from 'src/app/constants/masterTable';
import { URL } from 'src/app/constants/url';
import { func } from 'src/app/constants/func';
import { errorMaping } from 'src/app/constants/errorMaping';
import { documentTypeMaping } from 'src/app/constants/documentTypeMaping';
import { terminosCondicionesMaping } from 'src/app/constants/terminosCondicionesMaping';
import { responseTerminosCondicionesTo } from 'src/app/model/UserModel/responseTerminosCondicionesTo';
import { pattern } from 'src/app/constants/pattern';
import { SimplemodalComponent } from '../../AppComponents/simplemodal/simplemodal.component';
import { ExistDataInCore } from 'src/app/model/UserModel/ExistDataInCore';
import { formatDate } from '@angular/common';
import { investorRequestRut } from 'src/app/model/UserModel/investorRequestRut';
import { MESSAGE_ES } from 'src/app/constants/message_es';



@Component({
  selector: 'ark-suscripcion',
  templateUrl: './suscripcion.component.html',
  styleUrls: ['./suscripcion.component.css']
})
export class SuscripcionComponent implements OnInit {

  // y símbolos [+, -, #, /, !, @, $, |, _ ]
  @Output() alertEvent        	= new EventEmitter<String>();
  mensaje = '';
  httpClientPost = '';
  // campos obligatorios para registro
  // nacionalidad = new FormControl('', Validators.required);
  tipoPersona = new FormControl('', Validators.required);
  tipoDocumento = new FormControl('', Validators.required);
  numeroDocumento = new FormControl('', Validators.required );
  correo = new FormControl('', [Validators.required, Validators.email]);
  correo2 = new FormControl('', [Validators.required, Validators.email]);

  tipoOperadora = new FormControl('');
  numeroCelular = new FormControl('', [Validators.required, Validators.minLength(9), Validators.maxLength(9)]);
  nombre = new FormControl('', Validators.required);
  apellidoPaterno = new FormControl('', Validators.required);
  apellidoMaterno = new FormControl('', Validators.required);
  descripcionJuridico = new FormControl('', Validators.required);
  fechaValidacion = new FormControl('', Validators.required);//formatDate(new Date(), "dd/MM/yyyy", "en")
  contrasena1 = new FormControl('', [Validators.required, Validators.minLength(8)]);
  contrasena2 = new FormControl('', [Validators.required, Validators.minLength(8)]);
  terminosCondiciones = new FormControl('');
  submitted = false;  //send parameters
  
  ID_NATURAL_PERSON = 1;
  ID_JURIDIC_PERSON = 2;

  documentSize = 0;
  /*
  selectAvatar = new FormControl('');
  avatar1 = AVATAR.AVOCADO;
  avatar2 = AVATAR.BATMAN;
  avatar3 = AVATAR.NINJA;
  avatar4 = AVATAR.COFFE_ZORRO;
  paramAvatarSelect = null;

  emailFormat = pattern.EMAIL;
  */
  showPassword1 = false;
  showPassword2 = false;

  //listCountrys : Array<parameterTableTo> = [] as Array<parameterTableTo>;
  paramTipoDocumentoView : Array<parameterTableTo> = [] as Array<parameterTableTo>;
  paramTipoOperadorView : Array<parameterTableTo> = [] as Array<parameterTableTo>;
  paramTipoPersonaView : Array<parameterTableTo> = [] as Array<parameterTableTo>;

  terminosCondicionesTo : responseTerminosCondicionesTo = {} as responseTerminosCondicionesTo;


  //regex = "^[0-9]*$";

  isPeru = null;
  @ViewChild(SimplemodalComponent,{static: false}) simplemodalComponent: SimplemodalComponent;

  constructor(private httpClient: HttpClient,
              private modalService: ModalService, 
              private parameterService: ParameterService,
              private _el: ElementRef, 
              private router: Router,
              private  spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.tipoPersona.setValue(this.ID_NATURAL_PERSON);
    this.spinner.show();  
    var variable = window.location.search.replace( "?transactionId=", "" );
    //this.paramAvatarSelect  = null;
    this.getParameterTable(); 
    this.loadTerminosCondiciones();
    //this.loadCountrys();
    this.isPeru = countryMaping.PERU; 
    this.spinner.hide();
  }

  async getParameterTable(){

    var tempParameter: parameterTableTo = {} as parameterTableTo;
    tempParameter.parameter_table_pk = this.ID_NATURAL_PERSON;
    tempParameter.description = 'NATURAL';
    this.paramTipoPersonaView.push(tempParameter);
    
    tempParameter = {} as parameterTableTo;
    tempParameter.parameter_table_pk = this.ID_JURIDIC_PERSON;
    tempParameter.description = 'JURIDICO Y PATRIMONIO AUTONOMO';
    this.paramTipoPersonaView.push(tempParameter);

    this.paramTipoDocumentoView = await this.parameterService.getParameterDocumentType(MASTER_TABLE.DOCUMENT_TYPE,this.tipoPersona.value)  as Array<parameterTableTo>;
    this.paramTipoOperadorView = await this.parameterService.getParameter(MASTER_TABLE.TELEPHONE_OPERATOR_TYPE)  as Array<parameterTableTo>;

    
   /* if(this.paramTipoDocumentoView != null && this.paramTipoDocumentoView.length > 0){
      var tmpDocumentType:Array<parameterTableTo> = [] as Array<parameterTableTo>;
      for(var document of this.paramTipoDocumentoView){  if(document.short_integer == 1){  tmpDocumentType.push(document); } }
      this.paramTipoDocumentoView = tmpDocumentType;
    }*/

  }

  async changeTipoPersona(){
    console.log(this.tipoPersona.value)
    this.paramTipoDocumentoView = await this.parameterService.getParameterDocumentType(MASTER_TABLE.DOCUMENT_TYPE,this.tipoPersona.value)  as Array<parameterTableTo>;
    if(this.tipoPersona.value === this.ID_JURIDIC_PERSON){
      
    }else{//NATURAL
      
    }
  }

/*
  async loadCountrys(){
    this.listCountrys = await this.parameterService.getGeograficCountry() as Array<parameterTableTo>;
  }
*/
  alertar(mensaje:String){
    this.alertEvent.emit(mensaje);
    return;
  }

  public onKeyUpDNI(){
    var documentNumner = this.numeroDocumento.value;
    if(documentNumner.length != 8){
      this.numeroDocumento.setValue(null);
      this.alertar("El DNI debe tener 8 caracteres.");//El DNI debe tener 8 dígitos
    }
  }

  //validatePassword:boolean = false;

  onValidatePassword(object:FormControl){
    var completePassword = object.value;

    var inArrayLetter = 0;
    for(var currentPass of func.arrayLetter()){
      for(var  i=0 ; i < completePassword.length ; i++) {
        var password = completePassword.charAt(i);  
        if(currentPass == password){
          inArrayLetter = 1;
          break;
        }

      }
    }

    var inArrayCaseLetter = 0;
    for(var currentPass of func.arrayCaseLetter()){
      for(var  i=0 ; i < completePassword.length ; i++) {
        var password = completePassword.charAt(i);  
        if(currentPass == password){
          inArrayCaseLetter = 1;
          break;
        }
      }
    }

    var inArrayNumber = 0;
    for(var currentPass of func.arrayNumber()){
      for(var  i=0 ; i < completePassword.length ; i++) {
        var password = completePassword.charAt(i);  
        if(currentPass == password){
          inArrayNumber = 1;
          break;
        }
      }
    }

    var inArraySimbole = 0;
    for(var currentPass of func.arraySimbole()){
      for(var  i=0 ; i < completePassword.length ; i++) {
        var password = completePassword.charAt(i);  
        if(currentPass == password){
          inArraySimbole = 1;
          break;
        }
      }
    }

    if(inArrayLetter == 1 && inArrayCaseLetter == 1 && inArrayNumber == 1 && inArraySimbole == 1){
      return true;
    }else{
      return false;
    }

  }

  
  public beforeSave() {

    this.submitted = true;

    let validationNatural: boolean =  (this.tipoDocumento.value    == '' || this.numeroDocumento.value == '' || this.nombre.value == '' ||
                                       this.apellidoPaterno.value  == '' || this.apellidoPaterno.value == '' || this.apellidoMaterno.value     == '' );
    let validationJuridico: boolean = (this.tipoDocumento.value    == '' || this.descripcionJuridico.value == '' );

    if (
        ( this.tipoDocumento.value == this.ID_NATURAL_PERSON && validationNatural || 
          this.tipoDocumento.value == this.ID_JURIDIC_PERSON && validationJuridico ) ||
        this.numeroDocumento.value == '' || this.correo.value == '' || this.correo2.value == '' ||
        this.tipoOperadora.value == '' || this.numeroCelular.value == '' || /*this.fechaValidacion.value == '' ||*/
        this.contrasena1.value == '' || this.contrasena2.value == ''  
      ) {//|| this.terminosCondiciones.value != true
      this.simplemodalComponent.alertar("Mensaje de Alerta",'Debe ingresar los campos obligatorios. Verifique'); 
      return;
    }
    
    if (this.contrasena1.value !== this.contrasena2.value) {
      this.mensaje = "Las contraseñas no coinciden";
      this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);
      return;
    } else if (this.correo.value !== this.correo2.value) {
      this.mensaje = "Los correos no coinciden";
      this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);
      return;
    } else if (this.terminosCondiciones.value == false) {
      this.mensaje = "Políticas de Privacidad y Términos y Condiciones obligatorios.";
      this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);
      return;
    } else {
      var pass1:boolean = this.onValidatePassword(this.contrasena1);
      var pass2:boolean =this.onValidatePassword(this.contrasena2);
      if(!pass1 || !pass2){
        this.simplemodalComponent.alertar("Mensaje de Alerta",'El password debe contener mínimo 8 carácteres incluye minúsculas, mayúsculas, números y símbolos ( ! , # $ ; ^ - + _ /  ).'); 
        return;
      }

      this.validarDatos();
      //this.validateExistInFichacliente();
    }

  }

  onReset() {
    //this.nacionalidad.setValue('');
    this.tipoDocumento.setValue('');
    this.numeroDocumento.setValue(null);
    this.correo.setValue(null);
    this.correo2.setValue(null);
    this.tipoOperadora.setValue(null);
    this.numeroCelular.setValue(null);
    this.nombre.setValue(null);
    this.apellidoPaterno.setValue(null);
    this.apellidoMaterno.setValue(null);
    this.contrasena1.setValue(null);
    this.contrasena2.setValue(null);
    this.terminosCondiciones.setValue(false);
    this.submitted = false;

    this.showPassword1 = false;
    this.showPassword2 = false;
  }

  validateExistInFichacliente(){

    let datosGuardar = {
      "email": this.correo.value,	
      "documentType": this.tipoDocumento.value,
      "documentNumber": this.numeroDocumento.value,
      "mobileNumber": this.numeroCelular.value
    }    

    this.spinner.show();
    this.httpClient.post(URL.SERVICE_VALIDATE_EXIST_DATA_IN_CORE,
             datosGuardar, { headers: func.WSHeaderTokenCore() })
            . subscribe(
          (response) => {  
              var dataInCore:ExistDataInCore = response as ExistDataInCore;

              if(dataInCore.codeNotifation == 0){
                // no existen los datos en ninguna ficha
                this.registrarSuscripcion();
              }else if(dataInCore.codeNotifation == 1){
                // todos los datos estan registrados en la ficha, la validacion sera ahora sobre el portal.
                this.registrarSuscripcion();
              }else{
                //alguno de los datos estan registrado en alguna ficha cliente
                this.mensaje = ""+dataInCore.messageNotification;
                this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);
              }

              this.spinner.hide();  
          },
          (err: HttpErrorResponse) => {
            this.spinner.hide();
            this.mensaje = "Ocurrio un error: "+err.message+".Reintente por favor";
            this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);
      },
      () => {
        this.spinner.hide();
      }
    );
 
  }

  validarDatos(){
    var body = {
      "document_type": ""+ this.tipoDocumento.value,
      "document_number": this.numeroDocumento.value,
      "email": this.correo.value,
    }

    this.httpClient.post(URL.SERVICE_API_V1_CORE_POST_INVESTOR_RUT, body , {  headers: func.WSHeaderTokenCore() })
      .subscribe(
          (response) => {  
            var rutCode: String = "";
            var fullname: String = "";
              var investorRequestRut: Array<investorRequestRut> = response as Array<investorRequestRut>;
              for(var i=0; i < investorRequestRut.length; i++){
                rutCode += (i == 0)?""+investorRequestRut[i].rut:","+investorRequestRut[i].rut;
                fullname = investorRequestRut[i].fullname;
              }
              
              if(investorRequestRut.length!=null && investorRequestRut.length>0){
                this.registrarSuscripcion();
              }else{
                this.simplemodalComponent.alertar(MESSAGE_ES.CABECERA.WARNING, "Contactese con Acres para validar sus datos");
                this.spinner.hide();
                return;
              }  
          },
          (err: HttpErrorResponse) => {
            this.simplemodalComponent.alertar(MESSAGE_ES.CABECERA.WARNING, "No esta disponible por ahora");
            this.spinner.hide();     
          },
          () => {
            
          }
      ); 
  }

  registrarSuscripcion() {

    let respuesta = 0;
    let datosGuardar = {
      "client_key": "8888-8888-8888-8888", 
      //"juridic_description": this.descripcionJuridico.value,
      "name": (this.nombre.value == null || this.nombre.value == "" )?this.descripcionJuridico.value:this.nombre.value,
      "first_lastname": this.apellidoPaterno.value,
      "second_lastname": this.apellidoMaterno.value,
      "email": this.correo.value,	
      "document_type": this.tipoDocumento.value,
      "document_number": this.numeroDocumento.value,
      "operator_company": this.tipoOperadora.value,
      //"str_date_validation": this.fechaValidacion.value,
      "phone_number": this.numeroCelular.value,
      "password": this.contrasena1.value,
      "id_terms_conditions_fk" : this.terminosCondicionesTo.id_terms_conditions_pk,
      "user_type": "1",
      "login_user":"jovalle",
      "registry_User":"jovalle",
      "status":"1",

    }    

    this.spinner.show();
    this.httpClient.post(URL.SERVICE_REGISTER_ACCOUNT,
             datosGuardar, { headers: func.WSHeader() })
            . subscribe(
          (response) => {  

              this.spinner.hide();  
              this.onReset();
              this.router.navigate(['/suscripcion-sucess']);
              
          },
          (err: HttpErrorResponse) => {

            if(err.status == errorMaping.CODE_400) {
              this.spinner.hide();
              this.mensaje = "Existe un usuario ya registrado con la información ingresada.";
              this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);
            }else {
              this.spinner.hide();
              this.mensaje = "Ocurrio un error: "+err.message;
              this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);
            }
      },
      () => {
        this.spinner.hide();
      }
    );
 
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  gotoLogin() {
    this.router.navigate(["/login"]);
  }

  gotoConfirmarSuscripcion() {
    this.router.navigate(["/confirmarSuscripcion"]);
  }

  toggleShowPassword(prm : number) {
    if(prm == 1){

      this.showPassword1 = !this.showPassword1;

    }else {
      this.showPassword2 = !this.showPassword2;

    }
  }

  gotoPage(page: String){
    this.router.navigate(["/login"]);
  } 

  changeDocument(){    
    this.numeroDocumento = new FormControl('');
    //this.setLongImputDocType();   
  }
  /*
  setLongImputDocType(){
    this.documentSize = 0;
    if(this.tipoDocumento.value != null && this.tipoDocumento.value == documentTypeMaping.DNI)
      this.documentSize = 8;
    else if(this.tipoDocumento.value != null && this.tipoDocumento.value == documentTypeMaping.CARNET_EXTRANJERIA)
      this.documentSize = 14;
    else if(this.tipoDocumento.value != null && this.tipoDocumento.value == documentTypeMaping.PASAPORTE)
      this.documentSize = 14;
  }
  */

  onKeyDocumentNumber(event: any){  
    if(this.tipoDocumento.value == documentTypeMaping.DNI)
       this.numeroDocumento.setValue(func.validateImputDni(event.target.value));
    else if(this.tipoDocumento.value == documentTypeMaping.CARNET_EXTRANJERIA)
       this.numeroDocumento.setValue(func.validateCarnetYPasaporte(event.target.value)); 
    else if(this.tipoDocumento.value == documentTypeMaping.PASAPORTE)
       this.numeroDocumento.setValue(func.validateCarnetYPasaporte(event.target.value)); 
  }

  gotoTerminosCondiciones(){
    
   console.log(document.location.origin);
   sessionStorage.setItem('terminoCondicionesType', JSON.stringify(terminosCondicionesMaping.SUSCRIPCION));
   this.router.navigate([]).then(result => {  window.open(document.location.origin+'/terminos', '_blank'); });

  }

  
  loadTerminosCondiciones(){

    this.spinner.show();
      this.httpClient.get(URL.SERVICE_API_TERMS_CONDITIONS+'1345',
            { headers: func.WSHeader() })
          . subscribe(
          (response) => {  
  
            let terminos = <responseTerminosCondicionesTo>response;    
            
            
            if( terminos    == null ||  
                terminos.id_terms_conditions_pk == null ){  

                this.simplemodalComponent.alertar("Mensaje de Alerta","No existen terminos y condiciones en estado activo");
                this.gotoLogin();
                return; 
            }else{ 
              
              this.terminosCondicionesTo = terminos;
            }
           
          },
          (err: HttpErrorResponse) => {
            
              this.spinner.hide();
              return ;
          },
          () => {
            this.spinner.hide();
          }
      );      

  } 

  
  /*
      generic functions
  */
  /*
  onKeyUpDNI(event: any, object:FormControl){
    var documentNumner = event.target.value;
    if(documentNumner.length < 8){
      object.setValue("");
    }
  }*/

  onKeyUpOnliDecimal(event: any, object:FormControl){
    object.setValue(func.inputOnliDecimal(event.target.value));
  }

  onKeyOutOnlyNumericDecimal(event: any,object:FormControl){
    var valor:number = Number(event.target.value);
    var valorFormateado = func.formatNumber(valor);
    if( valorFormateado == "NaN" || valorFormateado == null){
      object.setValue("0.00");
    }else{
      object.setValue(valorFormateado);
    }
  }

  
  onKeyUpPassword(event: any, object:FormControl){
    object.setValue(func.inputBlurPassword(event.target.value));
  }
  
  onKeyUpEmail(event: any, object:FormControl){
    object.setValue(func.inputEmail(event.target.value));
  }

  onKeyUpOnliNumeric(event: any, object:FormControl){
    object.setValue(func.inputOnliNumeric(event.target.value));
  }

  onKeyUpOnliText(event: any, object:FormControl){
    object.setValue(func.inputOnliText(event.target.value));
  }

  onKeyUpAplhaNumeric(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumeric(event.target.value));
  }

  onKeyUpAplhaNumericSpace(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumericSpace(event.target.value));
  }

  onKeyUpAplhaNumericSimple(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumericSimple(event.target.value));
  }

  onKeyUpAplhaNumericSpecial(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumericSpecial(event.target.value));
  }

  onKeyUpOnliTextAndSpace(event: any, object:FormControl){
    object.setValue(func.inputOnliTextAndSpace(event.target.value));
  }



}
