import { Component, OnInit, ViewChild } from '@angular/core'; 
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http'; 
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { ModalService } from 'src/app/configuration/providers/modal';
import { ProductoFinancieroTo } from 'src/app/model/UserModel/ProductoFinancieroTo';
import { userTo } from 'src/app/model/UserModel/userTo';
import { func } from 'src/app/constants/func';
import { URL } from 'src/app/constants/url';
import { responseProductsTo } from 'src/app/model/UserModel/responseProductsTo';
import { FormControl } from '@angular/forms';
import { BOOL_TYPE } from '@angular/compiler/src/output/output_ast';
import { isBoolean } from 'util';
import { SimplemodalComponent } from '../../AppComponents/simplemodal/simplemodal.component';
import { DomSanitizer } from '@angular/platform-browser';
//import { DatoTestTo } from 'src/app/portalTo/DatoTestTo';


@Component({
  selector: 'ark-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.css']
})
export class ProductoComponent implements OnInit {

existeData = false;
menuOrigin = null;

vigentes = false;

@ViewChild(SimplemodalComponent,{static: false}) simplemodalComponent: SimplemodalComponent;


constructor(private httpClient: HttpClient,
            private loginService: LoginService,
            private router: Router,
            private  spinner: NgxSpinnerService, 
            private modalService: ModalService, private domSanitizer: DomSanitizer) { } 

  listProductoTopThree : Array<ProductoFinancieroTo> = [] as Array<ProductoFinancieroTo>;
  listProductoAll : Array<ProductoFinancieroTo> = [] as Array<ProductoFinancieroTo>;
  
  listRspuesta: Array<Object> = [] as Array<ProductoFinancieroTo>;
  mensaje = '';
  filterprm = new FormControl('');
  top3DisponibleBool = false;
  TOP3 = 3;

  productMessage="Buscando productos de inversión para usted ...";

  ngOnInit() {  
    let objUserTo = <userTo>this.loginService.currentUser(); 
    /*this.httpClient.get(URL.SERVICE_API_V1_USER + objUserTo.id_user_pk + URL.SERVICE_LOAD_PRODUCTS_END,*/

    this.top3DisponibleBool = true;
    this.loadProductTop(true); 
  }

  gotoHome(){
    this.router.navigate(["/home"]);
  }

  public closeModal(id: string) {
    this.modalService.close(id);
 }

 filtrarPorService(){
    this.loadProductAll(!this.top3DisponibleBool);
 } 

 safeImagen(imagenBase64){ 
  return <String>this.domSanitizer.bypassSecurityTrustUrl(imagenBase64) ;
}

 loadProductTop(top3DisponibleBool: boolean){ 

      this.top3DisponibleBool = !top3DisponibleBool;

      var body = {
        "filterprm" : null,
        "top3" : false//top3DisponibleBool
      }
      
          this.spinner.show();
          this.httpClient.post(URL.SERVICE_API_V1_CORE_GET_VIEW_ALL_PRODUCTS,
                              body,
                    { headers: func.WSHeaderTokenCore() })
              . subscribe(
              (response) => { 

              let productos = <responseProductsTo>response; 
              let productosVigentes:Array<ProductoFinancieroTo> = [];
    
              if(productos != null){
                var img:String = "";
                for(var i = 0; i< productos.items.length; i++){
                  //img = func.getImgWithIssuerCode(productos.items[i].issuerCode);
                  //productos.items[i].imgbase64 = "data:image/jpeg;base64,"+img;//

                  if(productos.items[i].labelProduct=="DISPONIBLE" || productos.items[i].labelProduct=="PROXIMAMENTE"){
                    productosVigentes.push(productos.items[i])
                  }
                }

                this.existeData = true;
                
                  //productos.menuOrigin = this.menuOrigin;
                  let lstAux = productosVigentes;//productos.items; 
                  this.listProductoTopThree    = [] as Array<ProductoFinancieroTo>; 

                  if(lstAux.length > 0){
                      let positionIsCenter = 0;
                      
                      let just3 = 0;
                      for (var i = 0; i < lstAux.length; i++) {
                          // despues del 2do  cada 3 posiciones deben tener el positionCenter = 1 
                          
                          let prod = lstAux[i];

                          // logica para ordernar el 2do producto en alineacion mas abajo que el 1ro y 3ro
                          prod.position = i;
                          prod.positionCenter = 0;

                          if(i == 0){
                            this.listProductoTopThree.push(prod);
                          }
                          if(i == 1){
                            prod.positionCenter = 1;
                            this.listProductoTopThree.push(prod);
                          }
                          if(i > 1){
                            positionIsCenter = positionIsCenter + 1;
                            if(positionIsCenter == 3){
                              prod.positionCenter = 1;
                              positionIsCenter = 0;
                            }
                            this.listProductoTopThree.push(prod);
                          }
                          // fin logica

                          just3 = just3 + 1;
                          if(just3 == this.TOP3){
                            break;
                          }

                      }
                  }

                  if(this.listProductoTopThree.length == 0){
                    this.productMessage = "AÚN NO HAY PRODUCTOS DISPONIBLES";
                  }

              }else{
                this.existeData = false;
              }
              
              },
              (err: HttpErrorResponse) => {
                /*console.log(err.error);
                console.log(err.name);
                console.log(err.message);
                console.log(err.status);
                */
                this.spinner.hide();
                //this.mensaje = "Ocurrio un error \n " + err.message;
                //this.modalService.open('idModalErrorfull');
                
                this.productMessage = "Aún no hay productos disponibles";
            },
            () => {
              this.spinner.hide();
              console.log("************* this.httpClient.post ()::" + "The confirmar registro de suscripcion POST observable is now completed.");
            }
        );
  
  }

 
 loadProductAll(top3DisponibleBool: boolean){
  

  this.listProductoAll = [] as Array<ProductoFinancieroTo>;

  this.top3DisponibleBool = !top3DisponibleBool;

  var body = {
    "filterprm" : this.filterprm.value != '' ? this.filterprm.value : null,
    "top3" : top3DisponibleBool
  }
  
// URL.SERVICE_API_V1_CORE_GET_VIEW_ALL_PRODUCTS
      // 
      // "https://cors-anywhere.herokuapp.com/http://52.89.179.248:8080/iBusinessIntegration/resources/external/portal/getViewAllProducts"

      this.spinner.show();
      this.httpClient.post(URL.SERVICE_API_V1_CORE_GET_VIEW_ALL_PRODUCTS,
                           body,
                { headers: func.WSHeaderTokenCore() })
          . subscribe(
          (response) => { 

          console.log("datos" + JSON.stringify(response));
          let productos = <responseProductsTo>response; 

              if(productos != null){
                var img:String = "";
                for(var i = 0; i< productos.items.length; i++){
                  img = func.getImgWithIssuerCode(productos.items[i].issuerCode);
                  productos.items[i].imgbase64 = "data:image/jpeg;base64,"+img;//
                }

                this.existeData = true;  
                this.listProductoAll = productos.items;  
              }else{
                this.existeData = false;
              }
          
          },
          (err: HttpErrorResponse) => {
            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);
            
            this.spinner.hide();
            //this.mensaje = "Ocurrio un error \n " + err.message;
            //this.modalService.open('idModalErrorfull');
            
        },
        () => {
          this.spinner.hide();
          console.log("************* this.httpClient.post ()::" + "The confirmar registro de suscripcion POST observable is now completed.");
        }
    );   
  }

 
  gotoPage(page: String){
    this.router.navigate([page]); 
  }
  
  openModalInfo(){ 
    //this.modalService.open('idInfo');  
    this.simplemodalComponent.openModalInfo();
  }/*

  closeModalInfo(file_code: String){ 
    this.modalService.close('idInfo');
  }

  openTerminos(){
    this.modalService.open('idTerminos');  
  }

  closeTerminos(file_code: String){ 
    this.modalService.close('idTerminos');
  }
 */
}