import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { step2 } from 'src/app/model/UserModel/step2';
import { FormControl, FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { func } from 'src/app/constants/func';
import { ParameterService } from 'src/app/services/ParameterServices/parameter.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PARAMETER_TABLE_YES_NO } from 'src/app/constants/PARAMETER_TABLE_YES_NO';
import { countryMaping } from 'src/app/constants/countryMaping';
import { InvestorRequestSurveyToReturn } from 'src/app/model/UserModel/InvestorRequestSurveyToReturn';
import { HttpClient } from '@angular/common/http';
import { surveyTo } from 'src/app/model/UserModel/surveyTo';
import { InvReqPersonReturn } from 'src/app/model/UserModel/InvReqPersonReturn';

@Component({
  selector: 'ark-datosperfilriesgo',
  templateUrl: './datosperfilriesgo.component.html',
  styleUrls: ['./datosperfilriesgo.component.css']
})
export class DatosperfilriesgoComponent implements OnInit {

  @Input()  inputData:step2   	= {} as step2;
  @Output() outputDataEvent     = new EventEmitter<step2>();
  @Output() alertEvent        	= new EventEmitter<String>();
  @Output() modalEvent          = new EventEmitter<String>();
  
  
  perfilRiesgoForm :FormGroup;
  currentData:step2   = {} as step2;
  
  investment_other          ;
  equities_investment       ;
  fixed_rent_investment     ;
  local_market_investment   ;
  foreign_market_investment ;

  scoreTotal = 0;
  submitQuestion:boolean = false;
  check_tipoInversionRealizar = true;

  constructor(private formBuilder: FormBuilder, private  spinner: NgxSpinnerService,private parameterService: ParameterService,private httpClient: HttpClient) { }

  ngOnInit() {
    this.initVariables();
    this.loadParameters();
    this.setDefaultData();
    this.initForm();
    this.initActionsPostForm();
  }

  async initVariables(){
    this.currentData                            = this.inputData;

    if(this.currentData.entity_InvReqPerson == null ){
      this.currentData.entity_InvReqPerson        = {} as InvReqPersonReturn;
    }
    if(this.currentData.allInvestorRequestSurvey == null ){
      this.currentData.allInvestorRequestSurvey   = [] as Array<InvestorRequestSurveyToReturn>;
    }
    if(this.currentData.allSurvey == null){
      this.currentData.allSurvey                  = [] as Array<surveyTo>;
      //this.currentData.allSurvey                  = await this.parameterService.getAllSurvey(1) as Array<surveyTo>;  //obtener del core                 

    }
  }
  
  loadParameters(){
  }

  setDefaultData(){
    if(this.currentData.action == null || this.currentData.action == 'R'){
      
    }else{//M
      
    }
  }
  
  nullObject(object){
    return (object == null)?'':object;
  }

  initForm(){
    this.perfilRiesgoForm = this.formBuilder.group({
      radioSelected: this.formBuilder.array([])
    });
    this.investment_other          = new FormControl(this.currentData.entity_InvReqPerson.investmentOther);
    this.equities_investment       = new FormControl(this.currentData.entity_InvReqPerson.equitiesInvestment);
    this.fixed_rent_investment     = new FormControl(this.currentData.entity_InvReqPerson.fixedRentInvestment);
    this.local_market_investment   = new FormControl(this.currentData.entity_InvReqPerson.localMarketInvestment);
    this.foreign_market_investment = new FormControl(this.currentData.entity_InvReqPerson.foreignMarketInvestment);
  }

  initActionsPostForm(){
    this.getSurveyLoad();
    this.tipoInversionRealizarLoad();

    if(this.currentData.action == null || this.currentData.action == 'M' && !this.currentData.enabledForm){
        this.disabled_inputs_registrar_ficha();
    }
  }

  disabled_inputs_registrar_ficha(){
    eval('$(function(){$("#newInvestorDiv :input").prop("disabled", true); });');
    eval('$(function(){$(".navigateButton").prop("disabled", false); });');
  }
  
  enabled_inputs_registrar_ficha(){
    this.currentData.enabledForm = true;
    eval('$(function(){$("#newInvestorDiv :input").prop("disabled", false); });');
  }

  isSucessDatosPerfilRiesgo(){
   if(this.currentData.allInvestorRequestSurvey != null && 
      this.currentData.allInvestorRequestSurvey.length > 0 && 
      this.check_tipoInversionRealizar){
       for(var i=0; i< this.currentData.allInvestorRequestSurvey.length; i++){
         if(this.currentData.allInvestorRequestSurvey[i].score == null){  return false;  }
       }
       return true;
   }else{
     return false;
   }

  }

  saveSetp2()
  {
    this.submitQuestion = true;
    this.validateTipodeInvestionRealizar();

    if(this.isSucessDatosPerfilRiesgo()){
      this.setDataSetp2();
    }else{
      this.alertar("Completar los datos obligatorios. Verifique por favor.");
      return;
    }
  }

  setDataSetp2(){
    this.setDataPerfilRiesgo();
    this.inputData = this.currentData;
    this.outputDataEvent.emit(this.inputData);
  }

  setDataPerfilRiesgo(){
    this.currentData.entity_InvReqPerson.investmentOther          = this.investment_other.value;
    this.currentData.entity_InvReqPerson.equitiesInvestment       = this.equities_investment.value;
    this.currentData.entity_InvReqPerson.fixedRentInvestment      = this.fixed_rent_investment.value;
    this.currentData.entity_InvReqPerson.localMarketInvestment    = this.local_market_investment.value;
    this.currentData.entity_InvReqPerson.foreignMarketInvestment  = this.foreign_market_investment.value;
  }

  alertar(mensaje:String){
    this.alertEvent.emit(mensaje);
    return;
  }

  openModal(mensaje:String){
    this.modalEvent.emit(mensaje);
    return;
  }

  
  /*
      form functions
  */
  async getSurveyLoad(){
    
    if(this.currentData.allInvestorRequestSurvey != null && this.currentData.allInvestorRequestSurvey.length > 0){

        for(var i=0; i < this.currentData.allInvestorRequestSurvey.length; i++){
          this.radioSelected.push(this.formBuilder.control(this.currentData.allInvestorRequestSurvey[i].idSurveyDetailFk));
          this.clickSurveyDetail(this.currentData.allInvestorRequestSurvey[i].idSurveyDetailFk, 
                                  this.currentData.allInvestorRequestSurvey[i].idSurveyFk, 
                                  this.currentData.allInvestorRequestSurvey[i].score);
          var miFunction ="$(function() { ";
              miFunction += "$('input[name="+this.currentData.allInvestorRequestSurvey[i].idSurveyFk+"][ng-reflect-value="+this.currentData.allInvestorRequestSurvey[i].idSurveyDetailFk+"]').prop('checked', 'checked'); ";
              miFunction += "});";
          eval(miFunction);
        }
    }else{

      var objInvestorRequestSurveyTo:InvestorRequestSurveyToReturn = {} as InvestorRequestSurveyToReturn;
      this.currentData.allInvestorRequestSurvey = [] as Array<InvestorRequestSurveyToReturn>;

      for(var i=0; i < this.currentData.allSurvey.length; i++){
        objInvestorRequestSurveyTo = {} as InvestorRequestSurveyToReturn;
        objInvestorRequestSurveyTo.idSurveyFk = this.currentData.allSurvey[i].id_survey_pk;
        this.currentData.allInvestorRequestSurvey.push(objInvestorRequestSurveyTo);
        this.radioSelected.push(this.formBuilder.control(null));
      }
    }
    
  }

  tipoInversionRealizarLoad(){

    var miFunction = "$(function() { ";
    if(this.equities_investment.value == null || this.equities_investment.value==0){
      this.equities_investment.setValue(0);
      miFunction += " $('input[name=ti_renta_variable0]').prop('checked', ''); ";
    }else{
      this.equities_investment.setValue(1);
      miFunction += " $('input[name=ti_renta_variable0]').prop('checked', 'checked'); ";
    }
    if(this.fixed_rent_investment.value == null || this.fixed_rent_investment.value==0){
      this.fixed_rent_investment.setValue(0);
      miFunction += " $('input[name=ti_renta_variable1]').prop('checked', ''); ";
    }else{
      this.fixed_rent_investment.setValue(1);
      miFunction += " $('input[name=ti_renta_variable1]').prop('checked', 'checked'); ";
    }
    if(this.local_market_investment.value == null || this.local_market_investment.value==0){
      this.local_market_investment.setValue(0);
      miFunction += " $('input[name=ti_renta_variable2]').prop('checked', ''); ";
    }else{
      this.local_market_investment.setValue(1);
      miFunction += " $('input[name=ti_renta_variable2]').prop('checked', 'checked'); ";
    }
    if(this.foreign_market_investment.value == null || this.foreign_market_investment.value==0){
      this.foreign_market_investment.setValue(0);
      miFunction += " $('input[name=ti_renta_variable3]').prop('checked', ''); ";
    }else{
      this.foreign_market_investment.setValue(1);
      miFunction += " $('input[name=ti_renta_variable3]').prop('checked', 'checked'); ";
    }
    miFunction += " });";
    eval(miFunction);
    
  }

  tipoInversionRealizarChecked(posicion){
    
    var miFunction = "";
    
    if(posicion!=null && posicion==0){
      if(this.equities_investment.value == null || this.equities_investment.value==0){
        this.equities_investment.setValue(1);
        miFunction = "$(function() { $('input[name=ti_renta_variable0]').prop('checked', 'checked'); });";
      }else{
        this.equities_investment.setValue(0);
        miFunction = "$(function() { $('input[name=ti_renta_variable0]').prop('checked', ''); });";
      }
      eval(miFunction);
    }

    if(posicion!=null && posicion==1){
      if(this.fixed_rent_investment.value == null || this.fixed_rent_investment.value==0){
        this.fixed_rent_investment.setValue(1);
        miFunction = "$(function() { $('input[name=ti_renta_variable1]').prop('checked', 'checked'); });";
      }else{
        this.fixed_rent_investment.setValue(0);
        miFunction = "$(function() { $('input[name=ti_renta_variable1]').prop('checked', ''); });";
      }
      eval(miFunction);
    }

    if(posicion!=null && posicion==2){
      if(this.local_market_investment.value == null || this.local_market_investment.value==0){
        this.local_market_investment.setValue(1);
        miFunction = "$(function() { $('input[name=ti_renta_variable2]').prop('checked', 'checked'); });";
      }else{
        this.local_market_investment.setValue(0);
        miFunction = "$(function() { $('input[name=ti_renta_variable2]').prop('checked', ''); });";
      }
      eval(miFunction);
    }

    if(posicion!=null && posicion==3){
      if(this.foreign_market_investment.value == null || this.foreign_market_investment.value==0){
        this.foreign_market_investment.setValue(1);
        miFunction = "$(function() { $('input[name=ti_renta_variable3]').prop('checked', 'checked'); });";
      }else{
        this.foreign_market_investment.setValue(0);
        miFunction = "$(function() { $('input[name=ti_renta_variable3]').prop('checked', ''); });";
      }
      eval(miFunction);
    }

  }

  validateTipodeInvestionRealizar(){
    if( (this.equities_investment.value == 0 || this.equities_investment.value == null)   &&
        (this.fixed_rent_investment.value==0 || this.fixed_rent_investment.value == null) &&
        (this.local_market_investment.value==0 || this.local_market_investment.value == null) && 
        (this.foreign_market_investment.value==0 || this.foreign_market_investment.value == null)
    ){
      this.check_tipoInversionRealizar = false;
    }else{
      this.check_tipoInversionRealizar = true;
    }
  }

  get radioSelected() {
    return this.perfilRiesgoForm.get('radioSelected') as FormArray;
  }


  clickSurveyDetail(id_survey_detail_pk:number, id_survey_pk:number, score:number){

    for(var i=0; i< this.currentData.allSurvey.length; i++){
      if( this.currentData.allSurvey[i].id_survey_pk == id_survey_pk ){
        this.currentData.allSurvey[i].tmpScore = score;
        for(var detail of this.currentData.allSurvey[i].survey_details){
          if(detail.id_survey_detail_pk == id_survey_detail_pk){
            detail.style = 'selected';
          }else{
            detail.style = '';
          }
        }
      }
    }
    
    this.scoreTotal = 0;
    for(var i=0; i< this.currentData.allInvestorRequestSurvey.length; i++){
      if(this.currentData.allInvestorRequestSurvey[i].idSurveyFk == id_survey_pk ){
        this.currentData.allInvestorRequestSurvey[i].idSurveyDetailFk = id_survey_detail_pk;
        this.currentData.allInvestorRequestSurvey[i].score = score;
      }
      if(this.currentData.allInvestorRequestSurvey[i].score != null){
        this.scoreTotal += this.currentData.allInvestorRequestSurvey[i].score;
      }
    }

  }

  /*
      public constants
  */

  get yes() { return PARAMETER_TABLE_YES_NO.YES.ID; }
  get no()  { return PARAMETER_TABLE_YES_NO.NO.ID; }
  get localCountry(){ return countryMaping.PERU;}

  /*
      generic functions
  */

  existInList(lista:Array<number>, id:number){
    for(var current of lista){
      if(current == id){
        return true;
      }
    }
    return false;
  }

  onKeyUpDNI(event: any, object:FormControl){
    var documentNumner = event.target.value;
    if(documentNumner.length != 8){
      object.setValue("");
      this.alertar("El DNI debe tener 8 caracteres.");//El DNI debe tener 8 dígitos
    }
  }

  onKeyUpOnliDecimal(event: any, object:FormControl){
    object.setValue(func.inputOnliDecimal(event.target.value));
  }

  onKeyOutOnlyNumericDecimal(event: any,object:FormControl){
    var valor:number = Number(event.target.value);
    var valorFormateado = func.formatNumber(valor);
    if( valorFormateado == "NaN" || valorFormateado == null){
      object.setValue("0.00");
    }else{
      object.setValue(valorFormateado);
    }
  }

  onKeyUpEmail(event: any, object:FormControl){
    object.setValue(func.inputEmail(event.target.value));
  }

  onKeyUpOnliNumeric(event: any, object:FormControl){
    object.setValue(func.inputOnliNumeric(event.target.value));
  }

  onKeyUpOnliText(event: any, object:FormControl){
    object.setValue(func.inputOnliText(event.target.value));
  }

  onKeyUpAplhaNumeric(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumeric(event.target.value));
  }

  onKeyUpAplhaNumericSpace(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumericSpace(event.target.value));
  }

  onKeyUpAplhaNumericSimple(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumericSimple(event.target.value));
  }

  onKeyUpAplhaNumericSpecial(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumericSpecial(event.target.value));
  }

  onKeyUpOnliTextAndSpace(event: any, object:FormControl){
    object.setValue(func.inputOnliTextAndSpace(event.target.value));
  }

  setMaxDateBirth() { 
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = (today.getFullYear()-18);
    
    this.currentData.maxDateBirth = yyyy + '-' + mm + '-' + dd ;
  }

  setMinDateBirth() { 
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = (today.getFullYear()-110);
    
    this.currentData.minDateBirth = yyyy + '-' + mm + '-' + dd ;
  }

  setMaxDateToday() { 
    var today = new Date();
    var dd = String(today.getDate()-1).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = (today.getFullYear());
    
    this.currentData.maxDateToday = yyyy + '-' + mm + '-' + dd ;
  }

}
