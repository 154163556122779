import { Component, OnInit, ViewChild } from '@angular/core'; 
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { ModalService } from 'src/app/configuration/providers/modal';
import { investmentTo } from 'src/app/model/UserModel/investmentTo';
import { userTo } from 'src/app/model/UserModel/userTo';
import { URL } from 'src/app/constants/url';
import { CURRENCY } from 'src/app/constants/CURRENCY';
import { func } from 'src/app/constants/func';
import { responseInvesmentTo } from 'src/app/model/UserModel/responseInvesmentTo';
import { SimplemodalComponent } from '../../AppComponents/simplemodal/simplemodal.component';

@Component({
  selector: 'ark-mis-inversiones-anteriores',
  templateUrl: './todas-mis-inversiones-anteriores.component.html',
  styleUrls: ['./todas-mis-inversiones-anteriores.component.css']
})
export class TodasMisInversionesAnterioresComponent implements OnInit {

  title = 'Mis inversiones en Curso';
  selectSoles = false;
  selectDolares = false;

  DEFAULT_ZERO = 0.00;

  totalSoles = this.DEFAULT_ZERO;
  totalDolares = this.DEFAULT_ZERO;
  totalSolesStr = this.DEFAULT_ZERO;
  totalDolaresStr = this.DEFAULT_ZERO;

  monedaSelect = 0;
  claseSeleccionSoles = 'btn-inversiones-unselected';
  claseSeleccionDolares = 'btn-inversiones-unselected';

 // noSoles   = false;
 // noDolares = false;

  objUserTo = null;
  mensaje = '';
  @ViewChild(SimplemodalComponent,{static: false}) simplemodalComponent: SimplemodalComponent;

  constructor(private httpClient: HttpClient, private modalService: ModalService,
              private loginService: LoginService, private router: Router,
              private  spinner: NgxSpinnerService) { } 

  //listinvestmentToDolares : Array<investmentTo> = [] as Array<investmentTo>;
  //listinvestmentToSoles : Array<investmentTo> = [] as Array<investmentTo>;
  listInvestmentToAll : Array<investmentTo> = [] as Array<investmentTo>;

  ngOnInit() { 
      this.objUserTo = <userTo>this.loginService.currentUser();
      this.loadInversionesDolare();
      this.loadInversionesSoles();
      this.listInvestmentToAll = [] as Array<investmentTo>;
    }

    loadInversionesDolare(){
      this.totalDolares =  this.DEFAULT_ZERO; 
    //  this.noDolares = false;

      this.spinner.show();

      // "https://cors-anywhere.herokuapp.com/http://52.89.179.248:8080/iBusinessIntegration/resources/external/portal/getViewAllInvestments"
  
      // URL.SERVICE_API_V1_CORE_GET_VIEW_ALL_INVESTMENTS;
      let urlDol = URL.SERVICE_API_V1_CORE_GET_VIEW_ALL_INVESTMENTS;
      let objUserTo = <userTo>this.loginService.currentUser();    
      var body = {
        "rut": objUserTo.rut,
        "currency": CURRENCY.DOLARES.ID,
      }
      //this.objUserTo.id_user_pk
      //SERVICE_API_V1_CORE_GET_VIEW_ALL_INVESTMENTS

      this.httpClient.post(urlDol , body, { headers: func.WSHeaderTokenCore() })
            . subscribe(
            (response) => {     
              this.spinner.hide();
              //console.log("urlDol:: "+JSON.stringify(response));
              let inversiones = <investmentTo>response;  
              for (var i = 0; i < Object.values(inversiones).length; i++) {
                this.totalDolares = this.totalDolares + inversiones[i].investmentAmount;//investment_amount
                
                if( ( inversiones[i].investmentAmount == null || inversiones[i].investmentAmount == 0 ) ){
                  this.listInvestmentToAll.push(inversiones[i]);  
                }
                
              } 
       
              this.totalDolaresStr = func.formatNumber(this.totalDolares);
              
            },
            (err: HttpErrorResponse) => {
    
              this.spinner.hide();
    
            },
            () => {
              this.spinner.hide();
              console.log("************* this.httpClient.post ()::" + "The  is now completed.");
            }
        );   

      
  }

  loadInversionesSoles(){
    this.totalSoles = this.DEFAULT_ZERO; 
  //  this.noSoles = false;

    this.spinner.show();

    // "https://cors-anywhere.herokuapp.com/http://52.89.179.248:8080/iBusinessIntegration/resources/external/portal/getViewAllInvestments"
    // URL.SERVICE_API_V1_CORE_GET_VIEW_ALL_INVESTMENTS;
    let urlSol = URL.SERVICE_API_V1_CORE_GET_VIEW_ALL_INVESTMENTS
    let objUserTo = <userTo>this.loginService.currentUser();    
      var body = {
        "rut": objUserTo.rut,
        "currency": CURRENCY.SOLES.ID,
      }

    this.httpClient.post(urlSol, body , { headers: func.WSHeaderTokenCore() })
          . subscribe(
          (response) => {     
            this.spinner.hide();
            //console.log("urlSol:: "+JSON.stringify(response));
              let inversiones = <responseInvesmentTo>response;  
              for (var i = 0; i < Object.values(inversiones).length; i++) {
                this.totalSoles = this.totalSoles + inversiones[i].investmentAmount;//investment_amount
                //this.listInvestmentToAll.push(inversiones[i]); 
                
                
                if( ( inversiones[i].investmentAmount == null || inversiones[i].investmentAmount == 0 ) ){
                //if( !( inversiones[i].strClosingDate ==null || Date.parse(inversiones[i].strClosingDate) <= Date.parse(func.currentDate()) ) ){
                  this.listInvestmentToAll.push(inversiones[i]);  
                }

              } 
              this.totalSolesStr = func.formatNumber(this.totalSoles);
          },
          (err: HttpErrorResponse) => {
    
            this.spinner.hide();
  
          },
          () => {
            this.spinner.hide();
            console.log("************* this.httpClient.post ()::" + "The  is now completed.");
          }
    ); 
    
  } 

  gotoPage(page: String){
    this.router.navigate([page]);
  } 
  
  showInversionesAnteriores(){
    this.router.navigate(['/inversionesAnteriores']); 
  }

  showDetail(idSecurityCodePk: String){
    sessionStorage.setItem('invIdSecurityCodePk',JSON.stringify(idSecurityCodePk));
    this.router.navigate(['/detalleInversion']);    
  }

  gotoPageDetCronograma(idSecurityCodePk: String){
    sessionStorage.setItem('invIdSecurityCodePk',JSON.stringify(idSecurityCodePk));
    this.router.navigate(['/detalleCronograma']);
  }

  gotoPageDetMovimiento(idSecurityCodePk: String){
    sessionStorage.setItem('invIdSecurityCodePk',JSON.stringify(idSecurityCodePk));
    this.router.navigate(['/detalleMovimiento']);
  }

  formatQuantity(number){
    return func.formatQuantity(number);
  }

  formatNumber(number){
    return func.formatNumber(number);
  }

  openTerminos(){
    //this.modalService.open('idTerminos');  
    this.simplemodalComponent.openModalInfo();
  }
/*
  closeTerminos(file_code: String){ 
    this.modalService.close('idTerminos');
  }*/


}