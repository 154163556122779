import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core'; 
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router'; 
import { terminosCondicionesTo } from 'src/app/model/UserModel/terminosCondicionesTo';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { ModalService } from 'src/app/configuration/providers/modal';
import { URL } from 'src/app/constants/url';
import { func } from 'src/app/constants/func';



@Component({
  selector: 'ark-terminos',
  templateUrl: './terminos.component.html',
  styleUrls: ['./terminos.component.css']
})
export class TerminosComponent implements OnInit {

 
  description = '';
  terminosCondicionesTo : terminosCondicionesTo = {} as terminosCondicionesTo;
    
 
  constructor(private httpClient: HttpClient,
              private loginService: LoginService,
              private router: Router,
              private  spinner: NgxSpinnerService,
              private modalService: ModalService) { }
  
  ngOnInit() {    

  }

  wservices(){
     
    this.spinner.show();

    let termino = JSON.parse(sessionStorage.getItem('terminoCondicionesType'));
    this.httpClient.get(URL.SERVICE_API_TERMS_CONDITIONS + termino,{ headers: func.WSHeader() })
    . subscribe(
        (response) => {  
          let terminos = <terminosCondicionesTo>response;    
          this.terminosCondicionesTo.description = terminos.description;
        },
        (err: HttpErrorResponse) => {
            this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
    );   
  }
 

  gotoPage(page: String){
    this.router.navigate([page]);
  } 

}
