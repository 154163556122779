import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductoDetalleTo } from 'src/app/model/UserModel/ProductoDetalleTo';
import { ProductoFinancieroTo } from 'src/app/model/UserModel/ProductoFinancieroTo';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { produtOriginMaping } from 'src/app/constants/produtOriginMaping';
import { userTo } from 'src/app/model/UserModel/userTo';
import { URL } from 'src/app/constants/url';
import { func } from 'src/app/constants/func';
import { responseDocumentsProductTo } from 'src/app/model/UserModel/responseDocumentsProductTo';
import { documentsProductTo } from 'src/app/model/UserModel/documentsProductTo';
import { DomSanitizer } from '@angular/platform-browser'; 
import { pagoCapitalTo } from 'src/app/model/UserModel/pagoCapitalTo'; 
import { ModalService } from 'src/app/configuration/providers/modal';
import { SimplemodalComponent } from '../../AppComponents/simplemodal/simplemodal.component';
 


@Component({
  selector: 'ark-producto-informacion',
  templateUrl: './producto-informacion.component.html',
  styleUrls: ['./producto-informacion.component.css']
})
export class ProductoInformacionComponent implements OnInit {

  objUserTo = null;
  id_security_code_pk = null;
  entityProductoDetalleTo : ProductoDetalleTo = {} as ProductoDetalleTo; 
  @ViewChild(SimplemodalComponent,{static: false}) simplemodalComponent: SimplemodalComponent;

  productPrm : ProductoFinancieroTo = {} as ProductoFinancieroTo;
  menuOrigin : null
  productoBool  = false;
  inversionBool = false;
  selectTab : number;

  lstScheduleProductsInterest : Array<pagoCapitalTo> = [] as Array<pagoCapitalTo>;
  lstScheduleProductsCapital  : Array<pagoCapitalTo> = [] as Array<pagoCapitalTo>;
  listDocumentos : Array<documentsProductTo> = [] as Array<documentsProductTo>;
 
  invIdSecurityCodePk = null;
 
  constructor(private httpClient: HttpClient,
              private loginService: LoginService,
              private  spinner: NgxSpinnerService,
              private router: Router,
              private route: ActivatedRoute,
              private modalService: ModalService,
              private sanitizer: DomSanitizer) { }

  ngOnInit() {
 
    this.selectTab = 0;

    this.menuOrigin = JSON.parse(sessionStorage.getItem('menuOrigin'));
    if(this.menuOrigin != null){
        if(this.menuOrigin == produtOriginMaping.MARKET){
            this.productoBool  = true;
            this.inversionBool = false;      
        }else if(this.menuOrigin == produtOriginMaping.INVERSIONES){
            this.productoBool  = false;
            this.inversionBool = true;      
        }
    } 
    
    this.objUserTo = <userTo>this.loginService.currentUser();
    this.menuOrigin = JSON.parse(sessionStorage.getItem('menuOrigin')); 
    this.productPrm = JSON.parse(sessionStorage.getItem('product'));

    var body = {
      "id_security_code_pk": this.productPrm.idSecurityCodePk
    }

    this.spinner.show();
 
    this.httpClient.post(URL.SERVICE_API_V1_CORE_GET_VIEW_PRODUCT_DETAIL
                ,body,        
            { headers: func.WSHeaderTokenCore() })
            . subscribe(
            (response) => { 

              let productoDetalle = <ProductoDetalleTo>response; 
              this.entityProductoDetalleTo = productoDetalle;  

              this.lstScheduleProductsInterest = this.entityProductoDetalleTo.scheduleProductsInterest;
              this.lstScheduleProductsCapital  = this.entityProductoDetalleTo.scheduleProductsCapital;
              
  
            },
            (err: HttpErrorResponse) => {
                console.log(err.error);
                console.log(err.name);
                console.log(err.message);
                console.log(err.status);

                this.spinner.hide();
                return ;
          },
          () => {
            this.spinner.hide();
          }
      );

      console.log("prev - this.loadDocumentos");
      this.loadDocumentos();

  }

  changeSelectTab(tabNumber :number) { 
    this.selectTab = tabNumber; 
  }

  gotoPage(page: String){
    this.router.navigate([page]);
  } 

  loadDocumentos(){
    
    this.spinner.show(); 

    var body = {
      "id_isin_code": this.productPrm.idSecurityCodePk
    }
    console.log("ws::"+URL.SERVICE_API_V1_CORE_GET_DOCUMENTS_ATTACHED_BY_SECURITY);
    console.log("body::"+JSON.stringify(body));

    this.httpClient.post(URL.SERVICE_API_V1_CORE_GET_DOCUMENTS_ATTACHED_BY_SECURITY  
               , body,        
              { headers: func.WSHeaderTokenCore() })
      . subscribe(
            (response) => {  
                console.log(" response :: " + JSON.stringify(response) ); 
                
                let responseBd = <responseDocumentsProductTo>response;  

                if(responseBd != null){
                  this.listDocumentos = responseBd.items; 
                }
                 
            },
            (err: HttpErrorResponse) => {
              console.log(err.error);
              console.log(err.name);
              console.log(err.message);
              console.log(err.status);
              
              this.spinner.hide();  
          },
          () => {
            this.spinner.hide();
            console.log("************* this.httpClient.post ()::" + "The get pdf poliza now completed.");
          }
    ); 

  } 

  formatNumber(number){
    return func.formatNumber(number);
  }

  formatNumberFourDecimal(number){  
    return func.formatNumberFourDecimal(number); 
  } 

  formatDate(date){
    return func.formatFechaDBtoUi(date);
  }  

  downloadFileBD(downloadFile :documentsProductTo) { 
  
      var body = {
        "fileType"      : downloadFile.fileType,
        "idIsinCode"    : downloadFile.idIsinCode,
        "idFilePkFrom"  : downloadFile.idFilePkFrom
      }   

      console.log("url::"+URL.SERVICE_API_V1_CORE_GET_DOCUMENT_FILE_BY_PARAMS+" body::"+JSON.stringify(body));
      this.httpClient.post(URL.SERVICE_API_V1_CORE_GET_DOCUMENT_FILE_BY_PARAMS 
                  , body,       
            { headers: func.WSHeaderTokenCore() ,  responseType: 'blob'  } )
          . subscribe(
          (response) => {    

            var downloadURL = window.URL.createObjectURL(response);
            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = ""+downloadFile.fileName;
            link.click();    
          },
          (err: HttpErrorResponse) => {
              console.log(err.error);
              console.log(err.name);
              console.log(err.message);
              console.log(err.status);  
              this.spinner.hide();
              return ;
          },
          () => {
            this.spinner.hide();
          }
      );  
  }

  gotoProductoCalculadora(){
    //
  }
       
  openTerminos(){
    //this.modalService.open('idTerminos');  
    this.simplemodalComponent.openModalInfo();
  }
/*
  closeTerminos(file_code: String){ 
    this.modalService.close('idTerminos');
  }
*/
}
