import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';    //se agrego para usar el Ejemplo Formulario 2 y 3
import { Validators } from '@angular/forms';  //se agrego para usar el Ejemplo Formulario 1 , 2 , 3 
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from 'src/app/configuration/providers/modal';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { userTo } from 'src/app/model/UserModel/userTo';
import { URL } from 'src/app/constants/url';
import { func } from 'src/app/constants/func';
import { userAccountTo } from 'src/app/model/UserModel/userAccountTo';
import { responseTo } from 'src/app/model/UserModel/responseTo';
import { ParameterService } from 'src/app/services/ParameterServices/parameter.service';
import { MASTER_TABLE } from 'src/app/constants/masterTable';
import { documentTypeMaping } from 'src/app/constants/documentTypeMaping';
import { userAccountRequestTo } from 'src/app/model/UserModel/userAccountRequestTo';
import { errorMaping } from 'src/app/constants/errorMaping';
import { parameterTableTo } from 'src/app/model/UserModel/parameterTableTo';
import { flowChangePassword } from 'src/app/constants/flowChangePassword';
import { responseServiceTo } from 'src/app/model/UserModel/responseServiceTo';
import { SimplemodalComponent } from '../../AppComponents/simplemodal/simplemodal.component';



@Component({
  selector: 'ark-mi-cuenta',
  templateUrl: './mi-cuenta.component.html',
  styleUrls: ['./mi-cuenta.component.css']
})
export class MicuentaComponent implements OnInit {

 
  userTo:userTo = this.loginService.currentUser();

  id_user_pk : number = null ;
  nombre : String = '';
  apellidoPaterno : String = '';
  apellidoMaterno : String = '';
  tipoDocumento : String = '';
  numeroDocumento : String = '';
  correo : String = '';
  numeroCelular : String = '';

   
  originalTelefono : String = '';
  originalCorreo : String = '';
  operator_company : number;
  

  // datos form modificar
  contrasena1 = new FormControl('', [Validators.required, Validators.minLength(6)]);
  contrasena2 = new FormControl('', [Validators.required, Validators.minLength(6)]);
  contrasenaNueva = new FormControl('', [Validators.required, Validators.minLength(6)]);

  correo1 = new FormControl('', [Validators.required, Validators.email]);
  correoNuevo = new FormControl('', [Validators.required, Validators.email]);

  telefono1 = new FormControl('', [Validators.required, Validators.minLength(9), Validators.maxLength(9)]);
  telefonoNuevo = new FormControl('', [Validators.required, Validators.minLength(9), Validators.maxLength(9)]);

  paramTipoOperadorView : Array<parameterTableTo> = [] as Array<parameterTableTo>;
  mapTipoOperadorView = new Map();
  


  token = new FormControl('', Validators.required);
  submitted = false;  
  submitted2 = false; 
  submitted3 = false;


  paramSelect = null;

  modificar = new FormControl(''); 

  mensaje = '';

  completeBool = false; 

  showPassword1 = false; 
  showPassword2 = false; 
  showPassword3 = false; 

  formatChange = new FormControl('');

  selectTab : number;

  isFavorite: boolean = true;
  
  flowView : number;
  flowLoadProfile    = flowChangePassword.LOAD_PROFILE;
  flowChangePassword = flowChangePassword.FORMAT_CHANGE_PASSWORD;
  flowRedToken       = flowChangePassword.READ_TOKEN;
  flowExpiredToken   = flowChangePassword.EXPIRED_TOKEN;
  flowNotFoundToken  = flowChangePassword.NOT_FOUND_TOKEN;
  flowNewPassword    = flowChangePassword.NEW_PASSWORD;

  text1 = new FormControl(null);
  text2 = new FormControl(null);
  text3 = new FormControl(null);
  text4 = new FormControl(null);
  text5 = new FormControl(null);
  text6 = new FormControl(null);
  @ViewChild(SimplemodalComponent,{static: false}) simplemodalComponent: SimplemodalComponent;

  constructor(private httpClient: HttpClient,
              private parameterService: ParameterService, 
              private modalService: ModalService,
              private loginService: LoginService, 
              private router: Router,
              private  spinner: NgxSpinnerService) { }
 
  ngOnInit() {

    this.selectTab = 0;
    this.flowView = flowChangePassword.LOAD_PROFILE;
    this.getParameterTable(); 
    //let objUserTo = <userTo>this.loginService.currentUser(); 

    this.id_user_pk = this.userTo.id_user_pk;
    this.nombre = this.userTo.name;
    this.apellidoPaterno = this.userTo.first_lastname;
    this.apellidoMaterno = this.userTo.second_lastname; 
    this.tipoDocumento = documentTypeMaping.getDocumentTypeDescription(this.userTo.document_type);
    this.numeroDocumento = this.userTo.document_number;
    this.correo = this.userTo.email;
    this.numeroCelular = this.userTo.phone_number; 
    this.operator_company = this.userTo.operator_company;   

    this.limpiarOpciones();  
  }

  public limpiarOpciones() {
    this.contrasena1.setValue('');
    this.contrasena2.setValue('');
    this.contrasenaNueva.setValue('');
  
    this.correo1.setValue('');
    this.correoNuevo.setValue('');
  
    this.telefono1.setValue('');
    this.telefonoNuevo.setValue('');

    this.showPassword1 = false; 
    this.showPassword2 = false; 
    this.showPassword3 = false; 

    this.submitted = false;  
    this.submitted2 = false;  
    this.submitted3 = false;  
  }


  async getParameterTable(){
      this.paramTipoOperadorView = await this.parameterService.getParameter(MASTER_TABLE.TELEPHONE_OPERATOR_TYPE)  as Array<parameterTableTo>;
      for (var i in this.paramTipoOperadorView) {  
        this.mapTipoOperadorView.set(this.paramTipoOperadorView[i].parameter_table_pk, this.paramTipoOperadorView[i].description);
      }
  }

  readParameterDesciption(pk: number){
    return this.mapTipoOperadorView.get(pk);
  }

  beforeModificar() {

    // 89 cambiar contrasena, 90 cambiar telefono, 91 cambiar correo

    if(this.modificar.value == 89){

      if( (this.contrasena1 != null && this.contrasena1.value == '') ||  
           (this.contrasena2 != null &&  this.contrasena2.value == '') || 
           (this.contrasenaNueva != null &&  this.contrasenaNueva.value == '') ) {
            
          this.mensaje = "Ingrese los datos requeridos";
          this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);
          return false;
      }

      if(this.contrasena2.value !== this.contrasenaNueva.value) {
        this.mensaje = "Las contraseñas no coinciden";
        this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);
        return false;
      }

    }else if(this.modificar.value == 90) {

      if ( (this.telefono1 != null && this.telefono1.value == '') || 
           (this.telefonoNuevo != null && this.telefonoNuevo.value == '') ) {
            
          this.mensaje = "Ingrese los datos requeridos";
          this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);
          return false;
      }

      if (this.telefono1.value !== this.telefonoNuevo.value) {
        this.mensaje = "Las teléfonos no coinciden";
        this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);
        return false;
      }
      

    }else if(this.modificar.value == 91) {

      if( (this.correo1 != null && this.correo1.value == '') || 
           (this.correoNuevo != null && this.correoNuevo.value == '') ) {
            
          this.mensaje = "Ingrese los datos requeridos";
          this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);
          return false;
      }

      if(this.correo1.value !== this.correoNuevo.value) {
        this.mensaje = "Las correos no coinciden";
        this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);
        return false;
      }
      
    }
    return true;
    
  }


  solicitarModificacion() {// onSubmit

    if(this.modificar.value == 89){

      this.submitted = true;

      if (this.contrasena1.invalid ||
          this.contrasena2.invalid   ||
          this.contrasenaNueva.invalid  
      ) {
        return;
      }

    }else if(this.modificar.value == 90) {
      
      this.submitted2 = true;

      if (this.telefono1.invalid ||
          this.telefonoNuevo.invalid 
      ) {
        return;
      }    

    }else if(this.modificar.value == 91) {

      this.submitted3 = true;

      if (this.correo1.invalid ||
          this.correoNuevo.invalid 
      ) {
        return;
      }
      
    }
    
 
    var urlService = null;

    if(this.beforeModificar()){
  
      let datosUpdate =  null;   

      if(this.modificar.value == 89){ // contrasena
        datosUpdate =  {
          "old_password" : this.contrasena1.value,
          "password": this.contrasenaNueva.value, 
          "phone_number" : '',
          "email" : '',
        }  
      }else if(this.modificar.value == 90) { // telefono
         datosUpdate =  {  
          "old_password" : '',
          "password": '', 
          "phone_number": this.telefonoNuevo.value, // correo
          "email": '',

        }  
      }else if(this.modificar.value == 91) {
         datosUpdate =  { 
          "old_password" : '',
          "password": '', 
          "phone_number": '',
          "email": this.correoNuevo.value
        }  
      }

      let userTokenRequest =  {
        "phone_token": true,
        "email_token": true
      }
     
      this.spinner.show();
      this.httpClient.post(URL.SERVICE_API_V1_USER + this.id_user_pk + 
                           URL.SERVICE_SAVE_USER_ACCOUNT_REQUEST_END,
              datosUpdate, { headers: func.WSHeader() })
                . subscribe(
              (response) => {  

                  this.limpiarOpciones();
                  this.simplemodalComponent.alertar("Mensaje de Alerta","Se modifico Satisfactoriamente");
 
              },
              (err: HttpErrorResponse) => {
                this.spinner.hide();
          },
          () => {
            this.spinner.hide();
          }
      );  
 
    }
 
  }


  

  public gotoConfirmarModificarSuscripcion() { 
    this.router.navigate(["/confirmarModificarSuscripcion"]);
  } 


  toggleShowPassword(prm : number) {
    if (prm == 1){
      this.showPassword1 = !this.showPassword1; 
    }else if (prm == 2){
      this.showPassword2 = !this.showPassword2; 
    }else if (prm == 3) {
      this.showPassword3 = !this.showPassword3; 
    }
  }

  onKeyDocumentNumber(event: any, prm: number){   
    if(this.modificar.value == 90){ // telefono
      if(prm == 1){
        this.telefono1.setValue(func.validateImputTelefono(event.target.value));
      }else if(prm == 2){
        this.telefonoNuevo.setValue(func.validateImputTelefono(event.target.value));
      }      
    } 
  }

  /** cambios  */
  loadChangePassword(){
    this.flowView = flowChangePassword.FORMAT_CHANGE_PASSWORD;
  }

  changePassword() {

    if(this.contrasena1.value == ''){ this.simplemodalComponent.alertar("Mensaje de Alerta","Ingrese la contraseña nueva 1 ");return; }
    if(this.contrasena2.value == ''){ this.simplemodalComponent.alertar("Mensaje de Alerta","Ingrese la contraseña nueva 2 ");return; }
    
    if (this.contrasena1.value !== this.contrasena2.value) {

      this.mensaje = "Las contraseñas no coinciden";
      this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);
      return;

    } else {

      var pass1:boolean = this.onValidatePassword(this.contrasena1);
      var pass2:boolean =this.onValidatePassword(this.contrasena2);
      if(!pass1 || !pass2){
        this.simplemodalComponent.alertar("Mensaje de Alerta",'El password debe contener mínimo 8 carácteres incluye minúsculas, mayúsculas, números y símbolos ( ! , # $ ; ^ - + _ /  ).'); 
        return;
      }


            let datos = {
              "phone_token"        : this.token.value,
              "id_user_request_pk" : this.id_user_pk,
              "new_password"       : this.contrasena1.value
            } 
        
            this.httpClient.post(URL.SERVICE_API_V1_USER + this.id_user_pk + URL.SERVICE_MODIFY_PASSWORD_END ,
                            datos, { headers: func.WSHeader() })
                    . subscribe(
                    (response) => {   
              
                        console.log(response);
              
                        this.mensaje = 'Confirmación realizada correctamente.';
                        this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);
                        this.flowView = flowChangePassword.LOAD_PROFILE;
        
                    },
                    (err: HttpErrorResponse) => {
                      
                        if(err.error.api_code == "TOK_02"){
                          this.cleanPassword();
                          this.flowView = flowChangePassword.EXPIRED_TOKEN;
                          return;
                        }
        
                        if(err.error.code == 404) {
                          this.spinner.hide(); 
                          this.flowView = flowChangePassword.READ_TOKEN;
                        }else {
                          this.spinner.hide(); 
                          this.flowView = flowChangePassword.READ_TOKEN;
                        } 
                    },
                    () => {
                      this.spinner.hide();
                      this.cleanPassword();
                      
                    }
              );   


    }       
  }

  cleanPassword(){
    this.contrasena1 = new FormControl('', [Validators.required, Validators.minLength(6)]);
    this.contrasena2 = new FormControl('', [Validators.required, Validators.minLength(6)]);
  }
  
  validateTokenPassword(){

        this.token.setValue(this.text1.value+""+this.text2.value+""+this.text3.value+""+this.text4.value+""+this.text5.value+""+this.text6.value);

        if(this.token.invalid) {
          return;
        }
      
        let datosToken = {
          "enabled" : true,
          "id_user_account_fk" : this.id_user_pk,
          "phone_token" : this.token.value,
          "email_token" : '' 
        }

        this.spinner.show();
        this.httpClient.post(URL.SERVICE_VERIFY_TOKEN,
              datosToken, { headers: func.WSHeader() })
            . subscribe(
            (response) => {   
  
                console.log(response); 
                let respuesta = <responseServiceTo>response;

                this.flowView = flowChangePassword.NEW_PASSWORD;
 
                // 200 OK - 404 NO FOUND
                if(Number(respuesta.registros) <= 0){
                    this.spinner.hide();
                    this.mensaje = "Token no encontrado, verifique";
                  
                    this.cleanToken(); 
                    this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);                     
                    return; 
                }
          },
          (err: HttpErrorResponse) => {          
            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status); 

            if(err.status == 404) { 
              this.spinner.hide();
              this.flowView = flowChangePassword.NOT_FOUND_TOKEN;
              this.cleanToken();
              this.mensaje = "Token no encontrado, verifique"; 
              this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);
            }else {
              this.spinner.hide(); 
              this.cleanToken();
              this.mensaje = "Ocurrio un problema al generar el token \n " + err.message;
              this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);
            } 

          },
          () => {
            this.spinner.hide();
            this.cleanToken();
          }
      );   
  }

  nextKey(event: any, nextIdInput){
    //eval("$(function() {  $('#"+nextIdInput+"').focus(); });");
    var tecla = event.target.value;
    if(tecla != null && tecla != "" && tecla != " "){
      if(nextIdInput=="text2"){
        this.text2.setValue(null);
      }
      if(nextIdInput=="text3"){
        this.text3.setValue(null);
      }
      if(nextIdInput=="text4"){
        this.text4.setValue(null);
      }
      if(nextIdInput=="text5"){
        this.text5.setValue(null);
      }
      if(nextIdInput=="text6"){
        this.text6.setValue(null);
      }
      eval("$(function() {  $('#"+nextIdInput+"').focus(); });");
    }

  }

  requestTokenChangePassword(){
/*
        if(this.formatChange.value == ''){
          this.simplemodalComponent.alertar("Mensaje de Alerta","Debe seleccionar un medio de envio para token.");
          return;
        }
        else*/
console.log("requestTokenChangePassword: "+URL.SERVICE_API_V1_USER + this.id_user_pk +URL.SERVICE_SAVE_USER_ACCOUNT_REQUEST_END+" ");
        { 
            let datosUpdate =  null;       
            datosUpdate =  {
              "old_password" : '',
              "new_password": 'new', 
              "phone_number" : '', 
            }   
            this.spinner.show();
            this.httpClient.post(URL.SERVICE_API_V1_USER + this.id_user_pk + 
                                URL.SERVICE_SAVE_USER_ACCOUNT_REQUEST_END,
                    datosUpdate, { headers: func.WSHeader() })
                      . subscribe(
                    (response) => {  

                        this.limpiarOpciones();
                        this.flowView = flowChangePassword.READ_TOKEN;
                        this.mensaje = "Se envió el token correctamente"
                        this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);
                        this.cleanToken();
                    },
                    (err: HttpErrorResponse) => {

                      var result = <userAccountRequestTo>err.error;
                      if(err.status === errorMaping.CODE_405) {
                        this.spinner.hide();
                        this.mensaje = "Existe una solicitud activa por confirmar.";
                        this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);
                      }else  if(err.status === errorMaping.CODE_400) {
                        this.spinner.hide();
                        this.mensaje = "La contraseña actual(old password) ingresada es incorrecta.";
                        this.cleanToken();
                        this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);
                      }else {
                        this.spinner.hide();
                        this.cleanToken();
                        this.mensaje = "Ocurrio un error al solicitar modificar datos \n " + err.message;
                        this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);                        
                      }                    
                },
                () => {
                  this.spinner.hide();
                  this.cleanToken();
                }
            );   
        }
 
  }

  downloadFileBD() { 
  
    var body = {
      "documentType"      : this.userTo.document_type,
      "documentNumber"    : this.userTo.document_number,
      "email"             : this.userTo.email
    }

    console.log("URL::"+URL.SERVICE_API_V1_CORE_GET_DOCUMENT_FILE_CLIENT + " body:: " + JSON.stringify(body) );
    
    this.httpClient.post(URL.SERVICE_API_V1_CORE_GET_DOCUMENT_FILE_CLIENT 
                , body,       
          { headers: func.WSHeaderTokenCore() ,  responseType: 'blob'  } )
        . subscribe(
        (response) => {    

          var downloadURL = window.URL.createObjectURL(response);
          var link = document.createElement('a');
          link.href = downloadURL;
          link.download = "fichaCliente.pdf";
          link.click();    
        },
        (err: HttpErrorResponse) => {
            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);  
            this.spinner.hide();
            return ;
        },
        () => {
          this.spinner.hide();
        }
    );  
}

  
  cleanToken(){
    this.text1 = new FormControl(null);
    this.text2 = new FormControl(null);
    this.text3 = new FormControl(null);
    this.text4 = new FormControl(null);
    this.text5 = new FormControl(null);
    this.text6 = new FormControl(null);
  }
 


  changeSelectTab(tabNumber :number) { 
    this.selectTab = tabNumber;
    this.cleanTab1();
  }

  cleanTab1(){
    
    this.cleanToken();
    this.contrasena1.setValue('');
    this.contrasena2.setValue('');
    this.formatChange = new FormControl('');
    this.flowView = flowChangePassword.LOAD_PROFILE;
  }
    
  gotoPage(page: String){
    this.router.navigate([page]); 
  }    

  gotoPageWithRut(page: String){
    if(this.userTo.rut!=null){
      this.router.navigate([page]);
    }
  }

  openTerminos(){
    this.simplemodalComponent.openModalInfo();
    //this.modalService.open('idTerminos');  
  }
/*
  closeTerminos(file_code: String){ 
    this.modalService.close('idTerminos');
  }
  ----------


*/

  onValidatePassword(object:FormControl){
    var completePassword = object.value;

    var inArrayLetter = 0;
    for(var currentPass of func.arrayLetter()){
      for(var  i=0 ; i < completePassword.length ; i++) {
        var password = completePassword.charAt(i);  
        if(currentPass == password){
          inArrayLetter = 1;
          break;
        }

      }
    }

    var inArrayCaseLetter = 0;
    for(var currentPass of func.arrayCaseLetter()){
      for(var  i=0 ; i < completePassword.length ; i++) {
        var password = completePassword.charAt(i);  
        if(currentPass == password){
          inArrayCaseLetter = 1;
          break;
        }
      }
    }

    var inArrayNumber = 0;
    for(var currentPass of func.arrayNumber()){
      for(var  i=0 ; i < completePassword.length ; i++) {
        var password = completePassword.charAt(i);  
        if(currentPass == password){
          inArrayNumber = 1;
          break;
        }
      }
    }

    var inArraySimbole = 0;
    for(var currentPass of func.arraySimbole()){
      for(var  i=0 ; i < completePassword.length ; i++) {
        var password = completePassword.charAt(i);  
        if(currentPass == password){
          inArraySimbole = 1;
          break;
        }
      }
    }

    if(inArrayLetter == 1 && inArrayCaseLetter == 1 && inArrayNumber == 1 && inArraySimbole == 1){
      return true;
    }else{
      return false;
    }

  }

  onKeyUpPassword(event: any, object:FormControl){
    object.setValue(func.inputBlurPassword(event.target.value));
  }

}
