import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ProductoFinancieroHomeTo } from 'src/app/model/UserModel/ProductoFinancieroHomeTo';
import { func } from 'src/app/constants/func';
import { ProductoFinancieroTo } from 'src/app/model/UserModel/ProductoFinancieroTo';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'ark-productohome',
  templateUrl: './productohome.component.html',
  styleUrls: ['./productohome.component.css']
})
export class ProductohomeComponent implements OnInit {

  @Input() entity : ProductoFinancieroHomeTo = {} as ProductoFinancieroHomeTo;  //parametros

  nominal_value = 0;
  tax = 0;
  issuance_date = null;
  image = null;

  constructor(private router: Router, private domSanitizer: DomSanitizer) { }

  ngOnInit() {
    this.nominal_value = this.entity.nominalValue;// func.formatNumber();
    this.tax = this.entity.tax;//func.formatNumber();
    this.issuance_date = this.entity.issuanceDate;//func.formatFechaDBtoUi();
    console.log("func.getImgWithIssuerCode(this.entity.issuerCode)::"+ func.getImgWithIssuerCode(this.entity.issuerCode));
    this.entity.imgBase64 = func.getImgWithIssuerCode(this.entity.issuerCode);
    this.image = this.safeImagen("data:image/jpeg;base64,"+this.entity.imgBase64);
    this.entity.imgBase64 = this.image;
  }

  safeImagen(imagenBase64){ 
    return this.domSanitizer.bypassSecurityTrustUrl(imagenBase64);
  }

  formatNumber(number){
    if(number == null){
      return "0.00"
    }else{
      return func.formatNumber(number);
    }
  }

  formatDate(date){
    return func.formatFechaDBtoUi(date);
  }
  
  gotoProductoDetalleInversion(product : ProductoFinancieroHomeTo){

    var productoFinancieroTo: ProductoFinancieroTo = {} as ProductoFinancieroTo;  
    productoFinancieroTo.securitySerial = product.securitySerial;
    productoFinancieroTo.idSecurityCodePk = product.idSecurityCodePk;
      /*"security_serial"       : String,
      "id_issuance_code_pk"   : String,
      id_security_code_pk     : String,
      "issuer_name"           : String,
      "issuance_name"         : String,
      "nominal_value"         : number,
      "currency"              : String,
      "tax"                   : number,
      "issuance_date"         : String,
      "term"                  : number,
      "term_time"             : String,
      "imgbase64"             : String,
      "issuance_description"  : String,
      "offerstart_date"       : String,
      "placement_date"        : String, */

      
    //sessionStorage.setItem('serialCode',JSON.stringify(serialCode));
    sessionStorage.setItem('product',JSON.stringify(productoFinancieroTo));
    this.router.navigate(["/detalleInversion"]);
  }
  
  getClaseInteres(cashNominal: number){
    return func.getClaseInteres(cashNominal); 
  }
  
}