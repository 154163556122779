import { Component, OnInit, Input, ViewChild } from '@angular/core'; 
import { Validators } from '@angular/forms';  //se agrego para usar el Ejemplo Formulario 1 , 2 , 3
import { FormGroup, FormControl } from '@angular/forms'; 
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { viewAllCashAccountTo } from 'src/app/model/UserModel/viewAllCashAccountTo';
import { cashAccountTo } from 'src/app/model/UserModel/cashAccountTo';
import { viewAllBankAccountTo } from 'src/app/model/UserModel/viewAllBankAccountTo';
import { ModalService } from 'src/app/configuration/providers/modal';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { userTo } from 'src/app/model/UserModel/userTo';
import { URL } from 'src/app/constants/url';
import { CURRENCY } from 'src/app/constants/CURRENCY';
import { func } from 'src/app/constants/func';
import { parameterTableTo } from 'src/app/model/UserModel/parameterTableTo';
import { SimplemodalComponent } from '../../AppComponents/simplemodal/simplemodal.component';


@Component({
  selector: 'ark-mis-efectivos',
  templateUrl: './mis-efectivos.component.html',
  styleUrls: ['./mis-efectivos.component.css']
})
export class MisEfectivosComponent implements OnInit {

  cashAccountTypeSoles:Array<parameterTableTo> = [] as Array<parameterTableTo>;
  cashAccountTypeDolares:Array<parameterTableTo> = [] as Array<parameterTableTo>;

  respontCashAccountToSoles : viewAllCashAccountTo = {} as viewAllCashAccountTo;
  respontCashAccountToDolares : viewAllCashAccountTo = {} as viewAllCashAccountTo;

  listaSolesMovimientoEfectivoTo  : Array<cashAccountTo> = [] as Array<cashAccountTo>;
  listaDolaresMovimientoEfectivoTo  : Array<cashAccountTo> = [] as Array<cashAccountTo>;

  listaSolesCuentaBancariaTo    : Array<viewAllBankAccountTo> = [] as Array<viewAllBankAccountTo>;
  listaDolaresCuentaBancariaTo  : Array<viewAllBankAccountTo> = [] as Array<viewAllBankAccountTo>;

  solesTotal = '';
  solesDisponible = '';
  solesTramite = '';
  solesRetirar = new FormControl('', Validators.required);
  solesRetirarMask = new FormControl('', Validators.required);
  solesBankAccount = new FormControl('', Validators.required);


  dolaresTotal = '';
  dolaresDisponible = '';
  dolaresTramite = '';
  dolaresRetirar = new FormControl('', Validators.required);
  dolaresRetirarMask = new FormControl('', Validators.required);
  
  dolaresBankAccount = new FormControl('', Validators.required);

  cashAccountTypeSolesSelectedFormat = new FormControl('', Validators.required);
  cashAccountTypeSolesSelected = new FormControl('', Validators.required);
  cashAccountTypeDolaresSelectedFormat = new FormControl('', Validators.required);
  cashAccountTypeDolaresSelected = new FormControl('', Validators.required);
  
  totalCashAccountTypeSolesSelected:number = 0;
  availableCashAccountTypeSolesSelected:number = 0;

  totalCashAccountTypeDolaresSelected:number = 0;
  availableCashAccountTypeDolaresSelected:number = 0;

  mensaje = '';
  objUserTo = null;

  noSoles   = false;
  noDolares = false;

  total_balance_soles = 0;
  available_balance_soles = 0;
  transit_balance_soles = 0;

  total_balance_dolares = 0;
  available_balance_dolares = 0;
  transit_balance_dolares = 0; 

  submitted = false;  //send parameters
  submitted2 = false;  //send parameters

  toSaveCurrency = null;
  @ViewChild(SimplemodalComponent,{static: false}) simplemodalComponent: SimplemodalComponent;

  constructor(private modalService: ModalService, 
              private httpClient: HttpClient,
              private loginService: LoginService,
              private  spinner: NgxSpinnerService,
              private router: Router) { }

  ngOnInit() { 
    this.objUserTo = <userTo>this.loginService.currentUser(); 
    this.loadEfectivosDolare();
    this.loadEfectivosSoles(); 

    this.cuentaBancariaDolares();
    this.cuentaBancariaSoles();
    
    this.loadAccountTypeDolares();
    this.loadAccountTypeSoles();

    var mifunction="$(function() { $('[data=hide]').hide(); $('[data=show]').show(); });";
    eval(mifunction);
    
  } 

  setAccountTypeSolesSelected(){
    for(var parameterTable of this.cashAccountTypeSoles){
      if(parameterTable.parameterTablePk == this.cashAccountTypeSolesSelected.value){
        this.totalCashAccountTypeSolesSelected = parameterTable.decimalAmount1;
        this.availableCashAccountTypeSolesSelected = parameterTable.decimalAmount2;
        return;
      }
    }
  }
  
  cashAccountTypeSolesClick(parameterTablePk){
    this.cashAccountTypeSolesSelected.setValue(parameterTablePk);
    this.setAccountTypeSolesSelected();
    //console.log("this.cashAccountTypeSolesSelected.value:: "+this.cashAccountTypeSolesSelected.value);
  }
  
  cashAccountTypeDolaresClick(parameterTablePk){
    this.cashAccountTypeDolaresSelected.setValue(parameterTablePk);
    this.setAccountTypeDolaresSelected();
    //console.log("this.cashAccountTypeDolaresSelected.value:: "+this.cashAccountTypeDolaresSelected.value);
  }

  setAccountTypeDolaresSelected(){
    for(var parameterTable of this.cashAccountTypeDolares){
      if(parameterTable.parameterTablePk == this.cashAccountTypeDolaresSelected.value){
        this.totalCashAccountTypeDolaresSelected = parameterTable.decimalAmount1;
        this.availableCashAccountTypeDolaresSelected = parameterTable.decimalAmount2;
        return;
      }
    }
  }

  onKeyOutOnlyNumericDecimalSimple(event: any,object:FormControl){
    var valor:number = Number(event.target.value);
    var valorFormateado = func.formatNumber(valor);
    if( valorFormateado == "NaN" || valorFormateado == null){
      object.setValue("0.00");
    }else{
      object.setValue(valorFormateado);
    }

  }

  onKeyOutOnlyNumericDecimalDouble(event: any,objectMask:FormControl,objectTarget:FormControl){
    
    var target_value = event.target.value;
    target_value = func.replaceAll(target_value,',','');
    var valor:number = Number(target_value);
    var valorFormateado = func.formatNumber(valor);
    if( valorFormateado == "NaN" || valorFormateado == null){
      objectMask.setValue("0.00");
      objectTarget.setValue("0.00");
    }else{
      objectMask.setValue(valorFormateado);
      objectTarget.setValue(func.replaceAll(valorFormateado,',',''));

      //console.log("objectMask::"+objectMask.value);
      //console.log("objectTarget::"+objectTarget.value);
    }

    /*
    var target_value = event.target.value;
    var valor:number = Number(target_value);
    var valorFormateado = func.formatNumber(valor);
    if( valorFormateado == "NaN" || valorFormateado == null){
      objectMask.setValue("0.00");
      objectTarget.setValue("0.00");
    }else{
      objectMask.setValue(valorFormateado);
      objectTarget.setValue(func.replaceAll(valorFormateado,',',''));

      //console.log("objectMask::"+objectMask.value);
      //console.log("objectTarget::"+objectTarget.value);
    }*/

  }

  onKeyUpOnliDecimal(event: any, object:FormControl){
    object.setValue(func.inputOnliDecimal(event.target.value));
  }

  formatQuantity(variable){
    return func.formatNumber(variable);
  }

  loadAccountTypeSoles(){
    this.noSoles = false;

    this.spinner.show();

    let urlViewCashAccount = URL.SERVICE_API_V1_CORE_GET_CASH_ACCOUNT_TYPES;
    let objUserTo = <userTo>this.loginService.currentUser();
    var body = {
      "rut": ""+objUserTo.rut,
      "currency": ""+CURRENCY.SOLES.ID,
    }
    //console.log("Service::"+urlViewCashAccount);
    //console.log("body::"+JSON.stringify(body));
    this.httpClient.post(urlViewCashAccount, body , {  headers: func.WSHeaderTokenCore() })
      .subscribe(
              (response) => {
                  //console.log(" response :: " + JSON.stringify(response) ); 
                  
                  this.cashAccountTypeSoles = response as Array<parameterTableTo>;  


              },
              (err: HttpErrorResponse) => {
                console.log(err.error);
                console.log(err.name);
                console.log(err.message);
                console.log(err.status);
                
                this.spinner.hide();
                this.mensaje = "Ocurrio un error al cargar los efectivos \n " + err.message;
               
            },
            () => {
              this.spinner.hide();
              //console.log("************* this.httpClient.post ()::" + "The get mi efectivo now completed.");
            }
      ); 

  }
  
  loadAccountTypeDolares(){
    this.noSoles = false;

    this.spinner.show();

    let urlViewCashAccount = URL.SERVICE_API_V1_CORE_GET_CASH_ACCOUNT_TYPES;
    let objUserTo = <userTo>this.loginService.currentUser();
    var body = {
      "rut": ""+objUserTo.rut,
      "currency": ""+CURRENCY.DOLARES.ID,
    }
    //console.log("Service::"+urlViewCashAccount);
    //console.log("body::"+JSON.stringify(body));
    this.httpClient.post(urlViewCashAccount, body , {  headers: func.WSHeaderTokenCore() })
      .subscribe(
              (response) => {
                  //console.log(" response :: " + JSON.stringify(response) ); 
                  
                  this.cashAccountTypeDolares = response as Array<parameterTableTo>;  


              },
              (err: HttpErrorResponse) => {
                console.log(err.error);
                console.log(err.name);
                console.log(err.message);
                console.log(err.status);
                
                this.spinner.hide();
                this.mensaje = "Ocurrio un error al cargar los efectivos \n " + err.message;
               
            },
            () => {
              this.spinner.hide();
              //console.log("************* this.httpClient.post ()::" + "The get mi efectivo now completed.");
            }
      ); 

  }

  loadEfectivosDolare(){
    this.noDolares = false;

    this.spinner.show(); 

    let urlViewCashAccount = URL.SERVICE_API_V1_CORE_GET_VIEW_GROUP_CASH_ACCOUNT;
    let objUserTo = <userTo>this.loginService.currentUser();
    var body = {
      "rut": ""+objUserTo.rut,
      "currency": ""+CURRENCY.DOLARES.ID,
    }

    //console.log("Service::"+urlViewCashAccount);
    //console.log("body::"+JSON.stringify(body));
    this.httpClient.post(urlViewCashAccount, body , {  headers: func.WSHeaderTokenCore() })
        . subscribe(
              (response) => {  
                  
                  this.respontCashAccountToDolares = <viewAllCashAccountTo>response; 
                  this.total_balance_dolares     =  func.formatNumber(this.respontCashAccountToDolares.totalBalance);
                  this.available_balance_dolares =  func.formatNumber(this.respontCashAccountToDolares.availableBalance);
                  this.transit_balance_dolares   =  func.formatNumber(this.respontCashAccountToDolares.transitBalance);
                  this.noDolares = true;  
                
              },
              (err: HttpErrorResponse) => {
                console.log(err.error);
                console.log(err.name);
                console.log(err.message);
                console.log(err.status);
                
                this.total_balance_dolares     =  func.formatNumber(0);
                this.available_balance_dolares =  func.formatNumber(0);
                this.transit_balance_dolares   =  func.formatNumber(0);
                this.noDolares = true;  

                this.spinner.hide();
                this.mensaje = "Ocurrio un error al cargar los efectivos \n " + err.message;
                
            },
            () => {
              this.spinner.hide();
              //console.log("************* this.httpClient.post ()::" + "The get mi efectivo now completed.");
            }
      ); 

  }

  loadEfectivosSoles(){
    this.noSoles = false;

    this.spinner.show();

    let urlViewCashAccount = URL.SERVICE_API_V1_CORE_GET_VIEW_GROUP_CASH_ACCOUNT;
    let objUserTo = <userTo>this.loginService.currentUser();
    var body = {
      "rut": ""+objUserTo.rut,
      "currency": ""+CURRENCY.SOLES.ID,
    }
    //console.log("Service::"+urlViewCashAccount);
    //console.log("body::"+JSON.stringify(body));
    this.httpClient.post(urlViewCashAccount, body , {  headers: func.WSHeaderTokenCore() })
      .subscribe(
              (response) => {
                  //console.log(" response :: " + JSON.stringify(response) ); 
                  
                  this.respontCashAccountToSoles = <viewAllCashAccountTo>response;  

                  this.total_balance_soles     = func.formatNumber(this.respontCashAccountToSoles.totalBalance);
                  this.available_balance_soles = func.formatNumber(this.respontCashAccountToSoles.availableBalance);
                  this.transit_balance_soles   = func.formatNumber(this.respontCashAccountToSoles.transitBalance);
                  this.noSoles = true;

              },
              (err: HttpErrorResponse) => {
                console.log(err.error);
                console.log(err.name);
                console.log(err.message);
                console.log(err.status);
                
                this.total_balance_soles     = func.formatNumber(0);
                this.available_balance_soles = func.formatNumber(0);
                this.transit_balance_soles   = func.formatNumber(0);
                this.noSoles = true;

                this.spinner.hide();
                this.mensaje = "Ocurrio un error al cargar los efectivos \n " + err.message;
               
            },
            () => {
              this.spinner.hide();
              //console.log("************* this.httpClient.post ()::" + "The get mi efectivo now completed.");
            }
      ); 

  }

  cuentaBancariaSoles(){
    var body = {
      "currency":""+CURRENCY.SOLES.ID,
      "document_type":""+this.objUserTo.document_type,
      "document_number":""+this.objUserTo.document_number,
    }
    
    this.spinner.show();
    this.httpClient.post(URL.SERVICE_API_V1_CORE_GET_LIST_VIEW_ALL_BANK_ACCOUNT,
                          body, { headers: func.WSHeaderTokenCore() })
          . subscribe(
          (response) => {    

            this.listaSolesCuentaBancariaTo = response as Array<viewAllBankAccountTo>;
            //console.log("this.listaSolesCuentaBancariaTo::"+JSON.stringify(this.listaSolesCuentaBancariaTo));
          },
          (err: HttpErrorResponse) => {

            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);

            this.spinner.hide();  
          },
          () => {
            this.spinner.hide();
            //console.log("************* this.httpClient.post ()::" + "The  is now completed.");
          }
      );  
  }

  cuentaBancariaDolares(){
    var body = {
      "currency":""+CURRENCY.DOLARES.ID,
      "document_type":""+this.objUserTo.document_type,
      "document_number":""+this.objUserTo.document_number,
    }
    
    this.spinner.show();
    this.httpClient.post(URL.SERVICE_API_V1_CORE_GET_LIST_VIEW_ALL_BANK_ACCOUNT,
                          body, { headers: func.WSHeaderTokenCore() })
          . subscribe(
          (response) => {    

            this.listaDolaresCuentaBancariaTo = response as Array<viewAllBankAccountTo>;
            //console.log("this.listaDolaresCuentaBancariaTo::"+JSON.stringify(this.listaDolaresCuentaBancariaTo));
          },
          (err: HttpErrorResponse) => {

            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);

            this.spinner.hide();  
          },
          () => {
            this.spinner.hide();
            //console.log("************* this.httpClient.post ()::" + "The  is now completed.");
          }
      );  
  } 

  descargarExcel(nomeda : number){


  }
 
  descargarPdf(nomeda : number){


  }

  getIdBankWithBankAccountDol(){
    for(var i=0; i<this.listaDolaresCuentaBancariaTo.length; i++){
      if(this.listaDolaresCuentaBancariaTo[0].idBankAccountPk == this.dolaresBankAccount.value){
        return this.listaDolaresCuentaBancariaTo[0].idBankPk;
      }
    }
  }

  getIdBankWithBankAccountSol(){
    for(var i=0; i<this.listaSolesCuentaBancariaTo.length; i++){
      if(this.listaSolesCuentaBancariaTo[0].idBankAccountPk == this.solesBankAccount.value){
        return this.listaSolesCuentaBancariaTo[0].idBankPk;
      }
    }
  }

  headTextConfirm: String;
  bodyTextConfirm: String;

  closeAlertarConfirm(){
    this.closeModal("alertarConfirm");
    this.ngOnInit();
  }

  confirmarRetiroSoles(){

    //this.modalService.close('idModalConfirmSoles');

    this.spinner.show();

    let urlViewCashAccount = URL.SERVICE_API_V1_CORE_SAVE_RETIREMENT_FUND_OPERATION;
    var body = {
      "currency": ""+CURRENCY.SOLES.ID,
      "rut":""+this.objUserTo.rut,
      "ammount":""+this.solesRetirar.value,
      "bank_account":""+this.solesBankAccount.value,
      "bank":""+this.getIdBankWithBankAccountSol(),
      "cashAccountType":""+this.cashAccountTypeSolesSelected.value
    }

    //console.log("URL.SERVICE_API_V1_CORE_SAVE_RETIREMENT_FUND_OPERATION :: "+URL.SERVICE_API_V1_CORE_SAVE_RETIREMENT_FUND_OPERATION);
    //console.log("body :: "+JSON.stringify(body));

    this.httpClient.post(urlViewCashAccount, body , {  headers: func.WSHeaderTokenCore() })
      .subscribe(
              (response) => {  
                  //console.log(" response :: " + JSON.stringify(response) ); 
                  
                  
                  this.spinner.hide();
                  this.solesRetirar.setValue('');
                  //this.mensaje = 'Se registró el retiro de efectivo en soles';

                  this.headTextConfirm = 'Mensaje de Alerta';
                  this.bodyTextConfirm = 'Se registró el retiro de efectivo en soles';
                  
                  this.modalService.open('alertarConfirm');
                  //this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);   
                
              },
              (err: HttpErrorResponse) => {
                console.log(err.error);
                console.log(err.name);
                console.log(err.message);
                console.log(err.status);
                
                this.spinner.hide();
                this.mensaje = "Ocurrio un error al registrar el retiro de efetivo en soles. Contacte con su administrador \n " + err.message;
                this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);                
               
            },
            () => {
              this.spinner.hide();
              //console.log("************* this.httpClient.post ()::" + "The get mi efectivo now completed.");
            }
      ); 

  }

  confirmarRetiroDolares(){
    
    //this.modalService.close('idModalConfirmDolares');

    this.spinner.show();

    let urlViewCashAccount = URL.SERVICE_API_V1_CORE_SAVE_RETIREMENT_FUND_OPERATION;
    var body = {
      "currency": ""+CURRENCY.DOLARES.ID,
      "rut":""+this.objUserTo.rut,
      "ammount":""+this.dolaresRetirar.value,
      "bank_account":""+this.dolaresBankAccount.value,
      "bank":""+this.getIdBankWithBankAccountDol(),
      "cashAccountType":""+this.cashAccountTypeDolaresSelected.value
    }
    //console.log("URL.SERVICE_API_V1_CORE_SAVE_RETIREMENT_FUND_OPERATION :: "+URL.SERVICE_API_V1_CORE_SAVE_RETIREMENT_FUND_OPERATION);
    //console.log("body :: "+JSON.stringify(body));

    this.httpClient.post(urlViewCashAccount, body , {  headers: func.WSHeaderTokenCore() })
      .subscribe(
              (response) => {  
                  //console.log(" response :: " + JSON.stringify(response) ); 
                  
                  this.spinner.hide();
                  this.dolaresRetirar.setValue('');
                  
                  this.headTextConfirm = 'Mensaje de Alerta';
                  this.bodyTextConfirm = 'Se registró el retiro de efectivo en dólares';
                  
                  this.modalService.open('alertarConfirm');

                  //this.mensaje = 'Se registró el retiro de efectivo en dólares';
                  //this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);   
                
              },
              (err: HttpErrorResponse) => {
                console.log(err.error);
                console.log(err.name);
                console.log(err.message);
                console.log(err.status);
                
                this.spinner.hide();
                this.dolaresRetirar.setValue('');
                this.mensaje = "Ocurrio un error al registrar el retiro de efetivo en dólares. Contacte con su administrador \n " + err.message;
                //this.modalService.open('idModalErrorfull');    
                this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);               
               
            },
            () => {
              this.spinner.hide();
              //console.log("************* this.httpClient.post ()::" + "The get mi efectivo now completed.");
            }
      ); 

  }
  downloadDetailPEN(){

    var body = {
      "currency":  10,
      "rut":  ""+this.objUserTo.rut
    }

    console.log("URL::"+URL.SERVICE_API_V1_CORE_GET_DOCUMENT_FILE_CLIENT_SECU_HEM + " body:: " + JSON.stringify(body) );
    
    this.httpClient.post(URL.SERVICE_API_V1_CORE_GET_DOCUMENT_FILE_CLIENT_SECU_HEM 
                , body,       
          { headers: func.WSHeaderTokenCore() ,  responseType: 'blob'  } )
        . subscribe(
        (response) => {    

          var downloadURL = window.URL.createObjectURL(response);
          var link = document.createElement('a');
          link.href = downloadURL;
          link.download = "MovimientosEfectivo.pdf";
          link.click();    
        },
        (err: HttpErrorResponse) => {
            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);  
            this.spinner.hide();
            this.simplemodalComponent.alertar("Mensaje de Alerta","Ocurrio un error inesperado, contacte con el administrador.");
            return ;
        },
        () => {
          this.spinner.hide();
        }
    );  

  }

  downloadDetailUSD(){

    var body = {
      "currency":  11,
      "rut":  ""+this.objUserTo.rut
    }

    console.log("URL::"+URL.SERVICE_API_V1_CORE_GET_DOCUMENT_FILE_CLIENT_SECU_HEM + " body:: " + JSON.stringify(body) );
    
    this.httpClient.post(URL.SERVICE_API_V1_CORE_GET_DOCUMENT_FILE_CLIENT_SECU_HEM 
                , body,       
          { headers: func.WSHeaderTokenCore() ,  responseType: 'blob'  } )
        . subscribe(
        (response) => {    

          var downloadURL = window.URL.createObjectURL(response);
          var link = document.createElement('a');
          link.href = downloadURL;
          link.download = "MovimientosEfectivo.pdf";
          link.click();    
        },
        (err: HttpErrorResponse) => {
            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);  
            this.spinner.hide();
            this.simplemodalComponent.alertar("Mensaje de Alerta","Ocurrio un error inesperado, contacte con el administrador.");
            return ;
        },
        () => {
          this.spinner.hide();
        }
    );  

  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  beforeRetiro(currency: number){

    this.toSaveCurrency = currency;

    if(currency == CURRENCY.DOLARES.ID){ // 11

      this.submitted = true; 

      if (this.dolaresRetirar.invalid ||
        this.dolaresBankAccount.invalid   ||
        this.cashAccountTypeDolaresSelected.invalid
      ) {
        this.simplemodalComponent.alertar("Mensaje de Alerta","Para el retiro en dolares, hay datos pendientes por ingresar");
        return;
      }
      
      if (Math.round(this.dolaresRetirar.value * 100) > Math.round(func.formatNumber(this.availableCashAccountTypeDolaresSelected) * 100)){
        this.simplemodalComponent.alertar("Mensaje de Alerta","El monto a retirar es mayor que el monto disponible en la cuenta efectivo.");
        return;
      }

      this.confirmarRetiroDolares();
      
    }else if(currency == CURRENCY.SOLES.ID){ // 10
      
      this.submitted2 = true;

      if (this.solesRetirar.invalid ||
          this.solesBankAccount.invalid    ||
          this.cashAccountTypeSolesSelected.invalid 
      ) {
        this.simplemodalComponent.alertar("Mensaje de Alerta","Para el retiro en soles, hay datos pendientes por ingresar");
        return;
      }    
      
      if (Math.round(this.solesRetirar.value * 100) > Math.round(func.formatNumber(this.availableCashAccountTypeSolesSelected) * 100)){
        this.simplemodalComponent.alertar("Mensaje de Alerta","El monto a retirar es mayor que el monto disponible en la cuenta efectivo.");
        return;
      }
      this.confirmarRetiroSoles();

    }

  } 

  movimientoSoles(){

    var funcion = "$(function() {  ";
    funcion += "$('#idRetirarSoles').attr('data','hide');";
    funcion += "$('#idDepositarSoles').attr('data','hide');";
    funcion += "$('[data=\"hide\"]').hide();";
    funcion += " }); ";

    eval(funcion);
  }
  
  retiroSoles(){

    var funcion = "$(function() {  ";
    funcion += "$('#idSolesMovimientos').attr('data','hide');";
    funcion += "$('#idDepositarSoles').attr('data','hide');";
    funcion += "$('[data=\"hide\"]').hide();";
    funcion += " }); ";

    eval(funcion);
  }
  
  depositoSoles(){

    var funcion = "$(function() {  ";
    funcion += "$('#idSolesMovimientos').attr('data','hide');";
    funcion += "$('#idRetirarSoles').attr('data','hide');";
    funcion += "$('[data=\"hide\"]').hide();";
    funcion += " }); ";

    eval(funcion);
  }

  movimientoDolares(){

    var funcion = "$(function() {  ";
    funcion += "$('#idRetirarDolares').attr('data','hide');";
    funcion += "$('#idDepositarDolares').attr('data','hide');";
    funcion += "$('[data=\"hide\"]').hide();";
    funcion += " }); ";

    eval(funcion);
  }
  
  retiroDolares(){

    var funcion = "$(function() {  ";
    funcion += "$('#idDolaresMovimientos').attr('data','hide');";
    funcion += "$('#idDepositarDolares').attr('data','hide');";
    funcion += "$('[data=\"hide\"]').hide();";
    funcion += " }); ";

    eval(funcion);
  }
  
  depositoDolares(){

    var funcion = "$(function() {  ";
    funcion += "$('#idDolaresMovimientos').attr('data','hide');";
    funcion += "$('#idRetirarDolares').attr('data','hide');";
    funcion += "$('[data=\"hide\"]').hide();";
    funcion += " }); ";

    eval(funcion);
  }

  cleanRequest(currency: number){

    if(currency == CURRENCY.DOLARES.ID){
      this.dolaresRetirar.setValue('');
      this.dolaresBankAccount.setValue('');
      this.dolaresRetirarMask.setValue('');
      this.cashAccountTypeDolaresSelected.setValue(null);
      this.submitted = false;  //send parameters   

    }else if(currency == CURRENCY.SOLES.ID){
      this.solesRetirar.setValue('');
      this.solesBankAccount.setValue('');
      this.solesRetirarMask.setValue('');
      this.cashAccountTypeSolesSelected.setValue(null);
      this.submitted2 = false;  //send parameters
    }

  }

  onKeyRetirar(event: any, currency: number){   
    if(currency == CURRENCY.DOLARES.ID){  
        this.dolaresRetirar.setValue(func.validateImputAmount(event.target.value));
    }else if(currency == CURRENCY.SOLES.ID){
        this.solesRetirar.setValue(func.validateImputAmount(event.target.value));    
    } 
  }

  gotoPage(page: String){
    this.router.navigate([page]);
  }

  
  openTerminos(){
    //this.modalService.open('idTerminos');  
    this.simplemodalComponent.openModalInfo();
  }
/*
  closeTerminos(file_code: String){ 
    this.modalService.close('idTerminos');
  }
*/
}
