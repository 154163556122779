import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { responseMovimientoTo } from 'src/app/model/UserModel/responseMovimientoTo';
import { movimientoDetalleTo } from 'src/app/model/UserModel/movimientoDetalleTo';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { userTo } from 'src/app/model/UserModel/userTo';
import { URL } from 'src/app/constants/url';
import { func } from 'src/app/constants/func';


@Component({
  selector: 'ark-mis-movimientos',
  templateUrl: './mis-movimientos.component.html',
  styleUrls: ['./mis-movimientos.component.css']
})
export class MisMovimientosComponent implements OnInit {

  entityMovimientoTo : responseMovimientoTo = {} as responseMovimientoTo;  

  listMovimientoDetalleTo  : Array<movimientoDetalleTo> = [] as Array<movimientoDetalleTo>;

  security_serial = null; 

  constructor(private httpClient: HttpClient,
              private router: Router,
              private loginService: LoginService,
              private  spinner: NgxSpinnerService) { }

  ngOnInit() {

      this.security_serial = JSON.parse(sessionStorage.getItem('serialCode')); 
      let objUserTo = <userTo>this.loginService.currentUser();

      console.log("para el servico :: " + this.security_serial);

      this.spinner.show();
      this.httpClient.get(URL.SERVICE_API_V1_USER + objUserTo.id_user_pk +    
                          URL.SERVICE_LOAD_MIS_INVERSIONES_DETAIL_END + this.security_serial,
            { headers: func.WSHeader() })
          . subscribe(
          (response) => {  

            let movimientos = <responseMovimientoTo>response;  
            // this.entityMovimientoTo.items = movimientos.items;  

            if(movimientos.items != null && movimientos.items.length > 0){
                for(var i=0 ; i < movimientos.items.length ; i++){
                  let detalle : movimientoDetalleTo;
                  detalle = {} as movimientoDetalleTo ;
                  
                  detalle = movimientos.items[i];
                  detalle.movement_date   = func.formatFechaDBtoUi(movimientos.items[i].movement_date);
                  detalle.operation_nro   = func.formatQuantity(movimientos.items[i].operation_nro);
                  detalle.operation_date  = func.formatFechaDBtoUi(movimientos.items[i].operation_date);
                  detalle.quantity        = func.formatQuantity(movimientos.items[i].quantity);

                  this.listMovimientoDetalleTo.push(detalle);
                }
                this.entityMovimientoTo.items = this.listMovimientoDetalleTo;
            }



            //this.entityMovimientoToSent. = (this.listaMovimientoDetalleTo);
          },
          (err: HttpErrorResponse) => {
              console.log(err.error);
              console.log(err.name);
              console.log(err.message);
              console.log(err.status);
              
              // this.mensaje = "Ocurrio un error mis inversiones \n " + err.message;
              // this.modalService.open('idModalErrorfull');
            this.spinner.hide();
            return ;
        },
        () => {
          this.spinner.hide();
          console.log("************* this.httpClient.post ()::" + "The observable is now completed.");
        }

      ); 
  }

  gotoPage(page: String){
    this.router.navigate([page]);
  }


  gotoPageRendimientos(page: String){
    sessionStorage.setItem('serialCode', JSON.stringify(this.security_serial)); 
    this.router.navigate([page]);
  }
 

}
