import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { URL } from 'src/app/constants/url';

@Component({
  selector: 'ark-headermenu-whitoutsession',
  templateUrl: './headermenu-whitoutsession.component.html',
  styleUrls: ['./headermenu-whitoutsession.component.css']
})
export class HeadermenuWhitoutsessionComponent implements OnInit {


  constructor(private router: Router) { }

  ngOnInit() {
  }

  gotoPage(page: String){
    this.router.navigate([page]); 
  }

  gotoInicio(){
    //window.location.href = URL.GO_TO_WEB_INICIO;
    this.router.navigate(['/login']); //URL.GO_TO_WEB_INICIO
  }
  
  gotoNosotros(){
    window.location.href = "https://www.acres-sab.com/"; //URL.GO_TO_WEB_NOSOTROS; 
    //this.router.navigate([URL.GO_TO_WEB_NOSOTROS]); 
  }
  
  gotoProducto(){
    window.location.href = URL.GO_TO_WEB_PRODUCTOS;
    //this.router.navigate([URL.GO_TO_WEB_PRODUCTOS]); 
  }
  
  gotoContacto(){
    window.location.href = "https://www.acres-sab.com/contactanos"; //URL.GO_TO_WEB_CONTACTO;
    //this.router.navigate([URL.GO_TO_WEB_CONTACTO]); 
  }

}
