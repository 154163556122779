import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductoDetalleTo } from 'src/app/model/UserModel/ProductoDetalleTo';
import { ProductoFinancieroTo } from 'src/app/model/UserModel/ProductoFinancieroTo';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { produtOriginMaping } from 'src/app/constants/produtOriginMaping';
import { userTo } from 'src/app/model/UserModel/userTo';
import { URL } from 'src/app/constants/url';
import { func } from 'src/app/constants/func';
import { ModalService } from 'src/app/configuration/providers/modal';
import { SimplemodalComponent } from '../../AppComponents/simplemodal/simplemodal.component';


@Component({
  selector: 'ark-detalle-inversion',
  templateUrl: './detalle-inversion.component.html',
  styleUrls: ['./detalle-inversion.component.css']
})
export class DetalleInversionComponent implements OnInit {

  @ViewChild(SimplemodalComponent,{static: false}) simplemodalComponent: SimplemodalComponent;
  objUserTo = null;
  id_security_code_pk = null;
  entityProductoDetalleTo : ProductoDetalleTo = {} as ProductoDetalleTo; 

  productPrm : ProductoFinancieroTo = {} as ProductoFinancieroTo;
  menuOrigin : null
  productoBool  = false;
  inversionBool = false;
 

 // invIdSecurityCodePk = null;

  private sub: any;

  constructor(private httpClient: HttpClient,
              private loginService: LoginService,
              private  spinner: NgxSpinnerService,
              private router: Router,
              private modalService: ModalService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.objUserTo = <userTo>this.loginService.currentUser();
   
    // parametro que lleva de mis inversiones anteriores
    let invIdSecurityCodePk = JSON.parse(sessionStorage.getItem('invIdSecurityCodePk')); 
    this.loadProductDetailBySession(invIdSecurityCodePk); 
  }


  loadProductDetailBySession(invIdSecurityCodePk: String){ 

        var body = {
          "id_security_code_pk":  invIdSecurityCodePk // invIdSecurityCodePk / "PE00006000001"
        }

        this.spinner.show(); 
        // URL.SERVICE_API_V1_CORE_GET_VIEW_PRODUCT_DETAIL
        // "https://cors-anywhere.herokuapp.com/http://52.89.179.248:8080/iBusinessIntegration/resources/external/portal/getViewProductDetail"
    
 
        this.httpClient.post(URL.SERVICE_API_V1_CORE_GET_VIEW_PRODUCT_DETAIL
                    ,body,        
              { headers: func.WSHeaderTokenCore() })
              . subscribe(
              (response) => { 

                console.log(" URL.SERVICE_API_V1_CORE_GET_VIEW_PRODUCT_DETAIL :: datos :: " + JSON.stringify(response));
                let productoDetalle = <ProductoDetalleTo>response; 
                // this.entityProductoDetalleTo.menuOrigin = 'market'; 
                this.entityProductoDetalleTo = productoDetalle; 

              },
              (err: HttpErrorResponse) => {
                  console.log(err.error);
                  console.log(err.name);
                  console.log(err.message);
                  console.log(err.status); 
                this.spinner.hide();
                return ;
            },
            () => {
              this.spinner.hide();
            console.log("************* this.httpClient.post ()::" + "The observable is now completed.");
            }
        ); 
  }

  downloadDetail(){

    var body = {
      "isin":  this.entityProductoDetalleTo.idSecurityCodePk,
      "rut":  ""+this.objUserTo.rut
    }

    console.log("URL::"+URL.SERVICE_API_V1_CORE_GET_DOCUMENT_FILE_CLIENT_SECU_HOM + " body:: " + JSON.stringify(body) );
    
    this.httpClient.post(URL.SERVICE_API_V1_CORE_GET_DOCUMENT_FILE_CLIENT_SECU_HOM 
                , body,       
          { headers: func.WSHeaderTokenCore() ,  responseType: 'blob'  } )
        . subscribe(
        (response) => {    

          var downloadURL = window.URL.createObjectURL(response);
          var link = document.createElement('a');
          link.href = downloadURL;
          link.download = "MovimientoSaldos.pdf";
          link.click();    
        },
        (err: HttpErrorResponse) => {
            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);  
            this.spinner.hide();
            this.simplemodalComponent.alertar("Mensaje de Alerta","Ocurrio un error inesperado, contacte con el administrador.");
            return ;
        },
        () => {
          this.spinner.hide();
        }
    );  

  }

  formatNumber(number){
    return func.formatNumber(number);
  }

  formatNumberFourDecimal(number){  
    return func.formatNumberFourDecimal(number); 
  } 

  gotoPage(page: String){
    this.router.navigate([page]);
  }

  gotoPageComprar(page: String){
    sessionStorage.setItem('product',JSON.stringify(this.productPrm));
    sessionStorage.setItem('productShop',null);  
    this.router.navigate([page]);
  }

  gotoPageDetCronograma(){
    let invIdSecurityCodePk = JSON.parse(sessionStorage.getItem('invIdSecurityCodePk')); 
    sessionStorage.setItem('invIdSecurityCodePk',JSON.stringify(invIdSecurityCodePk));
    this.router.navigate(['/detalleCronograma']);
  }


  gotoPageDetMovimiento(){
    let invIdSecurityCodePk = JSON.parse(sessionStorage.getItem('invIdSecurityCodePk')); 
    sessionStorage.setItem('invIdSecurityCodePk',JSON.stringify(invIdSecurityCodePk)); 
    this.router.navigate(['/detalleMovimiento']);
  }

  openTerminos(){
    this.simplemodalComponent.openModalInfo();
    //this.modalService.open('idTerminos');  
  }
/*
  closeTerminos(file_code: String){ 
    this.modalService.close('idTerminos');
  }
  */
}
