import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { step3 } from 'src/app/model/UserModel/step3';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { func } from 'src/app/constants/func';
import { ParameterService } from 'src/app/services/ParameterServices/parameter.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PARAMETER_TABLE_YES_NO } from 'src/app/constants/PARAMETER_TABLE_YES_NO';
import { countryMaping } from 'src/app/constants/countryMaping';
import { InvReqPersonReturn } from 'src/app/model/UserModel/InvReqPersonReturn';
import { MASTER_TABLE } from 'src/app/constants/masterTable';
import { parameterTableTo } from 'src/app/model/UserModel/parameterTableTo';

@Component({
  selector: 'ark-datosactividadinstruccion',
  templateUrl: './datosactividadinstruccion.component.html',
  styleUrls: ['./datosactividadinstruccion.component.css']
})
export class DatosactividadinstruccionComponent implements OnInit {

  @Input()  inputData:step3   	= {} as step3;
  @Output() outputDataEvent     = new EventEmitter<step3>();
  @Output() alertEvent        	= new EventEmitter<String>();
  @Output() modalEvent            = new EventEmitter<String>();
  
  
  currentData:step3   = {} as step3;

  formDatosActividadInstruccion   : FormGroup;
  submitted_actividadInstruccion  : boolean;
    
  constructor(private formBuilder: FormBuilder, private  spinner: NgxSpinnerService,private parameterService: ParameterService) { }

  ngOnInit() {
    this.initVariables();
    this.loadParameters();
    this.setDefaultData();
    this.initFormActividadinstruccion();
    this.initActionsPostForm();
  }

  
  nullObject(object){
    return (object == null)?'':object;
  }

  initVariables(){
    this.submitted_actividadInstruccion = false;
    this.currentData                    = this.inputData;
    //console.log("this.currentData\n"+JSON.stringify(this.currentData));
    if(this.currentData.entity_InvReqPerson == null){ this.currentData.entity_InvReqPerson = {} as InvReqPersonReturn; }
  }
  
  async loadParameters()
  {

    if(this.currentData.paramActivityType == null){
      this.currentData.paramActivityType = await this.parameterService.getParameter(MASTER_TABLE.ACTIVITY_TYPE) as Array<parameterTableTo>;
    }

    if(this.currentData.paramEducationLeve == null){
      this.currentData.paramEducationLeve = await this.parameterService.getParameter(MASTER_TABLE.EDUCATION_LEVEL) as Array<parameterTableTo>;
    }
  
    if(this.currentData.paramCommercialActivity == null){
      this.currentData.paramCommercialActivity = await this.parameterService.getParameter(MASTER_TABLE.COMERCIAL_ACTIVITY) as Array<parameterTableTo>;
    }

  }

  setDefaultData(){
    if(this.currentData.action == null || this.currentData.action == 'R'){
      
    }else{//M
      
    }
  }

  initFormActividadinstruccion(){

    this.formDatosActividadInstruccion = this.formBuilder.group({
      work_activity_type:                      [this.nullObject(this.currentData.entity_InvReqPerson.workActivityType), Validators.required],	
      education_level:                         [this.nullObject(this.currentData.entity_InvReqPerson.educationLevel), Validators.required],	
      regular_occupation_profession:           [this.nullObject(this.currentData.entity_InvReqPerson.regularOccupationProfession), Validators.required],	
      workplace:                               [this.nullObject(this.currentData.entity_InvReqPerson.workplace), Validators.required],	
      work_position:                           [this.nullObject(this.currentData.entity_InvReqPerson.workPosition), Validators.required],	
      work_entry_date:                         [this.nullObject(this.currentData.entity_InvReqPerson.workEntryDate), Validators.required],	
      workplace_ruc:                           [this.nullObject(this.currentData.entity_InvReqPerson.workplaceRuc), Validators.required],	
      company_commercial_activity:             [this.nullObject(this.currentData.entity_InvReqPerson.companyCommercialActivity), Validators.required],

      /* start formDatosExposicionPolitica */
      is_pep: 				                         [this.currentData.entity_InvReqPerson.isPep, Validators.required],
      is_spouse_pep: 		                       [this.currentData.entity_InvReqPerson.isSpousePep, Validators.required], 
      has_relative_pep: 		                   [this.currentData.entity_InvReqPerson.hasRelativePep, Validators.required],
      has_relative_pep_count:                  [this.currentData.entity_InvReqPerson.hasRelativePepCount ],
      /* end formDatosExposicionPolitica */
    });

  }

  initActionsPostForm(){
    this.changeWorkActivityType_actividadinstruccion();
    if(this.currentData.action == null || this.currentData.action == 'M' && !this.currentData.enabledForm){
      this.disabled_inputs_registrar_ficha();
    }
  }

  disabled_inputs_registrar_ficha(){
      eval('$(function(){$("#newInvestorDiv :input").prop("disabled", true); });');
      eval('$(function(){$(".navigateButton").prop("disabled", false); });');
  }

  enabled_inputs_registrar_ficha(){
    this.currentData.enabledForm = true;
    eval('$(function(){$("#newInvestorDiv :input").prop("disabled", false); });');
  }

  saveSetp3(){
    this.submitted_actividadInstruccion = true;
    if( this.formDatosActividadInstruccion.invalid ){
      this.alertar("Completar los datos obligatorios. Verifique por favor.");
      return;
    }else{
      this.setDataSetp3();
    }
  }
  
  setDataSetp3(){
    this.setEntityDataActividadinstruccion();
    this.inputData = this.currentData;
    this.outputDataEvent.emit(this.inputData);
  }

  alertar(mensaje:String){
    this.alertEvent.emit(mensaje);
    return;
  }

  openModal(mensaje:String)
  {
    this.modalEvent.emit(mensaje);
    return;
  }

  
  /*
      form functions
  */
  onKeyUpRUC(event: any, object:FormControl){
    var ruc = event.target.value;
    if(ruc.length < 11){
      object.setValue("");
    }
  }

  get form_actividadinstruccion() { return this.formDatosActividadInstruccion.controls; }

  changeWorkActivityType_actividadinstruccion(){
    this.submitted_actividadInstruccion = false;
    if(this.formDatosActividadInstruccion.value.work_activity_type == 270  ){ //NO LABORA
      this.removeValidations_actividadinstruccion_270();
    }else{//DEPENDIENTE - INDEPENDIENTE - JUBILADO/PENSIONISTA
      this.addValidations_actividadinstruccion_all();
    }
  }

  validateRuc(){
    if(!func.isValidDataRUC(this.form_actividadinstruccion.workplace_ruc.value)){
      this.form_actividadinstruccion.workplace_ruc.setValue(null);
      this.alertar("El RUC ingresado no es válido. Verifique por favor");
    }
    
  }

  addValidations_actividadinstruccion_all(){
    this.formDatosActividadInstruccion.get('regular_occupation_profession').setValidators([Validators.required]);
    this.formDatosActividadInstruccion.get('workplace').setValidators([Validators.required]);
    this.formDatosActividadInstruccion.get('work_position').setValidators([Validators.required]);
    this.formDatosActividadInstruccion.get('work_entry_date').setValidators([Validators.required]);
    this.formDatosActividadInstruccion.get('company_commercial_activity').setValidators([Validators.required]);
    this.formDatosActividadInstruccion.get('workplace_ruc').setValidators([Validators.required]);
    
    this.formDatosActividadInstruccion.get('regular_occupation_profession').updateValueAndValidity();
    this.formDatosActividadInstruccion.get('workplace').updateValueAndValidity();
    this.formDatosActividadInstruccion.get('work_position').updateValueAndValidity();
    this.formDatosActividadInstruccion.get('work_entry_date').updateValueAndValidity();
    this.formDatosActividadInstruccion.get('company_commercial_activity').updateValueAndValidity();
    this.formDatosActividadInstruccion.get('workplace_ruc').updateValueAndValidity();
  }

  removeValidations_actividadinstruccion_270(){
    this.formDatosActividadInstruccion.get('regular_occupation_profession').clearValidators();
    this.formDatosActividadInstruccion.get('workplace').clearValidators();
    this.formDatosActividadInstruccion.get('work_position').clearValidators();
    this.formDatosActividadInstruccion.get('work_entry_date').clearValidators();
    this.formDatosActividadInstruccion.get('company_commercial_activity').clearValidators();
    this.formDatosActividadInstruccion.get('workplace_ruc').clearValidators();
    
    this.formDatosActividadInstruccion.get('regular_occupation_profession').updateValueAndValidity();
    this.formDatosActividadInstruccion.get('workplace').updateValueAndValidity();
    this.formDatosActividadInstruccion.get('work_position').updateValueAndValidity();
    this.formDatosActividadInstruccion.get('work_entry_date').updateValueAndValidity();
    this.formDatosActividadInstruccion.get('company_commercial_activity').updateValueAndValidity();
    this.formDatosActividadInstruccion.get('workplace_ruc').updateValueAndValidity();
  }

  setEntityDataActividadinstruccion(){
    this.currentData.entity_InvReqPerson.workActivityType             = this.formDatosActividadInstruccion.value.work_activity_type;
    this.currentData.entity_InvReqPerson.educationLevel               = this.formDatosActividadInstruccion.value.education_level;
    this.currentData.entity_InvReqPerson.regularOccupationProfession  = this.formDatosActividadInstruccion.value.regular_occupation_profession;
    this.currentData.entity_InvReqPerson.workplace                    = this.formDatosActividadInstruccion.value.workplace;
    this.currentData.entity_InvReqPerson.workplaceRuc                 = this.formDatosActividadInstruccion.value.workplace_ruc;
    this.currentData.entity_InvReqPerson.workPosition                 = this.formDatosActividadInstruccion.value.work_position;
    this.currentData.entity_InvReqPerson.workEntryDate                = this.formDatosActividadInstruccion.value.work_entry_date;
    this.currentData.entity_InvReqPerson.companyCommercialActivity    = this.formDatosActividadInstruccion.value.company_commercial_activity;

    this.currentData.entity_InvReqPerson.isPep                        = this.formDatosActividadInstruccion.value.is_pep;			
    this.currentData.entity_InvReqPerson.isSpousePep                  = this.formDatosActividadInstruccion.value.is_spouse_pep; 		    
    this.currentData.entity_InvReqPerson.hasRelativePep               = this.formDatosActividadInstruccion.value.has_relative_pep; 
  }

  /*
      public constants
  */

  get yes() { return PARAMETER_TABLE_YES_NO.YES.ID; }
  get no()  { return PARAMETER_TABLE_YES_NO.NO.ID; }
  get localCountry(){ return countryMaping.PERU;}

  /*
      generic functions
  */

  existInList(lista:Array<number>, id:number){
    for(var current of lista){
      if(current == id){
        return true;
      }
    }
    return false;
  }

  onKeyUpDNI(event: any, object:FormControl){
    var documentNumner = event.target.value;
    //console.log("onKeyUpDNI::"+documentNumner);
    if(documentNumner.length != 8){
      object.setValue("");
      this.alertar("El DNI debe tener 8 caracteres.");//El DNI debe tener 8 dígitos
    }
  }

  onKeyUpOnliDecimal(event: any, object:FormControl){
    object.setValue(func.inputOnliDecimal(event.target.value));
  }

  onKeyOutOnlyNumericDecimal(event: any,object:FormControl){
    var valor:number = Number(event.target.value);
    var valorFormateado = func.formatNumber(valor);
    if( valorFormateado == "NaN" || valorFormateado == null){
      object.setValue("0.00");
    }else{
      object.setValue(valorFormateado);
    }
  }

  onKeyUpEmail(event: any, object:FormControl){
    object.setValue(func.inputEmail(event.target.value));
  }

  onKeyUpOnliNumeric(event: any, object:FormControl){
    object.setValue(func.inputOnliNumeric(event.target.value));
  }

  onKeyUpOnliText(event: any, object:FormControl){
    object.setValue(func.inputOnliText(event.target.value));
  }

  onKeyUpAplhaNumeric(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumeric(event.target.value));
  }

  onKeyUpAplhaNumericSpace(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumericSpace(event.target.value));
  }

  onKeyUpAplhaNumericSimple(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumericSimple(event.target.value));
  }

  onKeyUpAplhaNumericSpecial(event: any, object:FormControl){
    object.setValue(func.inputAlphaNumericSpecial(event.target.value));
  }

  onKeyUpOnliTextAndSpace(event: any, object:FormControl){
    object.setValue(func.inputOnliTextAndSpace(event.target.value));
  }

  setMaxDateBirth() { 
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = (today.getFullYear()-18);
    
    this.currentData.maxDateBirth = yyyy + '-' + mm + '-' + dd ;
  }

  setMinDateBirth() { 
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = (today.getFullYear()-110);
    
    this.currentData.minDateBirth = yyyy + '-' + mm + '-' + dd ;
  }

  setMaxDateToday() { 
    var today = new Date();
    var dd = String(today.getDate()-1).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = (today.getFullYear());
    
    this.currentData.maxDateToday = yyyy + '-' + mm + '-' + dd ;
  }

}
