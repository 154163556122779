import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { JsonPipe } from '@angular/common';
import { tick } from '@angular/core/testing';
import { MisInversionesTo } from 'src/app/model/UserModel/MisInversionesTo';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { ParameterService } from 'src/app/services/ParameterServices/parameter.service';
import { ModalService } from 'src/app/configuration/providers/modal';
import { URL } from 'src/app/constants/url';
import { userTo } from 'src/app/model/UserModel/userTo';
import { func } from 'src/app/constants/func';
import { produtOriginMaping } from 'src/app/constants/produtOriginMaping';



@Component({
  selector: 'ark-misinversiones',
  templateUrl: './misinversiones.component.html',
  styleUrls: ['./misinversiones.component.css']
})
export class MisinversionesComponent implements OnInit {

 
  listEntityMisInversionesTo : Array<MisInversionesTo> = [] as Array<MisInversionesTo>;
  total = 0;
  mensaje = '';
  token:String = "";

  constructor(private httpClient: HttpClient,
              private loginService: LoginService,
              private router: Router,
              private  spinner: NgxSpinnerService,
              private parameterService: ParameterService,
              private modalService: ModalService) { }
  
  async ngOnInit() {
    var ticket = await this.parameterService.getLoginExternal() as String;
    this.token = ticket;
    await this.getInvestmentsHome();
  }

  getTipoInversion(currency){
    if(currency=="SOL" || currency=="SOLES" || currency=="PEN"){
      return "INVERSIONES SOLES";
    }else if(currency=="DOL" || currency=="DOLARES" || currency=="USD"){
      return "INVERSIONES DOLARES AMERICANOS";
    }else{
      return "OTRAS INVERSIONES"
    }
  }

  async getInvestmentsHome(){

    var urlInvestmentHome= URL.SERVICE_API_V1_CORE_GET_INVESTMENTS_HOME;
    let objUserTo = <userTo>this.loginService.currentUser();    
    var body = {
      "rut": ""+objUserTo.rut
    }

    this.spinner.show();
    this.httpClient.post(urlInvestmentHome, body, { headers: func.WSHeaderTokenCore() })
        . subscribe(
        (response) => {

          let inversiones = response as Array<MisInversionesTo>;  
          this.listEntityMisInversionesTo = inversiones;
          
          if(this.listEntityMisInversionesTo ==null || this.listEntityMisInversionesTo.length == 0){
            
            let misInversionesTo:MisInversionesTo = {} as MisInversionesTo;
            let inversiones:Array<MisInversionesTo> = [];
            
            misInversionesTo = {} as MisInversionesTo;
            misInversionesTo.currency = "PEN";
            misInversionesTo.total = 0;
            inversiones.push(misInversionesTo);

            misInversionesTo = {} as MisInversionesTo;
            misInversionesTo.title_header = "Inversiones en Dolares";
            misInversionesTo.currency = "USD";
            misInversionesTo.total = 0;
            inversiones.push(misInversionesTo);

            this.listEntityMisInversionesTo = inversiones;
          }else if(this.listEntityMisInversionesTo.length == 1){
            let misInversionesTo:MisInversionesTo = {} as MisInversionesTo;
            if(this.listEntityMisInversionesTo[0].currency='USD'){
              
              misInversionesTo = {} as MisInversionesTo;
              misInversionesTo.currency = "PEN";
              misInversionesTo.total = 0;
            }else{

              misInversionesTo = {} as MisInversionesTo;
              misInversionesTo.currency = "USD";
              misInversionesTo.total = 0;
            }
            this.listEntityMisInversionesTo.push(misInversionesTo);
          }

        },
        (err: HttpErrorResponse) => {
            console.log("getInvestmentsHome::err.error: "+JSON.stringify(err.error));
            console.log("getInvestmentsHome::err.name: "+err.name);
            console.log("getInvestmentsHome::err.message: "+err.message);
            console.log("getInvestmentsHome::err.status: "+err.status);

            let misInversionesTo:MisInversionesTo = {} as MisInversionesTo;
            let inversiones:Array<MisInversionesTo> = [];
            
            misInversionesTo = {} as MisInversionesTo;
            misInversionesTo.currency = "PEN";
            misInversionesTo.total = 0;
            inversiones.push(misInversionesTo);

            misInversionesTo = {} as MisInversionesTo;
            misInversionesTo.title_header = "Inversiones en Dolares";
            misInversionesTo.currency = "USD";
            misInversionesTo.total = 0;
            inversiones.push(misInversionesTo);

            this.listEntityMisInversionesTo = inversiones;

          this.spinner.hide();
          return ;
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  totalFormat(prm: number){
    return func.formatNumber(prm);
  } 


  closeModal(id: string) {
    this.modalService.close(id);
  }

  gotoPage(page: String){
    this.router.navigate([page]);
  }

  gotoPageMisInversiones(page: String){
    sessionStorage.setItem('menuOrigin', JSON.stringify(produtOriginMaping.INVERSIONES));
    this.router.navigate([page]);
  }

}
