import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { preguntasTo } from 'src/app/model/UserModel/preguntasTo';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { ModalService } from 'src/app/configuration/providers/modal';
import { URL } from 'src/app/constants/url';
import { func } from 'src/app/constants/func';
import { responsePreguntasTo } from 'src/app/model/UserModel/responsePreguntasTo';



@Component({
  selector: 'ark-preguntas',
  templateUrl: './preguntas.component.html',
  styleUrls: ['./preguntas.component.css']
})
export class PreguntasComponent implements OnInit {

  listPreguntasTo : Array<preguntasTo> = [] as Array<preguntasTo>;
   
  preguntaModal : preguntasTo = {}as preguntasTo;

  constructor(private httpClient: HttpClient,
              private loginService: LoginService,
              private router: Router,
              private  spinner: NgxSpinnerService,
              private modalService: ModalService) { }
  
  ngOnInit() {

      this.spinner.show();
      this.httpClient.get(URL.SERVICE_API_V1_QUESTIONS,
        { headers: func.WSHeader() }). subscribe(
            (response) => {
              let preguntasBd = <responsePreguntasTo>response;    
              this.listPreguntasTo = preguntasBd.items;
            },
            (err: HttpErrorResponse) => {
              this.spinner.hide();
            },
            () => {
              this.spinner.hide();
            }
      );   
    
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  gotoPage(page: String){
    this.router.navigate([page]);
  }

  verRespuestas(pregunta: preguntasTo){ 
    this.preguntaModal = pregunta;
    this.modalService.open('idModalDetail');
  }

}
