import { monthsTo } from '../model/UserModel/monthsTo';
import { detalleMesCronogramaTo } from '../model/UserModel/detalleMesCronogramaTo';


 
export const monthTypeMaping = {

    ENERO                   : 1,  
    FEBRERO                 : 2,  
    MARZO                   : 3,  
    ABRIL                   : 4,  
    MAYO                    : 5,
    JUNIO                   : 6,  
    JULIO                   : 7,  
    AGOSTO                  : 8,  
    SEPTIEMBRE              : 9,  
    OCTUBRE                 : 10,  
    NOVIEMBRE               : 11,  
    DICIEMBRE               : 12,  
 
    getMonthDescription(type: number){ 
        var meses = new Map(); 
        meses.set(1,"ENERO");
        meses.set(2,"FEBRERO");
        meses.set(3, "MARZO"); 
        meses.set(4, "ABRIL"); 
        meses.set(5, "MAYO"); 
        meses.set(6, "JUNIO"); 
        meses.set(7, "JULIO"); 
        meses.set(8, "AGOSTO"); 
        meses.set(9, "SEPTIEMBRE"); 
        meses.set(10, "OCTUBRE"); 
        meses.set(11, "NOVIEMBRE"); 
        meses.set(12, "DICIEMBRE"); 
        return meses.get(type); 
    },

    getListMonts(){
        let listMonth : Array<monthsTo> = [] as Array<monthsTo>;
        let monthsTo : monthsTo = {} as monthsTo;
        
        monthsTo = {} as monthsTo; monthsTo.month_code = 1; monthsTo.month_description = 'ENERO'; listMonth.push(monthsTo);
        monthsTo = {} as monthsTo; monthsTo.month_code = 2; monthsTo.month_description = 'FEBRERO'; listMonth.push(monthsTo);
        monthsTo = {} as monthsTo; monthsTo.month_code = 3; monthsTo.month_description = 'MARZO'; listMonth.push(monthsTo);
        monthsTo = {} as monthsTo; monthsTo.month_code = 4; monthsTo.month_description = 'ABRIL'; listMonth.push(monthsTo);
        monthsTo = {} as monthsTo; monthsTo.month_code = 5; monthsTo.month_description = 'MAYO'; listMonth.push(monthsTo);
        monthsTo = {} as monthsTo; monthsTo.month_code = 6; monthsTo.month_description = 'JUNIO'; listMonth.push(monthsTo);
        monthsTo = {} as monthsTo; monthsTo.month_code = 7; monthsTo.month_description = 'JULIO'; listMonth.push(monthsTo);
        monthsTo = {} as monthsTo; monthsTo.month_code = 8; monthsTo.month_description = 'AGOSTO'; listMonth.push(monthsTo);
        monthsTo = {} as monthsTo; monthsTo.month_code = 9; monthsTo.month_description = 'SEPTIEMBRE'; listMonth.push(monthsTo);
        monthsTo = {} as monthsTo; monthsTo.month_code = 10; monthsTo.month_description = 'OCTUBRE'; listMonth.push(monthsTo);
        monthsTo = {} as monthsTo; monthsTo.month_code = 11; monthsTo.month_description = 'NOVIEMBRE'; listMonth.push(monthsTo);
        monthsTo = {} as monthsTo; monthsTo.month_code = 12; monthsTo.month_description = 'DICIEMBRE'; listMonth.push(monthsTo);
                
        return listMonth;
    },

    getMontDecember(){
        let listMonth : Array<monthsTo> = [] as Array<monthsTo>;
        let monthsTo : monthsTo = {} as monthsTo;
        
        monthsTo = {} as monthsTo; monthsTo.month_code = 12; monthsTo.month_description = 'DICIEMBRE'; listMonth.push(monthsTo);
                
        return listMonth;
    },


    
    getListMontsMap(){
        let detalle : Array<detalleMesCronogramaTo> = [] as Array<detalleMesCronogramaTo>;
        var meses = new Map(); 
        detalle = [] as Array<detalleMesCronogramaTo>;
        meses.set(1, detalle);
        detalle = [] as Array<detalleMesCronogramaTo>;
        meses.set(2, detalle);
        detalle = [] as Array<detalleMesCronogramaTo>;
        meses.set(3, detalle); 
        detalle = [] as Array<detalleMesCronogramaTo>;
        meses.set(4, detalle); 
        detalle = [] as Array<detalleMesCronogramaTo>;
        meses.set(5, detalle); 
        detalle = [] as Array<detalleMesCronogramaTo>;
        meses.set(6, detalle); 
        detalle = [] as Array<detalleMesCronogramaTo>;
        meses.set(7, detalle); 
        detalle = [] as Array<detalleMesCronogramaTo>;
        meses.set(8, detalle); 
        detalle = [] as Array<detalleMesCronogramaTo>;
        meses.set(9, detalle); 
        detalle = [] as Array<detalleMesCronogramaTo>;
        meses.set(10, detalle); 
        detalle = [] as Array<detalleMesCronogramaTo>;
        meses.set(11, detalle); 
        detalle = [] as Array<detalleMesCronogramaTo>;
        meses.set(12, detalle); 
        return meses; 

    }
 }
