import { Component, OnInit, Input } from '@angular/core'; 
import { HttpErrorResponse, HttpClient } from '@angular/common/http'; 
import { NgxSpinnerService } from 'ngx-spinner'; 
import { ModalService } from 'src/app/configuration/providers/modal';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { userTo } from 'src/app/model/UserModel/userTo';
import { URL } from 'src/app/constants/url';
import { func } from 'src/app/constants/func';
import { userAccountTo } from 'src/app/model/UserModel/userAccountTo';
import { responseTo } from 'src/app/model/UserModel/responseTo';
import { ProductoFinancieroHomeTo } from 'src/app/model/UserModel/ProductoFinancieroHomeTo';
import { cuentaBancariaTo } from 'src/app/model/UserModel/cuentaBancariaTo';
import { CURRENCY } from 'src/app/constants/CURRENCY';
import { bankCashAccount } from 'src/app/model/UserModel/bankCashAccount';
import { Router } from '@angular/router';

@Component({
  selector: 'ark-cuentaBancaria',
  templateUrl: './cuentaBancaria.component.html',
  styleUrls: ['./cuentaBancaria.component.css']
})
export class CuentaBancariaComponent implements OnInit {

 
  objUserTo = null; 
   
  listaSolesCuentaBancariaTo    : Array<bankCashAccount> = [] as Array<bankCashAccount>;
  listaDolaresCuentaBancariaTo  : Array<bankCashAccount> = [] as Array<bankCashAccount>;
 
  constructor(private httpClient: HttpClient,
              private loginService: LoginService,
              private  spinner: NgxSpinnerService,
              private router: Router) { }

  ngOnInit() {
    
    this.objUserTo = <userTo>this.loginService.currentUser();    
    
    console.log("this.objUserTo::"+ JSON.stringify(this.objUserTo));
    this.cuentaBancariaSoles();
    this.cuentaBancariaDolares();

  } 

  gotoPage(page: String){
    this.router.navigate([page]);
  }

  cuentaBancariaSoles(){

    var body = {
      "document_type": ""+this.objUserTo.document_type,
      "document_number":""+this.objUserTo.document_number,
      "currency": ""+CURRENCY.SOLES.ID,
    }

      this.spinner.show();
      this.httpClient.post(URL.SERVICE_API_V1_CORE_POST_BANK_CASH_ACCOUNT,
                        body, { headers: func.WSHeaderTokenCore() })
            . subscribe(
            (response) => {    

              this.listaSolesCuentaBancariaTo = response as Array<bankCashAccount>;

            },
            (err: HttpErrorResponse) => {
    
              console.log(err.error);
              console.log(err.name);
              console.log(err.message);
              console.log(err.status);
    
              this.spinner.hide();  
            },
            () => {
              this.spinner.hide();
              console.log("************* this.httpClient.post ()::" + "The  is now completed.");
            }
        );  
  }

  cuentaBancariaDolares(){
      
    var body = {
        "document_type": ""+this.objUserTo.document_type,
        "document_number":""+this.objUserTo.document_number,
        "currency": ""+CURRENCY.DOLARES.ID,
      }

      this.spinner.show();
      this.httpClient.post(URL.SERVICE_API_V1_CORE_POST_BANK_CASH_ACCOUNT,
                          body,  { headers: func.WSHeaderTokenCore() })
            . subscribe(
            (response) => {    

              this.listaDolaresCuentaBancariaTo = response as Array<bankCashAccount>;
              console.log("response::cuentaBancariaDolares()"+JSON.stringify(response));
            },
            (err: HttpErrorResponse) => {
    
              console.log(err.error);
              console.log(err.name);
              console.log(err.message);
              console.log(err.status);
    
              this.spinner.hide();  
            },
            () => {
              this.spinner.hide();
              console.log("************* this.httpClient.post ()::" + "The  is now completed.");
            }
        );  
  } 
 

}
