import { ENVIRONMENT } from './config';

export const MASTER_TABLE = {

    DOCUMENT_TYPE           : /*1,*/ 1,
    OPERATOR_COMPANY        : 2,
    USER_TYPE               : 3,
    USER_ACCOUNT_STATES     : 4,
    STREET_TYPE             : /*5,*/ 9,
    SETTLEMENT_TYPE         : /*6,*/ 10,
    GEO_COUNTRY             : 7,
    GEO_DEPARTMENT          : 8,
    GEO_PROVINCE            : 9,
    GEO_DISTRICT            : 10,
    MARTIAL_STATUS          : /*11,*/5,
    SEX                     : -1,//ASIGNARLE VALORES DEFAULT
    YES_NO                  : -2,//ASIGNARLE VALORES DEFAULT
    ACCEPT_DONT_ACEPT       : -4,//ASIGNARLE VALORES DEFAULT
    PARAM_ORDER_STATE       : -5,//ASIGNARLE VALORES DEFAULT
    SAB_RELATIONSHIP_TYPE   : /*14,*/28,
    KNOWLEDGE               : /*15,*/12,
    INVESTMENT_HORIZON      : /*16,*/11,
    SABS                    : /*17,*/13,
    ACTIVITY_TYPE           : /*18,*/14, 
    EDUCATION_LEVEL         : /*19,*/15,
    COMERCIAL_ACTIVITY      : /*19,*/29,
    MARTIAL_REGIMEN         : /*20,*/20,
    TYPE_PERSON_PEP         : /*21,*/21,
    FAMILIY_RELATIONSHIP    : /*22,*/22,
    BANK                    : /*23,*/-4,
    BANK_ACCOUNT_TYPE       : /*24,*/4,
    CURRENCY                : /*25,*/3,
    SEND_ORDER_BY_STR       : /*26,*/16,
    SEND_POLICY_BY          : -3,
    SEND_ACCOUNT_STATEMENT_BY: 28,
    PERFORM_OPERATIONS_ABOARD: 29,
    INCOME_COMES_FROM       : /*30,*/17,
    FUNDS_SOURCE_STR        : /*31,*/30,
    SUSCRIPTION_MODIFY      : 32, 
    FACTOR_RETENCION_PERSONA :112, // DNL - master creado por amador
    TIPO_COMISION_SAB_CLIENTE :110, // DNL - master creado por amador
    TELEPHONE_OPERATOR_TYPE : 136,
    PATRIMONIAL_AMOUNT      : 126,
    CONFIGURACION_ORDEN_PORTAL : 147,
    INVESTMENTS_OTHER          : -6,
    
 }
