import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { notificationTo } from 'src/app/model/UserModel/notificationTo';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { userTo } from 'src/app/model/UserModel/userTo';
import { URL } from 'src/app/constants/url';
import { NgxSpinnerService } from 'ngx-spinner';
import { func } from 'src/app/constants/func';
import { notificationCoreTo } from 'src/app/model/UserModel/notificationCoreTo';
import { HttpErrorResponse } from '@angular/common/http';
import { notificationsAllTo } from 'src/app/model/UserModel/notificationsAllTo';
import { emailType } from 'src/app/constants/emailType';
import { FormControl } from '@angular/forms';
import { SimplemodalComponent } from '../../AppComponents/simplemodal/simplemodal.component';
import { ModalService } from 'src/app/configuration/providers/modal';


@Component({
  selector: 'ark-mis-notificaciones',
  templateUrl: './mis-notificaciones.component.html',
  styleUrls: ['./mis-notificaciones.component.css']
})
export class MisNotificacionesComponent implements OnInit {

  @ViewChild(SimplemodalComponent,{static: false}) simplemodalComponent: SimplemodalComponent;
  objUserTo = {} as userTo; 
  mailReading : number;
  selectTab : number;
  lstNotificationTo : notificationTo[] = [];
  currentNotification : notificationTo; 
  terminos = new FormControl('');

  lstAllNotificationCoreTo : Array<notificationCoreTo> = [] as Array<notificationCoreTo>; 
  lstNotificationCoreTo : Array<notificationCoreTo> = [] as Array<notificationCoreTo>; 
  lstPages : Array<Number> = [] as Array<Number>; 

  constructor(private httpClient: HttpClient, 
              private loginService: LoginService,
              private router: Router,
              private modalService: ModalService,
              private  spinner: NgxSpinnerService) {
    this.currentNotification = { id_notification_pk: null,
                                user_emisor_Name: null,
                                user_receptor_name: null,
                                id_notification_type_pk: null,
                                id_template_pk: null,
                                date_register: null,
                                status: null,
                                message_body: null,
                                message_subject: null,
                                send_attemp: null
                              };
  }

  ngOnInit() {
    this.objUserTo = <userTo>this.loginService.currentUser();

    // this.getUserNotifications(objUserTo);

    this.loadNotification(emailType.NORMAL.ID);
    this.mailReading = null;

    this.selectTab = emailType.NORMAL.ID;
    this.terminos.setValue('');
  }


  setPage(pageNumber:number){
    this.lstNotificationCoreTo = [] as Array<notificationCoreTo>; 
    
    for( var i=(10*pageNumber); i < (10*pageNumber)+10; i++ ){
      
      if(this.lstAllNotificationCoreTo[i] != null){
        this.lstNotificationCoreTo.push(this.lstAllNotificationCoreTo[i]);
        console.log("Page: i:"+i);
      }
      
    }

    /*
    for(var i=0; i < this.lstAllNotificationCoreTo.length; i++){
      if( i >= (this.lstAllNotificationCoreTo.length-(10*pageNumber))  && i < (this.lstAllNotificationCoreTo.length-(10*pageNumber)) + 10  ){
        this.lstNotificationCoreTo.push(this.lstAllNotificationCoreTo[i]);
        console.log("Page: i:"+i);
      }
    }*/

  }

  lastPage(){
    this.lstNotificationCoreTo = [] as Array<notificationCoreTo>; 
    for(var i=0; i < this.lstAllNotificationCoreTo.length; i++){
      if( i >= (this.lstAllNotificationCoreTo.length-10)  && i < this.lstAllNotificationCoreTo.length ){
        this.lstNotificationCoreTo.push(this.lstAllNotificationCoreTo[i]);
        console.log("lastPage: i:"+i);
      }
    }

  }

  firstPage(){
    this.lstNotificationCoreTo = [] as Array<notificationCoreTo>; 
    for(var i=0; i < this.lstAllNotificationCoreTo.length; i++){
      if(i<10){
        this.lstNotificationCoreTo.push(this.lstAllNotificationCoreTo[i]);
        console.log("firstPage: i:"+i);
      }
    }

  }

  loadNotification(type :number){
    this.selectTab = type;

    this.mailReading = null;
    this.lstNotificationCoreTo = [] as Array<notificationCoreTo>; 


      let idUserPk = this.objUserTo.id_user_pk;

      var body = {
        "email" : this.objUserTo.email,
        "type"  : type,
        "terminos" : this.terminos.value != '' ? this.terminos.value : null
      }

      // URL.SERVICE_API_V1_CORE_GET_LIST_NOTIFICATIONS
      // URL.SERVICE_API_V1_CORE_GET_LIST_NOTIFICATIONS  
      // "https://cors-anywhere.herokuapp.com/http://52.89.179.248:8080/iBusinessIntegration/resources/external/portal/getListNotifications"
     
      this.spinner.show(); 
      this.httpClient.post(URL.SERVICE_API_V1_CORE_GET_LIST_NOTIFICATIONS  
                          ,body,        
              { headers: func.WSHeaderTokenCore() })
              . subscribe(
              (response) => { 
  
                let respuestaBD = <notificationsAllTo>response; 
                this.lstAllNotificationCoreTo = respuestaBD.items;
                
                console.log("Cantidad total de registros:: "+this.lstAllNotificationCoreTo.length);
                
                if(this.lstAllNotificationCoreTo.length%10 == 0){ //numeros entero
                
                  for(var i=0; i < (this.lstAllNotificationCoreTo.length/10)-1; i++){
                    this.lstPages.push(i+1);
                  }
                  
                }else{//tiene decimales
                  
                  for(var i=0; i < (this.lstAllNotificationCoreTo.length/10)-1; i++){
                    this.lstPages.push(i+1);
                  }
                  
                }
                console.log("Cantidad de paginas:: "+this.lstPages.length);
                  
                //console.log("cantidad de digitos"+this.lstPages.length);

                this.firstPage();

                this.spinner.hide(); 

              },
              (err: HttpErrorResponse) => {
                  console.log(err.error);
                  console.log(err.name);
                  console.log(err.message);
                  console.log(err.status);
                  
                  this.spinner.hide();
                  return ;
            },
            () => {
              this.spinner.hide();
          //  console.log("************* this.httpClient.post ()::" + "The observable is now completed. loadNotification.");
            }
      );

     
  
  }


  gotoHome(){
    this.router.navigate(["/home"]);
  }

  /**
  getUserNotifications(objUserTo: userTo){
    this.httpClient.get(URL.SERVICE_API_V1_USER + objUserTo.id_user_pk + "/externalNotifier",{}) 
    .subscribe(
      (data : notificationTo[]) => {
        this.lstNotificationTo = data;
      },
      (err) => console.log(err),
      () => console.log("Petición finalizada")
    )
  } */

  gotoPage(page: String){
    this.router.navigate([page]); 
  }
  
  gotoPageWithRut(page: String){
    if(this.objUserTo.rut!=null){
      this.router.navigate([page]);
    }
  }

  showNotification(notification: notificationTo){
    this.currentNotification = notification;
  }

  formatDate(date){
    return func.formatFechaDBtoUi(date);
  }

  readMail(idMail :number){ 
    if(this.mailReading == null){
      this.mailReading = idMail;
    }else{
      this.mailReading = null;
    }  
  }

  marcarImportanteMail(idProcessNotificationPk){
    this.changeEmailNotificationType(idProcessNotificationPk, emailType.IMPORTANT.ID);
  }


  archivarMail(idProcessNotificationPk){
    this.changeEmailNotificationType(idProcessNotificationPk, emailType.ARCHIVED.ID);
  }

  changeEmailNotificationType(id :number, type :number){

      var body = {
        "idNotificationLoggerPk" : id, // id,
        "typeNotification" :  type
      }

      // URL.SERVICE_API_V1_CORE_POST_UPDATE_NOTIFICATION_TYPE  
      // "https://cors-anywhere.herokuapp.com/http://52.89.179.248:8080/iBusinessIntegration/resources/external/portal/getListNotifications"
      this.spinner.show(); 
      this.httpClient.post(URL.SERVICE_API_V1_CORE_POST_UPDATE_NOTIFICATION_TYPE
                      ,body,        
            { headers: func.WSHeaderTokenCore() })
            . subscribe(
            (response) => { 
 
              console.log(response);
              this.spinner.hide();

              if(type == emailType.IMPORTANT.ID){
                this.simplemodalComponent.alertar("Mensaje de Alerta","Correo " + emailType.IMPORTANT.VALUE+ " correctamente.");
              }else if(type == emailType.ARCHIVED.ID){
                this.simplemodalComponent.alertar("Mensaje de Alerta","Correo " + emailType.ARCHIVED.VALUE+ " correctamente.");
              }

            
            },
            (err: HttpErrorResponse) => {
                console.log(err.error);
                console.log(err.name);
                console.log(err.message);
                console.log(err.status);
                
                this.spinner.hide();
                return ;
          },
          () => {
            this.spinner.hide();
        //  console.log("************* this.httpClient.post ()::" + "The observable is now completed. loadNotification.");
          }
      );

  }
  
 
  openTerminos(){
    this.simplemodalComponent.openModalInfo();
    //this.modalService.open('idTerminos');  
  }
/*
  closeTerminos(file_code: String){ 
    this.modalService.close('idTerminos');
  }
*/
}
