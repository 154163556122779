import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from 'src/app/configuration/providers/modal';
import { URL } from 'src/app/constants/url';
import { func } from 'src/app/constants/func';
import { userAccountTo } from 'src/app/model/UserModel/userAccountTo';
import { responseTo } from 'src/app/model/UserModel/responseTo';
import { SimplemodalComponent } from '../../AppComponents/simplemodal/simplemodal.component';

@Component({
  selector: 'ark-confirmar-actualizar-contrasena',
  templateUrl: './confirmar-actualizar-contrasena.component.html',
  styleUrls: ['./confirmar-actualizar-contrasena.component.css']
})
export class ConfirmarActualizarContrasenaComponent implements OnInit {

  @ViewChild(SimplemodalComponent,{static: false}) simplemodalComponent: SimplemodalComponent;

  mensaje = '';
  userId = null;
  fullName = '';
  email_token = '';
  mensajeSub = '';
  submitted = false;  //send parameters
  isValidToken = false;

  
  text1 = new FormControl(null);
  text2 = new FormControl(null);
  text3 = new FormControl(null);
  text4 = new FormControl(null);
  text5 = new FormControl(null);
  text6 = new FormControl(null);

  nuevaContrasena1 = new FormControl('', [Validators.required, Validators.minLength(6)]);
  nuevaContrasena2 = new FormControl('', [Validators.required, Validators.minLength(6)]); 
  token = new FormControl('', Validators.required);

  showPassword1 = false;
  showPassword2 = false;

  constructor(private httpClient: HttpClient, private modalService: ModalService, 
              private router: Router,
              private  spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.isValidToken = false;
    this.userId = '';
    this.fullName = ''; 

    this.showPassword1 = false;
    this.showPassword2 = false;
    
        var emailweb = window.location.search;
        this.email_token = emailweb.replace( "?emailToken=", "" ); 
        //console.log("el web tkn :: "+ this.email_token); 
 
        this.spinner.show();
        this.httpClient.get(URL.SERVICE_GET_USER_ACCOUNT_BY_EMAIL_TOKEN + this.email_token,
                  {  headers: func.WSHeader() })
                . subscribe(
              (response) => {   
                  let obj : userAccountTo = <userAccountTo>response ;
                  if(obj.items[0].status == 1){
                    this.gotoLogin();
                  }
                  this.userId = obj.items[0].id_user_pk ;
                  this.fullName = obj.items[0].name+', '+obj.items[0].first_lastname; // response 
              },
              (err: HttpErrorResponse) => {
                console.log(err.error);
                console.log(err.name);
                console.log(err.message);
                console.log(err.status);
                
                this.spinner.hide();
                this.mensajeSub = '';
                this.mensaje = "Ocurrio un error \n " + err.message;
                this.modalService.open('idModalErrorfull');
                return ;
          },
          () => {
            this.spinner.hide();
            console.log("************* this.httpClient.post ()::" + "The confirmar recuperar clave de suscripcion POST observable is now completed.");
          }
        ); 


  }
 
  public abrirModalReenvio() {
    this.modalService.open('idModalReenvio');    
  }


  reenviarToken() {
 
    this.spinner.show();
    this.httpClient.get(URL.SERVICE_API_V1_USER + this.userId + 
                        URL.SERVICE_GET_USER_TOKEN_OUT_LINE_END + this.email_token,
                          { headers: func.WSHeader() })
          . subscribe(
        (response) => {  

              let bodyParam = {
                  "phone_token" : true,
                  "email_token" : false
              }

              let responseTmp = <responseTo>response; // items[0].user_token_pk;
              let userTokenId = responseTmp.items[0].id_user_token_pk;

              this.httpClient.post(URL.SERVICE_API_V1_USER + this.userId + 
                                   URL.SERVICE_RECREATE_TOKEN_MIDDLE + userTokenId +
                                   URL.SERVICE_RECREATE_TOKEN_END,
                        bodyParam, { headers: func.WSHeader() })
                          . subscribe(
                        (response) => {  
                            console.log(" response :: " + JSON.stringify(response) );
                          
                            this.modalService.close('idModalReenvio');
                            this.mensajeSub = "Token reenviado";
                            this.mensaje = "Se generó un nuevo token de manera satisfactoria, revise su Email.";
                            this.modalService.open('idModalSuccesfullSms');
                            
                        },
                        (err: HttpErrorResponse) => {
                          console.log(err.error);
                          console.log(err.name);
                          console.log(err.message);
                          console.log(err.status);
                          
                          this.spinner.hide();
                          this.mensajeSub = '';
                          this.mensaje = "Ocurrio un error \n " + err.message;
                          this.modalService.open('idModalErrorfull');
                          return ;
                      },
                      () => {
                        this.spinner.hide();
                        console.log("************* this.httpClient.post ()::" + "The confirmar registro de suscripcion POST observable is now completed.");
                      }
              );

              // fin refrescar token
            
        },
        (err: HttpErrorResponse) => {
          console.log(err.error);
          console.log(err.name);
          console.log(err.message);
          console.log(err.status);
          
          this.spinner.hide();
          this.mensajeSub = '';
          this.mensaje = "Ocurrio un error \n " + err.message;
          this.modalService.open('idModalErrorfull');              
          return ;
      },
      () => {
        this.spinner.hide();
        console.log("************* this.httpClient.post ()::" + "The confirmar registro de suscripcion POST observable is now completed.");
      }
    );
           

}
  /*
  nextKey(nextIdInput){
    if(nextIdInput=="text2"){
      this.text2.setValue(null);
    }
    if(nextIdInput=="text3"){
      this.text3.setValue(null);
    }
    if(nextIdInput=="text4"){
      this.text4.setValue(null);
    }
    if(nextIdInput=="text5"){
      this.text5.setValue(null);
    }
    if(nextIdInput=="text6"){
      this.text6.setValue(null);
    }
    eval("$(function() {  $('#"+nextIdInput+"').focus(); });");
  }*/

  nextKey(event: any, nextIdInput){
    var tecla = event.target.value;
    if(tecla != null && tecla != "" && tecla != " "){
      if(nextIdInput=="text2"){
        this.text2.setValue(null);
      }
      if(nextIdInput=="text3"){
        this.text3.setValue(null);
      }
      if(nextIdInput=="text4"){
        this.text4.setValue(null);
      }
      if(nextIdInput=="text5"){
        this.text5.setValue(null);
      }
      if(nextIdInput=="text6"){
        this.text6.setValue(null);
      }
      eval("$(function() {  $('#"+nextIdInput+"').focus(); });");
    }
  }


  closeModal(id: string) {
     this.modalService.close(id);
  }

  

  onValidToken(){
    
    this.token.setValue(this.text1.value+""+this.text2.value+""+this.text3.value+""+this.text4.value+""+this.text5.value+""+this.text6.value);
    this.submitted = true; 

    let datos = {
      "phone_token": this.token.value,
      "email_token" : this.email_token,
    }

      this.spinner.show();
      this.httpClient.post(URL.SERVICE_API_V1_USER + this.userId + 
                           URL.SERVICE_POST_PASSWORD_VALID ,
          datos, { headers: func.WSHeader() })
  
          . subscribe(
          (response) => {   
            console.log("validtoken::response:: "+response)
            this.spinner.hide();
            this.isValidToken = true;
            this.submitted = false; 

          },
          (err: HttpErrorResponse) => {

            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);

            this.spinner.hide();
            this.mensajeSub = '';
            
            this.mensaje = "El código de recuperación no es válido. Verifique por favor"//"Ocurrio un problema en el cambio de la contraseña, reintente \n " + err.message;
            this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);
            this.isValidToken = false;
            this.submitted = false; 
          },
          () => {
            this.spinner.hide();
            //console.log("************* this.httpClient.post ()::" + "The change password is now completed.");
          }

      );   
         
  }

  onSubmit() {

    var pass1:boolean = this.onValidatePassword(this.nuevaContrasena1);
    var pass2:boolean =this.onValidatePassword(this.nuevaContrasena2);
    if(!pass1 || !pass2){
      this.simplemodalComponent.alertar("Mensaje de Alerta",'El password debe contener mínimo 8 carácteres incluye minúsculas, mayúsculas, números y simbolos ( ! , # $ ; ^ - + _ /  ).'); return;
      return;
    }


    this.token.setValue(this.text1.value+""+this.text2.value+""+this.text3.value+""+this.text4.value+""+this.text5.value+""+this.text6.value);
    this.submitted = true; 

    if(this.nuevaContrasena1.invalid ||
       this.nuevaContrasena2.invalid) {
      return;
    } 

    if (this.nuevaContrasena1.value !== this.nuevaContrasena2.value) {
      this.mensaje = "Las contraseñas no coinciden, Verifique.";
      this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);
      return;
    }  

    let datos = {
      "phone_token": this.token.value,
      "email_token" : this.email_token,
      "password": this.nuevaContrasena1.value 
    }

      this.spinner.show();
      this.httpClient.post(URL.SERVICE_API_V1_USER + this.userId + 
                           URL.SERVICE_POST_PASSWORD_RECOVER ,
          datos, { headers: func.WSHeader() })
  
          . subscribe(
          (response) => {   

            this.spinner.hide();
            this.mensajeSub = '';

            this.nuevaContrasena1.setValue(null);
            this.nuevaContrasena2.setValue(null);
            this.text1.setValue(null);
            this.text2.setValue(null);
            this.text3.setValue(null);
            this.text4.setValue(null);
            this.text5.setValue(null);
            this.text6.setValue(null);

            //this.mensaje = "Se cambio la contraseña satisfactoriamente.";
            //this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);
            //this.gotoPage("/login");
            this.submitted = false; 
            this.modalService.open("alertarManual");

          },
          (err: HttpErrorResponse) => {

            this.spinner.hide();
            this.mensajeSub = '';
            this.mensaje = "El código de recuperación no es válido. Verifique por favor"//"Ocurrio un problema en el cambio de la contraseña, reintente \n " + err.message;
            this.simplemodalComponent.alertar("Mensaje de Alerta",this.mensaje);

          },
          () => {
            this.spinner.hide();
            console.log("************* this.httpClient.post ()::" + "The change password is now completed.");
          }

      );   
              
  }

  closeModalAndGo(id:String, page:String){
    this.close(id);
    this.gotoPage(page)
  }

  close(id:String){
    this.modalService.close(id+"");
  }

  gotoLogin() {
    this.router.navigate(["/login"]);
  }

  gotoPage(page) {
    this.router.navigate([page]);
  }

  toggleShowPassword(prm : number) {
    if(prm == 1){

      this.showPassword1 = !this.showPassword1;

    }else {
      this.showPassword2 = !this.showPassword2;

    }
  }

  onKeyUpPassword(event: any, object:FormControl){
    object.setValue(func.inputBlurPassword(event.target.value));
  }

  onValidatePassword(object:FormControl){
    var completePassword = object.value;
    
    var inArrayLetter = 0;
    for(var currentPass of func.arrayLetter()){
      for(var  i=0 ; i < completePassword.length ; i++) {
        var password = completePassword.charAt(i);  
        if(currentPass == password){
          inArrayLetter = 1;
          break;
        }

      }
    }

    var inArrayCaseLetter = 0;
    for(var currentPass of func.arrayCaseLetter()){
      for(var  i=0 ; i < completePassword.length ; i++) {
        var password = completePassword.charAt(i);  
        if(currentPass == password){
          inArrayCaseLetter = 1;
          break;
        }
      }
    }

    var inArrayNumber = 0;
    for(var currentPass of func.arrayNumber()){
      for(var  i=0 ; i < completePassword.length ; i++) {
        var password = completePassword.charAt(i);  
        if(currentPass == password){
          inArrayNumber = 1;
          break;
        }
      }
    }

    var inArraySimbole = 0;
    for(var currentPass of func.arraySimbole()){
      for(var  i=0 ; i < completePassword.length ; i++) {
        var password = completePassword.charAt(i);  
        if(currentPass == password){
          inArraySimbole = 1;
          break;
        }
      }
    }

    //console.log("inArrayLetter == 1 && inArrayCaseLetter == 1 && inArrayNumber == 1 && inArraySimbole == 1 "+(inArrayLetter == 1 && inArrayCaseLetter == 1 && inArrayNumber == 1 && inArraySimbole == 1));
    if(inArrayLetter == 1 && inArrayCaseLetter == 1 && inArrayNumber == 1 && inArraySimbole == 1){
      return true;
    }else{
      return false;
    }

  }

}