import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ProductoDetalleTo } from 'src/app/model/UserModel/ProductoDetalleTo'; 
import { func } from 'src/app/constants/func';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'ark-detalle-producto',
  templateUrl: './ark-detalle-producto.component.html',
  styleUrls: ['./ark-detalle-producto.component.css']
})
export class ArkDetalleProductoComponent implements OnInit {

  @Input() entity : ProductoDetalleTo = {} as ProductoDetalleTo;  //parametros

  constructor(private router: Router, private domSanitizer: DomSanitizer) { }

  ngOnInit() {
   
  } 

  formatNumber(number){
    return func.formatNumber(number);
  }

  formatNumberFourDecimal(number){  
    return func.formatNumberFourDecimal(number); 
  } 

  getClaseInteres(cashNominal: number){
    return func.getClaseInteres(cashNominal); 
  }

 
}
