import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { viewAllCashAccountTo } from 'src/app/model/UserModel/viewAllCashAccountTo';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { ModalService } from 'src/app/configuration/providers/modal';
import { userTo } from 'src/app/model/UserModel/userTo';
import { URL } from 'src/app/constants/url';
import { func } from 'src/app/constants/func';
import { CURRENCY } from 'src/app/constants/CURRENCY';
 
@Component({
  selector: 'ark-miefectivo',
  templateUrl: './miefectivo.component.html',
  styleUrls: ['./miefectivo.component.css']
})
export class MiefectivoComponent implements OnInit {

 
  listEntityMiEfectivoTo : Array<viewAllCashAccountTo> = [] as Array<viewAllCashAccountTo>;

  mensaje = '';

  constructor(private httpClient: HttpClient,
              private loginService: LoginService,
              private router: Router,
              private  spinner: NgxSpinnerService,
              private modalService: ModalService) {
             }

  ngOnInit() {
    
    this.callService();
    
  }

  callService(){
    //this.loadEfectivosSoles();
    this.loadEfectivosDolares();
  }
  
  loadEfectivosDolares(){

    this.spinner.show(); 

    let urlViewCashAccount = URL.SERVICE_API_V1_CORE_GET_VIEW_GROUP_CASH_ACCOUNT;
    let objUserTo = <userTo>this.loginService.currentUser();
    var body = {
      "rut": ""+objUserTo.rut,
      "currency": ""+CURRENCY.DOLARES.ID,
    }

    this.httpClient.post(urlViewCashAccount, body , {  headers: func.WSHeaderTokenCore() })
        . subscribe(
              (response) => {  
                  
                //this.listEntityMiEfectivoTo = [] as Array<viewAllCashAccountTo>;
              var respontCashAccountToDolares:viewAllCashAccountTo = <viewAllCashAccountTo>response; 
              var miefectivo: viewAllCashAccountTo = {} as viewAllCashAccountTo;
              miefectivo = {} as viewAllCashAccountTo;
              miefectivo.totalBalance = respontCashAccountToDolares.totalBalance;//func.formatNumber()
              if(respontCashAccountToDolares.availableBalance != null && 
                 respontCashAccountToDolares.availableBalance != 0){
                  miefectivo.availableBalance = respontCashAccountToDolares.availableBalance;//func.formatNumber()
              }else{
                miefectivo.availableBalance = 0;
              }
              miefectivo.currencyNemonic = "USD";
              miefectivo.currencyCode = 11;
              miefectivo.currency = "DOLARES";
              miefectivo.cuentaMoneda = "CUENTA DOLARES AMERICANOS";

              this.listEntityMiEfectivoTo.push(miefectivo);
              
              this.loadEfectivosSoles();
                
              },
              (err: HttpErrorResponse) => {
                console.log(err.error);
                console.log(err.name);
                console.log(err.message);
                console.log(err.status);
                
                var miefectivo: viewAllCashAccountTo = {} as viewAllCashAccountTo;
                miefectivo = {} as viewAllCashAccountTo;
                miefectivo.totalBalance = 0;
                miefectivo.availableBalance = 0;
                miefectivo.currencyNemonic = "USD";
                miefectivo.currencyCode = 11;
                miefectivo.currency = "DOLARES";
                miefectivo.cuentaMoneda = "CUENTA DOLARES AMERICANOS";
                this.listEntityMiEfectivoTo.push(miefectivo);

                this.spinner.hide();
                this.mensaje = "Ocurrio un error al cargar los efectivos \n " + err.message;
                
                this.loadEfectivosSoles();

            },
            () => {
              this.spinner.hide();
              
            }
      ); 

  }

  
  loadEfectivosSoles(){

    this.spinner.show();

    let urlViewCashAccount = URL.SERVICE_API_V1_CORE_GET_VIEW_GROUP_CASH_ACCOUNT;
    let objUserTo = <userTo>this.loginService.currentUser();
    var body = {
      "rut": ""+objUserTo.rut,
      "currency": ""+CURRENCY.SOLES.ID,
    }
    
    this.httpClient.post(urlViewCashAccount, body , {  headers: func.WSHeaderTokenCore() })
      .subscribe(
              (response) => {
                  
                  var respontCashAccountToDolares:viewAllCashAccountTo = <viewAllCashAccountTo>response; 
                  var miefectivo: viewAllCashAccountTo = {} as viewAllCashAccountTo;
                  miefectivo = {} as viewAllCashAccountTo;
                  miefectivo.totalBalance = respontCashAccountToDolares.totalBalance;//func.formatNumber()
                  if(respontCashAccountToDolares.availableBalance != null && 
                     respontCashAccountToDolares.availableBalance != 0){
                     miefectivo.availableBalance = respontCashAccountToDolares.availableBalance;
                  }else{
                    miefectivo.availableBalance = 0;
                  }
                  miefectivo.currencyNemonic = "PEN";
                  miefectivo.currencyCode = 10;
                  miefectivo.currency = "SOLES";
                  miefectivo.cuentaMoneda = "CUENTA SOLES";
      
                  this.listEntityMiEfectivoTo.push(miefectivo);
                  
                  //this.loadEfectivosDolares();
    
              },
              (err: HttpErrorResponse) => {
                console.log(err.error);
                console.log(err.name);
                console.log(err.message);
                console.log(err.status);
                
                var miefectivo: viewAllCashAccountTo = {} as viewAllCashAccountTo;
                miefectivo = {} as viewAllCashAccountTo;
                miefectivo.totalBalance = 0;
                miefectivo.availableBalance = 0;
                miefectivo.currencyNemonic = "PEN";
                miefectivo.currencyCode = 10;
                miefectivo.currency = "SOLES";
                miefectivo.cuentaMoneda = "CUENTA SOLES";
                this.listEntityMiEfectivoTo.push(miefectivo);
               
                //this.loadEfectivosDolares();

            },
            () => {
              this.spinner.hide();
              //console.log("************* this.httpClient.post ()::" + "The get mi efectivo now completed.");
            }
      ); 

  }


  totalFormat(prm: number){
    if(prm==0){
      return "0.00";
    }else{
      return func.formatNumber(prm);
    }
  } 

  gotoPage(page: String){
    this.router.navigate([page]);
  }

}
