import { ENVIRONMENT } from './config';

export const URL = {

    SERVICE_LOGIN : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/authorize',
    SERVICE_LOGIN_TRACKING : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/authorize/tracking',
    SERVICE_TRACKING : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/authorize/userAuditTracking',

    SERVICE_LOAD_APP : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + 'user/loadApp',
    SERVICE_PARAMETER_TABLE : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + 'parameter/parameterTable',
    
    SERVICE_REGISTER_ACCOUNT : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/users/account',
    SERVICE_VERIFY_TOKEN : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/users/213/token/verify'  ,
    SERVICE_USER_ACOUNT : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/user-account/me',

    SERVICE_GET_USER_ACCOUNT_BY_PARAMS : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/user-account/me?',

    SERVICE_UPDATE_USER_ACOUNT_OUT_LINE : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/users/',
    SERVICE_UPDATE_USER_ACOUNT_OUT_LINE_END : '/account',

    SERVICE_DISABLED_USER_TOKEN_OUT_LINE : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/users/public/token/disabled',


    SERVICE_GET_USER_ACCOUNT_BY_EMAIL_TOKEN : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/users/me/account?email_token=',  
    
    SERVICE_GET_USER_ACCOUNT_REQUEST_BY_EMAIL_TOKEN : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/user-account-request/me?email_token=',

    
    SERVICE_EMAIL_TOKEN : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/user-account',
    SERVICE_GET_USER_TOKEN : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/user-token/me?email_token=',

    SERVICE_GET_USER_TOKEN_OUT_LINE_END : '/token?email_token=',
 
    SERVICE_PASSWORD_RECOVER_EMAIL : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/user-token/password-recover/email',
    SERVICE_PASSWORD_RECOVER_IDENTITY : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/user-token/password-recover/identity',
    SERVICE_PASSWORD_RECOVER_PHONE : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/user-token/password-recover/phone',

    SERVICE_RECREATE_PASSWORD : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/user-token/{{',
 
    SERVICE_RECREATE_TOKEN_MIDDLE : '/token/',
    SERVICE_RECREATE_TOKEN_END : '/recreate',

    SERVICE_CONFIRM_MODIFY_SUSC_END : '/request/confirm',

    SERVICE_MODIFY_PASSWORD_END : '/request/updatePassword',    

    SERVICE_POST_PASSWORD_RECOVER : '/password/recover/confirm',
    SERVICE_POST_PASSWORD_VALID : '/password/validtoken/confirm',

    SERVICE_API_V1_QUESTIONS : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/survey/questions',
    SERVICE_API_TERMS_CONDITIONS : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/terms/conditions/type/',

    SERVICE_RELEVANT_INFORMATION: ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/relevant/information/',

    SERVICE_API_V1_USER : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/users/',
    SERVICE_GET_USER_ACCOUNT_END : '/account', 

    FILE_PATH : '/file/', 
    POLICY_END : '/policy',
    ACCOUNT_STATEMENTS_END : '/account-statements',
    PATH_POLICY : '?type=POLICY', 
    PATH_CERTIFICATE_END : '/certificate',

    REPORT_PATH_FILE : '/file/',
    REPORT_PATH_TYPE_FILE : '?type=',     


    SERVICE_CONFIRM_ACCOUNT : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/xx',
    SERVICE_CONFIRM_UPDATE_PASSWORD : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/xx',


    SERVICE_GENERATE_EMAIL_TOKEN : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/user-token/xx',

    SERVICE_USER_ACCOUNT_REQUEST : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/user-account-request/me',

    
    SERVICE_OBTENER_USER_ACCOUNT_REQUEST : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/users/public/token?email_token=',
    SERVICE_OBTENER_USER_ACCOUNT_REQUEST_END : '&generated_by=UPDATE_INFO',
    

     SERVICE_SAVE_USER_ACCOUNT_REQUEST_END : '/request',
     
     // SERVICE_LOAD_PRODUCTS_END: '/products',
     SERVICE_LOAD_PRODUCTS_END:'/products?details=true',		
     // ERVICE_LOAD_PRODUCTS_HOME_END : '/products/home',
     ERVICE_LOAD_PRODUCTS_HOME_END : '/products',

     ERVICE_LOAD_PRODUCTS_DETAIL_END : '/products?details=true&id_security_code_pk=',

     SERVICE_LOAD_INVERSIONES_HOME_END : '/investments?details=true&currency=',

     ERVICE_LOAD_INVERSIONES_END : '/investments',
     ERVICE_LOAD_INVERSIONES_USER_ACCOUNT : '/investments/user-account',

     SERVICE_LOAD_EFECTIVO_END : '/cash-account/',

     SERVICE_LOAD_MIS_INVERSIONES_DETAIL_END : '/investments?details=true&action=movement&id_security_code_pk=',
     SERVICE_LOAD_MIS_RENDIMIENTOS_DETAIL_END : '/investments?details=true&action=yield&id_security_code_pk=',

     SERVICE_LOAD_BANK_END : '/cash-account/banks?currency=',
 
     MI_INVESTMENT_END : '/investments/currency/',
     MI_CASH_ACCOUNT_END : '/cash-account?details=true&currency=',

     SERVICE_FORGOT_PASSWORD_BY_DOCUMENT : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/users/public/password/recover/identity',

     SERVICE_FORGOT_PASSWORD_BY_EMAIL : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/users/public/password/recover/email',

     SERVICE_USER_ACCOUNT_RESEND : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/users/account/resend',

     GO_TO_TERMS_CONDITIONS  :  ENVIRONMENT.HOST_FRONTEND_APPCRES + '/terminos',

     //SERVICE_PARAMETER_TABLE_BY_MASTER : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/properties/master-table/',
     
     SERVICE_GET_PAYMENT_SCHEDULE: ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/payment/schedule/',

     SERVICE_LOAD_SURVEY : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/users/',
     SERVICE_LOAD_SURVEY_END : '/account/survey',

     SERVICE_GEOGRAFIC_COUNTRY: ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/properties/master-table/getGeographicLocationCountry',
     SERVICE_GEOGRAFIC_DEPARTMENT: ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/properties/master-table/getGeographicLocationDepartment/',
     SERVICE_GEOGRAFIC_PROVINCE: ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/properties/master-table/getGeographicLocationProvince/',
     SERVICE_GEOGRAFIC_DISTRICT: ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/properties/master-table/getGeographicLocationDistrict/',
     
    SERVICE_REGISTER_CLIENT_REQUEST : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/request',
    SERVICE_GET_CLIENT_REQUEST : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/request',
    //SERVICE_ALL_BANK: ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/bank',

    
    
    

    SERVICE_API_V1_CORE_GET_LIST_VIEW_ALL_BANK_ACCOUNT : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/getListViewAllBankAccount',
    SERVICE_API_V1_CORE_SAVE_RETIREMENT_FUND_OPERATION : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/saveRetirementFundOperation',

    SERVICE_API_V1_CORE_SAVE_INVESTOR_REQUEST : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/saveInvestorRequestAndDependencies',
    SERVICE_API_V1_CORE_GET_INVESTOR_REQUEST_EDIT : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/getInvestorRequestAndDependenciesEdit',
    
    SERVICE_API_V1_CORE_USER : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/api/v1/users/',   
    SERVICE_API_V1_CORE_LOGIN_EXTERNAL : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE_SECURITY + '/resources/users/loginexternal',   
    SERVICE_API_V1_CORE_GET_INVESTMENTS_HOME : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/getInvestmentsHome/',
    SERVICE_API_V1_CORE_GET_VIEW_ALL_INVESTMENTS : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/getViewAllInvestments/',
    SERVICE_API_V1_CORE_GET_INVESTMENTS_MOVEMENT : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/getInvestmentsMovement/',    
    SERVICE_API_V1_CORE_GET_INVESTMENTS_YIELD : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/getInvestmentsYield/',    
    SERVICE_API_V1_CORE_GET_LIST_INVESTMENTS_YIELD : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/getListInvestmentsYield/',    
    SERVICE_API_V1_CORE_GET_VIEW_ALL_CASH_ACCOUNT_SUM : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/getViewAllCashAccountSUM/',  

    SERVICE_API_V1_CORE_GET_INVESTOR_REQUEST_STATUS : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/getInvestorRequestStatus',
    SERVICE_API_V1_CORE_POST_INVESTOR_RUT : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES +'/api' + '/resources/external/portal/getRutsWithAccountRequest',
    SERVICE_API_V1_CORE_POST_PDF_RUT : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES +'/api' + '/resources/external/portal/getPDFWithAccountRequest',

    
    SERVICE_API_V1_CORE_GET_CASH_ACCOUNT_MOVEMENTS : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/getPortalViewCashAccountMovement/',
    SERVICE_API_V1_CORE_GET_CASH_ACCOUNT_TYPES : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/getPortalCashAccountTypes/',
    SERVICE_API_V1_CORE_GET_GROUP_CASH_ACCOUNT_MOVEMENTS : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/getPortalViewCashAccountMovement/',//getPortalViewGroupCashAccountMovement
    
    SERVICE_API_V1_CORE_GET_PRODUCTS_HOME : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/getProductsHome/',
    
    SERVICE_API_V1_CORE_GET_CASH_ACCOUNT_HOME : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/getListPortalViewCashAccount/',    
    SERVICE_API_V1_CORE_GET_VIEW_CASH_ACCOUNT : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/getPortalViewCashAccount/',
    SERVICE_API_V1_CORE_GET_VIEW_GROUP_CASH_ACCOUNT : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/getPortalViewGroupCashAccount/',    
    SERVICE_API_V1_CORE_GET_VIEW_ALL_PRODUCTS : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/getViewAllProducts/',
    SERVICE_API_V1_CORE_GET_VIEW_PRODUCT_DETAIL : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/getViewProductDetail/',
    
    SERVICE_API_V1_CORE_GET_LIST_NOTIFICATIONS : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/getListNotifications/',    
    SERVICE_API_V1_CORE_POST_UPDATE_NOTIFICATION_TYPE : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/getUpdateNotificationType/',    

    SERVICE_API_V1_CORE_GET_DOCUMENTS_ATTACHED_BY_SECURITY : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/getDocumentAttachedBySecurity/',    
     
    
    SERVICE_API_V1_CORE_GET_PREVIUS_INVESTMENTS : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/getPreviousInvestments/',
    SERVICE_API_V1_CORE_GET_DOCUMENT_FILE_BY_PARAMS : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/getDocumentFileByParams/',
    SERVICE_API_V1_CORE_GET_DOCUMENT_FILE_CLIENT : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/downloadFileClient/',
    SERVICE_API_V1_CORE_GET_DOCUMENT_FILE_CLIENT_SECU_CRONO : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/downloadFileClientSecCrono/',
    SERVICE_API_V1_CORE_GET_DOCUMENT_FILE_CLIENT_SECU_MOV : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/downloadFileClientSecMove/',
    SERVICE_API_V1_CORE_GET_DOCUMENT_FILE_CLIENT_SECU_HOM : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/downloadReportHAM/',
    SERVICE_API_V1_CORE_GET_DOCUMENT_FILE_CLIENT_SECU_HEM : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/downloadReportHAEM/',

    SERVICE_API_V1_CORE_POST_INVESTMENTS_AMORTIZATION_PAYMENT : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/getViewInvestmentAmortizationPayment/',
    SERVICE_API_V1_CORE_POST_INVESTMENTS_INTEREST_PAYMENT : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/getViewInvestmentInteresPayment/',
    SERVICE_API_V1_CORE_POST_BANK_CASH_ACCOUNT : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/getBankCashAccount/',    
    
    SERVICE_GET_PURCHASE_SALE_ORDER : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/get-purchase-sale-order',
    SERVICE_GET_SHOPPING_CART_DETAIL : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/get-shopping-cart-order', 
    //SERVICE_GET_SHOPPING_CART_DETAIL : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/users/cash-account/get-shopping-cart-order',
    SERVICE_REGISTER_SHOPPING_CART : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/shopping-cart',
    //SERVICE_REGISTER_SHOPPING_CART : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/v1/users/cash-account/shopping-cart',

    SERVICE_API_V1_CORE_POST_ISSUER_SESSION : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/getIssuerPortal/',
    SERVICE_API_V1_CORE_POST_ISSUER_W_SECURITY_CODE : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/getIssuerWSecurity/',
    SERVICE_ALL_BANK: ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/getAllbanks',
    SERVICE_GEOGRAFIC_LOCATION: ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/getGeographicLocation/',
    SERVICE_PARAMETER_TABLE_BY_MASTER : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_APPCRES + ENVIRONMENT.SERVICE_CONTEXT_APPCRES + '/api/resources/external/portal/getListParameterTable/',
    
    SERVICE_VALIDATE_EXIST_DATA_IN_CORE : ENVIRONMENT.CORS_ANYWHERE + ENVIRONMENT.HOST_BACKEND_CSDCORE + ENVIRONMENT.SERVICE_CONTEXT_CSDCORE + '/resources/external/portal/validateExistDataInCore/',
   

    GO_TO_WEB_INICIO               :  ENVIRONMENT.HOST_FRONTEND_WEB + '/home',
    GO_TO_WEB_NOSOTROS             :  ENVIRONMENT.HOST_FRONTEND_WEB + '/nosotros',
    GO_TO_WEB_PRODUCTOS            :  ENVIRONMENT.HOST_FRONTEND_WEB + '/productosPrincipales',
    GO_TO_WEB_CONTACTO             :  ENVIRONMENT.HOST_FRONTEND_WEB + '/contacto',
    GO_TO_WEB_LIBRO_RECLAMACIONES  :  ENVIRONMENT.HOST_FRONTEND_WEB + '/libro',
    GO_TO_WEB_PREGUNTAS            :  ENVIRONMENT.HOST_FRONTEND_WEB + '',
    GO_TO_WEB_TERMINOS_CONDICIONES :  ENVIRONMENT.HOST_FRONTEND_WEB + '',

}
