import { Component, OnInit } from '@angular/core'; 
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { ModalService } from 'src/app/configuration/providers/modal';
import { investmentTo } from 'src/app/model/UserModel/investmentTo';
import { userTo } from 'src/app/model/UserModel/userTo';
import { URL } from 'src/app/constants/url';
import { CURRENCY } from 'src/app/constants/CURRENCY';
import { func } from 'src/app/constants/func';
import { responseInvesmentTo } from 'src/app/model/UserModel/responseInvesmentTo';
import { responseInvestmentsTo } from 'src/app/model/UserModel/responseInvestmentsTo';
import { viewAllCashAccountTo } from 'src/app/model/UserModel/viewAllCashAccountTo';

@Component({
  selector: 'ark-inversiones-anteriores',
  templateUrl: './inversiones-anteriores.component.html',
  styleUrls: ['./inversiones-anteriores.component.css']
})
export class InversionesAnterioresComponent implements OnInit {

  objUserTo = null; 

  constructor(private httpClient: HttpClient, private modalService: ModalService,
              private loginService: LoginService, private router: Router,
              private  spinner: NgxSpinnerService) { } 

  listPreviusInvestmentToAll : Array<investmentTo> = [] as Array<investmentTo>;

  ngOnInit() { 
      this.objUserTo = <userTo>this.loginService.currentUser(); 
      this.loadAllPreviousInvestments();
  } 

  loadAllPreviousInvestments(){
   //  this.noDolares = false;

    this.spinner.show();

    // "https://cors-anywhere.herokuapp.com/http://52.89.179.248:8080/iBusinessIntegration/resources/external/portal/getPreviousInvestments"

    // URL.SERVICE_API_V1_CORE_GET_PREVIUS_INVESTMENTS;
    let urlDol = URL.SERVICE_API_V1_CORE_GET_PREVIUS_INVESTMENTS;
    let objUserTo = <userTo>this.loginService.currentUser();    
    var body = { 
      "rutCode":  this.objUserTo.rut // 1111122  - 
    } 

    this.httpClient.post(URL.SERVICE_API_V1_CORE_GET_PREVIUS_INVESTMENTS 
                , body, { headers: func.WSHeaderTokenCore() })
          . subscribe(
          (response) => {     
            console.log("urlDol:: "+JSON.stringify(response));
            let inversiones = <responseInvestmentsTo>response;  
            
            for (var i = 0; i < Object.values(inversiones.items).length; i++) {
              inversiones.items[i].open = false;
              this.listPreviusInvestmentToAll.push(inversiones.items[i]);          
            }              

          },
          (err: HttpErrorResponse) => {
  
            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);
  
            this.spinner.hide();
            this.modalService.open('idModalErrorfull');
          },
          () => {
            this.spinner.hide();
            console.log("************* this.httpClient.post ()::" + "The  is now completed.");
          }
      );    
  }

  showDetailMovement(i :number, open :boolean){ 
    this.listPreviusInvestmentToAll[i].open = !this.listPreviusInvestmentToAll[i].open;
  }   

  formatQuantity(number){
    return func.formatQuantity(number);
  }

  formatNumber(number){
    return func.formatNumber(number);
  }

  gotoPageDetailProduct(idSecurityCodePk :String){
    sessionStorage.setItem('invIdSecurityCodePk',JSON.stringify(idSecurityCodePk));
    this.router.navigate(['/detalleInversion']);  
  }  


}