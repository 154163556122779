import { Component, OnInit, ViewChild } from '@angular/core'; 
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';  
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { ModalService } from 'src/app/configuration/providers/modal';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { userTo } from 'src/app/model/UserModel/userTo';
import { URL } from 'src/app/constants/url';
import { func } from 'src/app/constants/func';
import { ProductoFinancieroTo } from 'src/app/model/UserModel/ProductoFinancieroTo';
import { carritoDetalleTo } from 'src/app/model/UserModel/carritoDetalleTo';
import { shoppingCartTo } from 'src/app/model/UserModel/shoppingCartTo';
import { responseCarritoTo } from 'src/app/model/UserModel/responseCarritoTo';
import { ParameterService } from 'src/app/services/ParameterServices/parameter.service';
import { terminosCondicionesMaping } from 'src/app/constants/terminosCondicionesMaping';
import { errorMaping } from 'src/app/constants/errorMaping';
import { shoppingCartDetailTo } from 'src/app/model/UserModel/shoppingCartDetailTo';
import { PurchaseSaleOrderTo } from 'src/app/model/UserModel/PurchaseSaleOrderTo';
import { parameterTableTo } from 'src/app/model/UserModel/parameterTableTo';
import { MASTER_TABLE } from 'src/app/constants/masterTable';
import { SimplemodalComponent } from '../../AppComponents/simplemodal/simplemodal.component';
import { shoppingCartResponseTo } from 'src/app/model/UserModel/shoppingCartResponseTo';
import { PurchaseSaleOrderReponse } from 'src/app/model/UserModel/PurchaseSaleOrderReponse';
import { ordenCompra } from 'src/app/model/UserModel/ordenCompra';
import { CURRENCY } from 'src/app/constants/CURRENCY';
import { viewAllCashAccountTo } from 'src/app/model/UserModel/viewAllCashAccountTo';
import { issuerSession } from 'src/app/model/UserModel/issuerSession';


@Component({
  selector: 'ark-carrito',
  templateUrl: './carrito.component.html',
  styleUrls: ['./carrito.component.css']
})
export class CarritoComponent implements OnInit {

  @ViewChild(SimplemodalComponent,{static: false}) simplemodalComponent: SimplemodalComponent;
  productPrm : ProductoFinancieroTo = {} as ProductoFinancieroTo; 
  listCarritoDetail : Array<carritoDetalleTo> = [] as Array<carritoDetalleTo>;
  listCarritoDetailSol : Array<carritoDetalleTo> = [] as Array<carritoDetalleTo>;
  listCarritoDetailDol : Array<carritoDetalleTo> = [] as Array<carritoDetalleTo>;
  listCarritoDelete : Array<carritoDetalleTo> = [] as Array<carritoDetalleTo>;
  
  listOrderShoppinCart:Array<shoppingCartResponseTo> = [] as Array<shoppingCartResponseTo>;

  totalSol:number = 0;
  totalDol:number = 0;

  carrito : responseCarritoTo = {} as responseCarritoTo;
  listCarrito : Array<responseCarritoTo> = [] as Array<responseCarritoTo>;

  terminosCondiciones = new FormControl('');

  objUserTo = null;
  listProductos = null; 
  mensaje = '';

  currentPurchaseSaleOrder : PurchaseSaleOrderReponse = {} as PurchaseSaleOrderReponse;
  parameterTablePSO:Array<parameterTableTo> = [] as Array<parameterTableTo>;     

  constructor(private modalService: ModalService, 
              private httpClient: HttpClient,
              private loginService: LoginService,
              private parameterService: ParameterService,
              private  spinner: NgxSpinnerService,
              private router: Router) { }

  async ngOnInit() {
  
    this.spinner.show();
    this.objUserTo = <userTo>this.loginService.currentUser();

    this.parameterTablePSO  = await this.parameterService.getParameter(MASTER_TABLE.CONFIGURACION_ORDEN_PORTAL) as Array<parameterTableTo>;   
    //console.log("this.parameterTablePSO::"+JSON.stringify(this.parameterTablePSO));

    this.consultartarMiCarrito();
    this.getShoppingCart();

    this.spinner.hide();
  }

  getCurrency(currency){
    
    if(currency == 10){
      return 'PEN';
    }else if(currency == 11){
      return 'USD';
    }else if(currency == 12){
      return 'EUR';
    }

  }

  getCarritoDetalleToSessionStorage(){
    return JSON.parse(sessionStorage.getItem('listCarritoDetalleTo'));
  }

  removeCarritoDetalleToSessionStorage(){
    sessionStorage.removeItem('listCarritoDetalleTo');
  }

  consultartarMiCarrito(){

    let responseBD : responseCarritoTo = {} as responseCarritoTo;
    let detalle :  carritoDetalleTo = {} as carritoDetalleTo;
    
    var listCarritoDetalleTo:Array<carritoDetalleTo> = this.getCarritoDetalleToSessionStorage() as Array<carritoDetalleTo> ;
    if(listCarritoDetalleTo!=null && listCarritoDetalleTo.length>0){
      this.splitCarritoSolDol(listCarritoDetalleTo);
    }
  }

  splitCarritoSolDol(listCarritoDetalleTo){
    this.listCarritoDetailDol = [];
    this.listCarritoDetailSol = [];

    this.listCarritoDetail = listCarritoDetalleTo;
    this.totalDol = 0;
    this.totalSol = 0;
    var carrito = null;
    for(var i=0; i<this.listCarritoDetail.length; i++){
      carrito = this.listCarritoDetail[i];
      if(carrito.moneda.trim() == 'DOL' || carrito.moneda.trim() == 'USD'){
        this.listCarritoDetailDol.push(carrito);
        this.totalDol += carrito.subtotal_payment;
      }else if(carrito.moneda.trim() == 'PEN' || carrito.moneda.trim() == 'SOL'){
        this.listCarritoDetailSol.push(carrito);
        this.totalSol += carrito.subtotal_payment;
      }
    }
    
    if(this.totalSol > 0){
      this.loadEfectivosSoles();
    }
    if(this.totalDol > 0){
      this.loadEfectivosDolares();
    }
  }

  formatNumber(input){
    if(input != null && input != "" && input !=0 && input !="undefined"){
      return func.formatNumber(input);
    }
    return input;
  }
  
  gotoPage(page: String){
    this.router.navigate([page]);
  }

  
  gotoPageBlankTerminos(page: String){
    this.modalService.close('idTerminosCompra');
    this.router.navigate([page]);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  openTerminos(){
    this.modalService.open('idTerminosCompra');  
  }

  closeTerminos(file_code: String){ 
    this.modalService.close('idTerminosCompra');
  }

  
  openTerminos2(){
    this.simplemodalComponent.openModalInfo();
    //this.modalService.open('idTerminos2');  
  }
/*
  closeTerminos2(file_code: String){ 
    this.modalService.close('idTerminos2');
  }
*/
 
  eliminarProducto(prod){
    
    var flag = false;
    let listaAux : Array<carritoDetalleTo> = [] as Array<carritoDetalleTo>;
    for(var i=0; i< this.listCarritoDetail.length; i++ )  {

      if(this.listCarritoDetail[i].id_security_code_pk == prod.id_security_code_pk && this.listCarritoDetail[i].stock_quantity == prod.stock_quantity && !flag ){
          this.listCarritoDelete.push(this.listCarritoDetail[i]);
          flag = true;
      }else{
          listaAux.push(this.listCarritoDetail[i]);
      }
    }

    this.listCarritoDetail = listaAux;
    sessionStorage.setItem('listCarritoDetalleTo', JSON.stringify(this.listCarritoDetail));

    this.splitCarritoSolDol(this.listCarritoDetail);

  }

  visualizarProducto(prod){

    var producto = null;
    for(var i=0; i< this.listCarritoDetail.length; i++ )  {
      if(this.listCarritoDetail[i].id_security_code_pk == prod.id_security_code_pk){
        producto = this.listCarritoDetail[i];
      }
    }
    
    sessionStorage.setItem('productShop',JSON.stringify(producto));    
    this.router.navigate(["/comprar"]);
  }


  gotoTerminosCondiciones(){
    sessionStorage.setItem('terminoCondicionesType', JSON.stringify(terminosCondicionesMaping.COMPRA_PRODUCTO));
    this.router.navigate([]).then(result => {  window.open(URL.GO_TO_TERMS_CONDITIONS, '_blank'); });
  }
   
  comprarCarrito(){


    this.mensaje = '';
    if(this.terminosCondiciones.value != null && this.terminosCondiciones.value == false){
      
      this.simplemodalComponent.alertar("Mensaje de Alerta","Debe marcar los Términos y condiciones para poder realizar la compra.");
    }else{
  
      if(this.listCarritoDetailDol.length > 0 && this.available_balance_dol < this.totalDol){
        this.simplemodalComponent.alertar("Mensaje de Alerta","El efectivo disponible es insuficiente para la moneda en dolares");
        return;
      }
      

      if(this.listCarritoDetailSol.length > 0 && this.available_balance_sol < this.totalSol){
        this.simplemodalComponent.alertar("Mensaje de Alerta","El efectivo disponible es insuficiente para la moneda en soles");
        return;
      }
      
      var data = this.shoppingCartAndDetail();
      this.sendShoppingCart(data);
    } 
  }

  getShoppingCartStatus(status){
    if(status == 1){
      return "Registrado";
    }else if(status == 2){
      return "Firmado";
    }else if(status == 3){
      return "Enviado";
    }else if(status == 4){
      return "Anulado";
    }
  }

  formatQuantity(variable){
    return func.formatNumber(variable);
  }

  getParameterData(idParameterTablePk){
    for(var param of this.parameterTablePSO){
      if(param.short_integer == idParameterTablePk){
        return param.text1;
      }
    }
  }

  getPurchaseSaleOrderDetail(id_shopping_cart_pk){
    //console.log("getPurchaseSaleOrderDetail::id_shopping_cart_pk::"+id_shopping_cart_pk);
    /*
    var listIssuerSession:Array<issuerSession> = JSON.parse(sessionStorage.getItem("issuerSession")) as Array<issuerSession>;
    for(var issuerSession of listIssuerSession){
      if(issuerSession.issuerCode == issuerCode){
        return <String>issuerSession.imgBase64;
      }
    }*/

    for(var shoppingCart of this.listOrderShoppinCart){
      if(shoppingCart.idShoppingCartPk == id_shopping_cart_pk){
        if(shoppingCart.listPurchaseSaleOrder !=null && shoppingCart.listPurchaseSaleOrder.length > 0){
          for(var purchaseSaleOrder of shoppingCart.listPurchaseSaleOrder){
            //if(purchaseSaleOrder.id_purchase_sale_order_pk == id_purchase_sale_order_pk){

              

              this.currentPurchaseSaleOrder = purchaseSaleOrder;
              this.currentPurchaseSaleOrder.id_shopping_cart_pk = shoppingCart.idShoppingCartPk;
              this.currentPurchaseSaleOrder.register_date = shoppingCart.registerDate;
              this.currentPurchaseSaleOrder.currency = shoppingCart.currency;
              this.currentPurchaseSaleOrder.register_status = shoppingCart.registerStatus;

              this.settIssuerSessionData(this.currentPurchaseSaleOrder.idSecurityCodeFk);
              this.currentPurchaseSaleOrder.issuerTransiet = this.issuerSession.businessName;

              this.modalService.open('purchaseSaleOrderModal');
              break;
            //}
          }
        }
      }
    }

  }

  issuerSession: issuerSession = {} as issuerSession;
  public settIssuerSessionData(id_security_code_pk:String){ 

    var body = {"idSecurityCodePk":id_security_code_pk}

    this.spinner.show();
    this.httpClient.post(URL.SERVICE_API_V1_CORE_POST_ISSUER_W_SECURITY_CODE, body, { headers: func.WSHeaderTokenCore() } )
      .subscribe(
        (response) => {
            console.log("response::"+ JSON.stringify(response));
            
            this.issuerSession =  response as issuerSession; 
            this.spinner.hide();
        },
        (err: HttpErrorResponse) => {
        },
        () => {
            this.spinner.hide();
        }
      );

}

  mensajeConsulta:string = "Buscando órdenes de compra ...";
  getShoppingCart(){

    this.mensajeConsulta = "Buscando órdenes de compra ...";

    var currentShoppinCart:shoppingCartTo;
    currentShoppinCart = {} as shoppingCartTo; 
    currentShoppinCart.rut_code=this.objUserTo.rut;

    let datosGuardar = currentShoppinCart;
    this.spinner.show();

    this.httpClient.post(URL.SERVICE_GET_SHOPPING_CART_DETAIL,
             datosGuardar, { headers: func.WSHeaderTokenCore() })
            . subscribe(
          (response) => {  
              this.listOrderShoppinCart = response as Array<shoppingCartResponseTo>;

              if(this.listOrderShoppinCart == null || this.listOrderShoppinCart.length == 0){
                this.mensajeConsulta = "Ud. no tiene ninguna orden en curso";
              }
              
          },
          (err: HttpErrorResponse) => {
            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);
            this.mensajeConsulta = "Ocurrio un error al buscar las órdenes de compra. Contacte con su Adminsitrador.";
      },
      () => {
        this.spinner.hide();
      }
    );

  }


  sendShoppingCart(datosGuardar){
    
    //console.log("URL.SERVICE_REGISTER_SHOPPING_CART::body:: "+JSON.stringify(datosGuardar));
    
    this.spinner.show();
    this.httpClient.post(URL.SERVICE_REGISTER_SHOPPING_CART,
             datosGuardar, { headers: func.WSHeaderTokenCore() })
            . subscribe(
          (response) => {  
              var currentShoppinCart:shoppingCartTo = response as shoppingCartTo;
              this.spinner.hide();  
              
              //console.log("JSON.stringify(currentShoppinCart)::"+ JSON.stringify(currentShoppinCart) );
              if(currentShoppinCart.idShoppingCartPk == 0 || currentShoppinCart.idShoppingCartPk == null){
                this.simplemodalComponent.alertar("Mensaje de Alerta","Ocurrio un error inesperado, contacte con el administrador.");
              }else{
                this.simplemodalComponent.alertar("Mensaje de Alerta","Se registro exitosamente la compra");
                this.removeCarritoDetalleToSessionStorage();
                this.listCarritoDetailDol = [];
                this.listCarritoDetailSol = [];
                this.getShoppingCart();
              }
              
          },
          (err: HttpErrorResponse) => {
            console.log(err.error);
            console.log(err.name);
            console.log(err.message);
            console.log(err.status);
      },
      () => {
        this.spinner.hide();
        //console.log("************* this.httpClient.post ()::" + "The registrarSuscripcion POST observable is now completed.");
      }
    );
  }

  formatDateCore(date:String){
    return func.formatDateCore(date);
  }
  formatDate(date:String){
    return func.formatFechaDBtoUi(date);
  }

  formatDate_line(date:String){
    return func.formatFechaDBtoUi_line(date);
  }

  shoppingCartAndDetail(){
    
    var listCurrentShoppinCart:Array<shoppingCartTo> = [] as Array<shoppingCartTo>;
    var currentShoppinCart:shoppingCartTo;
    
    if(this.listCarritoDetailDol != null && this.listCarritoDetailDol.length>0){
      //dolares
      currentShoppinCart = {} as shoppingCartTo; 
      currentShoppinCart.rut_code=this.objUserTo.rut;
      currentShoppinCart.currency = 11;//11 - dolares
      currentShoppinCart.items_quantity = this.listCarritoDetailDol.length;
      currentShoppinCart.total_payment = this.totalDol;
      currentShoppinCart.register_status = 1;
      currentShoppinCart.list_shopping_cart_detail = this.shoppingCartDetail(this.listCarritoDetailDol);
      currentShoppinCart.register_user = this.objUserTo.username;
      listCurrentShoppinCart.push(currentShoppinCart);
    }

    if(this.listCarritoDetailSol != null && this.listCarritoDetailSol.length>0){
      //soles
      currentShoppinCart = {} as shoppingCartTo; 
      currentShoppinCart.rut_code=this.objUserTo.rut;
      currentShoppinCart.currency = 10;//10 - soles
      currentShoppinCart.items_quantity = this.listCarritoDetailSol.length;
      currentShoppinCart.total_payment = this.totalSol;
      currentShoppinCart.register_status = 1;
      currentShoppinCart.list_shopping_cart_detail = this.shoppingCartDetail(this.listCarritoDetailSol);
      currentShoppinCart.register_user = this.objUserTo.username;
      listCurrentShoppinCart.push(currentShoppinCart);
    }

    var orden:ordenCompra = {} as ordenCompra;
    orden.listCurrentShoppinCart = listCurrentShoppinCart;
    return orden;

  }

  
  shoppingCartDetail(listCarrito:Array<carritoDetalleTo>){
    var listShoppingCartDetail:Array<shoppingCartDetailTo> = [] as Array<shoppingCartDetailTo>;

    
    var currentShoppingCartDetail:shoppingCartDetailTo;
    currentShoppingCartDetail = {} as shoppingCartDetailTo;

    for(var i=0; i < listCarrito.length; i++){
      currentShoppingCartDetail.nominal_value    = listCarrito[i].valornNominal;
      currentShoppingCartDetail.stock_quantity   = listCarrito[i].stock_quantity;
      currentShoppingCartDetail.id_security_code_fk = listCarrito[i].id_security_code_pk;
      currentShoppingCartDetail.subtotal_payment = (currentShoppingCartDetail.nominal_value * currentShoppingCartDetail.stock_quantity);
      currentShoppingCartDetail.register_status  = 1;
      listShoppingCartDetail.push(currentShoppingCartDetail);
    }

    return listShoppingCartDetail;
  }


  respontCashAccountTo : viewAllCashAccountTo = {} as viewAllCashAccountTo;
  available_balance_sol = 0;
  available_balance_dol = 0;

  loadEfectivosDolares(){

    this.spinner.show(); 

    let urlViewCashAccount = URL.SERVICE_API_V1_CORE_GET_VIEW_GROUP_CASH_ACCOUNT;
    let objUserTo = <userTo>this.loginService.currentUser();
    var body = {
      "rut": ""+objUserTo.rut,
      "currency": ""+CURRENCY.DOLARES.ID,
    }

    this.httpClient.post(urlViewCashAccount, body , {  headers: func.WSHeaderTokenCore() })
        . subscribe(
              (response) => {  
                  
                  this.respontCashAccountTo = <viewAllCashAccountTo>response; 
                  this.available_balance_dol =  this.respontCashAccountTo.availableBalance;
                
              },
              (err: HttpErrorResponse) => {
                console.log(err.error);
                console.log(err.name);
                console.log(err.message);
                console.log(err.status);
                
                this.available_balance_dol =  0;

                this.spinner.hide();
            },
            () => {
              this.spinner.hide();
              //console.log("************* this.httpClient.post ()::" + "The get mi efectivo now completed.");
            }
      ); 

  }

  loadEfectivosSoles(){

    this.spinner.show();

    let urlViewCashAccount = URL.SERVICE_API_V1_CORE_GET_VIEW_GROUP_CASH_ACCOUNT;
    let objUserTo = <userTo>this.loginService.currentUser();
    var body = {
      "rut": ""+objUserTo.rut,
      "currency": ""+CURRENCY.SOLES.ID,
    }
    
    this.httpClient.post(urlViewCashAccount, body , {  headers: func.WSHeaderTokenCore() })
      .subscribe(
              (response) => {
                
                  this.respontCashAccountTo = <viewAllCashAccountTo>response;  
                  this.available_balance_sol = this.respontCashAccountTo.availableBalance;

              },
              (err: HttpErrorResponse) => {
                console.log(err.error);
                console.log(err.name);
                console.log(err.message);
                console.log(err.status);
                
                this.available_balance_sol = 0;
            },
            () => {
              this.spinner.hide();
              //console.log("************* this.httpClient.post ()::" + "The get mi efectivo now completed.");
            }
      ); 

  }

  
}
