export const claseInteresType = {  
    
    EFECTIVO          : 1211,
    NOMINAL           : 1212, 

    getClaseInteresTypeDescription(type: number){ 
        var claseInteresMap = new Map(); 
        claseInteresMap.set(1211, "TEA");
        claseInteresMap.set(1212, "TNA"); 

        return claseInteresMap.get(type); 
    },


};
