import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { URL } from 'src/app/constants/url';
import { func } from 'src/app/constants/func';
import { responseTo } from 'src/app/model/UserModel/responseTo';
import { SimplemodalComponent } from '../../AppComponents/simplemodal/simplemodal.component';
import { MESSAGE_ES } from 'src/app/constants/message_es';

@Component({
  selector: 'ark-suscripcion-confirmerror',
  templateUrl: './suscripcion-confirmerror.component.html',
  styleUrls: ['./suscripcion-confirmerror.component.css']
})
export class SuscripcionConfirmerrorComponent implements OnInit {

  @ViewChild(SimplemodalComponent,{static: false}) simplemodalComponent: SimplemodalComponent;
  userId = null;
  email_token = null;

  constructor(private httpClient: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private  spinner: NgxSpinnerService) { }

  ngOnInit() {
  }

  reenviarToken() {
 
    this.spinner.show();
    this.httpClient.get(URL.SERVICE_API_V1_USER + this.userId + 
                        URL.SERVICE_GET_USER_TOKEN_OUT_LINE_END + this.email_token,
                          { headers: func.WSHeader() })
          . subscribe(
        (response) => {  

              let bodyParam = {
                "phone_token" : true,
                "email_token" : false
              }

              let responseTmp = <responseTo>response;
              let userTokenId = responseTmp.items[0].id_user_token_pk;

              this.httpClient.post(URL.SERVICE_API_V1_USER + this.userId + 
                                   URL.SERVICE_RECREATE_TOKEN_MIDDLE + userTokenId +
                                   URL.SERVICE_RECREATE_TOKEN_END, bodyParam, { headers: func.WSHeader() })
                    . subscribe(
                    (response) => {  
                      this.simplemodalComponent.alertar(MESSAGE_ES.CABECERA.WARNING, MESSAGE_ES.GENERIC.RESEND_TOKEN_SUCESS);
                    },
                    (err: HttpErrorResponse) => {
                    },
                    () => {
                      this.spinner.hide();
                    }
              );
            
        },
        (err: HttpErrorResponse) => {
          
          this.simplemodalComponent.alertar(MESSAGE_ES.CABECERA.WARNING, MESSAGE_ES.GENERIC.RESEND_TOKEN_ERROR);
      },
      () => {
        this.spinner.hide();
      }
    );
           

}
  
}
