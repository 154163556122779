import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { userTo } from 'src/app/model/UserModel/userTo';
import { BehaviorSubject, Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private userTo$ = new Subject<userTo>();

  getUserTo$():Observable<userTo>{
    return this.userTo$.asObservable();
  }

  constructor(private httpClient: HttpClient, private router: Router) { }

  public currentUser(): userTo {
    return <userTo>JSON.parse(sessionStorage.getItem('loginUser'));
  }

  public isLogged(){
    let userToLocal = (<userTo>JSON.parse(sessionStorage.getItem('loginUser')));
    return (userToLocal != null && userToLocal.login_user != null)? true : false; 
  }

  public isLoggedAndRut(){
    let userToLocal = (<userTo>JSON.parse(sessionStorage.getItem('loginUser')));
    return (userToLocal != null && userToLocal.rut != null)? true : false; 
  }

  public pageWithoutMenu(){
    var url = this.router.url
    var pageWithoutMenu = ( url.includes("/login") || url.includes("/suscripcion") );

    return pageWithoutMenu; 
  }

  public isNewInvestor(){
    var url = this.router.url
    var isNewInvestor = ( url.includes("/newinvestor") );

    return isNewInvestor; 
  }

  public logout() {
    sessionStorage.removeItem('loginUser');
    this.userTo$.next(<userTo>{id:0, fullname:null, username:null});
    this.router.navigate(['/login']);
  }

  public login(userTo: userTo){
    this.userTo$.next(userTo);
  }

}
