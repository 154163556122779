import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { responseRendimientoTo } from 'src/app/model/UserModel/responseRendimientoTo';
import { rendimientoDetalleTo } from 'src/app/model/UserModel/rendimientoDetalleTo';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { URL } from 'src/app/constants/url';
import { userTo } from 'src/app/model/UserModel/userTo';
import { func } from 'src/app/constants/func';

@Component({
  selector: 'ark-mis-rendimientos',
  templateUrl: './mis-rendimientos.component.html',
  styleUrls: ['./mis-rendimientos.component.css']
})
export class MisRendimientosComponent implements OnInit {


  entityRendimientoTo : responseRendimientoTo = {} as responseRendimientoTo;
  listRendimientoDetalleTo  : Array<rendimientoDetalleTo> = [] as Array<rendimientoDetalleTo>;

  security_serial = null; 

  constructor(private httpClient: HttpClient,
              private router: Router,
              private loginService: LoginService,
              private  spinner: NgxSpinnerService) { }

  ngOnInit() {

      this.security_serial = JSON.parse(sessionStorage.getItem('serialCode')); 
      let objUserTo = <userTo>this.loginService.currentUser();

      console.log("para el servico :: " + this.security_serial);

      this.spinner.show();
      this.httpClient.get(URL.SERVICE_API_V1_USER + objUserTo.id_user_pk +    
                          URL.SERVICE_LOAD_MIS_RENDIMIENTOS_DETAIL_END + this.security_serial,
            { headers: func.WSHeader() })
          . subscribe(
          (response) => {  

            let redimiento = <responseRendimientoTo>response;    

            if(redimiento.investments_yield != null && redimiento.investments_yield.length > 0){
                for(var i=0 ; i < redimiento.investments_yield.length ; i++){
                  let detalle : rendimientoDetalleTo;
                  detalle = {} as rendimientoDetalleTo ;
                  
                  detalle = redimiento.investments_yield[i];
                  detalle.deposit_date    = func.formatFechaDBtoUi(redimiento.investments_yield[i].deposit_date);
                  detalle.yield_percent   = func.formatNumber(redimiento.investments_yield[i].yield_percent);
                  // detalle.state_code      = 2;
                  detalle.operation_nro   = func.formatQuantity(redimiento.investments_yield[i].operation_nro);

                  this.listRendimientoDetalleTo.push(detalle);
                }
                this.entityRendimientoTo.investments_ammount  = func.formatNumber(redimiento.investments_ammount);
                this.entityRendimientoTo.amortize_ammount     = func.formatNumber(redimiento.amortize_ammount);
                this.entityRendimientoTo.yield_percent        = func.formatNumber(redimiento.yield_percent);
                this.entityRendimientoTo.currency             = redimiento.currency;
                this.entityRendimientoTo.investments_yield    = this.listRendimientoDetalleTo; // lista
            } 
 
          },
          (err: HttpErrorResponse) => {
              console.log(err.error);
              console.log(err.name);
              console.log(err.message);
              console.log(err.status);
              
              // this.mensaje = "Ocurrio un error mis inversiones \n " + err.message;
              // this.modalService.open('idModalErrorfull');
            this.spinner.hide();
            return ;
        },
        () => {
          this.spinner.hide();
          console.log("************* this.httpClient.post ()::" + "The observable is now completed.");
        }

      ); 
  }

  gotoPage(page: String){
    this.router.navigate([page]);
  }

  gotoPageMovimientos(page: String){
    sessionStorage.setItem('serialCode', JSON.stringify(this.security_serial)); 
    this.router.navigate([page]);
  }


}