import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ParameterService } from 'src/app/services/ParameterServices/parameter.service';
import { func } from 'src/app/constants/func';
import { step3CurrentPep } from 'src/app/model/UserModel/step3CurrentPep';
import { InvestorRequestPepToReturn } from 'src/app/model/UserModel/InvestorRequestPepToReturn';
import { InvReqPepRelativeToReturn } from 'src/app/model/UserModel/InvReqPepRelativeToReturn';
import { InvReqPepLegalEntityParticToReturn } from 'src/app/model/UserModel/InvReqPepLegalEntityParticToReturn';
import { PARAMETER_TABLE_YES_NO } from 'src/app/constants/PARAMETER_TABLE_YES_NO';
import { countryMaping } from 'src/app/constants/countryMaping';
import { MASTER_TABLE } from 'src/app/constants/masterTable';
import { parameterTableTo } from 'src/app/model/UserModel/parameterTableTo';

@Component({
  selector: 'ark-datosanexopep',
  templateUrl: './datosanexopep.component.html',
  styleUrls: ['./datosanexopep.component.css']
})
export class DatosanexopepComponent implements OnInit {



  @Input()  inputData:step3CurrentPep   	= {} as step3CurrentPep;
  @Output() outputDataEvent               = new EventEmitter<step3CurrentPep>();
  @Output() alertEvent        	          = new EventEmitter<String>();
  @Output() modalEvent                    = new EventEmitter<String>();

  submitted:boolean = false;
  submittedInvReqPepLegalEntityParticTo:boolean = false;
  submittedInvReqPepRelative:boolean = false;
  
  formDatosAnexoPep: FormGroup;
  formInvReqPepLegalEntityParticTo: FormGroup;
  formInvReqPepRelativeTo: FormGroup;

  currentData:step3CurrentPep   = {} as step3CurrentPep;
  
  constructor(private formBuilder: FormBuilder, private  spinner: NgxSpinnerService,private parameterService: ParameterService) { }

  ngOnInit() {
    this.initVariables();
    this.loadParameters();
    this.setDefaultData();
    this.initForm();
    this.initActionsPostForm();
  }

  initVariables(){
    this.currentData                              = this.inputData;
    if(this.currentData.currentInvestorRequestPepTo == null ){
      this.currentData.currentInvestorRequestPepTo                            = {} as InvestorRequestPepToReturn;      
      this.currentData.currentInvestorRequestPepTo.listInvReqPepLegalEntPart  = [] as Array<InvReqPepLegalEntityParticToReturn>;
      this.currentData.currentInvestorRequestPepTo.listInvReqPepRelative      = [] as Array<InvReqPepRelativeToReturn>; 
    }
    this.setMaxDateToday();
  }
  
  nullObject(object){
    return (object == null)?'':object;
  }

  async loadParameters(){

    
    if(this.currentData.paramTypePersonPep == null){
      this.currentData.paramTypePersonPep = await this.parameterService.getParameter(MASTER_TABLE.TYPE_PERSON_PEP)  as Array<parameterTableTo>;
    }
    
    if(this.currentData.paramFamilyRelationship == null){
      this.currentData.paramFamilyRelationship = await this.parameterService.getParameter(MASTER_TABLE.FAMILIY_RELATIONSHIP)  as Array<parameterTableTo>;
    }

  }

  setDefaultData(){
    if(this.currentData.action == null || this.currentData.action == 'R'){
      
    }else{//M
      
    }
  }

  initForm(){
    this.formDatosAnexoPep = this.formBuilder.group({
      type_person_pep: 				          [this.nullObject(this.currentData.currentInvestorRequestPepTo.typePersonPep)               , Validators.required],
      name: 		                        [this.nullObject(this.currentData.currentInvestorRequestPepTo.name)                        , Validators.required],
      first_lastname: 		              [this.nullObject(this.currentData.currentInvestorRequestPepTo.firstLastname)               , Validators.required],
      second_lastname: 		              [this.nullObject(this.currentData.currentInvestorRequestPepTo.secondLastname)                                   ],
      document_type: 		                [this.nullObject(this.currentData.currentInvestorRequestPepTo.documentType)                , Validators.required],
      document_number: 		              [this.nullObject(this.currentData.currentInvestorRequestPepTo.documentNumber)              , Validators.required],
      job_role:	 		                    [this.nullObject(this.currentData.currentInvestorRequestPepTo.jobRole)                     , Validators.required],                  
      job_entity:	               		    [this.nullObject(this.currentData.currentInvestorRequestPepTo.jobEntity)                   , Validators.required],
      job_country:                      [this.nullObject(this.currentData.currentInvestorRequestPepTo.jobCountry)                  , Validators.required],
      job_date_start:	           		    [this.nullObject(this.currentData.currentInvestorRequestPepTo.jobDateStart)                , Validators.required],
      job_date_end: 		                [this.nullObject(this.currentData.currentInvestorRequestPepTo.jobDateEnd)                  , Validators.required],
      involves_resource_management: 	  [this.nullObject(this.currentData.currentInvestorRequestPepTo.involvesResourceManagement)  , Validators.required],
      ListInvReqPepLegalEntityParticTo: [this.nullObject(this.currentData.currentInvestorRequestPepTo.listInvReqPepLegalEntPart)                        ],
      ListInvReqPepRelativeTo:          [this.nullObject(this.currentData.currentInvestorRequestPepTo.listInvReqPepRelative)                            ],
    });
 
    //formulario participacion en persona juridica
    this.formInvReqPepLegalEntityParticTo = this.formBuilder.group({
      entity_name: 		                      [''   , Validators.required],
      document_number: 		                  [''   , Validators.required],
      percent_participation: 		            [null , Validators.required],
    });
    
    //formulario pep en persona natural
    this.formInvReqPepRelativeTo = this.formBuilder.group({
      name: 		                            [''     , Validators.required],
      first_lastname: 		                  [''     , Validators.required],
      second_lastname: 		                  [''                          ],
      type_relative_pep: 		                [null   , Validators.required],
    });

  }

  initActionsPostForm(){
    if(this.currentData.action == null || this.currentData.action == 'M' && !this.currentData.enabledForm){
      this.disabled_inputs_registrar_ficha()
    }
  }

  disabled_inputs_registrar_ficha(){
    eval('$(function(){$("#newInvestorDiv :input").prop("disabled", true); });');
    eval('$(function(){$(".navigateButton").prop("disabled", false); });');
  }
  
  enabled_inputs_registrar_ficha(){
    this.currentData.enabledForm = true;
    eval('$(function(){$("#newInvestorDiv :input").prop("disabled", false); });');
    eval('$(function(){$(".inputNotEdit").prop("disabled", true); });');
  }

  saveAnexoPep(){
    this.inputData = this.currentData;
    this.outputDataEvent.emit(this.inputData);
  }

  alertar(mensaje:String){
    this.alertEvent.emit(mensaje);
    return;
  }

  openModal(mensaje:String)
  {
    this.modalEvent.emit(mensaje);
    return;
  }

  setMinDateCese(){
    this.currentData.minDateCese = this.formDatosAnexoPep.controls.job_date_start.value;
    this.formDatosAnexoPep.controls.job_date_end.setValue(null);
  };


  onKeyUpRUC(event: any, object:FormControl){
    var ruc = event.target.value;
    if(ruc.length < 11){
      object.setValue("");
    }
  }
  
  validateRuc(){
    if(!func.isValidDataRUC(this.formInvReqPepLegalEntityPartic.document_number.value)){
      this.formInvReqPepLegalEntityPartic.document_number.setValue(null);
      this.alertar("El RUC ingresado no es válido. Verifique por favor");
    }
    
  }

  /*
      functions
  */

  test(){
    this.setDataAnexoPep();
    this.saveAnexoPep();
  }

  get form() { return this.formDatosAnexoPep.controls; }
  get formInvReqPepLegalEntityPartic() { return this.formInvReqPepLegalEntityParticTo.controls; }
  get formInvReqPepRelative() { return this.formInvReqPepRelativeTo.controls; }


  setDataAnexoPep(){

    //this.currentData.currentInvestorRequestPepTo.listInvReqPepLegalEntPart  = this.currentData.currentInvestorRequestPepTo.listInvReqPepLegalEntPart;
    //this.currentData.currentInvestorRequestPepTo.listInvReqPepRelative      = this.currentData.currentInvestorRequestPepTo.listInvReqPepRelative;
    
    this.currentData.currentInvestorRequestPepTo.typePersonPep              = this.formDatosAnexoPep.value.type_person_pep;
    this.currentData.currentInvestorRequestPepTo.familyRelationship         = this.formDatosAnexoPep.value.family_relationship;
    this.currentData.currentInvestorRequestPepTo.name                       = this.formDatosAnexoPep.value.name;
    this.currentData.currentInvestorRequestPepTo.firstLastname              = this.formDatosAnexoPep.value.first_lastname;
    this.currentData.currentInvestorRequestPepTo.secondLastname             = this.formDatosAnexoPep.value.second_lastname;
    this.currentData.currentInvestorRequestPepTo.documentType               = this.formDatosAnexoPep.value.document_type;
    this.currentData.currentInvestorRequestPepTo.documentNumber             = this.formDatosAnexoPep.value.document_number;
    this.currentData.currentInvestorRequestPepTo.jobRole                    = this.formDatosAnexoPep.value.job_role;
    this.currentData.currentInvestorRequestPepTo.jobEntity                  = this.formDatosAnexoPep.value.job_entity;
    this.currentData.currentInvestorRequestPepTo.jobCountry                 = this.formDatosAnexoPep.value.job_country;
    this.currentData.currentInvestorRequestPepTo.jobDateStart               = this.formDatosAnexoPep.value.job_date_start;
    this.currentData.currentInvestorRequestPepTo.jobDateEnd                 = this.formDatosAnexoPep.value.job_date_end;
    this.currentData.currentInvestorRequestPepTo.involvesResourceManagement = this.formDatosAnexoPep.value.involves_resource_management;
    
  }

  onValidationFormDatosAnexoPep(){
    this.submitted = true;
    if( this.formDatosAnexoPep.invalid || 
       !(this.currentData.currentInvestorRequestPepTo.listInvReqPepRelative !=null && this.currentData.currentInvestorRequestPepTo.listInvReqPepRelative.length > 0) ){
      return false;
    }
    return true;
  }

  getTypeRelativePep(typeRelativePep:number){
    for( let parameterTableDesc of this.currentData.paramFamilyRelationship ){
      if( parameterTableDesc.parameterTablePk == typeRelativePep ){
        return parameterTableDesc.description;
      }
    }
    return '';
  }

  size11(){
    
    if(this.formInvReqPepLegalEntityPartic.document_number.value != null){
      var size = this.formInvReqPepLegalEntityPartic.document_number.value.length;
      if(size != 11){
        this.formInvReqPepLegalEntityPartic.document_number.setValue(null);
      }
    }
    
  }

  noMayor100yMayor25(){
    if(this.formInvReqPepLegalEntityPartic.percent_participation.value != null){

      var valor:number = Number(this.formInvReqPepLegalEntityPartic.percent_participation.value);
      if(valor < 25){
        this.formInvReqPepLegalEntityPartic.percent_participation.setValue(null);
      }
      if(valor>100){
        this.formInvReqPepLegalEntityPartic.percent_participation.setValue(null);
      }
    }
  }

  onValidationForm(){
      if( !(   ( this.currentData.currentInvestorRequestPepTo.listInvReqPepRelative != null  && 
                 this.currentData.currentInvestorRequestPepTo.listInvReqPepRelative.length >= 0 )  //natural  
            && ( !this.formDatosAnexoPep.invalid ) //formulario principal
        ) ){
          return false; //no es valido
        }
      return true;//es valido
  }


  //juridico / legal

  onValidationFormInvReqPepLegalEntityParticTo(){
    if(this.formInvReqPepLegalEntityParticTo.invalid ){
      return false;
    }
    return true;
  }

  onSubmitFormInvReqPepLegalEntityParticTo(){

    this.submittedInvReqPepLegalEntityParticTo = true;

    if(this.onValidationFormInvReqPepLegalEntityParticTo()){
      var rut = this.formInvReqPepLegalEntityParticTo.value.document_number;
      //validar si no supera el 100%
      if(this.currentData.currentInvestorRequestPepTo.listInvReqPepLegalEntPart != null){
          
        var porcentaje:number = 0;
        for(var legal of this.currentData.currentInvestorRequestPepTo.listInvReqPepLegalEntPart){
          if(legal.documentNumber == rut){
            this.alertEvent.emit("El ruc ya se encuentra agregado");
            return;
          }

          porcentaje += Number(legal.percentParticipation);
        }
        porcentaje = Number(porcentaje)+Number(this.formInvReqPepLegalEntityParticTo.value.percent_participation);
        if(porcentaje > 100){
          //this.simplemodalComponent.alertar("Mensaje de Alerta","La participacion no puede ser mayor al 100%");
          this.alertEvent.emit("La participacion no puede ser mayor al 100%");
          this.submittedInvReqPepLegalEntityParticTo = false;
          return;
        }
      }


      let insertObj:InvReqPepLegalEntityParticToReturn = {} as InvReqPepLegalEntityParticToReturn;
      insertObj.entityName            = this.formInvReqPepLegalEntityParticTo.controls.entity_name.value;
      insertObj.documentNumber        = this.formInvReqPepLegalEntityParticTo.controls.document_number.value;
      insertObj.percentParticipation  = this.formInvReqPepLegalEntityParticTo.controls.percent_participation.value;

      if( this.currentData.currentInvestorRequestPepTo.listInvReqPepLegalEntPart == null){
        this.currentData.currentInvestorRequestPepTo.listInvReqPepLegalEntPart = [] as Array<InvReqPepLegalEntityParticToReturn>;
      }
      this.currentData.currentInvestorRequestPepTo.listInvReqPepLegalEntPart.push(insertObj);


      this.formInvReqPepLegalEntityParticTo.reset();
      this.submittedInvReqPepLegalEntityParticTo = false;
    }

  }

  onResetFormInvReqPepLegalEntityParticTo(){
    this.submittedInvReqPepLegalEntityParticTo = false;
    this.formInvReqPepLegalEntityParticTo.reset();
  }

  removeInvReqPepRelativeTo(object:InvReqPepRelativeToReturn){
    var idx = this.currentData.currentInvestorRequestPepTo.listInvReqPepRelative.indexOf(object);
    if (idx !== -1) {
      this.currentData.currentInvestorRequestPepTo.listInvReqPepRelative.splice(idx,1);
    }
  }


  //-------------
  //natural

  onValidationFormInvReqPepRelativeTo(){
    if(this.formInvReqPepRelativeTo.invalid ){
      return false;
    }
    return true;
  }

  onSubmitFormInvReqPepRelativeTo(){

    if( this.currentData.currentInvestorRequestPepTo.listInvReqPepRelative != null && 
        this.currentData.currentInvestorRequestPepTo.listInvReqPepRelative.length > 0 ){
        //si son distintos de los hermanos, hijos y nietos, mios y del cónyuge
      if(this.formInvReqPepRelative.type_relative_pep.value != 924 && this.formInvReqPepRelative.type_relative_pep.value != 942
         && this.formInvReqPepRelative.type_relative_pep.value != 943 && this.formInvReqPepRelative.type_relative_pep.value != 304 
         && this.formInvReqPepRelative.type_relative_pep.value != 309 && this.formInvReqPepRelative.type_relative_pep.value != 310){

          for(var obj of this.currentData.currentInvestorRequestPepTo.listInvReqPepRelative){
            if(obj.typeRelativePep == this.formInvReqPepRelative.type_relative_pep.value){
              this.alertEvent.emit("ya existe un familiar con el mismo parentesco. Verifique por favor.");
              return;
            }
          }

         }
    }

    this.submittedInvReqPepRelative = true;

    if(this.onValidationFormInvReqPepRelativeTo()){

      let insertObj:InvReqPepRelativeToReturn = {} as InvReqPepRelativeToReturn;
      insertObj.name            = this.formInvReqPepRelativeTo.controls.name.value;
      insertObj.firstLastname   = this.formInvReqPepRelativeTo.controls.first_lastname.value;
      insertObj.secondLastname  = this.formInvReqPepRelativeTo.controls.second_lastname.value;
      insertObj.typeRelativePep = this.formInvReqPepRelativeTo.controls.type_relative_pep.value;
      
      if( this.currentData.currentInvestorRequestPepTo.listInvReqPepRelative == null){
        this.currentData.currentInvestorRequestPepTo.listInvReqPepRelative = [] as Array<InvReqPepRelativeToReturn>;
      }
      this.currentData.currentInvestorRequestPepTo.listInvReqPepRelative.push(insertObj);
      this.formInvReqPepRelativeTo.reset();
      this.submittedInvReqPepRelative = false;
    }
  }

  onResetFormInvReqPepRelativeTo(){
    this.submittedInvReqPepRelative = false;
    this.formInvReqPepRelativeTo.reset();
  }

  removeInvReqPepLegalEntityParticTo(object:InvReqPepLegalEntityParticToReturn){
    var idx = this.currentData.currentInvestorRequestPepTo.listInvReqPepLegalEntPart.indexOf(object);
    if (idx !== -1) {
      this.currentData.currentInvestorRequestPepTo.listInvReqPepLegalEntPart.splice(idx,1);
    }
  }


  /*
      public constants
  */

 get yes() { return PARAMETER_TABLE_YES_NO.YES.ID; }
 get no()  { return PARAMETER_TABLE_YES_NO.NO.ID; }
 get localCountry(){ return countryMaping.PERU;}

 /*
     generic functions
 */

 existInList(lista:Array<number>, id:number){
   for(var current of lista){
     if(current == id){
       return true;
     }
   }
   return false;
 }

 onKeyUpDNI(event: any, object:FormControl){
   var documentNumner = event.target.value;
   if(documentNumner.length != 8){
     object.setValue("");
     this.alertar("El DNI debe tener 8 caracteres.");//El DNI debe tener 8 dígitos
   }
 }

 onKeyUpOnliDecimal(event: any, object:FormControl){
   object.setValue(func.inputOnliDecimal(event.target.value));
 }

 onKeyOutOnlyNumericDecimal(event: any,object:FormControl){
   var valor:number = Number(event.target.value);
   var valorFormateado = func.formatNumber(valor);
   if( valorFormateado == "NaN" || valorFormateado == null){
     object.setValue("0.00");
   }else{
     object.setValue(valorFormateado);
   }
 }

 onKeyUpEmail(event: any, object:FormControl){
   object.setValue(func.inputEmail(event.target.value));
 }

 onKeyUpOnliNumeric(event: any, object:FormControl){
   object.setValue(func.inputOnliNumeric(event.target.value));
 }

 onKeyUpOnliText(event: any, object:FormControl){
   object.setValue(func.inputOnliText(event.target.value));
 }

 onKeyUpAplhaNumeric(event: any, object:FormControl){
   object.setValue(func.inputAlphaNumeric(event.target.value));
 }

 onKeyUpAplhaNumericSpace(event: any, object:FormControl){
   object.setValue(func.inputAlphaNumericSpace(event.target.value));
 }

 onKeyUpAplhaNumericSimple(event: any, object:FormControl){
   object.setValue(func.inputAlphaNumericSimple(event.target.value));
 }

 onKeyUpAplhaNumericSpecial(event: any, object:FormControl){
   object.setValue(func.inputAlphaNumericSpecial(event.target.value));
 }

 onKeyUpOnliTextAndSpace(event: any, object:FormControl){
   object.setValue(func.inputOnliTextAndSpace(event.target.value));
 }

 setMaxDateBirth() { 
   var today = new Date();
   var dd = String(today.getDate()).padStart(2, '0');
   var mm = String(today.getMonth() + 1).padStart(2, '0');
   var yyyy = (today.getFullYear()-18);
   
   this.currentData.maxDateBirth = yyyy + '-' + mm + '-' + dd ;
 }

 setMinDateBirth() { 
   var today = new Date();
   var dd = String(today.getDate()).padStart(2, '0');
   var mm = String(today.getMonth() + 1).padStart(2, '0');
   var yyyy = (today.getFullYear()-110);
   
   this.currentData.minDateBirth = yyyy + '-' + mm + '-' + dd ;
 }

 setMaxDateToday() { 
   var today = new Date();
   var dd = String(today.getDate()-1).padStart(2, '0');
   var mm = String(today.getMonth() + 1).padStart(2, '0');
   var yyyy = (today.getFullYear());
   
   this.currentData.maxDateToday = yyyy + '-' + mm + '-' + dd ;
   this.currentData.minDateCese = yyyy + '-' + mm + '-' + dd ;
 }


}
