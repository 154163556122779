import { Component, OnInit, ViewChild } from '@angular/core'; 
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient, HttpErrorResponse } from '@angular/common/http'; 
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ModalService } from 'src/app/configuration/providers/modal';
import { LoginService } from 'src/app/configuration/providers/SessionService/login.service';
import { userTo } from 'src/app/model/UserModel/userTo';
import { URL } from 'src/app/constants/url';
import { func } from 'src/app/constants/func';
import { ProductoFinancieroTo } from 'src/app/model/UserModel/ProductoFinancieroTo';
import { parameterTableTo } from 'src/app/model/UserModel/parameterTableTo';
import { ParameterService } from 'src/app/services/ParameterServices/parameter.service';
import { carritoDetalleTo } from 'src/app/model/UserModel/carritoDetalleTo';
import { MASTER_TABLE } from 'src/app/constants/masterTable';
import { countryMaping } from 'src/app/constants/countryMaping';
import { retencionTipoPersonaMapingTo } from 'src/app/constants/retencionTipoPersonaMapingTo';
import { responseProductsTo } from 'src/app/model/UserModel/responseProductsTo';
import { DomSanitizer } from '@angular/platform-browser';
import { produtOriginMaping } from 'src/app/constants/produtOriginMaping';
import { entity } from 'src/app/constants/entity';
import { SimplemodalComponent } from '../../AppComponents/simplemodal/simplemodal.component';
import { CURRENCY } from 'src/app/constants/CURRENCY';
import { viewAllCashAccountTo } from 'src/app/model/UserModel/viewAllCashAccountTo';


@Component({
  selector: 'ark-comprar',
  templateUrl: './comprar.component.html',
  styleUrls: ['./comprar.component.css']
})
export class ComprarComponent implements OnInit {

  productPrm : ProductoFinancieroTo =null ;//{} as ProductoFinancieroTo;
  nominal_value = null;

  currentProduct = new FormControl(''); 
  cantidad  = new FormControl('', Validators.required ); 
  submitted = false; 

  inversion = null;
  inversionTotal = null;

  tea:number = null;
  interesBrutoAnual:number = null;
  interesBrutototal:number = null;

  retencionAnual:number = null;
  retencionTotal:number = null;

  interesNetoAnual:number = null;
  interesNetoTotal:number = null;

  retencionSab:number = null;

  tipoComisionSab:number = null;

  lastQuantity:number = 0;

  parameterRetencionSab:Array<parameterTableTo> = [] as Array<parameterTableTo>;
  listProductoTopThree : Array<ProductoFinancieroTo> = [] as Array<ProductoFinancieroTo>;

  objUserTo = null;
  listRetencionTax = null;
  mapRetencionTax = new Map();

  @ViewChild(SimplemodalComponent,{static: false}) simplemodalComponent: SimplemodalComponent;
  
  constructor(private modalService: ModalService, 
              private httpClient: HttpClient,
              private loginService: LoginService,
              private parameterService: ParameterService,
              private  spinner: NgxSpinnerService,
              private router: Router, private domSanitizer: DomSanitizer) { }

    async ngOnInit() {
    
      this.spinner.show();
      this.objUserTo = <userTo>this.loginService.currentUser(); 

      await this.loadProductTop();
      await this.actionDefaultProduct();
      this.spinner.hide();
  }

  async actionDefaultProduct(){
    
    var productShopString = sessionStorage.getItem('productShop');

    if(productShopString != null && productShopString != "" && productShopString != "{}" && productShopString != "undefined" && productShopString != "null" ){
      var productShop:carritoDetalleTo = JSON.parse(productShopString);
      this.productPrm = productShop.productPrm;
      
      sessionStorage.setItem('product',JSON.stringify(this.productPrm));  
      this.cantidad.setValue(productShop.stock_quantity);
      await this.getParameterTable(); //va antes de la funcion calculate para que no se pierda las variables
      this.calculate();
    }else{
      if(sessionStorage.getItem('product')!=null && sessionStorage.getItem('product')!="undefined" && sessionStorage.getItem('product')!=""){
        this.productPrm = JSON.parse(sessionStorage.getItem('product'));
        await this.getParameterTable();
      }
    }

    this.nominal_value = (this.productPrm!=null)?func.formatNumber(this.productPrm.nominalValue):""; 

  }
  
  async loadProductTop(){ 

    var body = {
      "filterprm" : null,
      "top3" : false
    }
    
    this.spinner.show();
    this.httpClient.post(URL.SERVICE_API_V1_CORE_GET_VIEW_ALL_PRODUCTS,
              body,
        { headers: func.WSHeaderTokenCore() }). subscribe((response) => { 

        let productos = <responseProductsTo>response; 
        var newItems                 : Array<ProductoFinancieroTo>

        if(productos != null){
          newItems = productos.items; 
          this.listProductoTopThree = [] as Array<ProductoFinancieroTo>;

          for(var  item of newItems){
            if(item.labelProduct=='DISPONIBLE'){
              this.listProductoTopThree.push(item);
            }
          }
          
          console.log("this.listProductoTopThree::"+ JSON.stringify(this.listProductoTopThree) );
          if(this.listProductoTopThree != null && this.listProductoTopThree.length > 0){
            for(var i=0; i<this.listProductoTopThree.length; i++){
              this.listProductoTopThree[i].imgbase64 = "data:image/jpeg;base64,"+func.getImgWithIssuerCode(this.listProductoTopThree[i].issuerCode);
            }
          }

          
        }
      
      },
      (err: HttpErrorResponse) => {
        console.log(err.error);
        console.log(err.name);
        console.log(err.message);
        console.log(err.status);
        
      },() => {
          this.spinner.hide();
        }
    );

  }

  async gotoProductoCalculadora(){
    this.cleanCalculate();
    this.productPrm = this.currentProduct.value;
  }

  getProductWithName(issuanceName){
    for(var prod of this.listProductoTopThree){
      if(prod.issuanceName = issuanceName){
        return prod;
      }
      break;
    }
  }

  async getParameterTable(){
    let listBd  = await this.parameterService.getParameter(MASTER_TABLE.FACTOR_RETENCION_PERSONA);
    this.parameterRetencionSab = await this.parameterService.getParameter(MASTER_TABLE.TIPO_COMISION_SAB_CLIENTE) as Array<parameterTableTo>;
    this.listRetencionTax =  listBd as Array<parameterTableTo>; 
    this.mapRetencionTax  = func.convertParameterListInMap1(this.listRetencionTax);

    for( var i =0; i < this.parameterRetencionSab.length; i++ ){
      this.tipoComisionSab += this.parameterRetencionSab[i].decimal_amount1;
    }
    
  }

  gotoPage(page: String){
    this.router.navigate([page]);
  }
 
  onKeyNumber(event: any){  
      this.cantidad.setValue(func.validateImputEntero(event.target.value)); 
  }

  formatNumber(input){
    return func.formatNumber(input);
  }

  cleanCalculate(){
    
    this.interesBrutoAnual = null;
    this.interesBrutototal = null;

    this.retencionAnual = null;
    this.retencionTotal = null;

    this.interesNetoAnual = null;
    this.interesNetoTotal = null;
    
    this.retencionSab = null;
    this.inversionTotal = null;
    this.available_balance = null;
    
    this.claseInteres = "";
    this.productTax = 0;
    this.cantidadInstrumentos = 0;

    this.cantidad.setValue(null);
  }

  claseInteres:String = ""
  productTax:number = 0;
  cantidadInstrumentos = 0;

  calculate(){

    
    if(this.cantidad != null && this.cantidad.value != '' && this.cantidad.value >= 0){   

      if( this.cantidad.value < (this.productPrm.minimumInvesment/this.productPrm.nominalValue) ){
        this.simplemodalComponent.alertar("Mensaje de Alerta","El número de instrumentos financieros es menor a la inversión mínima. Verifique por favor.");
        return ;
      }

      if( (this.cantidad.value * this.productPrm.nominalValue) > this.productPrm.issuanceAmount ){
        this.simplemodalComponent.alertar("Mensaje de Alerta","El número de instrumentos no puede superar la emisión. Verifique por favor.");
        return ;
      }
      
      this.cantidadInstrumentos = this.cantidad.value;
      this.claseInteres = this.getClaseInteres(this.productPrm.cashNominal);
      this.productTax = this.productPrm.tax;
      
      if(this.productPrm.currency == 'USD'){
        this.loadEfectivosDolares();
      }else if(this.productPrm.currency == 'PEN'){
        this.loadEfectivosSoles();
      }

      this.lastQuantity = this.cantidad.value;
    
      let cantidad_anios:number = this.productPrm.term;
      let tasaInteres:number = this.productPrm.tax;
      let tasaRetencion:number = 1;
      this.inversion = this.cantidad.value * this.productPrm.nominalValue;
  
      if(this.objUserTo.nationality == countryMaping.PERU){
        tasaRetencion = parseFloat(this.mapRetencionTax.get(retencionTipoPersonaMapingTo.NACIONAL));
      }else{
        tasaRetencion = parseFloat(this.mapRetencionTax.get(retencionTipoPersonaMapingTo.EXTRANJERO));
      }
  
      this.interesBrutoAnual = this.inversion * (tasaInteres/100);
      this.interesBrutototal = this.interesBrutoAnual * cantidad_anios;
  
      this.retencionAnual = this.interesBrutoAnual * (tasaRetencion/100);
      this.retencionTotal = this.retencionAnual * cantidad_anios;
  
      this.interesNetoAnual = this.interesBrutoAnual - this.retencionAnual;
      this.interesNetoTotal = this.interesBrutototal - this.retencionTotal;

      this.retencionSab = this.inversion * (this.tipoComisionSab/100);
      this.inversionTotal = this.inversion ;
      
      
    }else{
      this.cleanCalculate();
    }

  }

  
  respontCashAccountTo : viewAllCashAccountTo = {} as viewAllCashAccountTo;
  available_balance = 0;

  loadEfectivosDolares(){

    this.spinner.show(); 

    let urlViewCashAccount = URL.SERVICE_API_V1_CORE_GET_VIEW_GROUP_CASH_ACCOUNT;
    let objUserTo = <userTo>this.loginService.currentUser();
    var body = {
      "rut": ""+objUserTo.rut,
      "currency": ""+CURRENCY.DOLARES.ID,
    }

    this.httpClient.post(urlViewCashAccount, body , {  headers: func.WSHeaderTokenCore() })
        . subscribe(
              (response) => {  
                  
                  this.respontCashAccountTo = <viewAllCashAccountTo>response; 
                  this.available_balance =  this.respontCashAccountTo.availableBalance;
                  this.spinner.hide();
                
              },
              (err: HttpErrorResponse) => {
                console.log(err.error);
                console.log(err.name);
                console.log(err.message);
                console.log(err.status);
                
                this.available_balance =  0;

                this.spinner.hide();
            },
            () => {
              this.spinner.hide();
              //console.log("************* this.httpClient.post ()::" + "The get mi efectivo now completed.");
            }
      ); 

  }

  loadEfectivosSoles(){

    this.spinner.show();

    let urlViewCashAccount = URL.SERVICE_API_V1_CORE_GET_VIEW_GROUP_CASH_ACCOUNT;
    let objUserTo = <userTo>this.loginService.currentUser();
    var body = {
      "rut": ""+objUserTo.rut,
      "currency": ""+CURRENCY.SOLES.ID,
    }
    
    this.httpClient.post(urlViewCashAccount, body , {  headers: func.WSHeaderTokenCore() })
      .subscribe(
              (response) => {
                
                  this.respontCashAccountTo = <viewAllCashAccountTo>response;  
                  this.available_balance = this.respontCashAccountTo.availableBalance;

                  this.spinner.hide();
              },
              (err: HttpErrorResponse) => {
                console.log(err.error);
                console.log(err.name);
                console.log(err.message);
                console.log(err.status);
                
                this.available_balance = 0;
                this.spinner.hide();
            },
            () => {
              this.spinner.hide();
              //console.log("************* this.httpClient.post ()::" + "The get mi efectivo now completed.");
            }
      ); 

  }



  onSubmit(){
    this.spinner.show();
    
    if(this.available_balance < this.inversionTotal){
      this.simplemodalComponent.alertar("Mensaje de Alerta","El efectivo disponible no es suficiente para agregar al carrito. Verifique por favor.");
      this.spinner.hide();
      return;
    }
    
    this.submitted = true;


    var listCarritoDetalleTo:Array<carritoDetalleTo> = <Array<carritoDetalleTo>>JSON.parse(sessionStorage.getItem('listCarritoDetalleTo'));

    if(listCarritoDetalleTo == null){
      listCarritoDetalleTo = [] as Array<carritoDetalleTo>;
    }

    let carritoDetalleTo:carritoDetalleTo = {} as carritoDetalleTo;
    carritoDetalleTo.interesBrutoAnual = this.interesBrutoAnual;
    
    carritoDetalleTo.interesBrutototal = this.interesBrutototal;
    carritoDetalleTo.retencionAnual = this.retencionAnual;
    carritoDetalleTo.retencionTotal = this.retencionTotal;
    carritoDetalleTo.interesNetoTotal = this.interesNetoTotal;
    carritoDetalleTo.retencionSab = this.retencionSab;
    carritoDetalleTo.inversionTotal = this.inversionTotal;
    carritoDetalleTo.productPrm = this.productPrm;
    carritoDetalleTo.valornNominal = carritoDetalleTo.productPrm.nominalValue;
    carritoDetalleTo.plazoEmision = this.productPrm.term;
    carritoDetalleTo.medidaTiempo = this.productPrm.termTime;
    
    
    carritoDetalleTo.id_security_code_pk  = carritoDetalleTo.productPrm.idSecurityCodePk;
    carritoDetalleTo.emisorDescripcion  = carritoDetalleTo.productPrm.issuerName ;
    carritoDetalleTo.stock_quantity = this.cantidad.value ;
    carritoDetalleTo.security_description = carritoDetalleTo.productPrm.issuanceName;
    carritoDetalleTo.subtotal_payment = this.inversionTotal;
    carritoDetalleTo.moneda = carritoDetalleTo.productPrm.currency;

    listCarritoDetalleTo.push(carritoDetalleTo);
    console.log(JSON.stringify(carritoDetalleTo.productPrm));
    sessionStorage.setItem('listCarritoDetalleTo', JSON.stringify(listCarritoDetalleTo));

    //this.spinner.hide();
    
    if (this.cantidad.invalid ) {
      this.spinner.hide();
      return;
    } 
    if(this.lastQuantity != this.cantidad.value){
      this.simplemodalComponent.alertar("Mensaje de Alerta","Debe volver a Calcular los montos para poder continuar.");
      this.spinner.hide();
      return;
    }
    
    this.spinner.hide();
    this.modalService.open('idModalAgregado');

  }

  closeModal(id: string) {
    this.modalService.close(id);
  }
 

  /***************/

  iraCarrito(){
    this.closeModal('idModalAgregado');
    this.gotoPage('/carrito');
  }
  
  formatFecha(date:String){
    return func.formatFechaDBtoUi(date); 
  }

 
  gotoProductoDetalleInversion(product: ProductoFinancieroTo){
    sessionStorage.setItem('menuOrigin', JSON.stringify(produtOriginMaping.INVERSIONES));
    sessionStorage.setItem('product',JSON.stringify(product));
    this.router.navigate(["/productInformacion"]); // /detalleInversion
    
  }
 

  safeImagen(imagenBase64){ 
    return this.domSanitizer.bypassSecurityTrustUrl(imagenBase64);
  }

  getClaseInteres(cashNominal: number){
    return func.getClaseInteres(cashNominal); 
  }
  
  
  openTerminos(){
    this.simplemodalComponent.openModalInfo();
    //this.modalService.open('idTerminos');  
  }
  /*
  closeTerminos(file_code: String){ 
    this.modalService.close('idTerminos');
  }
  */
}
